import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import BasicHeader from "../common/BasicHeader";
import {useTermsOfPrivacy} from "../../hooks/guideline/useTermsOfPrivacy";
import {useTermsOfUse} from "../../hooks/guideline/useTermsOfView";
import {HtmlToReact} from "./TermsOfUseView";


export default function PrivacyPolicyView(){

    const {termsOfPrivacy} = useTermsOfPrivacy()
    const navigate = useNavigate()
    const goBack = useCallback(() =>{
        navigate(-1)
    },[navigate])

    return (
        <section>
            <div className="flex flex-col items-center">
                <div className="w-screen ml-18pxr">

                </div>
                <HtmlToReact html={termsOfPrivacy?.html || ""} />
            </div>
        </section>
    )

}