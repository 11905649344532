
import {Card} from "../common";
import RequestModalView from "../common/RequestModalView";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useOnScreen} from "../../hooks/common/useOnScreen";
import {useWholeLobbyistList} from "../../hooks/main/useLobbyistWholeList";

export default function LobbyistWholeListView(){
    const { lobbyists, hasNextPage, fetchNextPage, isFetchingNextPage } = useWholeLobbyistList();

    const [isOpenModal, setOpenModal] = useState<boolean>(false);
    const [selectedLobbyistId, setSelectedLobbyistId] = useState<string | null>(null);
    const onClickToggleModal = useCallback((lobbyistId:string) => {
        setOpenModal(isOpenModal => !isOpenModal);
        setSelectedLobbyistId(lobbyistId);
    }, [selectedLobbyistId,isOpenModal]);

    const loadMoreRef = useOnScreen<HTMLDivElement>(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    });

    const lobbyistCard = lobbyists?.map((lobbyist,index) => {
        return (
            <Card lobbyist={lobbyist} openModal={() => onClickToggleModal(lobbyist._id)} className="relative" key={`${lobbyist._id}-${index}`}/>
        )
    })


    return (
        <section>
            <div className={`flex flex-wrap items-start justify-center w-full pt-10pxr md:pt-10pxr`}>
                    {lobbyistCard}
                    <div ref={loadMoreRef}></div>
                    {isOpenModal && selectedLobbyistId &&  <RequestModalView onClickRequestModal={() => onClickToggleModal(selectedLobbyistId)} key={selectedLobbyistId} lobbyistId={selectedLobbyistId}/>}

                </div>
        </section>
    )
}