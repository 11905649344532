
import {Card} from "../common";
import RequestModalView from "../common/RequestModalView";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLobbyistList} from "../../hooks/main/useLobbyistList";
import {MyCard} from "../common/MyCard";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {useUserContext} from "../../hooks/common/UserContext";

export default function LobbyistListView(){
    const { lobbyists , isLoading} = useLobbyistList();
    const {user} = useUserContext()
    const {userInformation} = useUserInformation()
    const [isOpenModal, setOpenModal] = useState<boolean>(false);
    const [selectedLobbyistId, setSelectedLobbyistId] = useState<string | null>(null);
    const onClickToggleModal = useCallback((lobbyistId:string) => {
        setOpenModal(isOpenModal => !isOpenModal);
        setSelectedLobbyistId(lobbyistId);
    }, [selectedLobbyistId,isOpenModal]);



    const lobbyistCard = lobbyists?.map((lobbyist,index) => {
        return (
            <Card lobbyist={lobbyist} openModal={() => onClickToggleModal(lobbyist._id)} className="relative" key={`${lobbyist._id}-${index}`}/>
        )
    })


    return (
        <section>
            {!isLoading ? (
                <div className={`flex flex-wrap items-start justify-center w-full pt-10pxr md:pt-10pxr`}>
                    {user ? (
                        <MyCard userInformation={userInformation}/>
                    ) : null }
                    {lobbyistCard}
                    {isOpenModal && selectedLobbyistId &&  <RequestModalView onClickRequestModal={() => onClickToggleModal(selectedLobbyistId)} key={selectedLobbyistId} lobbyistId={selectedLobbyistId}/>}

                </div>

            ):null}
        </section>
    )
}