import React, {useCallback, useMemo, useState} from "react";
import {Column, usePagination, useTable, Row, UsePaginationInstanceProps, TableInstance , TableState ,UsePaginationState} from "react-table";
import {LobbyistActivationIcon} from "./Component/LobbyistActivationIcon";
import {Title} from "../components/common";
import {useAdminWholeMember} from "./hooks/useAdminWholeMember";
import {useAdminOneToOneRequest} from "./hooks/useAdminOneToOneRequest";
import {AdminOneToOneRequest, IAdminMegaphoneRequest} from "./types";
import DateRangePicker from "./Component/DateRangePicker";
import {RequestDetailModal} from "../components/history/RequestDetailModal";
import {AdminRequestDetailModal} from "./Component/AdminRequestDetailModal";


interface CustomTableInstance extends TableInstance<AdminOneToOneRequest>, UsePaginationInstanceProps<AdminOneToOneRequest> {}
export default function OneToOneRequestsManagement(){

    const {
        requests,
        isLoading,
        isFetching,
        isError,
        error,
        pageIndex,
        maxPage,
        updatePageIndex,
        hasNextPage,
        updateLobbyStatus,
        lobbyStatus,
        updateCreatedStartedAt,
        updateCreatedEndedAt,
    } = useAdminOneToOneRequest();

    const [isRequestDetailModal, setIsRequestDetailModal] = useState<boolean>(false);
    const onClickToggleRequestDetailModal = useCallback(() => {
        setIsRequestDetailModal(isRequestDetailModal => !isRequestDetailModal);
    }, [isRequestDetailModal])


    const [selectedRequestId, setSelectedRequestId] = useState<string>("")
    const data = useMemo<AdminOneToOneRequest[]>(() =>
            requests

        , [isFetching]);
    const columns = useMemo(
        () => [
            { Header: "로비 ID", accessor: "_id" },

            { Header: "소개비", accessor: "introducePrice"},
            { Header: "로비진행상태", accessor: "lobbyStatus"},
            { Header: "로비스트 이메일", accessor: "lobbyistEmail" },
            { Header: "로비스트 닉네임", accessor: "lobbyistNickName" },
            { Header: "의뢰인 닉네임", accessor: "clientNickName" },
            { Header: "확인 여부", accessor: "readStatus", Cell: ({ value }: { value: boolean }) => <div> {value ? "예": "아니오"} </div>,},
            {
                Header: "",
                accessor:"_id",
                id: "details",
                Cell: ({ row, value }: { row: Row<IAdminMegaphoneRequest>, value: string }) => (
                    <div className="flex flex-row">
                        <button
                            className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`}
                            onClick={async () => {
                                await setSelectedRequestId(value)
                                onClickToggleRequestDetailModal()
                            }
                            }>상세 내용 보기</button>
                    </div>
                ),
            },
            // { MainHeader: "소개비결제상태", accessor: "status" },
            // { MainHeader: "의뢰내용", accessor: "content" },
            // { MainHeader: "소개비 권한/조치", accessor: "introduceCostManagement" },
        ],
        []
    );
    const initialState: Partial<TableState<AdminOneToOneRequest> & UsePaginationState<AdminOneToOneRequest>> = {
        pageIndex: 0,
        pageSize: 10,
    };


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageCount,
        prepareRow,
    } = useTable<AdminOneToOneRequest>(
        {
            columns: columns as Column<AdminOneToOneRequest>[],
            data,
            initialState: initialState,
        },
        usePagination
    ) as CustomTableInstance;


    return (
        <div className="flex flex-col items-center members mt-25pxr">
            <Title>일반 의뢰 관리</Title>


            <DateRangePicker
                onStartDateChange={(date) => updateCreatedStartedAt(date)}
                onEndDateChange={(date) => updateCreatedEndedAt(date)}
            />



            <div className="flex flex-row justify-end  items-end w-500pxr">
                <div>
                    로비 진행 상태
                    <select
                        className="border mx-10pxr mt-25pxr"
                        value={lobbyStatus || ""}
                        onChange={(e) => {
                            updateLobbyStatus(e.target.value as "request" | "complete" | "cancel" | null);
                        }}
                    >
                        <option value="">전체</option>
                        <option value="request">의뢰중</option>
                        <option value="complete">완료</option>
                        <option value="cancel">취소</option>
                    </select>
                </div>

            </div>
            <table {...getTableProps()} className="members-table  mx-auto ">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()} className="px-15pxr py-15pxr border-b-4">
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row: Row<AdminOneToOneRequest>) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td {...cell.getCellProps()} className="px-15pxr py-15pxr border-b-2">
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="flex flex-row pagination space-x-100pxr mt-50pxr">
                <button
                    onClick={() => updatePageIndex(-1)}
                    disabled={pageIndex === 1}
                    className="mx-6pxr"
                >
                    <img className="h-25pxr" src="/left-arrow.png"/>
                </button>
                <div>
                    {pageIndex} / {maxPage}
                </div>
                <button
                    onClick={() => updatePageIndex(+1)}
                    disabled={!hasNextPage}
                    className="mx-6pxr"
                >
                    <img className="h-25pxr" src="/right-arrow.png"/>
                </button>
            </div>
            {isRequestDetailModal &&
            <AdminRequestDetailModal onClickToggleRequestDetailModal={onClickToggleRequestDetailModal} lobbyId={selectedRequestId}/>
            }
        </div>
    );
}