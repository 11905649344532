import {UseMutateFunction, useMutation, useQueryClient} from "react-query";
import {IUser, PostRequestFormData} from "../../types/types";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import axios, {AxiosRequestConfig} from "axios";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {useUserContext} from "../common/UserContext";

export function useWriteCommunityPost(
    goBack: () => void)
    : UseMutateFunction<void, unknown, PostRequestFormData, unknown> {

    async function writeCommunity(postRequestData: PostRequestFormData, user: IUser | null,): Promise<void> {
        const config: AxiosRequestConfig = {};
        if (user){
            config.headers = getJWTHeader(user);
            await axiosInstance.post(`/user/post/`,postRequestData, config);
        }
    }




    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate} = useMutation((postRequestFormData:PostRequestFormData) => writeCommunity(postRequestFormData, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.community])
                goBack()
            }
        })

    return mutate
}
