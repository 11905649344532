import {Avatar, DivProps, HashTag, Paragraph, Subtitle, Title} from "../common";
import React, {FC, ReactElement, useCallback, useState} from "react";
import {ILobbyist} from "../../types/types";
import {formatCurrency} from "../../utils/data/helper";

export type MegaphoneCardProps = DivProps & {
    lobbyist: ILobbyist| null
}

export const MegaphoneCard: FC<MegaphoneCardProps> = ({className: _className,lobbyist,children,...props }) =>{

    const filteredPrices = lobbyist?.keywords
        ? lobbyist.keywords.filter(keyword => keyword.price > 0)
        : [];

    const leastPrice = filteredPrices.length > 0
        ? Math.min(...filteredPrices.map(keyword => keyword.price))
        : 0;


    const hashTags = lobbyist?.keywords ? lobbyist?.keywords.map((keyword,index) =>{
        return(
            <section key={index}>
                <div className="relative inline-block group">
                    <span className="cursor-pointer">
                        <HashTag  key={keyword.word} hashTag={keyword.word}/>
                    </span>

                    <div className="hidden group-hover:block absolute left-0 w-200pxr md:w-300pxr text-gray-700 rounded-xl bg-[#eaeaea] z-50">
                        <div className="flex flex-col text-[13px] text-[#555555] font-semibold px-15pxr py-12pxr">
                            <div>| 소개비: {formatCurrency(keyword.price)}원</div>
                            <div>| 설명: {keyword.detail}</div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }):[]


    return (
        <section key={props.key} className="p-10pxr">
            <div className={`flex flex-col justify-start pl-5pxr md:pl-20pxr min-h-[145px] md:min-h-[180px] w-340pxr md:w-420pxr border border-1 rounded-xl overflow-visible shadow-md `}>
                <div className="flex items-center justify-center h-5pxr md:h-18pxr"/>
                <div className={`flex flex-row  m-10pxr md:m-0pxr`}>

                    <div className={`flex flex-col`}>
                        <Avatar src={lobbyist?.profileImage} />

                    </div>

                    <div className={`flex flex-col  items-start ml-6pxr md:ml-14pxr`}>
                        <div className={`flex flex-row justify-between w-258pxr md:w-320pxr`}>
                            <div className={`flex flex-row items-end text-black`}>
                                <Subtitle numberOfLines={1}>{lobbyist?.nickName}</Subtitle>
                                <div className="text-[13px] font-semibold ml-3pxr md:ml-5pxr mt-16pxr md:mt-20pxr text-black">
                                    | {formatCurrency(leastPrice)}원 ~
                                </div>
                            </div>
                            <div>
                                {children}
                            </div>
                        </div>
                        <div className="my-8pxr md:my-12pxr mr-15pxr md:w-300pxr flex flex-wrap flex-row">
                            {hashTags}
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}
