import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IDetailLobbyistResponse, ILobbyist, ILobbyistResponse, IUser} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserContext} from "../common/UserContext";

async function getLobbyistSearchList(user: IUser | null, page: number, query:string): Promise<ILobbyistResponse> {
    const config: any = {};

    config.params = {
        page: page,
        limit: 12,
    };
    if (user){
        config.headers = getJWTHeader(user)
    }
    const { data }: AxiosResponse<ILobbyistResponse> = await axiosInstance.get(`/user/search/keyword?keyword=${query}`, config);
    if (data.count > page * 12) {
        data.nextPage = page + 1;
    }
    return data;
}

interface UseLobbyistList {
    searchedLobbyists: ILobbyist[] | undefined;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
    hasNextPage:boolean
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
}


export function useLobbyistSearchList(query:string) :UseLobbyistList{
    const { user, updateUser, clearUser } = useUserContext();

    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
    } = useInfiniteQuery<ILobbyistResponse>(
        [queryKeys.lobbyists,query],
        ({ pageParam = 1 }) => {
            if (pageParam) {
                return getLobbyistSearchList(user, pageParam, query)
            }else{
                return Promise.reject("No more pages");
            }
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.nextPage || false;
            },
            retry:0,
            refetchOnWindowFocus: true,
            staleTime: 0,
            cacheTime: 0,
        }
    );

    const hasNextPage = !!(
        data &&
        data.pages.length &&
        data.pages[data.pages.length - 1]?.nextPage
    );


    // 모든 페이지의 게시물을 단일 배열로 펼칩니다.
    const searchedLobbyists = data?.pages.flatMap((pageData) => pageData.rows) || [];

    return {
        searchedLobbyists,
        isLoading,
        isFetching,
        isError,
        error,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,};
}