import React, { useState, createContext, Dispatch, SetStateAction, ReactNode } from 'react';

type HeaderType = "main" | "request" | "chat";
type HeaderContextType = [HeaderType, Dispatch<SetStateAction<HeaderType>>];

export const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

interface IProps {
    children: ReactNode;
}

export const HeaderProvider: React.FC<IProps> = ({ children }) => {
    const [selectedHeader, setSelectedHeader] = useState<HeaderType>("main");

    return (
        <HeaderContext.Provider value={[selectedHeader, setSelectedHeader]}>
            {children}
        </HeaderContext.Provider>
    );
};
