import {DivProps} from "./Div";
import {FC} from "react";
import {IUserNotification} from "../../types/types";
import {timeAgo} from "../../utils/data/helper";

export type AlertViewProps = DivProps & {
    notification: IUserNotification
}

export const AlertView:FC<AlertViewProps> = ({className:_className,notification, ...props}) =>{


    return (
        <div className={`flex flex-col px-13pxr md:px-13pxr py-9pxr md:py-18pxr`}>
            <div className={`flex flex-row items-center text-[10px]  md:text-[15px] font-semibold line-clamp-1 whitespace-pre-wrap break-words w-150pxr md:w-220pxr`}>
                <div className="block ">{notification.title}</div>
            </div>
            <div className={`flex flex-row justify-between font-normal md:pt-5pxr text-[#b4b4b4]`}>
                <div className={`text-[10px] md:text-[15px] line-clamp-1 whitespace-pre-wrap break-words w-100pxr md:w-220pxr`}>{notification.content}</div>
                <div className={`text-[10px] md:text-[13px]`}> {timeAgo(notification.createdAt)}</div>
            </div>
        </div>
    )
}