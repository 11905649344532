import MainHeader from "../main/MainHeader";
import {useChatList} from "../../hooks/chat/useChatList";
import {AlertModal, Avatar} from "../common";
import {regaxNormal, timeAgo} from "../../utils/data/helper";
import {IChatList} from "../../types/types";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import BasicHeader from "../common/BasicHeader";
import {HeaderContext} from "../main/HeaderContext";
import ChatHeader from "../main/ChatHeader";
import {useDeleteComment} from "../../hooks/community/useDeleteComment";
import {useSocket} from "../../hooks/chat/SocketProvider";
import {QueryClient} from "react-query";
import {queryClient} from "../../services/react-query/queryClient";
import {queryKeys} from "../../services/react-query/constants";


export default function ChatMain(){
    const {chatLists,isLoading} = useChatList()
    const [searchLobbyistWord, setSearchLobbyistWord] = useState<string>("")
    const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false);
    const [selectedRoomId, setSelectedRoomId] = useState<string>("");
    const onClickToggleDelete = useCallback((roomId:string) => {
        setSelectedRoomId(roomId)
        setIsDeleteModal(isDeleteModal => !isDeleteModal);

    }, [isDeleteModal]);
    const { socket } = useSocket();
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.trim();
        if (regaxNormal(300).test(inputValue)) {
            setSearchLobbyistWord(inputValue)
        }
    };



    const navigate = useNavigate()

    const goChat = useCallback((roomId:string, partnerProfileImage:string, partnerNickname:string)=>{
        window.scroll(0,0)
        navigate(`/chat/${roomId}`,{
            state: {
                imagePath: partnerProfileImage,
                partnerNickname: partnerNickname,
            }
        })
    },[navigate])


    const headerContext = useContext(HeaderContext);
    if (!headerContext) {
        throw new Error("This component must be used within a <HeaderProvider> component");
    }
    const [selectedHeader, setSelectedHeader] = headerContext;

    useEffect(() => {
        queryClient.invalidateQueries([queryKeys.chatList])
    },[socket])

    useEffect(()=> {
        setSelectedHeader("chat")
    })
    const chatListCard = chatLists?.filter(chatRow => {
        // if searchLobbyistWord is empty or it's the same as the partnerNickName, we should keep the chat
        return searchLobbyistWord === "" || chatRow.partnerNickName.toLowerCase().includes(searchLobbyistWord.toLowerCase())
    }).map((chatRow, index) => {
        return (
            <div className="flex flex-col mt-20pxr">
                <div className="flex justify-end">
           <button onClick={() => onClickToggleDelete(chatRow._id)} className="flex justify-end text-[#b4b4b4] text-[10px]">삭제</button></div>
            <div key={chatRow._id} onClick={() => {
                goChat(chatRow._id, chatRow.partnerProfileImage,chatRow.partnerNickName)
                chatRow.unreadChatCount = 0
            }} className="flex flex-row justify-between w-400pxr ">

                <div className="flex flex-row items-center">

                    <Avatar src={chatRow.partnerProfileImage}/>
                    <div className="flex flex-col text-[15px] font-semibold pl-10pxr">
                        <div>{chatRow.partnerNickName}</div>
                        <div className="text-[#b4b4b4] max-w-[250px] line-clamp-2">{chatRow.lastChatContent}</div>

                    </div>
                </div>


                <div className="flex flex-col text-[15px] items-end text-[#b4b4b4] font-semibold">

                    <div>{timeAgo(chatRow.lastChattedAt)}</div>
                        {chatRow.unreadChatCount !== 0 && (
                            <div className="relative rounded-full bg-[#ff4d36] w-24pxr h-24pxr mt-5pxr">
                            <div className="absolute top-2pxr right-7pxr text-white">{chatRow.unreadChatCount}</div>
                            </div>
                        )}



                </div>
            </div>
            </div>
        )
    })



    return (
        <>
            <div>
                <ChatHeader/>
                <div className="flex flex-col items-center justify-center">
                    <div className={`flex flex-row items-center`}>
                        <input onChange={handleSearchChange} className={`p-10pxr md:p-20pxr h-30pxr md:h-45pxr w-400pxr mt-22pxr md:mt-42pxr rounded-lg border border-gray-500 text-[9px] md:text-[15px]  bg-white focus:outline-none`} placeholder={`로비스트의 닉네임을 입력하세요`} type={`search`}/>
                        <button className={`-ml-40pxr md:-ml-68pxr mt-22pxr md:mt-42pxr rounded-lg bg-white h-15pxr md:h-38pxr w-30pxr md:w-62pxr flex items-center justify-center`}><img className={`h-15pxr md:h-32pxr w-18pxr md:w-38pxr`} src={`../../light-search.png`}/></button>
                    </div>
                {chatListCard}

                </div>

            </div>

            {
                isDeleteModal && (
                    <AlertModal>
                        <div className={`flex flex-col items-center justify-center`}>
                            <div className={`text-[16px] md:text-[24px] mt-40pxr md:mt-50pxr text-black font-semibold `}>삭제하시겠습니까?</div>
                            <div className={`text-[10px] md:text-[13px] mt-36pxr md:mt-45pxr text-[#5d5d5d] font-semibold`}>삭제하시면 삭제한 대화가</div>
                            <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>사라지며 복구할 수 없습니다.</div>
                            <div className={`text-[10px] md:text-[13px] mt-10pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>정말 삭제하시겠습니까?</div>

                            <div className={`flex flex-row mt-25pxr md:mt-64pxr`}>
                                <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-[#e0e0e0] text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={() => onClickToggleDelete(selectedRoomId)}>취소</button>
                                <button onClick={() => {
                                    socket?.emit("exit",{ roomId: selectedRoomId })
                                    queryClient.invalidateQueries([queryKeys.chatList])
                                    onClickToggleDelete(selectedRoomId)
                                }} className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`}>확인</button>
                            </div>
                        </div>
                    </AlertModal>
                )
            }

        </>
    )
}