import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IDetailLobbyistResponse, ILobbyist, ILobbyistResponse, IUser} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {useEffect} from "react";
import {useUserContext} from "../common/UserContext";

async function getWholeLobbyistList(user: IUser | null,page:number, ): Promise<ILobbyistResponse> {
    const config: AxiosRequestConfig = {};

    config.params = {
        page: page,
        limit: 12,
    };
    if (user){
        config.headers = getJWTHeader(user)
    }
    const { data }: AxiosResponse<ILobbyistResponse> = await axiosInstance.get(`/user/search/list`, config);

    if (data.count > page * 12) {
        data.nextPage = page + 1;
    }
    return data;
}

interface UseLobbyistList {
    lobbyists: ILobbyist[] | undefined;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
    hasNextPage: boolean;
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;

}


export function useWholeLobbyistList() :UseLobbyistList{
    const { user, updateUser, clearUser } = useUserContext();

    const {data, isLoading, isFetching, isError, error, fetchNextPage, isFetchingNextPage,} = useInfiniteQuery<ILobbyistResponse>(
        [queryKeys.wholeLobbyist],
        ({ pageParam = 1 }) => {
            if (pageParam){
                return getWholeLobbyistList(user, pageParam)
            }else{
                return Promise.reject("No more pages");
            }
        },
        {
            getNextPageParam: (lastPage) => lastPage?.nextPage,
        }
    );

    const hasNextPage = !!(
        data &&
        data.pages.length &&
        data.pages[data.pages.length - 1]?.nextPage
    );


    const lobbyists = data?.pages.flatMap((pageData) => pageData.rows) || [];

    // 모든 페이지의 게시물을 단일 배열로 펼칩니다.

    return {
        lobbyists,
        isLoading,
        isFetching,
        isError,
        error,
        hasNextPage: hasNextPage ?? false,
        fetchNextPage,
        isFetchingNextPage,
    };
}