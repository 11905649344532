
import {axiosInstance, } from "../../services/axiosInstance";
import {ImageUrlResponse,} from "../../types/types";
import {UseMutateFunction, useMutation, useQuery} from "react-query";
import {queryKeys} from "../../services/react-query/constants";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {queryClient} from "../../services/react-query/queryClient";
import {AdminBannerRegistrationFormData, IAdminCommunityBanner, IAdminUser} from "../types";
import {useAdminUserContext} from "../AdminUserProvider";


export function useAdminCommunityBannerDelete(
    onSuccess:() => void,
) : UseMutateFunction<void, unknown, IAdminCommunityBanner, unknown> {

    async function setAdminBannerDelete(adminCommunityBanner: IAdminCommunityBanner, adminUser: IAdminUser | null,): Promise<void> {


        const config: AxiosRequestConfig = {};
        if (adminUser){
            config.headers = { authorization: `${adminUser.accessToken}` }
            await axiosInstance.delete(`/admin/banner/${adminCommunityBanner._id}`, config,);
        }
    }


    const { adminUser,setAdminUser } = useAdminUserContext();

    const {mutate} = useMutation((adminCommunityBanner: IAdminCommunityBanner) => setAdminBannerDelete(adminCommunityBanner, adminUser),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.adminCommunityBanner])
                onSuccess()
            }
        })

    return mutate
}

