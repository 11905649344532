
import {ChangeEvent, FC, useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DivProps} from "../common";
import {makeArray} from "../../utils/data/helper";

type BlockNetworkProps = DivProps & {
    networkValue:string
    getValue:(value:string) => void
    onRegister: () => void
    isLoading: boolean
    loadingToggle: () => void
}

export const BlockNetwork:FC<BlockNetworkProps> = ({networkValue, getValue ,onRegister,loadingToggle,isLoading}) => {
    const navigate = useNavigate()


    const network = ["해 본 사람","학생","유학생","장인","거주자","작가","인플루언서","합격자","통역사","직접입력"]
    const checked = makeArray(network.length).fill(false)
    const [isChecked, setIsChecked] = useState(checked)


    const goNext = useCallback(() =>{
        navigate("/auth/network")
        },[navigate])


    const onClick = useCallback((position:number) => {
        let updatedCheckedState = isChecked.fill(false)
        updatedCheckedState = updatedCheckedState.map((item, index) =>
            index === position && (item = true)
        )
        networkValue = network[position]
        getValue(networkValue)
        setIsChecked(updatedCheckedState)
    },[isChecked])

    // const handleButton = isChecked.filter((value) => value).length === 0

    const jobs = network.map((item,index) => {
        return(
            <button key={index} className={`flex w-80pxr h-80pxr md:w-100pxr md:h-100pxr`} onClick={() => onClick(index)}>
                <div className={`flex flex-row justify-end`}>

                    <div className={`relative p-5pxr flex flex-row items-center justify-center w-78pxr h-78pxr md:w-95pxr md:h-95pxr  rounded-xl  bg-black`}>
                        <div className={`flex flex-col justify-center items-center md:w-95pxr  text-[12px] md:text-[15px]  text-white font-extrabold`}>
                            {index === 9 ? (network[index]) : (`oo${network[index]}`)}
                            </div>
                        <div className={`absolute h-18pxr w-18pxr md:h-28pxr md:w-28pxr top-5pxr right-6pxr md:top-5pxr md:right-5pxr bg-gray-300 rounded-full`} />
                        {isChecked[index] && (<div className={`absolute h-14pxr w-14pxr md:h-20pxr md:w-20pxr top-7pxr right-8pxr  md:top-9pxr md:right-9pxr bg-black rounded-full`} />)}
                        {isChecked[index] && (<div className={`absolute h-14pxr w-14pxr md:h-20pxr md:w-20pxr top-7pxr right-8pxr  md:top-9pxr md:right-9pxr bg-black rounded-full`} />)}
                        </div>

                </div>
            </button>
        )

    })

    return (
        <section>
            <div className={`flex flex-wrap w-340pxr md:w-600pxr`}>
                {jobs}
            </div>
            <div className={`flex justify-center md:justify-end mt-47pxr md:mt-76pxr mb-27pxr md:mb-262pxr`}>

                    <button disabled={isLoading}  onClick={() => {
                        onRegister()
                        loadingToggle()
                    }}   className={`w-320pxr h-35pxr md:h-45pxr mr-15pxr md:mr-0pxr  md:w-200pxr rounded md:rounded-xl md:text-[18px] text-white bg-black  font-bold`}>
                    회원가입 완료
                    </button>


            </div>

        </section>
    )
}