import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IDetailLobbyistResponse,
    ILobbyist,
    ILobbyistResponse,
    IUser,
    ResponseTermsOfView,
    TermsOfView
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {useEffect} from "react";

async function getTermsOfPrivacy(): Promise<ResponseTermsOfView | null> {
    const config: AxiosRequestConfig = {};

    const { data }: AxiosResponse<ResponseTermsOfView> = await axiosInstance.get(`/user/terms/privacy`, config);

    return data;
}

interface UseTermsOfPrivacy {
    termsOfPrivacy: TermsOfView | undefined;
    isLoading: boolean;

}


export function useTermsOfPrivacy() :UseTermsOfPrivacy{

    const { data, isLoading, isFetching, isError, error} = useQuery<ResponseTermsOfView | null>(
        [queryKeys.termsOfView,"privacy"],
        () =>
            getTermsOfPrivacy(),
        {

        }
    );
    const termsOfPrivacy = data?.row
    // 모든 페이지의 게시물을 단일 배열로 펼칩니다.

    return {
        termsOfPrivacy ,
        isLoading,
    };
}