import ProfileInformationView from "./ProfileInformationView";
import BasicHeader from "../common/BasicHeader";



export default function ProfileSetting(){



    return (
        <section>
            <BasicHeader/>
            <ProfileInformationView/>
        </section>
    )
}