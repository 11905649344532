import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import io, {Manager} from 'socket.io-client';
import { Socket } from 'socket.io-client';
import {useUserContext} from "../common/UserContext";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {getStoredUser} from "../../utils/user-storage";
import {useNavigate} from "react-router-dom";

interface ISocketContext {
    socket: Socket | null;
}

const SocketContext = createContext<ISocketContext>({
    socket: null,
});
interface IProps {
    children: ReactNode;
}
export const SocketProvider: React.FC<IProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const {user} = useUserContext()
    const navigate = useNavigate()


    useEffect(() => {
        if (!user) return

        const manager = new Manager(process.env.REACT_APP_URL!, {
            transportOptions: {
                polling: {
                    extraHeaders: {
                        authorization: user.accessToken,
                    },
                },
            },
        });

        const newSocket = manager.socket("/");

        newSocket.on("error", (data) => {
            // handle error

        });

        setSocket(newSocket);

        socket?.onAny((data) => {
            console.log("애니",data)
        })

        return () => {
            newSocket.off()
            newSocket.close();
        };
    }, [user]);

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => useContext(SocketContext);
