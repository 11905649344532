import NetworkSettingView from "./NetworkSettingView";
import BasicHeader from "../common/BasicHeader";



export default function NetworkSetting(){



    return (
        <section>
            <BasicHeader/>
            <NetworkSettingView/>
        </section>
    )
}