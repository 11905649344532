import {DivProps} from "./Div";
import {FC} from "react";

export type AlertModalProps = DivProps & {
    onCloseIconClicked?:() => void
}

export const AlertModal: FC<AlertModalProps> = ({className:_className, onCloseIconClicked ,children, ...props}) => {
    const className = [_className,"z-50 bg-white h-230pxr md:h-350pxr w-290pxr md:w-400pxr rounded-xl"].join(' ')

    return(
            <div className={`fixed items-center justify-center w-full h-screen left-0pxr top-0pxr flex bg-black bg-opacity-70  z-10000`}>
                <div className={className}  style={{zIndex: 10001}}>

                    {children}
                </div>

            </div>

)
}
