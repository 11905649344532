export const formatCurrency = (value: number | undefined): string => {
    if (value === undefined) {
        return '-';
    }
    return value.toLocaleString('ko-KR');
};
export const makeArray = (length: number) => new Array(length).fill(null)

export const timeAgo = (dateString: string): string => {
    const inputDate = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - inputDate.getTime()) / 1000);

    const prefix = seconds < 0 ? "남음" : "전";
    const absSeconds = Math.abs(seconds);

    if (absSeconds < 60) {
        return `${absSeconds}초 ${prefix}`;
    }

    const minutes = Math.floor(absSeconds / 60);
    if (minutes < 60) {
        return `${minutes}분 ${prefix}`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours}시간 ${prefix}`;
    }

    const days = Math.floor(hours / 24);
    if (days < 30) {
        return `${days}일 ${prefix}`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
        return `${months}개월 ${prefix}`;
    }

    const years = Math.floor(months / 12);
    return `${years}년 ${prefix}`;
};

export function regaxNormal(range: number) {
    const regexString = `^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\\s!@#$%^&*()_+\\-=\\[\\]{}\\[\\]:;“”‘’'"\\/?,.<>-\\\\|~\`]{0,` + range + `}$`;
    return new RegExp(regexString);
}

export function formatDateString(dateString: string): string {
    const formattedDate = new Date(dateString).toLocaleString("ko-KR", {
        timeZone: "Asia/Seoul",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    }).replace(/[/.,]/g, ".");

    return `${formattedDate}`;
}