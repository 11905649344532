import axios, { AxiosResponse, AxiosError } from 'axios';

import type {IUser, IUserResponse, IUserValidateResponse} from "../../types/types";
import { axiosInstance, getJWTHeader } from '../../services/axiosInstance';
import { queryKeys } from '../../services/react-query/constants';
import {
    clearStoredUser, getLastVisit,
    getStoredUser, setLastVisit,
    setStoredUser,
} from '../../utils/user-storage';
import {useMutation, useQuery, useQueryClient, UseQueryOptions} from "react-query";
import {queryClient} from "../../services/react-query/queryClient";
import pLimit from 'p-limit';
import {useInterval} from "./useInterval";
import {useEffect} from "react";



const limit = pLimit(1);
export async function getAccessTokenWithMutex(user: IUser | null): Promise<IUser | null> {
    return limit(() => getAccessToken(user));
}
async function getAccessToken(user: IUser | null): Promise<IUser | null> {

    if (!user) return null;
    const { data }: AxiosResponse<IUserResponse> = await axiosInstance.post(
        `/user/member/token/refresh`,
        {
            "refreshToken": user.refreshToken
        },
        {
            headers:{
                "Content-Type": "application/json"
            }
        },
    )
    return data.row;



}
export async function doSignOut(user: IUser | null): Promise<void> {
    if (user){
        await axiosInstance.post(`/user/member/logout`,
            {
                headers: getJWTHeader(user),
            }
        );

    }
}



interface UseUser {
    user: IUser | null
    updateUser: (user: IUser) => void;
    clearUser: () => void;
}

export function useUser(): UseUser {
    const {data} = useQuery([queryKeys.user], () => getAccessTokenWithMutex(getStoredUser()), {
        // initialData: getStoredUser,
        onSuccess: (received: IUser | null) => {
            if (received){
                updateUser(received)
                queryClient.invalidateQueries([queryKeys.userInformation])
            }
        },
        onError: () => {
            clearUser()
        },
        refetchInterval: 1000 * 60 * 10,

    });


    const user = data || null





// meant to be called from useAuth
    function updateUser(newUser: IUser): void {
        queryClient.setQueryData([queryKeys.user], newUser)
        setStoredUser(newUser)
    }

// meant to be called from useAuth
    function clearUser() {
        doSignOut(user)
        clearStoredUser()
        queryClient.setQueryData([queryKeys.user],null)
        queryClient.removeQueries([queryKeys.user])
    }


    return { user, updateUser, clearUser };
}
