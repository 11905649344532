
import {Card} from "../common";
import RequestModalView from "../common/RequestModalView";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLobbyistSearchList} from "../../hooks/main/useLobbyistSearchList";
import {useOnScreen} from "../../hooks/common/useOnScreen";


type LobbyistListSearchedViewProps = {
    searchValue:string
}

export default function LobbyistListSearchedView({searchValue}:LobbyistListSearchedViewProps){
    const {searchedLobbyists, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading} = useLobbyistSearchList(searchValue)
    const [isOpenModal, setOpenModal] = useState<boolean>(false);
    const [selectedLobbyistId, setSelectedLobbyistId] = useState<string | null>(null);
    const onClickToggleModal = useCallback((lobbyistId:string) => {
        setOpenModal(isOpenModal => !isOpenModal);
        setSelectedLobbyistId(lobbyistId);
    }, [selectedLobbyistId,isOpenModal]);



    const lobbyistCard = searchedLobbyists?.map((lobbyist) => {
        return (
            <Card searchWord={searchValue} lobbyist={lobbyist} openModal={() => onClickToggleModal(lobbyist._id)} className="relative" key={lobbyist._id}/>
        )
    })

    const loadMoreRef = useOnScreen<HTMLDivElement>(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage(); // Pass the next page number
        }
    });

    const renderLoadingIndicator = () => {
        return (
            <div className="flex flex-row justify-center items-center  w-full">
            </div>
        );
    };

    return (
        isLoading ?
            renderLoadingIndicator()
        :
        <section>
            {
                searchedLobbyists?.length !== 0 ?
                    <div className={`flex flex-wrap items-start justify-center  w-full px-15pxr pt-10pxr md:pt-10pxr`}>
                        {lobbyistCard}
                        {isOpenModal && selectedLobbyistId &&  <RequestModalView onClickRequestModal={() =>onClickToggleModal(selectedLobbyistId)} key={selectedLobbyistId} lobbyistId={selectedLobbyistId}/>}
                        <div ref={loadMoreRef}></div>
                    </div>
                    :
                    <div className={`flex flex-col bg-white w-full items-center mt-66pxr md:mt-163pxr h-378pxr md:h-1200pxr`}>
                        <img src={`/NotSearched.png`} className={`flex h-119pxr w-194pxr md:h-240pxr md:w-390pxr`} alt="not_searched"/>
                        <div className="h-18pxr md:h-240pxr w-300pxr font-semibold md:w-576pxr text-[15px] md:text-[30px] mt-29pxr md:mt-68pxr text-black">“찾는 네트워크가 없다면 확성기를 이용해보세요”</div>
                    </div>

            }

        </section>
    )
}