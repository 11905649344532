import type {FC,DetailedHTMLProps,HTMLAttributes, PropsWithChildren} from "react";

import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {useRequestDetailHistory} from "../../hooks/history/useRequestDetailHistory";
import {formatCurrency, regaxNormal, timeAgo} from "../../utils/data/helper";
import {Modal} from "../../components/common/Modal";
import {useAdminMegaphoneDetailRequest} from "../hooks/useAdminMegaphoneDetailRequest";
import {Button} from "react-query/types/devtools/styledComponents";
import {useAdminMegaApprove} from "../hooks/useAdminMegaApprove";
import {useAdminMegaReject} from "../hooks/useAdminMegaReject";
import {CommentRequestFormData} from "../../types/types";
import {AdminBannerRegistrationFormData, AdminBannerRegistrationModifyFormData, AdminRejectFormData} from "../types";
import {Image} from "../../components/common/Image";
import {useAdminCommunityBannerRegistration} from "../hooks/useAdminCommunityBannerRegistration";
import {useAdminCommunityBannerDelete} from "../hooks/useAdminCommunityBannerDelete";
import {useAdminCommunityDetailBanner} from "../hooks/useAdminCommunityDetailBanner";
import {AlertModal} from "../../components/common";
import {useAdminCommunityBannerModify} from "../hooks/useAdminCommunityBannerModify";


export type AdminBannerModifyModalProps = {
    onClickToggleBannerModifyModal:() => void
    bannerId: string
}

export const AdminBannerModifyModal : FC<AdminBannerModifyModalProps> = ({onClickToggleBannerModifyModal, bannerId}) => {

    const deleteBanner = useAdminCommunityBannerDelete(() => onClickToggleBannerModifyModal())
    const {banner, isLoading, isFetching} = useAdminCommunityDetailBanner(bannerId)


    const [isDeleteBannerModal, setIsDeleteBannerModal] = useState<boolean>(false);
    const onClickToggleDeleteBanner = useCallback(() => {
        setIsDeleteBannerModal(isDeleteBannerModal => !isDeleteBannerModal);
    }, [isDeleteBannerModal]);


    const handleInputChange = (field: keyof AdminBannerRegistrationFormData, isNumber?: boolean) => (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = isNumber ? parseFloat(e.target.value) : e.target.value;
        updateFields({ [field]: value });
    };


    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState<string | null>(null);

    const handleButtonClick = () => {
        inputFileRef.current?.click();
    };
    const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const imgFile = e.target.files[0];
            setUploadedImage(imgFile);

            // Create a FileReader to read the image file
            const reader = new FileReader();
            reader.onloadend = () => {
                // Update the previewImage state with the Data URL
                setPreviewImage(reader.result as string);
            };
            reader.readAsDataURL(imgFile);
        }
    };


    const INITIAL_DATA: AdminBannerRegistrationModifyFormData = {
        title: banner?.title || "",
        businessPrice: banner?.businessPrice || "",
        image: banner?.image || "",
        introducePrice:banner?.introducePrice || 0,
        postId:banner?.postId ||"",
        bannerId:bannerId
    }
    const [bannerData, setBannerData] = useState<AdminBannerRegistrationModifyFormData>(INITIAL_DATA)
    const updateFields = (fields: Partial<AdminBannerRegistrationModifyFormData>) => {
        setBannerData(prev => {
            return { ...prev, ...fields }
        })
    }

    useEffect(() => {
        setBannerData({
            title: banner?.title || "",
            businessPrice: banner?.businessPrice || "",
            image: banner?.image || "",
            introducePrice:banner?.introducePrice || 0,
            postId:banner?.postId ||"",
            bannerId:bannerId
        })
    },[isLoading,isFetching])

    const modifyBanner = useAdminCommunityBannerModify(() => onClickToggleBannerModifyModal(), uploadedImage , bannerId)


    const isBannerDataEmpty = () => {
        return Object.values(bannerData).every(value => value === "");
    };
    const isPreviewImageEmpty = () => {
        return previewImage === "";
    };


    return (
        <Modal className="h-350pxr w-300pxr md:w-650pxr md:h-650pxr overflow-y-scroll " onCloseIconClicked={onClickToggleBannerModifyModal}>
            <div className="flex flex-col px-17pxr md:mx-20pxr mt-28pxr text-[17px] md:text-[25px] text-black font-semibold ">

                <div className={`flex flex-row items-end text-[15px]  mt-20pxr md:mt-35pxr md:text-[18px] font-bold `}>
                    <div className={`text-[12px] md:text-[18px] mr-5pxr`}>제목</div>
                </div>
                <div className={` md:h-90pxr md:w-550pxr mt-10pxr md:mt-12pxr`}>
                    <textarea  value={bannerData.title} onChange={handleInputChange('title')} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[20px] text-black placeholder:text-gray-300 rounded-xl`} rows={1} />
                </div>

                <div className={`text-[13px] md:text-[18px] font-bold mt-20pxr md:mt-35pxr`}>게시글 ID</div>
                <div className={`text-[8px] md:text-[15px]`}>( ex: URL 주소 뒤에있는 https://lobbyd.com/community/detail/<div className={`text-[8px] md:text-[15px] text-red-500 font-extrabold`}>644925179eda4c50bf6f4e26</div>) 이걸 붙여주시면 됩니다.</div>
                <div className={`h-94pxr w-341pxr md:h-300pxr md:w-550pxr mt-8pxr md:mt-12pxr`}>
                    <textarea value={bannerData.postId} onChange={handleInputChange('postId')} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr h-300pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl overflow-y-scroll  `}

                    />
                </div>
                <div className={`flex flex-row items-end  font-bold mt-20pxr md:mt-45pxr`}>
                    <div className={`text-[12px] md:text-[18px] md:mr-5pxr`}>업무비</div>
                </div>
                <div className={` md:h-90pxr md:w-500pxr mt-10pxr md:mt-12pxr`}>
                   <textarea value={bannerData.businessPrice} onChange={handleInputChange('businessPrice')} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl  `}/>
                </div>
                <div className={`flex flex-row items-end text-[15px] mt-20pxr md:mt-0pxr md:text-[18px] bfont-bold `}>
                    <div className={`text-[12px] md:text-[18px] mr-5pxr`}>소개비</div>
                </div>
                <div className={` md:h-90pxr md:w-550pxr mt-10pxr md:mt-12pxr`}>
                    <input type="text" value={bannerData.introducePrice} onChange={(e) => handleInputChange('introducePrice', true)(e)} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[20px] text-black placeholder:text-gray-300 rounded-xl`} min={0}/>
                </div>


                <div className={`mt-14pxr md:mt-6pxr`}>
                    <div className={`text-[15px] md:text-[30px] text-black font-extrabold`}>배너 사진</div>
                </div>
                <button onClick={handleButtonClick} className={`flex flex-col items-center mt-29pxr md:mt-40pxr`}>
                    {previewImage ? (
                        <Image className={`h-85pxr w-85pxr md:h-500pxr md:w-500pxr rounded-xl`} preview={true} src={previewImage} alt="profile"/>
                    ):(
                        <Image className={`h-85pxr w-85pxr md:h-500pxr md:w-500pxr rounded-xl`} src={banner?.image || "default-profile.png"} alt="banner"/>
                    )}
                    <div className={`mt-10pxr md:mt-15pxr text-[7px] md:text-[13px] font-bold text-[#696969]`}>배너 사진 등록</div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                        ref={inputFileRef}
                    />
                </button>


                <div className={`flex flex-row items-center justify-center my-25pxr md:my-64pxr`}>
                    <button disabled={isBannerDataEmpty() || isPreviewImageEmpty()} className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr ${isBannerDataEmpty() || isPreviewImageEmpty() ? "bg-[#696969]" : "bg-blue-500"} text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={() => modifyBanner(bannerData)}>배너 수정</button>
                    <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-red-500 text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={onClickToggleDeleteBanner}>배너 삭제</button>
                </div>


            </div>

            {
                isDeleteBannerModal && (
                    <AlertModal>
                        <div className={`flex flex-col items-center justify-center`}>
                            <div className={`text-[16px] md:text-[24px] mt-40pxr md:mt-50pxr text-black font-semibold `}>삭제하시겠습니까?</div>
                            <div className={`text-[10px] md:text-[13px] mt-36pxr md:mt-45pxr text-[#5d5d5d] font-semibold`}>삭제하시면 삭제한 배너</div>
                            <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>사라지며 복구할 수 없습니다.</div>
                            <div className={`text-[10px] md:text-[13px] mt-10pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>정말 삭제하시겠습니까?</div>

                            <div className={`flex flex-row mt-25pxr md:mt-64pxr`}>
                                <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-[#e0e0e0] text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={onClickToggleDeleteBanner}>취소</button>
                                <button onClick={() => deleteBanner(banner!)} className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`}>확인</button>
                            </div>
                        </div>
                    </AlertModal>
                )
            }

        </Modal>

    )
}