import {useNavigate} from "react-router-dom";
import {useCallback} from "react";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {PaymentMethodCard} from "./PaymentMethodCard";
import BasicHeader from "../common/BasicHeader";

export default function PaymentMethodView(){

    const {userInformation} = useUserInformation()

    const navigate = useNavigate()

    const goBack = useCallback(() =>{
        navigate(-1)
    },[navigate])

    return (
        <section>
            <div className={`flex flex-col items-center mt-23pxr md:mt-50pxr`}>

                <div className="w-screen ml-18pxr  md:w-1/2">
                    <button>
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}/>
                    </button>
                </div>
                <div>
                    <div className={` md:mt-6pxr`}>
                        <div className={`text-[15px] md:text-[30px] text-black font-extrabold`}>결제/계좌 관리</div>
                    </div>

                </div>
                <PaymentMethodCard className="mt-30pxr md:mt-50pxr" title="결제 카드 관리 (의뢰인)" card={true}/>
                <PaymentMethodCard className="mt-20pxr md:mt-35pxr" title="소개비 입금 받을 계좌 관리 (로비스트)" card={false}/>
                <div className="flex flex-col  w-296pxr md:w-380pxr items-start text-[#848484]">
                    <div className={`text-[10px] md:text-[14px] font-extrabold mt-39pxr md:mt-54pxr `}>
                        결제 카드와 입금 받으실 계좌를 미리 등록하면 거래 과정이 수월해요!
                    </div>
                    <div className="flex flex-col text-[10px] md:text-[14px] font-semibold  mt-16pxr md:mt-28pxr">
                        <div>1. 소개비 결제 시 로비스트에게 부과되는 수수료는 없으며, 거래확정 후 의뢰인에게 소개비의 10%가 서비스 수수료가 부과됩니다.</div>
                        <div>2. 의뢰인이 거래를 확정하면, 의뢰인이 책정한 소개비가 로비스트의 계좌로 입금됩니다.</div>

                    </div>

                </div>
                <div className="flex flex-col w-296pxr md:w-380pxr  items-start text-[#848484]">
                    <div className={`text-[10px] md:text-[14px] font-extrabold mt-42pxr md:mt-65pxr `}>
                        서비스 결제 단계
                    </div>
                    <div className="flex flex-col text-[10px] font-semibold md:text-[14px] mt-16pxr md:mt-28pxr">
                        <div>1. 결제대기</div>
                        <div>거래확정 전까지는 소개비가 결제되지 않습니다.</div>
                    </div>
                    <div className="flex flex-col text-[10px] font-semibold md:text-[14px] mt-16pxr md:mt-28pxr">
                        <div>2. 거래확정</div>
                        <div>의뢰인이 로비스트를 통해 소개받고 난 후 의뢰인이 거래를 확정하면
                            1시간 내로 로비스트의 계좌로 소개비가 자동 입금됩니다. 로비스트가
                            만약 계좌정보를 입력하지 않았다면 소개비 입금이 지연될 수 있습니다.
                        </div>
                    </div>
                    <div className="flex flex-col text-[10px] font-semibold md:text-[14px] mt-16pxr md:mt-28pxr">
                        <div>3. 입금완료</div>
                        <div>소개비가 로비스트의 계좌로 입금이 완료됐습니다.</div>
                    </div>
                    <div className="flex flex-col text-[10px] font-semibold md:text-[14px] mb-50pxr mt-16pxr md:mt-28pxr">
                        <div>4. 의뢰취소</div>
                        <div>의뢰인이 거래를 확정하기 전에 취소한 상태입니다.
                            의뢰인과 로비스트 간 개인적인 사정으로 거래를 취소하신다면 사전에
                            서로 협의해주세요.</div>
                    </div>

                </div>
            </div>
        </section>
    )

}