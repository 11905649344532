import {QueryClient} from "react-query";
import {queryKeys} from "./constants";


function queryErrorHandler(error: unknown): void {
    // error is type unknown because in js, anything can be an error (e.g. throw(5))
    const title =
        error instanceof Error ? error.message : 'error connecting to server';
    // queryClient.invalidateQueries([queryKeys.user])
    // queryClient.invalidateQueries([queryKeys.userInformation])
}


export const queryClient = new QueryClient({
    defaultOptions:{
        mutations:{
            onError: queryErrorHandler,

        },
        queries:{
            onError: queryErrorHandler,

        },

    }
});
// to satisfy typescript until this file has uncommented contents
export {}