import BasicHeader from "../common/BasicHeader";
import {useLobbyistReview} from "../../hooks/main/useLobbyistReview";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import React, {useCallback, useEffect, useState} from "react";
import {Comment} from "../community/Comment";
import {useNavigate} from "react-router-dom";
import {ReviewModal} from "../common/ReviewModal";
import {ReviewModifyFormData, ReviewRequestFormData} from "../../types/types";
import {useReviewRequest} from "../../hooks/history/useReviewRequest";
import {useReviewList} from "../../hooks/history/useReviewList";
import {Image} from "../common/Image";
import {formatDateString, timeAgo} from "../../utils/data/helper";
import {ReviewModifyModal} from "../common/ReviewModifyModal";
import {useReviewModifyRequest} from "../../hooks/history/useReviewModifyRequest";


export default function MyReputationView(){

    const navigate = useNavigate()

    const goBack = useCallback(() => {
        navigate(-1)
    },[navigate])

    const {userInformation} = useUserInformation()
    const { lobbyistReviews } = useLobbyistReview(userInformation._id)
    const commentsLists = lobbyistReviews?.reverse().map((review, index) => (
        <Comment review={review} key={`${review._id}-${index}`}/>
    ))

    const [selectedLobbyistId, setSelectedLobbyistId] = useState<string>("")


    const [isMyReputation, setIsMyReputation] = useState<boolean>(false)
    const reviewModeChange = useCallback(() => {
        setIsMyReputation(prevState => !prevState)
    },[isMyReputation])

// 리뷰 관련
    const [paymentId, setPaymentId] = useState<string>("")
    const [isReviewModal, setIsReviewModal] = useState<boolean>(false);
    const onClickToggleReviewModal = useCallback((paymentId:string, lobbyistId:string) => {
        setPaymentId(paymentId)
        setSelectedLobbyistId(lobbyistId)
        setIsReviewModal(isReviewModal => !isReviewModal);
    }, [isReviewModal, paymentId])
    const [reviewValue, setReviewValue] = useState("");
    const handleReviewSubmitButton = reviewValue.trim().length === 0
    const handleReviewChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = event.target.value;
        if (/^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣\s!@#$%^&*()_+{}\[\]:;“”‘’'"\/?,.<>-\\|~`]{0,300}$/.test(inputValue)) {
            setReviewValue(inputValue);
        }
    };
    const reviewRequestData : ReviewRequestFormData = {
        paymentId: paymentId,
        content: reviewValue,
    }
    // 리뷰 수정 관련

    const [reviewId, setReviewId] = useState<string>("")
    const [isReviewModifyModal, setIsReviewModifyModal] = useState<boolean>(false);
    const onClickToggleReviewModifyModal = useCallback((reviewId:string, reviewValue:string, lobbyistId:string) => {
        setReviewId(reviewId)
        setReviewValue(reviewValue)
        setSelectedLobbyistId(lobbyistId)
        setIsReviewModifyModal(isReviewModifyModal => !isReviewModifyModal);
    }, [isReviewModifyModal, reviewId])

    const reviewModifyRequestData : ReviewModifyFormData = {
        reviewId: reviewId,
        content: reviewValue,
    }

    const submitReview = useReviewRequest(() => onClickToggleReviewModal(paymentId,selectedLobbyistId), selectedLobbyistId)
    const modifyReview = useReviewModifyRequest(() => onClickToggleReviewModifyModal(paymentId,reviewValue,selectedLobbyistId),selectedLobbyistId )
    const {reviews} = useReviewList()

    const availableReviewList = reviews?.map((review, index) => {
        return (
            <div className="flex flex-row w-371pxr md:w-650pxr items-center justify-between mt-30pxr">
                <div className="flex flex-row items-center">
                    <Image src={review.lobbyistProfileImage} className="h-35pxr w-35pxr md:h-46pxr md:w-46pxr rounded-xl mr-10pxr"/>
                    <div className="font-bold text-black text-[10px] md:text-[20px] ">{review.lobbyistNickName}</div>
                </div>
                <div className="text-[10px] md:text-[20px] text-[#a5a5a5] font-bold">거래 완료 {formatDateString(review.paidAt)}</div>
                <button onClick={() => {
                    review.review ? onClickToggleReviewModifyModal(review.review._id, review.review.content, review.lobbyistId)  : onClickToggleReviewModal(review._id, review.lobbyistId)

                }} className=" w-75pxr md:w-150pxr h-30pxr md:h-43pxr bg-black text-white text-[10px] md:text-[15px] rounded-lg">
                    {review.review ? "후기 수정하기" : "후기 작성하기"}
                </button>
            </div>
            )

    })

    return (
        <section>
            <BasicHeader/>



            <div className={`flex flex-col justify-center items-center  mt-29pxr md:mt-20pxr px-17pxr md:px-45pxr`}>
                <div className="w-screen ml-18pxr  md:w-1/2">
                    <button>
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}/>
                    </button>
                </div>
                <div className="text-[20px] md:text-[30px] text-black font-extrabold">후기 관리</div>
                <div className="flex flex-row items-center  justify-center w-340pxr md:w-500pxr mb-15pxr mt-25pxr  md:mt-60pxr">

                    {isMyReputation ? (
                        <div className="flex flex-row justify-between space-x-30pxr md:space-x-103pxr ">
                            <button onClick={reviewModeChange}>
                                <div className="text-[13px] md:text-[20px] text-[#b5b5b5] font-extrabold">후기 작성</div>
                            </button>
                            <div className="flex flex-col items-center justify-center">
                                <div className="text-[13px] md:text-[20px]  text-black font-extrabold">나에 대한 후기</div>
                                <div className="relative top-4pxr md:top-5pxr h-1pxr w-35pxr md:h-3pxr md:w-50pxr bg-black rounded-xl"/>
                            </div>
                        </div>
                    ):(
                        <div className="flex flex-row justify-between space-x-30pxr md:space-x-103pxr ">
                            <div className="flex flex-col items-center justify-center">

                                <div className="text-[13px] md:text-[20px]  text-black font-extrabold">후기 작성</div>
                                <div className="relative top-4pxr md:top-5pxr h-1pxr w-35pxr md:h-3pxr md:w-60pxr bg-black rounded-xl"/>
                            </div>
                            <button onClick={reviewModeChange}>
                                <div className="text-[13px] md:text-[20px]  text-[#b5b5b5] font-extrabold">나에 대한 후기</div>
                            </button>
                        </div>
                    )}
                </div>

                {isMyReputation ? (
                    <>

                        <div className={`overflow-auto scrollbar my-18pxr md:my-40pxr h-140pxr md:h-180pxr `}>
                            {commentsLists?.length !== 0
                                ? (commentsLists)
                                : <div className={`text-[15px] md:text-[25px] font-bold text-center mt-50pxr md:mt-60pxr`}>
                                    아직 후기가 없어요
                                </div>
                            }

                        </div>
                    </>
                ):(
                    <>
                        {availableReviewList}
                    </>
                )}
                {isReviewModal &&
                    <ReviewModal onClickToggleReviewModal={() => onClickToggleReviewModal(paymentId, selectedLobbyistId)} handleReviewChange={handleReviewChange} reviewValue={reviewValue} handleReviewSubmitButton={handleReviewSubmitButton} submitReview={() => submitReview(reviewRequestData)} reviewRequestData={reviewRequestData}/>
                }
                {isReviewModifyModal &&
                    <ReviewModifyModal onClickToggleReviewModal={() => onClickToggleReviewModifyModal("","", selectedLobbyistId)} handleReviewChange={handleReviewChange} reviewValue={reviewValue} handleReviewSubmitButton={handleReviewSubmitButton}  modifyReview={() => modifyReview(reviewModifyRequestData)}  reviewModifyRequestData={reviewModifyRequestData}/>
                }

            </div>
        </section>

    )
}