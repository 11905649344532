import {AlertModal, Avatar, Subtitle} from "../common";
import React, {ChangeEvent, KeyboardEvent, KeyboardEventHandler, useCallback, useEffect, useState} from "react";
import {CommunityPostComment} from "./CommunityPostComment";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDetailCommunity} from "../../hooks/community/useDetailCommunity";
import {useUser} from "../../hooks/common/useUser";
import {useWriteComment} from "../../hooks/community/useWriteComment";
import {CommentRequestFormData, RegisterFormData} from "../../types/types";
import BasicHeader from "../common/BasicHeader";
import {useDeleteCommunityPost} from "../../hooks/community/useDeleteCommunityPost";
import {formatCurrency, regaxNormal, timeAgo} from "../../utils/data/helper";
import {useCompletePostStatus} from "../../hooks/community/useCompletePost";
import {useUserContext} from "../../hooks/common/UserContext";
import {useChatRoomProvider} from "../../hooks/chat/useChatRoomProvider";
import {useBlockUser} from "../../hooks/block/useBlockUser";
import {useReport} from "../../hooks/community/useReportCommunityPost";



export default function CommunityPostView(){





    const navigate = useNavigate()
    const goBack = useCallback(() => {
        window.scroll(0,0)
        navigate("/")
    },[navigate])

    const goLogin = useCallback(() => {
        window.scroll(0,0)
        navigate("/login")
    },[navigate])


    const {postId} = useParams()



    const goModifyPost = useCallback(()=>{
        window.scroll(0,0)
        navigate(`/community/modify/${postId}`,)
    },[navigate])

    const {user} = useUserContext()
    const {data} = useDetailCommunity(postId as string)

    const deletePost = useDeleteCommunityPost(postId!,() => goBack())
    const completePost = useCompletePostStatus(postId as string,() => goBack())

    const [isDeletePostModal, setIsDeletePostModal] = useState<boolean>(false);
    const onClickToggleDeletePost = useCallback(() => {
        setIsDeletePostModal(isDeletePostModal => !isDeletePostModal);
    }, [isDeletePostModal]);


    // TODO: 차단하기 기능 이용해서 UI 구성할 것
    const blockUser = useBlockUser(data?.writerId ?? "", () => null)
    const reportPost = useReport(() => null)


    const {fetchRoomIdAndNavigate} = useChatRoomProvider();
    const goChat = () => {
        fetchRoomIdAndNavigate(data?.writerId ?? "", data?.writerProfileImage ?? "", data?.writerNickName ?? "" )
    };
    const expiredDateString:string = data?.expiredAt || "2023-04-06T08:24:31.962Z"
    const createdDateString:string = data?.createdAt || "2023-04-06T08:24:31.962Z"

    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.href)
            .then(() => {
                alert('링크가 클립보드에 복사되었습니다.');
            })
            .catch(err => {
                console.error('클립보드 복사 실패:', err);
            });
    };


    return(
        <section>
            <BasicHeader/>
            <div className={`flex flex-col items-center h-full   md:mb-91pxr `}>

                <div className={`flex flex-col items-start w-350pxr md:w-600pxr`}>
                    <div className={`relative mt-20pxr md:mt-80xr items-start right-10pxr md:right-20pxr`}>
                        <button className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}>
                            <img src="/back.png" />
                        </button>
                    </div>
                    <div className={`flex flex-row justify-between w-350pxr md:w-600pxr items-end  mr-11pxr md:mr-17pxr mt-15pxr md:mt-33pxr`}>
                        <div className={`text-[20px] md:text-[30px] w-260pxr md:w-450pxr font-bold text-black whitespace-pre-wrap break-words`}>{data?.title}</div>
                        {(data?.writerId === user?._id && (data?.status !== "complete" || "expire"))  ?
                            (
                                <div className="flex flex-row space-x-15pxr md:space-x-29pxr text-[#d0d0d0] text-[8px] md:text-[13px] font-semibold">
                                    <button onClick={goModifyPost}>수정하기</button>
                                    <button onClick={onClickToggleDeletePost}>삭제하기</button>
                                </div>
                            ): (
                                <button onClick={copyToClipboard} className="flex flex-row space-x-15pxr md:space-x-29pxr text-[#d0d0d0] text-[8px] md:text-[13px] font-semibold">
                                    공유하기
                                </button>
                    )}
                    </div>


                    <div className={`flex flex-row w-350pxr md:w-600pxr items-end md:items-center justify-between  md:h-100pxr mt-20pxr md:mt-50pxr `}>
                        <div className="flex flex-row">
                            <div className="flex flex-col">
                                <Avatar src={data?.writerProfileImage} />
                            </div>
                            <div className={`flex flex-col ml-6pxr md:ml-14pxr `}>
                                <Subtitle className="text-start" numberOfLines={1}>{data?.writerNickName}</Subtitle>
                                <div className="text-[#d0d0d0] text-[8px] md:text-[13px] font-semibold">
                                    {timeAgo(createdDateString)}
                                </div>

                                        <div className="mt-15pxr mb-10pxr md:mb-0pxr md:mt-15pxr text-[10px] md:text-[14px] font-extrabold text-black">
                                            의뢰 마감 {(data?.status === "proceed") && timeAgo(expiredDateString)}
                                        </div>

                            </div>

                        </div>
                        {(data?.isOwner && (data?.status === "proceed")) &&
                            (
                                <button onClick={() => completePost(postId!)} className="h-25pxr w-95pxr  mb-5pxr md:h-40pxr md:w-120pxr bg-black text-white font-bold text-[8px] md:text-[13px] rounded md:rounded-lg">
                                    해결 완료 시 Click
                                </button>
                            )
                        }

                    </div>
                    <div className="w-350pxr md:w-600pxr h-1pxr  bg-[#d3d2d2]"/>

                    <div className="flex flex-col md:pl-15pxr mt-35pxr md:mt-30pxr">
                        <div className="font-extrabold text-[12px] md:text-[15px] text-black">
                            자기소개 / 찾는사람 / 요구사항
                        </div>
                        <div className="flex flex-col pt-11pxr md:pt-20pxr pl-7pxr md:pl-17pxr w-333pxr md:w-580pxr text-[12px] md:text-[15px] font-semibold text-[#717171]  whitespace-pre-wrap break-words">
                            {data?.content}
                        </div>

                        <div className="flex flex-row items-end pt-35pxr md:pt-30pxr font-extrabold text-[12px] md:text-[15px] text-black">
                            업무비 <div className="text-[10px] md:text-[13px] ml-2pxr md:ml-5pxr">(소개받으신 분께 자문을 구하거나 업무를 부탁드리는 경우 지급되는 보수입니다)</div>
                        </div>
                        <div className="flex flex-col pt-11pxr md:pt-27pxr pl-7pxr md:pl-17pxr w-333pxr md:w-650pxr text-[12px] md:text-[15px] font-semibold text-[#717171]">
                            {data?.businessPrice}
                        </div>
                        <div className="flex flex-row items-end pt-35pxr md:pt-44pxr  font-extrabold text-[12px] md:text-[15px] text-black">
                            소개비  <div className="text-[10px] md:text-[13px] ml-5pxr">(소개해주는 로비스트에게 지급되는 보수입니다)</div>
                        </div>
                        <div className="flex flex-col pt-11pxr md:pt-27pxr pl-7pxr md:pl-17pxr w-333pxr md:w-580pxr text-[12px] md:text-[15px] font-semibold text-[#717171]">
                            소개비 {formatCurrency(data?.introducePrice)}원
                        </div>
                    </div>
                    {!data?.isOwner && (
                        <button className={`flex justify-center mt-67pxr md:mt-68pxr text-white text-[15pxr] md:text-[18px] font-semibold items-center w-350pxr md:w-600pxr h-35pxr  md:h-45pxr bg-black rounded-md md:rounded-xl`} onClick={(user ? () => goChat() : goLogin)}>
                            <img src="/airplain_white@3x.png" className="h-35pxr w-35pxr mr-10pxr"/> 채팅 상담하기
                        </button>
                    )

                    }

                    {/*<div className="flex flex-row mt-32pxr md:mt-50pxr mb-12pxr md:mb-15pxr justify-between text-[12px] md:text-[22px] font-extrabold text-[#717171] ">*/}
                    {/*    <div className="flex flex-row items-center text-[12px] md:text-[13px]">*/}
                    {/*        <img className="h-12pxr w-12pxr md:h-15pxr text-[#696969] md:w-15pxr mr-5pxr md:mr-10pxr" src="/chat.png"/>*/}
                    {/*        {data?.replyCount}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="w-350pxr md:w-600pxr h-1pxr  bg-[#d3d2d2]"/>*/}

                    {/*<div className={`flex flex-row items-center text-[#bdbdc0] justify-between p-5pxr md:p-20pxr mb-40pxr h-30pxr md:h-45pxr w-285pxr w-350pxr md:w-600pxr mt-22pxr md:mt-27pxr rounded md:rounded-xl border border-[#d0d0d0]  `}>*/}
                    {/*    <input onChange={handleComment} value={commentData.content} className="w-320pxr md:w-600pxr bg-white text-black font-semibold text-[8px] md:text-[13px] placeholder:text-[#d0d0d0] focus:outline-none" placeholder="소개 가능한 로비스트께서 댓글을 남기시면 의뢰인이 의뢰를 요청드리게 됩니다" type={`text`}/>*/}
                    {/*    <button disabled={commentData.content.trim().length === 0 || commentLoading} onClick={() => {*/}
                    {/*        onSubmitComment()*/}
                    {/*        commentLoadingState()*/}
                    {/*    }} className=" font-extrabold w-30pxr text-[8px] md:text-[15px]">입력</button>*/}

                    {/*</div>*/}

                    {/*{comments}*/}
                </div>
            </div>

            {
                isDeletePostModal && (
                    <AlertModal>
                        <div className={`flex flex-col items-center justify-center`}>
                            <div className={`text-[16px] md:text-[24px] mt-40pxr md:mt-50pxr text-black font-semibold `}>삭제하시겠습니까?</div>
                            <div className={`text-[10px] md:text-[13px] mt-36pxr md:mt-45pxr text-[#5d5d5d] font-semibold`}>삭제하시면 삭제한 게시글이</div>
                            <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>사라지며 복구할 수 없습니다.</div>
                            <div className={`text-[10px] md:text-[13px] mt-10pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>정말 삭제하시겠습니까?</div>

                            <div className={`flex flex-row mt-25pxr md:mt-64pxr`}>
                                <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-[#e0e0e0] text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={onClickToggleDeletePost}>취소</button>
                                <button onClick={() => deletePost(data!)} className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`}>확인</button>
                            </div>
                        </div>
                    </AlertModal>
                )
            }


        </section>
    )
}