import {AlertView} from "../common";
import {IUserNotification} from "../../types/types";
import {useNotification} from "../../hooks/main/useNotification";



export default function AlertListView(){
    const {notifications} = useNotification()
    const alerts = notifications?.sort((a, b) => {
        // Sort by createdAt field in descending order (newest first)
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    }).map((notification) =>(
        <AlertView notification={notification} key={notification._id}/>
    )) || []



    return (
        <div className={`flex flex-col absolute font-normal bg-white w-180pxr md:w-310pxr h-160pxr md:h-260pxr border border-black rounded-xl -right-5pxr top-35pxr md:-right-10pxr md:top-60pxr`}>
            {alerts.length !== 0 ?
                (
                    <div className="text-black font-base text-[10px] md:text-[18px]  md:px-5pxr overflow-auto scrollbar my-3pxr mr-1pxr md:mr-3pxr">
                        {alerts}
                    </div>

                ):(
                    <div className="flex flex-col items-center h-160pxr md:h-260pxr font-semibold justify-center text-[10px] md:text-[15px]">
                        아직 알람이 없어요
                    </div>
                )}


        </div>
    )
}