export const banks = [
    "국민은행",
    "신한은행",
    "우리은행",
    "KEB하나은행",
    "농협은행",
    "기업은행",
    "카카오뱅크",
    "케이뱅크",
    "산업은행",
    "외환은행",
    "우체국",
    "수협중앙회",
    "수출입은행",
    "지역농축협",
    "제일은행",
    "한국씨티은행",
    "대구은행",
    "부산은행",
    "광주은행",
    "제주은행",
    "전북은행",
    "경남은행",
    "새마을금고중앙회",
    "신협중앙회",
    "상호저축은행",
    "모건스탠리은행",
    "HSBC은행",
    "도이치은행",
];