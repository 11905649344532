import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IDetailLobbyistResponse,
    ILobbyist,
    ILobbyistResponse,
    IReviewList,
    IReviewListReponse,
    IUser
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {clearStoredUser, getStoredUser, setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery, useQueryClient} from "react-query";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {useEffect} from "react";
import {useUserContext} from "../common/UserContext";

async function getReviewList(user: IUser | null): Promise<IReviewListReponse | null> {
    const config: AxiosRequestConfig = {};

    if (user){
        config.headers = getJWTHeader(user);
    }

    const { data } : AxiosResponse<IReviewListReponse> = await axiosInstance.get(`/user/review`, config,)
    return data


}

interface UseReviewList {
    reviews: IReviewList[] | undefined;
    isLoading: boolean;

}


export function useReviewList() :UseReviewList{
    const { user, updateUser, clearUser } = useUserContext();

    const { data, isLoading, isFetching, isError, error} = useQuery<IReviewListReponse | null>(
        [queryKeys.paidReview],
         () => getReviewList(user),
        {
            onSuccess: () => {

            },
        }
    );
    const reviews = data?.rows
    // 모든 페이지의 게시물을 단일 배열로 펼칩니다.

    return {
        reviews ,
        isLoading,
    };
}