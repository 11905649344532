import MegaphoneRequestForm from "./MegaphoneRequestForm";
import MegaphoneRequestPaymentSetting from "./MegaphoneRequestPaymentSetting";
import MegaphonePayment from "./MegaphonePayment";
import {useMultistepForm} from "../../hooks/common/useMultistepForm";
import React, {useEffect, useState} from "react";
import {MegaphoneRequestFormData, RequestFormData} from "../../types/types";
import {useLocation} from "react-router-dom";
import {useMegaphoneRequestData, useRequestData} from "../requests/RequestDataFormContext";
import {useMegaphoneRequest} from "../../hooks/megaphone/useMegaphoneRequest";

const INITIAL_DATA: MegaphoneRequestFormData = {
    lobbyistIds:[],
    content:"",
    introducePrice:0,
    businessPrice:"",
    contact:"",

}

export default function MegaphoneRequestProcess(){
    const location = useLocation()
    const lobbyistIds = location.state?.lobbyistIds || []
    const { megaphoneRequestDataForm, setMegaphoneRequestDataForm } = useMegaphoneRequestData();
    const updateFields = (updatedData: Partial<MegaphoneRequestFormData>) => {
        setMegaphoneRequestDataForm({...megaphoneRequestDataForm, ...updatedData})
    }

    useEffect(() => {
        updateFields({ lobbyistIds: lobbyistIds });
    }, []);


    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
        <MegaphoneRequestForm next={() => next()} data={megaphoneRequestDataForm} {...megaphoneRequestDataForm} updateFields={updateFields} />,
        <MegaphoneRequestPaymentSetting next={() => next()} back={() => back()} data={megaphoneRequestDataForm} {...megaphoneRequestDataForm} updateFields={updateFields}/>,
        <MegaphonePayment back={() => back()} data={megaphoneRequestDataForm} {...megaphoneRequestDataForm} />
    ])


    return(
        <section>
            {step}
        </section>
    )
}