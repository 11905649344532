import Footer from "../main/Footer";
import React, {useCallback, useState} from "react";
import JobGroupChoice from "./JobGroupChoice";
import MBTIChoice from "./MBTIChoice";
import NetworkChoice from "./NetworkChoice";
import {useMultistepForm} from "../../hooks/common/useMultistepForm";
import {RegisterFormData} from "../../types/types";
import {useAuth} from "../../hooks/auth/useAuth";
import {useLocation, useParams} from "react-router-dom";

const INITIAL_DATA: RegisterFormData = {
    nickName: "",
    keywords: [
        {
            word: "",
            detail: "",
            price: 0
        }
    ],
    profileImage: "defaultPI.png",
    authorizationCode: "",
    deviceToken : "",
    source: "google"

}



export default function SignUp(){
    const location = useLocation()
    const authCode = location.state.authCode
    const source = location.state.source
    INITIAL_DATA.authorizationCode = authCode
    INITIAL_DATA.source = source

    const [data, setData] = useState(INITIAL_DATA)

    const updateFields = (fields: Partial<RegisterFormData>) => {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }

    const auth = useAuth()

    const [signUpLoading, setSignUpLoading] = useState<boolean>(false);
    const loadingToggle = useCallback(() => {
        setSignUpLoading((signUpLoading) => !signUpLoading)
    },[signUpLoading])



    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
        useMultistepForm([
            // <JobGroupChoice next={()=> next()} {...data} updateFields={updateFields} />,
            // <MBTIChoice next={()=> next()} {...data} updateFields={updateFields} />,
            <NetworkChoice onRegister={() => auth.handleSignUp(data, loadingToggle)}  {...data} updateFields={updateFields} loadingToggle={() => loadingToggle()} isLoading={signUpLoading} />,
        ])



    return (
        <section className="flex flex-col items-center">
            {step}
        </section>

    )
}