import React, {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import BasicHeader from "../common/BasicHeader";


export default function UserGuideView(){


    const navigate = useNavigate()
    const goBack = useCallback(() =>{
        navigate(-1)
    },[navigate])

    const [firstToggle, setFirstToggle] = useState<boolean>(false)
    const [secondToggle, setSecondToggle] = useState<boolean>(false)
    const [thirdToggle, setThirdToggle] = useState<boolean>(false)
    const [forthToggle, setForthToggle] = useState<boolean>(false)
    const [fifthToggle, setFifthToggle] = useState<boolean>(false)
    const [sixthToggle, setSixthToggle] = useState<boolean>(false)

    const handleFirstToggle = useCallback(() => {
        setFirstToggle(firstToggle => !firstToggle)
    },[firstToggle])
    const handleSecondToggle = useCallback(() => {
        setSecondToggle(secondToggle => !secondToggle)
    },[secondToggle])
    const handleThirdToggle = useCallback(() => {
        setThirdToggle(thirdToggle => !thirdToggle)
    },[thirdToggle])
    const handleForthToggle = useCallback(() => {
        setForthToggle(forthToggle => !forthToggle)
    },[forthToggle])
    const handleFifthToggle = useCallback(() => {
        setFifthToggle(fifthToggle => !fifthToggle)
    },[fifthToggle])
    const handleSixthToggle = useCallback(() => {
        setSixthToggle(sixthToggle => !sixthToggle)
    },[sixthToggle])



    return (
        <section>
            <div className="flex flex-col items-center pb-133pxr">
                <BasicHeader/>

                <div className="w-screen ml-18pxr mt-30pxr   md:w-1/2">
                    <button>
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}/>
                    </button>
                </div>
                <div>
                    <div className="flex flex-col items-center ">
                        <div className="text-[15px] md:text-[30px] font-extrabold text-black">사용 설명서</div>
                    </div>

                </div>
                <div className="flex flex-col mt-72pxr md:mt-141pxr  w-350pxr md:w-600pxr text-black items-start text-[10px] md:text-[15px] font-medium">
                    <button onClick={handleFirstToggle}>
                        <div className="mt-14pxr md:mt-15pxr text-[15px] md:text-[20px] font-extrabold text-black">{firstToggle ? "▼" : "▶"} 사람 소개 받는 방법 3가지</div>
                    </button>

                    {firstToggle && (
                        <>
                            <div className={` mt-39pxr md:mt-54pxr text-[10px]  md:text-[18px] font-bold  md:ml-45pxr`}>
                                1. 필요한 네트워크 검색 (1:1 의뢰 요청)
                            </div>
                            <img className="mt-10pxr md:mt-32pxr" src="/user-guide1.png"/>


                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>찾고 있는 사람의 키워드를 검색하여 직접 선별 후 로비스트에게 의뢰를 요청해보세요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>#운전연수 잘하시는 분</div>
                                <div>”어머님이 25년간 장롱면허 상태에서 이분한테 배우고 지금 운전 잘 하고 다니십니다”</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>#동화작가님 #치킨창업 #뇌과학인공지능 #붕어빵장사</div>
                                <div>#서울대비올라 #NASA직원 #보스턴유학경험자</div>
                            </div>

                            <div className={` mt-40pxr md:mt-85pxr text-[10px]  md:text-[18px] font-bold  md:ml-45pxr`}>
                                2. 확성기 의뢰 발송 (의뢰 일괄 발송)
                            </div>
                            <img className="mt-10pxr md:mt-32pxr" src="/user-guide2.png"/>



                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>“사람 찾아 주세요!” “소개 가능한 로비스트분?”</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>LobbyD에 등록된 로비스트들에게 의뢰서를 일괄로 발송하실 수 있으며, 소개 연결이 가능한 로비스트가 회신을 드리게 됩니다.</div>
                            </div>

                            <div className={` mt-40pxr md:mt-85pxr text-[10px]  md:text-[18px] font-bold  md:ml-45pxr`}>
                                3. 커뮤니티 (공개 구인)
                            </div>
                            <img className="mt-10pxr md:mt-32pxr" src="/user-guide3.png"/>


                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>커뮤니티에 의뢰 내용을 올려 공개적으로 사람을 찾을 수 있습니다.</div>
                            </div>
                        </>

                    )}

                    <button onClick={handleSecondToggle}>
                        <div className="mt-14pxr md:mt-45pxr text-[15px] md:text-[20px] font-extrabold text-black">{secondToggle ? "▼" : "▶"} 소개 절차</div>
                    </button>

                    {secondToggle && (
                        <>
                            <img className="mt-10pxr md:mt-32pxr" src="/user-guide4.jpg"/>


                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>1. [의뢰] 의뢰인이 로비스트에게 의뢰를 요청합니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>2. [결정] 의뢰인은 로비스트로부터 연락 온 내용을 보고 소개를 받을지 결정을 하여 진행을 희망하면 로비스트에게 회신을 합니다. (이때 문제를 해결할 수 있는 사람이 로비스트 본인일 경우에도 진행은 동일합니다)</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>3. [상담] 의뢰인과 로비스트 간 서로 상세히 얘기를 나눠본 후 진행 여부를 최종 결정합니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>4. [소개연결] 협의가 잘 되었다면 의뢰인에게 지인분의 연락처를 건네줍니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>5. [소개비입금] 의뢰인과 지인분이 얘기를 한 번 나눠본 후 서로 업무 진행을 확정하는 시점에 소개비를 주고 받는 것을 추천드립니다.</div>
                            </div>
                            <div className="flex flex-row mt-16pxr md:mt-40pxr">
                                <div>이런 경우 때문이에요! : 의뢰인과 지인분이 막상 서로 얘기를 나눠보니 실제 업무를 진행하기엔 어려운 상황이라면 원하는 업무를 진행 못한 의뢰인의 입장에서는 소개비 지급이 부당하다고 느낄 수 있어요. <a className="font-bold underline"> 로비스트가 소개해주기 전 의뢰인과 자세하게 상담을 나눌수록 이러한 문제 발생을 줄일 수 있어요.</a> </div>
                            </div>
                            <div className="flex flex-row mt-16pxr md:mt-40pxr font-bold">
                                <div>* 소개비는 소개해주는 로비스트에게 지급되는 보수이며, 로비스트 본인이 해당될 경우에도 지급됩니다. </div>
                            </div>
                        </>
                    )}
                    <button onClick={handleThirdToggle}>
                        <div className="mt-14pxr md:mt-45pxr text-[15px] md:text-[20px] font-extrabold text-black">{thirdToggle ? "▼" : "▶"} 결제/정산</div>
                    </button>

                    {thirdToggle && (
                        <>
                            <img className="mt-10pxr md:mt-32pxr" src="/user-guide5.png"/>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>결제 카드와 입금 받으실 계좌를 미리 등록하면 거래 과정이 수월해요!</div>
                            </div>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr font-bold">
                                <div>1. 소개비 결제 (의뢰인)</div>
                            </div>
                            <img className="mt-10pxr md:mt-32pxr md:w-521pxr md:h-558pxr" src="/user-guide6.png"/>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>“의뢰인이 거래 확정 버튼을 누르기 전까지는 결제가 되지 않으니 걱정 말아요!”</div>
                            </div>
                            <div className="fl제ex flex-col mt-16pxr md:mt-40pxr">
                                <div>- 소개비 결제 시 로비스트에게 부과되는 수수료는 없으며, 거래확정 후 의뢰인에게 소개비의 10%가 서비스 수수료가 부과됩니다. </div>
                                <div>- 의뢰인이 거래를 확정하면, 의뢰인이 책정한 소개비가 로비스트의 계좌로 입금됩니다.</div>
                            </div>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>서비스 결제 단계</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-30pxr">
                                <div>1. 결제대기</div>
                                <div>거래확정 전까지는 소개비가 결제되지 않습니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-30pxr">
                                <div>2. 거래확정</div>
                                <div>의뢰인이 로비스트를 통해 소개받고 난 후 의뢰인이 거래를 확정하면
                                    1시간 내로 로비스트의 계좌로 소개비가 자동 입금됩니다. 로비스트가
                                    만약 계좌정보를 입력하지 않았다면 소개비 입금이 지연될 수 있습니다.
                                </div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-30pxr">
                                <div>3. 입금완료</div>
                                <div>소개비가 로비스트의 계좌로 입금이 완료됐습니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-30pxr">
                                <div>4. 의뢰취소</div>
                                <div>의뢰인이 거래를 확정하기 전에 취소한 상태입니다.
                                    의뢰인과 로비스트 간 개인적인 사정으로 거래를 취소하신다면 사전에
                                    서로 협의해주세요.
                                </div>
                            </div>

                            <div className="flex flex-col mt-40pxr md:mt-112pxr font-bold">
                                <div>2. 확성기 결제 (의뢰인)</div>
                            </div>
                            <img className="mt-10pxr md:mt-32pxr w-150pxr md:w-300pxr" src="/user-guide7.png"/>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>"심사 통과 시 등록된 카드로 확성기 비용이 결제되며 부적절한 내용이 포함되어 있을 경우 반려될 수 있습니다."</div>
                            </div>

                            <div className="flex flex-col mt-40pxr md:mt-112pxr font-bold">
                                <div>3. 소개비 정산 (로비스트)</div>
                            </div>
                            <img className="mt-10pxr md:mt-32pxr" src="/user-guide8.png"/>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>“의뢰인에게 소개를 완료하고 의뢰인이 거래를 확정하면 1시간 내로 로비스트의 계좌로 입금되며, 23:00 ~ 01:00 사이에는 은행 점검 등의 이유로 입금이 다소 늦어질 수 있습니다. 또한 로비스트가 계좌정보를 입력하지 않았다면 서비스 금액 입금이 지연될 수 있습니다.”</div>
                            </div>
                        </>
                    )}

                    <button onClick={handleForthToggle}>
                        <div className="mt-14pxr md:mt-45pxr text-[15px] md:text-[20px] font-extrabold text-black">{forthToggle ? "▼" : "▶"} 나의 네트워크(인맥) 등록 방법</div>
                    </button>

                    {forthToggle && (
                        <>
                            <img className="mt-10pxr md:mt-32pxr" src="/user-guide9.png"/>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>1. 나의 지인에 대한 키워드를 입력 후 + 버튼을 눌러주세요.</div>
                                <div>2. 아래 창이 생기면 나의 지인에 대한 설명을 입력해주세요.</div>
                                <div>3. 각각의 지인마다 원하는 소개비를 설정할 수 있으며, 소개비를 받지 않고 나의 가치를 올려줄 수 있는 소개 연결의 경우 ‘소개 나눔’으로 설정이 가능합니다.</div>
                                <div>4. 소개 연결을 성사 시킬 때마다 ‘해결 지수’가 상승하게 되며 소개 나눔 건의 경우에는 나눔 지수가 함께 상승됩니다.</div>
                            </div>

                        </>
                    )}
                    <button onClick={handleFifthToggle}>
                        <div className="mt-14pxr md:mt-45pxr text-[15px] md:text-[20px] font-extrabold text-black">{fifthToggle ? "▼" : "▶"} FAQ</div>
                    </button>
                    {fifthToggle && (
                        <>

                            <div className="flex flex-col mt-40pxr md:mt-112pxr font-bold">
                                <div>1. 소개비는 어느 타이밍에 주고 받는 것이 좋을까요?</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>사용자들마다 소개 방식과 상황이 다르지만 추천드려볼게요!</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>의뢰인과 소개해준 지인분이 얘기를 한 번 나눠본 후 서로 업무 진행을 확정하는 시점에 소개비를 주고 받는 것을 추천드립니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>이런 경우 때문이에요! : 의뢰인과 지인분이 막상 서로 얘기를 나눠보니 실제 업무를 진행하기엔 어려운 상황이라면 원하는 업무를 진행 못한 의뢰인의 입장에서는 소개비 지급이 부당하다고 느낄 수 있어요. 로비스트가 소개해주기 전 의뢰인과 자세하게 상담을 나눌수록 이러한 문제 발생을 줄일 수 있어요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>소개 과정에서 의뢰인의 변심, 불만족으로 인해 무산 될 수 있지만
                                    소개해주는 로비스트들은 중간에서 많은 시간과 노력을 들이고 있습니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>꼭! 서로 감정이 상하지 않게 자세한 상담을 나눈 후에 소개 요청을 해주세요.</div>
                            </div>

                            <div className="flex flex-col mt-40pxr md:mt-112pxr font-bold">
                                <div>2. 우리 로비스트들의 성향은 이렇대요</div>
                            </div>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>“사람과 사람을 소개해 주는 것 자체에 보람을 느끼는 사람”</div>
                                <div>“내가 도움이 되었을 때, 힘이 되었을 때 행복을 느끼는 사람”</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>{"<"}좋은 인맥이 많은 사람들의 성향{">"} *도서: 아는 사람의 힘</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>1. 커넥터는 무엇이든 잘 받아들인다. : 열린마음</div>
                                <div>2. 커넥터는 비전이 명확하다 : 방향성</div>
                                <div>3. 커넥터는 풍부함을 먼저 떠올린다. : 긍정 마인드셋</div>
                                <div>4. 커넥터는 의심보다 믿음을 선택한다. : 신뢰</div>
                                <div>5. 커넥터는 사교적이고 질문이 많다. : 호기심</div>
                                <div>6. 커넥터는 성실하다 : 책임감</div>
                                <div>7. 커넥터는 나누어 가진다. : 넉넉한 인심</div>
                            </div>

                            <div className="flex flex-col mt-40pxr md:mt-112pxr font-bold">
                                <div>3. 제가 찾는 사람이 LobbyD에 없어요</div>
                            </div>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>찾는 네트워크가 검색에 나오지 않는 경우</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>1. ‘확성기’ 서비스를 이용해주세요! LobbyD에 등록된 로비스트들에게 의뢰서를 일괄로 발송하실 수 있으며, 소개 연결이 가능한 로비스트가 회신을 드리게 됩니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>2. ‘커뮤니티’를 이용해주세요! 찾고 있는 사람을 커뮤니티에 글을 올리면 소개 연결이 가능한 로비스트가 댓글을 남기게 됩니다.</div>
                            </div>

                            <div className="flex flex-col mt-40pxr md:mt-112pxr font-bold">
                                <div>4. 타인의 허락을 맡지 않고 나의 네트워크(인맥)를 등록해도 되나요?</div>
                            </div>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>건강한 소개 문화를 만드는 LobbyD의 가치에 공감하여 착한 로비스트가 되어주세요!</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>사용자들이 네트워크를 등록할 때 가까운 친구, 지인, 가족의 경우는 동의를 구하지 않고 올리기도 하고, 서비스의 좋은 취지를 소개해주며 허락을 구하고 올리기도 합니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>최종적으로는 지인의 동의를 구해야만 중개가 가능하기에 소개 연결 시에는 지인의 동의가 필수입니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>인맥의 개인 정보가 비식별화 된 채로 올려지긴 하지만 혹여 지인이 원치 않는데 등록되어 있어 이에 신고 문의가 들어온다면 키워드 삭제 등의 제재가 이루어질 수 있습니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>LobbyD는 통신판매중개자로서 통신판매의 당사자가 아니며 개별 판매자가 제공하는 서비스에 대한 이행, 계약 등과 관련한 의무와 책임은 거래당사자에게 있습니다. 다만 일상 속 지인들간 소개로부터 발생하는 문제를 해결하고자 사회적 가치를 담아 좋은 취지로 시작된 서비스인만큼 유저들간 발생하는 문제를 최소화시키기 위해 서비스 기능을 꾸준히 발전시키고 CS 관리에 힘을 쏟아 악성 사용자에 대한 신고 관리를 통해 엄격히 제재를 취할 수 있도록 하겠습니다.</div>
                            </div>

                            <div className="flex flex-col mt-40pxr md:mt-112pxr font-bold">
                                <div>5. 어떤 사회적 가치가 있나요?</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>로비디는 사람들간 감정소비 없는 건강한 소개 문화를 만들어가고 있는 서비스입니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>“평소 누군가를 소개해달라는 부탁을 받거나, 부탁을 했던 경험 있으신가요?"</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>누군가를 소개해주기 위해서는 노력과 시간이 많이 드는데 선뜻 좋은 마음으로 나서긴 했지만 돌아오는 건 “고마워”, “다음에 밥살게” 로 끝나는 경우가 많습니다.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>딱히 바라는 건 없다곤 하지만 소소한 기프티콘 하나만 받아도 기분이 달라집니다.
                                    크던 작던 그 “성의”라는 것의 힘을 우리는 믿습니다.
                                </div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>작은 경험을 가진 사람부터 특이한 경험을 가진 사람들까지
                                    나의 인맥이 누군가에게 큰 도움이 될 수 있어요!</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>LobbyD 데이터 분석을 통해 바라봤을 때 집단 인맥의 파워는 대단했는데요.
                                    로비스트 160명일 때 의뢰 해결에 걸린 시간은 17분 ~ 17시간 사이, 의뢰 성사율 67%!</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>세상에 문제 10,000개가 있다면 6,000개 이상을 해결할 수 있는 우리들.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>우리 힘을 합쳐 세상에 있는 문제들을 함께 해결해 나가봐요!</div>
                            </div>
                        </>
                    )}
                    <button onClick={handleSixthToggle}>
                        <div className="mt-14pxr md:mt-45pxr text-[15px] md:text-[20px] font-extrabold text-black">{sixthToggle ? "▼" : "▶"}  사용자 간 분쟁 발생 시 (이용자 보호)</div>
                    </button>
                    {sixthToggle && (
                        <>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>로비디는 따뜻한 소개 여정을 경험하게 해드리는 것을 최우선 목표로 하고 있어요.
                                    로비스트, 의뢰인 모두의 행복을 위해 이용자 보호에 힘을 쓰고 있어요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>“거래 분쟁이 발생한 경우 어떻게 해야할까요?”
                                </div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>로비디의 모든 거래는 기본적으로 거래 당사자들끼리 대화를 통해 문제를 해결하도록 안내 드리고 있어요. 대화를 통해 거래 문제가 해결되지 않는 경우 아래 내용을 따라 상대방을 신고해 주세요. </div>
                            </div>
                            <div className="flex flex-row mt-16pxr md:mt-40pxr">
                                <div>더 자세한 내용은  <a className="text-[#0b58d5] underline font-bold" href="/userprotection">이용자 보호</a> 페이지에서 확인하실 수 있습니다.</div>
                            </div>

                        </>
                    )}




                </div>
            </div>
        </section>
    )

}