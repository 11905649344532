import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IPost,
    IPostResponse,
    IUser,
    IUserNotification,
    IUserNotificationCount,
    IUserNotificationResponse, IUserUnreadChatExist
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserInformation} from "../myprofile/useUserInformation";
import {useUserContext} from "../common/UserContext";

async function getUnreadChatExists(user:IUser|null): Promise<IUserUnreadChatExist> {

    const config: any = {};
    if (user){
        config.headers = getJWTHeader(user);
    }
    const { data } : AxiosResponse<IUserUnreadChatExist> = await axiosInstance.get('/user/chat/unread', config,);
    return data

}

interface UseUnreadChatExist {
    unreadExistCount:number;

}

export function useUnreadChatExist(): UseUnreadChatExist {
    const { user, updateUser, clearUser } = useUserContext();

    const {data, isLoading, isFetching, isError, error} = useQuery<IUserUnreadChatExist>(
        [queryKeys.unreadChatExist],
        () => getUnreadChatExists(user),
        {
            refetchOnMount:false,
            refetchOnReconnect:false,
            refetchIntervalInBackground:false,
            refetchInterval: 1000 * 5,
            retry:0,

            enabled: !!user, // user가 없으면 쿼리를 실행하지 않습니다.
        }

    );

    const unreadExistCount = data?.row.unreadMessageCount ?? 0
    return { unreadExistCount }

}
