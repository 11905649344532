import {FC, useMemo} from "react";

interface LobbyistActivationIconProps {
    status: boolean;
}

export const LobbyistActivationIcon: FC<LobbyistActivationIconProps> = ({ status }) => {


    return (
        <div className={`flex items-center justify-center mr-8pxr md:mr-12pxr w-109pxr h-36pxr md:h-45pxr ${status ? "bg-blue-500" : "bg-gray-500"}  text-white text-[14px] md:text-[18px] rounded font-bold`}>

      {status ? "ON": "OFF"}
    </div>
    );
};







