import {DivProps} from "./Div";
import {ChangeEvent, FC, useState} from "react";
import {formatCurrency} from "../../utils/data/helper";

export type ModalProps = DivProps & {
    className?:string
    onClickTogglePaymentModal:() => void
    introducePrice:number
    handleChange:(e: ChangeEvent<HTMLInputElement>) => void
    handleChickenPrice:() => void
    handleCoffeePrice:() => void
    onClickTogglePayMethodModal:() => void
    onClickConfirmPaymentModal:() => void
    handleCardRegister:boolean
}

export const PaymentModal: FC<ModalProps> = ({className:_className, onClickTogglePaymentModal ,children, introducePrice, handleChange, handleChickenPrice, handleCoffeePrice, handleCardRegister, onClickConfirmPaymentModal , onClickTogglePayMethodModal , ...props}) => {
    const className = ["bg-white w-350pxr  rounded-xl",_className].join(' ')


    return(

            <div className={`fixed items-center justify-center w-full h-screen left-0pxr top-0pxr flex bg-black bg-opacity-70 `}>
                <div className={className}>
                    <div className={`flex justify-end`}>
                        <button className={`h-12pxr w-12pxr  mt-11pxr mr-11pxr `} onClick={onClickTogglePaymentModal}>
                            <img src="/close.png"/>
                        </button>

                    </div>
                    <div className="flex flex-col items-center  mx-20pxr  mt-10pxr text-[25px] text-black font-semibold">
                        <div>소개비 지급하기</div>
                        <div className="flex flex-col justify-start w-330pxr">
                            <div className={`flex flex-row items-end text-black font-extrabold mt-30pxr `}>
                                <div className={`text-[13px] `}>소개비</div>

                            </div>
                            <div className="flex flex-row w-330pxr h-88pxr justify-start items-start">
                                <div className={`flex flex-row items-center h-44pxr w-220pxr ${introducePrice !== 0 && "pl-20pxr"} mt-10pxr bg-white rounded-xl border border-1`}>
                                    <input type="text"  value={introducePrice === 0 ? "" :formatCurrency(introducePrice)} onChange={handleChange} className={`resize-none ${introducePrice !== 0 ?  "w-180pxr" : "w-200pxr"}    py-12pxr bg-white text-black text-[12px] m-10pxr text-right ${introducePrice !== 0 &&  "-ml-20pxr"}  focus:outline-none `} placeholder={"원하는 만큼의 커피와 치킨을 클릭해보세요!"}/>
                                    {introducePrice !== 0 &&
                                        <div className="text-black text-[12px] ">원</div>
                                    }

                                </div>

                                <button onClick={handleCoffeePrice} className="flex flex-col items-center h-88pxr w-44pxr mt-10pxr ml-10pxr text-[10px] text-[#696969]">
                                    <img src="/coffee@3x.png" className="h-44pxr w-44pxr"/>
                                    <div>x1 click</div>
                                    <div>5,000원</div>
                                </button>
                                <button onClick={handleChickenPrice} className="flex flex-col items-center h-88pxr mt-10pxr w-44pxr ml-10pxr text-[10px] text-[#696969]">
                                    <img src="/chicken@3x.png" className="h-44pxr w-44pxr"/>
                                    <div>x1 click</div>
                                    <div>20,000원</div>
                                </button>
                            </div>

                            <div className={`text-[13px]  text-black mt-15pxr`}>결제예정 금액</div>
                            <div className={`flex flex-row items-center h-44pxr w-330pxr  mt-10pxr   bg-white rounded-xl border border-1`}>
                                <input type={`text`} value={formatCurrency(introducePrice * (1/10) + introducePrice)+"  원"}  readOnly={true}  className={`resize-none w-150pxr  p-8pxr ml-5pxr bg-white  text-black text-[12px]  text-left focus:outline-none  `}/>

                            </div>

                            <div className={`text-[10px] mt-10pxr text-[#696969]`}>
                                거래확정 후 결제 시 소개비의 10%가 서비스 수수료로 함께 부과됩니다.
                            </div>
                            <div className={`text-[10px] font-semibold mt-35pxr text-[#696969]`}>
                                결제 및 이용 안내
                            </div>
                            <div className={`text-[10px] font-semibold text-[#696969]`}>
                                - 결제 방식으로 등록된 신용/선불카드 결제만을 제공하고 있습니다.
                            </div>
                            <div className={`text-[10px] text-[#696969]`}>
                                - 명시된 금액은 부가세(VAT)포함 가격입니다.
                            </div>
                            <div className={`text-[10px] text-[#696969]`}>
                                - 결제 후 신용카드 전표는 개인 소득공제용으로만 이용하실 수 있습니다.
                            </div>
                        </div>

                        <button onClick={handleCardRegister ? onClickTogglePayMethodModal : onClickConfirmPaymentModal} disabled={introducePrice < 5000} className={`${introducePrice < 5000 ? "bg-[#e0e0e0]" : "bg-black"} text-[17px] h-40pxr my-30pxr rounded-md w-330pxr text-white font-bold`}>
                            거래 확정
                        </button>

                        {/*<button disabled={(handleButton && !isChecked && !isLoading )|| requestLoading} className={`w-341pxr h-35pxr md:w-600pxr md:h-45pxr mt-30pxr  md:mt-56pxr ${handleButton && !isChecked ? "bg-[#e0e0e0]" : "bg-black"} text-[15px] md:text-[20px] text-white font-bold rounded md:rounded-lg`}*/}
                        {/*        onClick={handleCardRegister ? onClickTogglePayMethodModal : () => {*/}
                        {/*            requestLoadingState()*/}
                        {/*            requestProgress(data)*/}
                        {/*        }}>의뢰서 발송하기</button>*/}

                    </div>
                </div>
            </div>


)
}
