import {UseMutateFunction, useMutation, useQueryClient} from "react-query";
import {
    CardPaymentConfirmRequestFormData,
    IUser,
} from "../../types/types";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import axios from "axios";
import {useUserContext} from "../common/UserContext";
export function usePaymentConfirmRequest(onSuccess:() => void, onError: (errorMessage: string) => void, onPaymentLoading:() => void ) : UseMutateFunction<void, unknown, CardPaymentConfirmRequestFormData, unknown> {

    async function confirmPaymentRequest(cardPaymentConfirmRequestFormData: CardPaymentConfirmRequestFormData, user: IUser | null,): Promise<void> {

        const config: any = {};
        if (user){
            config.headers = getJWTHeader(user);

            await axiosInstance.post(`/user/payment`, cardPaymentConfirmRequestFormData, config,);

        }
    }

    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate, isLoading} = useMutation((commentRequestData:CardPaymentConfirmRequestFormData) => confirmPaymentRequest(commentRequestData, user),
        {

            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.payment])
                queryClient.invalidateQueries([queryKeys.requestHistory])
                onSuccess()
                onPaymentLoading()
            },
            onError: (errorResponse) => {
                if (axios.isAxiosError(errorResponse)){
                    onError(errorResponse?.response?.data?.message || '알 수없는 에러가 발생했습니다. \n로비디 이메일로 연락주세요')
                    onPaymentLoading()
                }
            },
        })

    return mutate
}
