import axios , { AxiosResponse } from "axios";
import {axiosInstance} from "../../services/axiosInstance";
import type {IUserResponse} from "../../types/types";
import {useCallback, useState} from "react";
import {useGoogleLogin} from "@react-oauth/google";
import {useNavigate} from "react-router-dom";
import {RegisterFormData} from "../../types/types";
import {useUserContext} from "../common/UserContext";


interface UseAuth {
    googleLogin: () => void;
    appleLogin:(tokenResponse:string) => void;
    signin: (authorizationCode: string, source:"apple"|"google") => Promise<UserResponse>;
    handleSignUp: (authForm: RegisterFormData, toggleLoading:() => void) => void;
    signout: () => void;
}

type UserResponse = IUserResponse;


export function useAuth(): UseAuth {

    const { user, updateUser, clearUser } = useUserContext();

    const navigate = useNavigate()

    const goHome = useCallback(() => {
        navigate("/")
    },[navigate])

    const goSignUpForm = useCallback((tokenResponseCode:string, source:"google"|"apple") => {

        navigate(`/auth/`,{
            state:{
                authCode:tokenResponseCode,
                source:source
            }
        })
    },[navigate])

    const appleLogin = async (tokenResponse: string) => {
        try {
            const response: UserResponse  = await signin(tokenResponse, "apple");
            updateUser(response.row);
            goHome()

        }catch (errorResponse){
            console.log(errorResponse)
            axios.isAxiosError(errorResponse) && errorResponse.response?.status === 404 && goSignUpForm(tokenResponse, "apple")
        }
    }


    const handleGoogleSuccess = async (tokenResponse: any) => {
        try {
            const response: UserResponse  = await signin(tokenResponse.code, "google");
            updateUser(response.row);
            goHome()

        }catch (errorResponse){
            axios.isAxiosError(errorResponse) && errorResponse.response?.status === 404 && goSignUpForm(tokenResponse.code, "google")
        }
    }
    const googleLogin  = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        flow: "auth-code",
    });

    async function authServerSignInCall(
        urlEndpoint: string,
        authorizationCode: string,
        source: "google" | "apple"
    ): Promise<UserResponse> {

        const { data }: AxiosResponse<UserResponse> = await axiosInstance({
            url: urlEndpoint,
            method: "POST",
            data: {
                authorizationCode: authorizationCode,
                source: source,
                redirectUrl: source === "google" ? process.env.REACT_APP_REDIRECT_URL : `${process.env.REACT_APP_REDIRECT_URL}/login`,
                deviceToken: "",
            },
            headers: { "Content-Type": "application/json" },
        });
        return data;

    }


    const handleSignUp = async (registerFormData: RegisterFormData, toggleLoading:() => void) : Promise<void> => {
        try {
            const response: UserResponse  = await signup(registerFormData);
            console.log("확인" + response.row)
            updateUser(response.row)

            goHome();
        }catch (errorResponse){
            if (axios.isAxiosError(errorResponse))
                alert("회원가입중 에러가 나타났습니다 확인해주세요" + errorResponse.response?.data?.message )
        }finally {
            toggleLoading()
        }
    }

    async function authServerSignUpCall(
        urlEndpoint: string,
        authForm: RegisterFormData
    ): Promise<UserResponse> {
        const { data }: AxiosResponse<UserResponse> = await axiosInstance({
            url: urlEndpoint,
            method: "POST",
            data:  authForm ,
            headers: { "Content-Type": "application/json" },
        });
        return data;
    }




    async function signin(authorizationCode: string, source:"apple" | "google"): Promise<UserResponse> {
        // TODO: APPLE LOGIN 유무 파악 할 수 있도록 Setting
        return authServerSignInCall('/user/member/login', authorizationCode, source)

    }
    async function signup(authForm: RegisterFormData): Promise<UserResponse> {
        return authServerSignUpCall('/user/member/signup', authForm)
    }

    function signout(): void {
        clearUser();
    }
    return {googleLogin, appleLogin, signin, handleSignUp, signout}
}