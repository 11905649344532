import {Avatar, DivProps, HashTag, Paragraph, Subtitle, Title} from "./index";
import React, {FC, ReactElement, useCallback, useState} from "react";
import {ILobbyist} from "../../types/types";
import {formatCurrency} from "../../utils/data/helper";
import {useUser} from "../../hooks/common/useUser";
import {useUserContext} from "../../hooks/common/UserContext";

export type CardProps = DivProps & {
    openModal: (lobbyistId:string) => void
    lobbyist: ILobbyist
    searchWord?: string
}

export const Card: FC<CardProps> = ({className: _className,lobbyist, searchWord,openModal,...props }) =>{

    const {user} = useUserContext()


    const filteredPrices = lobbyist.keywords
        ? lobbyist.keywords.filter(keyword => keyword.price > 0)
        : [];

    const leastPrice = filteredPrices.length > 0
        ? Math.min(...filteredPrices.map(keyword => keyword.price))
        : 0;




    const filteredKeywords = lobbyist.keywords
        ? lobbyist.keywords.filter((keyword) => {
            if (searchWord) {
                const lowerSearchWord = searchWord.toLowerCase();
                return (
                    keyword.word.toLowerCase().includes(lowerSearchWord) ||
                    keyword.detail.toLowerCase().includes(lowerSearchWord)
                );
            }
        }):[]


    const hashTags = lobbyist.keywords ? lobbyist.keywords.map((keyword,index) =>{

        const isFiltered = filteredKeywords.includes(keyword);
        const bgColor = isFiltered ? " bg-black " : " bg-white ";
        const textColor = isFiltered ? " text-white " : " text-[#696969] ";

        return(
            <section key={index}>
                <div className="relative inline-block group">
                    {(keyword) &&
                        <>
                    <span className="cursor-pointer">
                        <HashTag className={bgColor + textColor} key={keyword.word} hashTag={keyword.word}/>
                    </span>
                            <div className="hidden group-hover:block absolute left-0 w-170pxr md:w-300pxr text-gray-700 rounded-xl bg-[#eaeaea] z-50">
                                <div className="flex flex-col text-[10px] md:text-[13px] text-[#555555] font-semibold px-15pxr py-12pxr">
                                    <div>| 소개비: {keyword.price ? formatCurrency(keyword.price) : "0"}원</div>
                                    <div>| 설명: {keyword.detail}</div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>
        )
    }) : []

    return (
        <section key={lobbyist._id} className="relative p-10pxr">
            <div className={`flex flex-col justify-start pl-5pxr md:pl-20pxr min-h-[145px] md:min-h-[180px] w-340pxr md:w-420pxr border border-1 rounded-xl overflow-visible shadow-md `}>
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center justify-center h-5pxr md:h-18pxr"/>
                </div>
                <div className={`flex flex-row  m-10pxr md:m-0pxr`}>
                    <div className={`w-53pxr md:w-84pxr flex flex-col`}>
                        <Avatar src={lobbyist.profileImage || "default-profile.png"} />
                        { user?._id !== lobbyist?._id && (
                            <button className={`rounded my-5pxr md:my-10pxr w-54pxr md:w-65pxr h-20pxr md:h-25pxr bg-black text-[10px] md:text-[11px] text-white flex items-center justify-center`} onClick={() => openModal(lobbyist._id)}>의뢰하기</button>
                        )}
                    </div>
                    <div className={`flex flex-col items-start mt-5pxr md:mt-0pxr ml-14pxr`}>
                        <div className={`flex flex-row items-center md:items-end w-258pxr  md:w-380pxr`}>
                            <div className={`flex flex-row  text-black `}>
                                <Subtitle numberOfLines={1}>{lobbyist.nickName}</Subtitle>
                            </div>
                            <div className="text-[13px] font-semibold ml-3pxr md:ml-5pxr md:mt-20pxr text-black">
                                | {formatCurrency(leastPrice)}원 ~
                            </div>
                        </div>
                        <div className="my-8pxr md:my-12pxr mr-15pxr md:w-300pxr flex flex-wrap flex-row">
                            {hashTags}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
