import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse, AxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";
import {
    AdminOneToOneRequest,
    IAdminOneToOneRequestResponse,
    IAdminUser,
    IAdminWholeMember,
    IAdminWholeMemberResponse
} from "../types";
import {useAdminUserContext} from "../AdminUserProvider";

async function getOneToOneRequest(
    adminUser: IAdminUser | null,
    page: number,
    lobbyStatus:string | null,
    createdStartedAt: Date | null,
    createdEndedAt: Date | null
): Promise<IAdminOneToOneRequestResponse> {


    const config: AxiosRequestConfig = {};
    if (adminUser)
        config.headers = { authorization: `${adminUser.accessToken}` }
    if (lobbyStatus){
        config.params = {
            page: page,
            limit: 10,
            createdStartedAt: createdStartedAt?.toISOString(),
            createdEndedAt: createdEndedAt?.toISOString(),
            lobbyStatus: lobbyStatus
        };
    }else{
        config.params = {
            page: page,
            limit: 10,
            createdStartedAt: createdStartedAt?.toISOString(),
            createdEndedAt: createdEndedAt?.toISOString(),

        };
    }


    const { data } : AxiosResponse<IAdminOneToOneRequestResponse> = await axiosInstance.get(`/admin/lobby/oto`, config,);


    return data;

}

interface UseAdminOneToOneRequest {
    requests:AdminOneToOneRequest[]
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
    updatePageIndex: (pageIncrement: number) => void;
    pageIndex: number;
    hasNextPage:boolean
    updateCreatedStartedAt: (date: Date | null) => void;
    updateCreatedEndedAt: (date: Date | null) => void;
    updateLobbyStatus: (query: "request" | "complete" | "cancel" | null) => void;
    lobbyStatus: "request" | "complete" | "cancel" | "" | null,
    maxPage: number;
}

export function useAdminOneToOneRequest(
    filter?: () => void
): UseAdminOneToOneRequest {

    const { adminUser,setAdminUser } = useAdminUserContext();
    const [lobbyStatus, setLobbyStatus] = useState<"request" | "complete" | "cancel"  | null>(null);
    const [createdStartedAt, setCreatedStartedAt] = useState<Date | null>(null);
    const [createdEndedAt, setCreatedEndedAt] = useState<Date | null>(null);
    const [pageIndex, setPageIndex] = useState(1)
    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
    } = useQuery<IAdminOneToOneRequestResponse>(
        [queryKeys.adminLobby,pageIndex, lobbyStatus, createdStartedAt, createdEndedAt],
        () => getOneToOneRequest(adminUser, pageIndex, lobbyStatus, createdStartedAt, createdEndedAt),{

        }
    )


    function updatePageIndex(pageIncrement:number):void {
        setPageIndex((prev) => prev + pageIncrement)
    }
    function updateCreatedStartedAt(date: Date | null): void {
        setCreatedStartedAt(date);
    }

    function updateCreatedEndedAt(date: Date | null): void {
        setCreatedEndedAt(date);
    }

    function updateLobbyStatus(status: "request" | "complete" | "cancel" | null) {
        setLobbyStatus(status);
        // 선택한 의뢰 상태를 사용하여 필터링을 적용하거나 필요한 로직을 수행합니다.
    }
    const hasNextPage = (data?.count || 0) >  (10 * pageIndex)
    const maxPage = Math.ceil((data?.count || 0) / 10);



    useEffect(() => {

        queryClient.prefetchQuery([queryKeys.adminLobby,pageIndex + 1],
            () => getOneToOneRequest(adminUser, pageIndex + 1,  lobbyStatus, createdStartedAt, createdEndedAt),
        )
    },[queryClient,pageIndex])



    const requests = data?.rows || []


    return {
        requests,
        isLoading,
        isFetching,
        isError,
        error,
        pageIndex,
        updatePageIndex,
        hasNextPage,
        updateLobbyStatus,
        lobbyStatus,
        updateCreatedEndedAt,
        updateCreatedStartedAt,
        maxPage
    };
}