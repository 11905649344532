import {FC, useCallback, useEffect, useState} from "react";
import {DivProps} from "../common/Div";
import {useNavigate} from "react-router-dom";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {queryClient} from "../../services/react-query/queryClient";
import {queryKeys} from "../../services/react-query/constants";

export type PaymentMethodCardProps = DivProps & {
    title:string
    card?:boolean
    bank?:boolean
    registered?:boolean
}

function formatCardNumber(cardNumber:string) {
    const formattedCardNumber = cardNumber.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();
    return formattedCardNumber;
}

export const PaymentMethodCard:FC<PaymentMethodCardProps> = ({className:_className ,title,card,bank,registered,...props}) =>{

    const navigate = useNavigate()
    const {userInformation , isLoading} = useUserInformation()
    const isCardRegistered = userInformation?.cardInfo && userInformation?.cardInfo.length > 0
    const isAccountRegistered = userInformation?.accountInfo

    const goCardRegisterForm = useCallback(() => {
        navigate("/payment/register/card")
        window.scrollTo(0, 0);
    },[navigate])

    const goAccountRegisterForm = useCallback(() => {
        navigate("/payment/register/account")
        window.scrollTo(0, 0);
    },[navigate])


    const className = [`flex flex-col w-296pxr h-173pxr md:w-380pxr md:h-220pxr ${card ? "bg-black" : "bg-[#2e2a4d]"}  rounded-xl`,_className].join(' ')

    return (
        <section>
            <div className={className}>
                <div className="text-white h-35pxr   ml-22pxr md:ml-35pxr mt-15pxr  text-[14px] md:text-[16px] font-bold">{title}</div>
                <div className="h-5pxr mt-5pxr md:mt-3pxr bg-white "/>
                {isLoading ? <div className="h-40pxr text-white  ml-22pxr mt-14pxr md:ml-35pxr md:mt-23pxr text-[18px] md:text-[20px] font-bold">카드 정보 가져오는중</div>:
                    ((card ?
                        (isCardRegistered ? (
                                <div className="h-40pxr text-white  ml-22pxr mt-14pxr md:ml-35pxr md:mt-23pxr text-[18px] md:text-[24px] font-bold">
                                    <div className="flex flex-col items-start">
                                        <div>{formatCardNumber(userInformation?.cardInfo[0].cardNo)}</div>
                                        <div>{userInformation?.cardInfo[0].cardName}</div>
                                    </div>
                                </div>):
                            <div className="h-40pxr text-white ㅏ ml-22pxr mt-14pxr md:ml-35pxr md:mt-23pxr text-[18px] md:text-[24px] font-bold">카드를 등록해주세요</div>)
                        :
                        (isAccountRegistered ? (
                                    <div className="h-40pxr text-white  ml-22pxr mt-14pxr md:ml-35pxr md:mt-23pxr text-[18px] md:text-[24px] font-bold">
                                        <div className="flex flex-col items-start">
                                            <div>{userInformation.accountInfo.accountNumber}</div>
                                            <div className="flex flex-row justify-between"><div>{userInformation?.accountInfo.bankName}</div>   <div className="ml-10pxr md:ml-15pxr">{userInformation.accountInfo.accountHolder}</div></div>
                                        </div>
                                    </div>
                        ):
                        <div className="h-40pxr text-white  ml-22pxr mt-14pxr md:ml-35pxr md:mt-23pxr  text-[18px] md:text-[24px] font-bold">계좌를 등록해주세요</div>
                        )
                    ))}

                <div className="flex flex-row items-end justify-end">
                    <div className="flex flex-col ">
                        <button onClick={card ?  goCardRegisterForm : goAccountRegisterForm} className="items-center justify-center bg-white h-25pxr w-80pxr md:h-30pxr md:w-110pxr mt-20pxr mr-15pxr md:mt-45pxr md:mr-20pxr text-black font-bold text-[10px] md:text-[14px] text-center rounded">
                            등록/변경하기
                        </button>
                    </div>

                </div>
            </div>
        </section>
    )

}