import {FC, useCallback, useState, ChangeEvent} from "react";
import {DivProps} from "../common/Div";
import {Input} from "postcss";
import {banks} from "../../BankLists";
import {AlertModal} from "../common/AlertModal";
import {useBankAccountRegister} from "../../hooks/payment/useBankAccountRegister";
import {BankAccountRegisterRequestFormData, CardRegisterRequestFormData} from "../../types/types";

export type AccountRegisterFormProps = DivProps & {
    updateFields: (fields: Partial<BankAccountRegisterRequestFormData>) => void,
    bankData: BankAccountRegisterRequestFormData
}



export const AccountRegisterForm:FC<AccountRegisterFormProps> = ({updateFields, bankData}) =>{

    const handleChange = (field: keyof BankAccountRegisterRequestFormData) => (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { value } = event.target;
        if (field === "accountNumber") {
            const regex = /^\d{0,14}$/;
            if (regex.test(value) || value === "") {
                updateFields({ [field]: value });
            }
        } else if (field === "accountHolder") {
            const regex = /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!@#$%^&*()_+{}\[\]:;“”‘’'"\/?,.<>-\\|~`]{0,20}$/;
            if (regex.test(value)) {
                updateFields({ [field]: value });
            }
        } else {
            updateFields({ [field]: value });
        }
    };


    const bankLists = banks.map((value) => {
        return (
            <option value={value}>{value}</option>
        )
    })

    return (
        <section>
            <div className="flex flex-col justify-center">
                <div className="mt-49pxr md:mt-50pxr text-[#696969] font-bold text-[13px] md:text-[20px] text-start">은행선택</div>
                <div className="flex flex-row space-x-12pxr md:space-x-20pxr w-260pxr md:w-400pxr mt-16pxr md:mt-26pxr">
                    <div className="flex flex-col justify-center items-start relative">
                        <select
                            name="bankName"
                            value={bankData.bankName}
                            onChange={(e) => handleChange("bankName")(e)}
                            className="bg-white w-91pxr md:w-150pxr  focus:outline-none text-gray-500 text-[13px] md:text-[20px]">
                            {bankLists}
                        </select>
                        <div className="h-2pxr w-91pxr mt-10pxr md:w-150pxr bg-black"/>
                    </div>
                </div>

                <div className="mt-49pxr md:mt-50pxr text-[#696969] font-bold text-[13px] md:text-[20px] text-start">계좌번호 입력 (-없이)</div>
                <div className="flex flex-row space-x-12pxr md:space-x-20pxr w-260pxr md:w-400pxr mt-16pxr md:mt-26pxr">
                    <div className="flex flex-col justify-center items-start">
                        <input
                            name="accountNumber"
                            value={bankData.accountNumber}
                            onChange={(e) => handleChange("accountNumber")(e)}
                            className="bg-white w-155pxr md:w-250pxr focus:outline-none text-gray-500 text-[13px] md:text-[20px]" type={"number"} placeholder="000000"/>

                        <div className="h-2pxr w-155pxr mt-10pxr md:w-250pxr bg-black"/>
                    </div>
                </div>
                <div className="mt-49pxr md:mt-50pxr text-[#696969] font-bold text-[13px] md:text-[20px] text-start">예금주명</div>
                <div className="flex flex-row space-x-12pxr md:space-x-20pxr w-260pxr md:w-400pxr mt-16pxr md:mt-26pxr">
                    <div className="flex flex-col justify-center items-start">
                        <input
                            name="accountHolder"
                            value={bankData.accountHolder}
                            onChange={(e) => handleChange("accountHolder")(e)}
                            className="bg-white w-53pxr md:w-150pxr focus:outline-none text-gray-500 text-[13px] md:text-[20px]" type={"text"} maxLength={10} placeholder=""/>

                        <div className="h-2pxr w-53pxr mt-10pxr md:w-86pxr bg-black"/>
                    </div>
                </div>
            </div>



        </section>
    )

}