import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {ImageUrlResponse, IUser, IUserResponse, RequestFormData, UserDetailRequestFormData} from "../../types/types";
import {UseMutateFunction, useMutation, useQuery} from "react-query";
import {queryKeys} from "../../services/react-query/constants";
import axios, {AxiosResponse} from "axios";
import {queryClient} from "../../services/react-query/queryClient";
import {useUserContext} from "../common/UserContext";


export function useModifyUserInformation(
    onSuccess:() => void,
    imageFile:File | null
) : UseMutateFunction<void, unknown, UserDetailRequestFormData, unknown> {

    async function setUserDetailRequest(userDetailRequestFormData: UserDetailRequestFormData, user: IUser | null,): Promise<void> {

        if (imageFile) {
            const imageUrl = await uploadImage(imageFile, user)
            if (imageUrl) {
                userDetailRequestFormData.profileImage = imageUrl
            }

        }

        const config: any = {};
        if (user) {
            config.headers = getJWTHeader(user);
            await axiosInstance.put(`/user/member/info/`, userDetailRequestFormData, config,);
        }
    }

    async function uploadImage(imageFile: File, user: IUser | null): Promise<string | null> {
        if (!user) {
            return null;
        }

        const formData = new FormData();
        formData.append('files', imageFile);

        const config: any = {
            headers: {
                ...getJWTHeader(user),
                'Content-Type': 'multipart/form-data',
            },
        };

        const response: AxiosResponse<ImageUrlResponse | null> = await axiosInstance.post('/shared/upload/public/images', formData, config);

        // 이미지 URL 추출
        return response.data?.rows[0] || "";
    }


    const { user, updateUser, clearUser } = useUserContext();

    const {mutate} = useMutation((userDetailRequestFormData:UserDetailRequestFormData) => setUserDetailRequest(userDetailRequestFormData, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.userInformation])
                queryClient.invalidateQueries([queryKeys.lobbyists])
                onSuccess()
            }
        })

    return mutate
}

