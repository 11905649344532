import React, {useState, useEffect, FormEvent, useCallback, ChangeEvent} from "react";
import axios from "axios";
import io from "socket.io-client";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {useAuth} from "../../hooks/auth/useAuth";
import {useUser} from "../../hooks/common/useUser";
import {text} from "stream/consumers";
import ChatMessageRow from "./ChatMessageRow";
import {AlertModal, Avatar, Card} from "../common";
import MainHeader from "../main/MainHeader";
import {Image} from "../common/Image";
import BasicHeader from "../common/BasicHeader";
import {useSocket} from "../../hooks/chat/SocketProvider";
import {ReviewModal} from "../common/ReviewModal";
import {ReviewModifyModal} from "../common/ReviewModifyModal";
import {CardPaymentConfirmRequestFormData, ReviewRequestFormData} from "../../types/types";
import {useReviewRequest} from "../../hooks/history/useReviewRequest";
import {Modal} from "../common/Modal";
import {PaymentModal} from "../common/ChatPaymentModal";
import {useOneToOneRequest} from "../../hooks/requests/useOneToOneRequest";
import {queryClient} from "../../services/react-query/queryClient";
import {queryKeys} from "../../services/react-query/constants";
import {DealCompleteAlertModal} from "../common/DealCompleteAlertModal";
import {usePaymentConfirmRequest} from "../../hooks/history/usePaymentConfirmRequest";
import {useUserContext} from "../../hooks/common/UserContext";



export type ChatMessage = {
    v: number;
    id: string;
    message: string;
    createdAt: string;
    roomId: string;
    senderId: string;
    unreadMemberIds: string[];
    updatedAt: string;
    isPaymentChat:boolean
    price: number
};
interface Member {
    leftAt: string;
    memberId: string;
    nickName: string;
    profileImage: string;
    status: string;
}

interface RoomInfo {
    members: Member[];
    roomId: string;
}
interface RoomInfo {
    members: Member[];
    roomId: string;
}

type ChatData = {
    chats: ChatMessage[];
    roomInfo: RoomInfo;
}

export function LobbyChat() {

    const {userInformation} = useUserInformation()
    const {user} = useUserContext()
    const { roomId } = useParams<{ roomId:string }>();
    // const location = useLocation();
    // const { imagePath, partnerNickname } = location.state;
    const navigate = useNavigate()
    const goBack = useCallback(() => {
        navigate(-1)
    },[navigate])
    const goReview = useCallback(() => {
        navigate("/review")
    },[navigate])

    const [input, setInput] = useState<string>("");

    const [send, setSend] = useState<boolean>(false);

    const [chatList, setChatList] = useState<ChatMessage[]>([]);
    const [memberList, setMemberList] = useState<Member[]>([]);
    const [redDot, setRedDot] = useState<boolean>(false);
    const { socket } = useSocket();
    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInput(e.target.value);
    };
    /* 리뷰 관련 변수들 */


    // 소개비 결제용 변수
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorModal, setIsErrorModal] = useState<boolean>(false);
    const onClickToggleErrorModal = useCallback((errorMessage:string) => {
        setIsErrorModal(isErrorModal => !isErrorModal);
        setErrorMessage(errorMessage)
    }, [isErrorModal])




    const [isPaymentModal, setIsPaymentModal] = useState<boolean>(false);
    const onClickTogglePaymentModal = useCallback(() => {
        setIsPaymentModal(isPaymentModal => !isPaymentModal);
    }, [isPaymentModal])

    const [introducePrice, setIntroducePrice] =  useState<number>(0)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/,/g, "");
        if (/^\d{0,8}$/.test(newValue)) {
            const parsedValue = parseInt(newValue, 10);
            let roundedValue = parsedValue;
            if (parsedValue >= 10000) {
                roundedValue = Math.round(parsedValue / 1000) * 1000;
            }
            setIntroducePrice(isNaN(roundedValue) ? 0 : roundedValue);
        }
    };

    const confirmRequestData : CardPaymentConfirmRequestFormData = {
        introducePrice: introducePrice,
        cardNo: userInformation?.cardInfo[0]?.cardNo || "",
        roomId: roomId || ""
    }


    const handleCoffeePriceChange = () => {
        setIntroducePrice(prevState => prevState + 5000)
    }
    const handleChickenPriceChange = () => {
        setIntroducePrice(prevState => prevState + 20000)
    }
    const sendMessage = () => {
        socket?.emit("send", { roomId: roomId, message: input });
        setInput("")
    };

    const [isConfirmPaymentModal, setIsConfirmPaymentModal] = useState<boolean>(false);
    const onClickConfirmPaymentModal = useCallback(() => {
        setIsConfirmPaymentModal(isConfirmPaymentModal => !isConfirmPaymentModal);
    }, [isConfirmPaymentModal])



    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
    const paymentLoadingState = useCallback(() => {
        setPaymentLoading((paymentLoading) => !paymentLoading)
    },[paymentLoading])

    const confirmRequest = usePaymentConfirmRequest(() => onClickConfirmPaymentModal(), (errorMessage) => {
        onClickToggleErrorModal(errorMessage)
        setErrorMessage(errorMessage)
    }, () => {
        paymentLoadingState()
        onClickTogglePaymentModal()
    })
    /* 카드 등록하기 로직 추가 */
    const goPaymentMethodRegister = useCallback(() => {
        navigate("/payment/register/card")
    },[navigate])

    const handleCardRegister:boolean = userInformation?.cardInfo.length === 0

    const [isPayMethodAlertModal, setIsPayMethodAlertModal] = useState<boolean>(false);
    const onClickTogglePayMethodModal = useCallback(() => {
        setIsPayMethodAlertModal(!isPayMethodAlertModal);
    }, [isPayMethodAlertModal]);

    const [isLoading, setIsLoading] = useState(false);
    const updateCardInfo = async () => {
        setIsLoading(true);
        try {
            await queryClient.invalidateQueries([queryKeys.userInformation]);
        } catch (error) {
            // 에러 처리
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        updateCardInfo()
    },[])



    useEffect(() => {
        if (!socket || !roomId) return;

        console.log(`Joining room ${roomId}`);
        socket.emit("join", { roomId });

        socket.on("join", (data: ChatData) => {
            console.log(`Joined room ${roomId}`);
            setChatList(data.chats);
            setMemberList(data.roomInfo.members);
        });

        socket.on("receive", (data: ChatMessage) => {
            console.log(`Received message in room ${roomId}: ${data.message}`);
            setChatList((prevChatList) => [...prevChatList, data]);
        });

        socket.on("exception", (error) => {
            console.error(`exception: ${error.errorMessage}`);
        });

        return () => {
            console.log(`Leaving room ${roomId}`);
            socket.emit("leave", { roomId });
            socket.off("join");
            socket.off("receive");
            socket.off("error");
        };
    }, [socket, roomId]);

    const oppositeLobbyist = memberList?.filter(member => member.memberId !== user?._id)[0]
    const oppositeLobbyistProfileImage = oppositeLobbyist ? oppositeLobbyist.profileImage : undefined;
    const oppositeLobbyistProfileNickname = oppositeLobbyist ? oppositeLobbyist.nickName : undefined;


    const renderChat = chatList.map((chatMessage: ChatMessage, index: number) => {
        console.log(chatMessage.price)
        return(
            <div className="my-15pxr" key={index}>

                <ChatMessageRow profileImage={oppositeLobbyistProfileImage ?? ""} message={chatMessage} goReviewPage={goReview}/>
            </div>
            )
    })

    const endOfMessagesRef = React.useRef<null | HTMLDivElement>(null);

    const [chatListLength, setChatListLength] = useState(chatList.length);
    useEffect(() => {
        if (chatList.length !== chatListLength) {
            endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
            setChatListLength(chatList.length);
        }
    }, [chatList, chatListLength]);



    return  (

        <div className="flex flex-col items-center justify-start w-screen h-650pxr md:h-screen">
            <BasicHeader/>
            <div className="flex flex-row justify-between items-between mt-20pxr w-375pxr ">

                <div className="flex flex-row">
                    <button className={`w-15pxr h-30pxr mr-10pxr`} onClick={goBack}>
                        <img src="/back.png"/>
                    </button>
                    <Image src={oppositeLobbyistProfileImage ?? ""} className="w-35pxr h-35pxr rounded-xl"/>
                    <div className="font-semibold text-[20px] pl-5pxr">{oppositeLobbyistProfileNickname ?? ""}</div>
                </div>
                {/*// TODO: 결제 하기 창 만들고 넣기 */}


                <button onClick={onClickTogglePaymentModal}  className="flex flex-row items-center justify-center bg-black w-135pxr h-35pxr rounded-lg">
                    <div className="flex flex-row items-end">
                        <img className="w-25pxr h-25pxr mr-10pxr" src="/card_white@3x.png"/>
                        <div className="text-[15px] font-semibold text-white">소개비 결제</div>

                    </div>
                </button>
            </div>
            {/*<div className="bg-[#a6a6a6] w-375pxr h-1pxr mt-10pxr"/>*/}
            <div className="flex-grow w-375pxr h-3/5 bg-[#ffffff]  mt-25pxr overflow-auto p-24pxr">
                <img src="/chat_guide.png" alt="가이드"/>
                {renderChat}
                <div ref={endOfMessagesRef}/>
            </div>

            <div className="flex space-x-2pxr md:mb-50pxr">
                <form
                    className="flex flex-row "
                    onSubmit={(e) => {
                        e.preventDefault();
                        sendMessage();
                    }}
                >
                    <input
                        className="w-315pxr mr-10pxr px-16pxr py-8pxr border rounded-lg"
                        type="text"
                        placeholder="내용을 입력하세요"
                        value={input}
                        onChange={handleTextChange}
                    />
                    <button
                        disabled={input.length === 0}
                        className="w-45pxr text-white rounded-lg"
                        type="submit"
                    >
                        <img src={input.length === 0 ? "/send.png" : "/send_black@3x.png"} className="w-45pxr h-45pxr" />
                    </button>
                </form>
            </div>

            {isPaymentModal &&
                <PaymentModal onClickTogglePaymentModal={onClickTogglePaymentModal} introducePrice={introducePrice} handleChange={handleChange} handleChickenPrice={handleChickenPriceChange} handleCoffeePrice={handleCoffeePriceChange} handleCardRegister={handleCardRegister} onClickTogglePayMethodModal={onClickTogglePayMethodModal} onClickConfirmPaymentModal={onClickConfirmPaymentModal}/>
            }
            {isPayMethodAlertModal  &&
                <AlertModal >
                    <div className={`flex flex-col items-center justify-center`}>
                        <div className={`text-[16px] md:text-[24px] mt-30pxr md:mt-50pxr text-black font-semibold `}>결제 카드 등록 안내</div>
                        <div className={`text-[10px] md:text-[13px] mt-36pxr md:mt-45pxr text-[#5d5d5d] font-semibold`}>소개비 설정을 위해서 결제 카드 등록이 필요합니다.</div>
                        <div className={`text-[10px] md:text-[13px] mt-10pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>의뢰 내용이 저장된 상태로</div>
                        <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>결제 카드 등록 페이지로 이동됩니다.</div>

                        <div className={`flex flex-row mt-35pxr md:mt-65pxr`}>
                            <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-[#e0e0e0] text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={onClickTogglePayMethodModal}>돌아가기</button>
                            <button  className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={() => {
                                goPaymentMethodRegister()
                            }}>등록하기</button>
                        </div>
                    </div>
                </AlertModal>}

            {isConfirmPaymentModal &&
                <DealCompleteAlertModal onClickToggleDealCompleteModal={() => onClickConfirmPaymentModal()} confirmRequestData={confirmRequestData} confirmRequest={confirmRequest} paymentLoadingToggle={() => paymentLoadingState()} paymentLoading={paymentLoading} />}

            {isErrorModal && (
                <AlertModal>
                    <div className={`flex flex-col items-center justify-center`}>
                        <div className={`text-[16px] md:text-[28px] mt-40pxr md:mt-65pxr text-black font-semibold `}>결제 오류</div>
                        <div className={`text-[10px] md:text-[17px] mt-36pxr md:mt-42pxr text-gray-400 font-semibold`}>{errorMessage}</div>
                        <div className={`text-[10px] md:text-[17px] mt-8pxr md:mt-15pxr text-gray-400 font-semibold`}>
                            <div className={`flex flex-row mt-36pxr md:mt-61pxr`}>
                                <button className={`mr-8pxr md:mr-12pxr w-243pxr md:w-407pxr h-36pxr md:h-60pxr bg-gray-400 text-white text-[14px] md:text-[25px] rounded md:rounded-xl  font-bold`} onClick={() =>onClickToggleErrorModal("")}>확인</button>
                            </div>

                        </div>
                    </div>
                </AlertModal>
            )}

        </div>
    );
}
