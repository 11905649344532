import type {FC,} from "react";

import {AlertModal} from "./AlertModal";
import React from "react";
import {UseMutateFunction} from "react-query";
import {CardPaymentConfirmRequestFormData} from "../../types/types";


export type DealCompleteAlertModalProps = {
    onClickToggleDealCompleteModal:() => void
    confirmRequestData: CardPaymentConfirmRequestFormData
    confirmRequest: UseMutateFunction<void, unknown, CardPaymentConfirmRequestFormData, unknown>
    paymentLoading:boolean
    paymentLoadingToggle:() => void
}

export const DealCompleteAlertModal : FC<DealCompleteAlertModalProps> = ({onClickToggleDealCompleteModal,confirmRequestData,confirmRequest,paymentLoading,paymentLoadingToggle}) => {

    return (
        <AlertModal>
            <div className={`flex flex-col items-center justify-center`}>
                <div className={`text-[16px] md:text-[24px] mt-18pxr md:mt-40pxr text-black font-semibold `}>거래확정</div>
                <div className={`text-[10px] md:text-[13px] mt-15pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>1. 거래한 로비스트가 맞는지 닉네임을</div>
                <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>다시 한 번 확인해주세요.</div>
                <div className={`text-[10px] md:text-[13px] mt-10pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>2. 거래확정 시 취소/환불이 불가합니다.</div>
                <div className={`text-[10px] md:text-[13px] mt-10pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>3. 거래를 확정하면 1시간 내로 로비스트의 계좌로 입금됩니다.</div>
                <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>만약 로비스트가 계좌정보를 입력하지 않았다면 </div>
                <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>서비스 금액 입금이 지연될 수 있습니다.</div>

                <div className={`flex flex-row mt-15pxr md:mt-35pxr`}>
                    <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-[#e0e0e0] text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={onClickToggleDealCompleteModal}>돌아가기</button>
                    <button disabled={paymentLoading} className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-[#463d8b] text-white text-[14px] md:text-[18px] rounded font-bold`}
                            onClick={() =>
                            {
                                confirmRequest(confirmRequestData)
                                paymentLoadingToggle()
                            }
                    }>거래확정</button>
                </div>
            </div>
        </AlertModal>

    )
}