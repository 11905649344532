import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse, AxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";
import {
    AdminOneToOneRequest, IAdminOneToOneDetailRequest, IAdminOneToOneDetailRequestResponse,
    IAdminOneToOneRequestResponse,
    IAdminUser,
    IAdminWholeMember,
    IAdminWholeMemberResponse
} from "../types";
import {useAdminUserContext} from "../AdminUserProvider";

async function getOneToOneDetailRequest(
    adminUser: IAdminUser | null,
    lobbyId:string
): Promise<IAdminOneToOneDetailRequestResponse> {


    const config: AxiosRequestConfig = {};
    if (adminUser)
        config.headers = { authorization: `${adminUser.accessToken}` }
    const { data } : AxiosResponse<IAdminOneToOneDetailRequestResponse> = await axiosInstance.get(`/admin/lobby/oto/${lobbyId}`, config,);


    return data;

}

interface UseAdminOneToOneDetailRequest {
    request:IAdminOneToOneDetailRequest | undefined
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;

}

export function useAdminOneToOneDetailRequest(
    lobbyId:string
): UseAdminOneToOneDetailRequest {

    const { adminUser,setAdminUser } = useAdminUserContext();

    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
    } = useQuery<IAdminOneToOneDetailRequestResponse>(
        [queryKeys.adminLobby, lobbyId],
        () => getOneToOneDetailRequest(adminUser, lobbyId),{

        }
    )





    const request = data?.row || undefined


    return {
        request,
        isLoading,
        isFetching,
        isError,
        error,

    };
}