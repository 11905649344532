
import {axiosInstance, } from "../../services/axiosInstance";
import {ImageUrlResponse,} from "../../types/types";
import {UseMutateFunction, useMutation, useQuery} from "react-query";
import {queryKeys} from "../../services/react-query/constants";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {queryClient} from "../../services/react-query/queryClient";
import {AdminBannerRegistrationFormData, IAdminUser} from "../types";
import {useAdminUserContext} from "../AdminUserProvider";


export function useAdminCommunityBannerRegistration(
    onSuccess:() => void,
    imageFile:File | null
) : UseMutateFunction<void, unknown, AdminBannerRegistrationFormData, unknown> {

    async function setAdminBannerRegistration(adminBannerRegistrationFormData: AdminBannerRegistrationFormData, adminUser: IAdminUser | null,): Promise<void> {

        if (imageFile) {
            const imageUrl = await uploadImage(imageFile, adminUser)
            if (imageUrl) {
                adminBannerRegistrationFormData.image = imageUrl
            }

        }

        const config: AxiosRequestConfig = {};
        if (adminUser){
            config.headers = { authorization: `${adminUser.accessToken}` }
            await axiosInstance.post(`/admin/banner/`, adminBannerRegistrationFormData, config,);
        }
    }

    async function uploadImage(imageFile: File, adminUser: IAdminUser | null): Promise<string | null> {
        if (!adminUser) {
            return null;
        }

        const formData = new FormData();
        formData.append('files', imageFile);

        const config: any = {
            headers: {
                authorization: `${adminUser.accessToken}`,
                'Content-Type': 'multipart/form-data',
            },
        };

        const response: AxiosResponse<ImageUrlResponse | null> = await axiosInstance.post('/shared/upload/public/images', formData, config);

        // 이미지 URL 추출
        return response.data?.rows[0] || "";
    }

    const { adminUser,setAdminUser } = useAdminUserContext();

    const {mutate} = useMutation((adminBannerRegistrationFormData: AdminBannerRegistrationFormData) => setAdminBannerRegistration(adminBannerRegistrationFormData, adminUser),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.adminCommunityBanner])
                onSuccess()
            }
        })

    return mutate
}

