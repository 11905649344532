import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {ILobbyist, ILobbyistResponse, IUser} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {useEffect} from "react";
import {useUserContext} from "../common/UserContext";

async function getMegaphoneLobbyistList(user: IUser | null, page: number, limit: number): Promise<ILobbyistResponse> {
    const config: AxiosRequestConfig = {};

    if (user){
        config.headers = getJWTHeader(user)
        config.params = {
            page: page,
            limit: limit,
        };
    }
    const { data }: AxiosResponse<ILobbyistResponse> = await axiosInstance.get('/user/search/list', config);

    if (data.count > page * limit) {
        data.nextPage = page + 1;
    }

    return data;
}

interface UseMegaphoneLobbyistList {
    lobbyists: ILobbyist[];
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
    hasNextPage:boolean
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;

}


export function useMegaphoneLobbyistList(page: number, limit: number) :UseMegaphoneLobbyistList {
    const { user, updateUser, clearUser } = useUserContext();

    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
    } = useInfiniteQuery<ILobbyistResponse>(
        [queryKeys.megaphoneLobbyist],
        ({pageParam = 1 }) =>{
            if (pageParam){
                return getMegaphoneLobbyistList(user, pageParam, limit)
            }else{
                return Promise.reject("No more pages");
            }
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.nextPage || false;
            },
            keepPreviousData: true,
            refetchOnWindowFocus:false,
            refetchOnMount:false,
            staleTime: 0,
            cacheTime: 0,
        },

    );
    const hasNextPage = !!(
        data &&
        data.pages.length &&
        data.pages[data.pages.length - 1]?.nextPage
    );




    // 모든 페이지의 게시물을 단일 배열로 펼칩니다.
    const lobbyists = data?.pages.flatMap((pageData) => pageData.rows).filter((lobbyist) => lobbyist._id !== user?._id) || []

    return {
        lobbyists,
        isLoading,
        isFetching,
        isError,
        error,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    };
}