import CommunityBoardListView from "./CommunityBoardListView";
import {CommunityBannerCard} from "./CommunityBannerCard";
import CommunityHeader from "../main/CommunityHeader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {MouseEvent, CSSProperties, FC, useContext, useEffect} from "react";
import {Title} from "../common";
import {useCommunityBanner} from "../../hooks/community/useCommunityBanner";
import {useUser} from "../../hooks/common/useUser";
import {useUserContext} from "../../hooks/common/UserContext";
import MainHeader from "../main/MainHeader";
import {HeaderContext} from "../main/HeaderContext";
import {Image} from "../common/Image";

interface ArrowProps {
    className?: string;
    onClick: (event: MouseEvent) => void;
    style?: CSSProperties;
    size?: number;
}
const CustomPrevArrow: FC<ArrowProps> = ({ className, onClick, style, size = 35 }) => {
    // ...
    return (
        <img
            src="/left-arrow.png"
            alt="Previous"
            className={className}
            onClick={onClick}
            style={{ ...style, width: `${size * 3 /5}px`, height: `${size}px` ,zIndex: 10, left: -12 }}
        />
    );
};

const CustomNextArrow: FC<ArrowProps> = ({ className, onClick, style, size = 35 }) => {
    // ...
    return (
        <img
            src="/right-arrow.png"// ...
            alt="Previous"
            className={className}
            onClick={onClick}
            style={{ ...style, width: `${size * 3 /5}px`, height: `${size}px` ,zIndex: 10, right: -10
                }}
        />
    );
};




export default function Community(){

    // const [selectedHeader, setSelectedHeader] = headerContext;
    const {user} = useUserContext()
    const settings = {
        className:"center",
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        arrows: false,
        // centerMode: true,
        centerPadding: "5px",
        vertical: true,

        responsive: [
            {
                breakpoint: 1024, // 태블릿 뷰로 전환되는 지점을 나타냅니다.
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: "0",
                },
            },
            {
                breakpoint: 768, // 모바일 뷰로 전환되는 지점을 나타냅니다.
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: "-1",

                },
            },
        ],
    };


    const {banners} = useCommunityBanner()

    const communityCards = banners.map((banner,index) => {
        return (
            <div key={index} className="flex flex-col text-[10px] md:text-[18px] mb-5pxr md:mb-15pxr font-bold text-black">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row justify-center items-center">
                        {index === 0 && <img src="/gold@3x.png" className="h-20pxr w-20pxr mr-5pxr"/>}
                        {index === 1 && <img src="/silver@3x.png" className="h-20pxr w-20pxr mr-5pxr"/>}
                        {index === 2 && <img src="/bronze@3x.png" className="h-20pxr w-20pxr mr-5pxr"/>}
                        {index === 3 && <img src="/black@3x.png" className="h-20pxr w-20pxr mr-5pxr"/>}
                        {index === 4 && <img src="/black@3x.png" className="h-20pxr w-20pxr mr-5pxr"/>}
                        <div className=" mr-10pxr">
                        {index + 1}
                        </div>
                        <Image src={banner.profileImage} className="h-20pxr w-20pxr  md:h-35pxr md:w-35pxr rounded-md md:rounded-xl  mr-10pxr"/>
                        <div className="line-clamp-1 ">
                            {banner.nickName}
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        <img src="/chicken_money@3x.png" className="h-15pxr w-15pxr md:h-30pxr md:w-30pxr mr-10pxr "/>
                        <div>{banner.totalIntroducePriceSentence}</div>
                    </div>

                </div>



            </div>
        )
    })


    return (
        <section >
            <MainHeader/>
            <div className="flex flex-col items-center justify-center md:pb-50pxr">

                <Title className="pt-50pxr md:pt-55pxr text-[15px] md:text-[30px] font-extrabold text-black">
                    내가 해결할 수 있는 의뢰가 있을지도..?
                </Title>
                <CommunityBoardListView user={user}/>
            </div>

        </section>
    )
}