import type {FC,DetailedHTMLProps,HTMLAttributes, PropsWithChildren} from "react"

export type ReactDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export type DivProps = ReactDivProps &{
    src?: string
}

export const Div : FC<DivProps> = ({

                                       style: _style, src,
                                       className: _className,

                                       ...props
                                   }) => {
    const style = {..._style, backgroundImage: src && `url(${src})`}
    const className = ['box-sizing', src && 'bg-gray-300', _className].join(' ')
    return <div {...props} className={className} style={style} />
}