import MainHeader from "./MainHeader";
import Banner from "./Banner";
import LobbyistListView from "./LobbyistListView";
import RequestModalView from "../common/RequestModalView";
import Footer from "./Footer";
import React, {useCallback, useContext, useEffect, useState} from "react";
import Community from "../community/Community";
import {useLobbyistSearchList} from "../../hooks/main/useLobbyistSearchList";
import LobbyistListSearchedView from "./LobbyistListSearchedView";
import {Route, useLocation, useNavigate} from "react-router-dom";
import CommunityHeader from "./CommunityHeader";
import LobbyistWholeListView from "./LobbyistWholeListView";
import ReactGA from "react-ga4";
import {useMegaphoneRequestData, useRequestData} from "../requests/RequestDataFormContext";
import {useReceivedRequestCount} from "../../hooks/main/useReceivedRequestCount";
import {useUserContext} from "../../hooks/common/UserContext";
import {Modal} from "../common/Modal";
import {useReceivedEventCount} from "../../hooks/main/useReceivedEventCount";
import {EventModal} from "../common/EventModal";
import {useAuth} from "../../hooks/auth/useAuth";
import {HeaderContext} from "./HeaderContext";
import RequestHeader from "./RequestHeader";
import {useSocket} from "../../hooks/chat/SocketProvider";


export default function Main(){
    const {user} = useUserContext()
    const { megaphoneRequestDataForm, setMegaphoneRequestDataForm } = useMegaphoneRequestData();
    const { requestDataForm, setRequestDataForm } = useRequestData();
    const auth = useAuth();
    const {socket} = useSocket()


    useEffect(()=> {
        console.log(socket, "소켓 상태 확인")
    },[socket])

    const headerContext = useContext(HeaderContext);
    if (!headerContext) {
        throw new Error("This component must be used within a <HeaderProvider> component");
    }
    const [selectedHeader, setSelectedHeader] = headerContext;

    const location = useLocation();

    useEffect(()=> {
        setSelectedHeader("request")
    })

    useEffect(() => {
        const resetContexts = () => {
            setRequestDataForm({
                lobbyistId: "",
                content: "",
                introducePrice: 0,
                businessPrice: "",
                contact: "",
            })
            setMegaphoneRequestDataForm({
                lobbyistIds: [],
                content: "",
                introducePrice: 0,
                businessPrice: "",
                contact: "",
            })
        }
        resetContexts()
    }, [location]);

    const [isCommunityView,setIsCommunityView] = useState<boolean>(false)
    const getStatus = useCallback((isCommunityViewStatus:boolean) => {
        setIsCommunityView(!isCommunityViewStatus)
    },[isCommunityView])



    const [isSearchedView, setIsSearchedView] = useState<boolean>(false)

    const [searchText, setSearchText] = useState<string>('')

    const handleSearch = useCallback((searchedText:string) => {
        setSearchText(searchedText)
        setIsSearchedView(true)
        setIsWholeLobbyistView(false)
    },[searchText])

    const [isWholeLobbyistView,  setIsWholeLobbyistView] = useState<boolean>(false)
    const handleWholeLobbyistView = useCallback(() => {
        setIsSearchedView(false)
        setIsWholeLobbyistView(handleWholeLobbyistView => !handleWholeLobbyistView)
    },[isWholeLobbyistView])

    const navigate = useNavigate()
    const goHistory = useCallback(() => {
        navigate("/request/history")
    },[navigate])
    const goReceivedRequest = useCallback(() =>{
        window.scrollTo(0, 0);
        navigate("/received")
    },[navigate])
    const goLogin = useCallback(() =>{
        window.scrollTo(0, 0);
        navigate("/login")
    },[navigate])
    const goMegaphone = useCallback(() =>{
        window.scrollTo(0, 0);
        navigate("/megaphone")
    },[navigate])


    /*
    TODO: 이벤트 관련 5월 31일날 모달 닫을 예정
     */
    // const {eventCount} = useReceivedEventCount()
    // const storedDate = localStorage.getItem('doNotShowModal');
    // const today = new Date();
    // let initialDoNotShowToday = false;
    //
    // if (storedDate) {
    //     const hideUntilDate = new Date(storedDate);
    //     if (today <= hideUntilDate) {
    //         initialDoNotShowToday = true;np
    //     }
    // }
    // const [secondsLeft, setSecondsLeft] = useState(0);
    // const [doNotShowToday, setDoNotShowToday] = useState(initialDoNotShowToday);
    // // Calculate the seconds left for the event
    // useEffect(() => {
    //     const eventDate = new Date('2023-06-01T00:00:00');
    //     let now = new Date();
    //     let diffInSeconds = Math.floor((eventDate.getTime() - now.getTime()) / 1000);
    //     setSecondsLeft(diffInSeconds);
    //
    //     const interval = setInterval(() => {
    //         setSecondsLeft((secondsLeft) => secondsLeft - 1);
    //     }, 1000);
    //
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);
    //
    // // Check the local storageㅣㅐ
    // useEffect(() => {
    //     const today = new Date();
    //     const storedDate = localStorage.getItem('doNotShowModal');
    //     if (storedDate) {
    //         const hideUntilDate = new Date(storedDate);
    //         if (today <= hideUntilDate) {
    //             setDoNotShowToday(true);
    //         }
    //     }
    // }, []);
    //
    // // Set the local storage when the checkbox is checked
    // const handleDoNotShowThisWeek = () => {
    //     const today = new Date();
    //     let hideUntilDate = new Date();
    //     hideUntilDate.setDate(today.getDate() + 7); // Get date 7 days ahead in YYYY-MM-DD format
    //     localStorage.setItem('doNotShowModal', hideUntilDate.toISOString());
    //     setDoNotShowToday(true);
    // }
    //
    // const handleDoNotShowToday = () => {
    //     const today = new Date();
    //     let hideUntilDate = new Date();
    //     hideUntilDate.setDate(today.getDate() + 1); // Get date 1 day ahead in YYYY-MM-DD format
    //     localStorage.setItem('doNotShowModal', hideUntilDate.toISOString());
    //     setDoNotShowToday(true);
    // };



    return (
        <section>
            <div>
                <RequestHeader/>
                <Banner wholeLobbyistView={handleWholeLobbyistView} searchLobbyist={handleSearch}/>

                {isWholeLobbyistView ?
                    <LobbyistWholeListView/>
                    :
                    (isSearchedView ?
                            <LobbyistListSearchedView searchValue={searchText}/>
                            :<LobbyistListView/>
                    )
                }



                {/*<div className={`fixed bottom-0pxr text-[15px] md:text-[22px]  w-full h-50pxr md:h-65pxr  bg-black text-white font-extrabold text-center flex flex-row items-center justify-center`}>*/}
                {/*    <button onClick={user ? goReceivedRequest : goLogin} className={`relative h-40pxr w-120pxr md:h-50pxr ml-20pxr md:w-180pxr border rounded bg-black  border-white `}>*/}
                {/*        {receivedCount !== 0 ? (*/}
                {/*            <div className="absolute bg-[#f44040] w-12pxr h-12pxr rounded-full top-5pxr right-5pxr"/>*/}
                {/*        ) :(*/}
                {/*            <></>*/}
                {/*        )}*/}
                {/*        띵똥! 의뢰 도착*/}
                {/*    </button>*/}
                {/*    <button onClick={user ? goHistory : goLogin} className={`h-40pxr w-120pxr md:h-50pxr ml-20pxr md:w-180pxr border rounded bg-black  border-white `}>*/}
                {/*        내가 요청한 의뢰*/}
                {/*    </button>*/}
                {/*</div>*/}
                {/*{!doNotShowToday && (*/}
                {/*    <EventModal className="flex flex-row relative">*/}
                {/*        <div className="relative">*/}
                {/*            <img className=" h-500pxr w-500pxr rounded-xl" src="/event.png" alt="Event" />*/}
                {/*            <div className="bg-[#333333] h-50pxr  z-50 rounded-xl"/>*/}
                {/*        </div>*/}

                {/*        <button*/}
                {/*            onClick={() => {*/}
                {/*                handleDoNotShowToday();*/}
                {/*                setDoNotShowToday(true);*/}
                {/*            }}*/}
                {/*            className="absolute bottom-10pxr right-10pxr text-[15px] text-white underline"*/}
                {/*        >*/}
                {/*            오늘 하루 동안 보지 않기*/}
                {/*        </button>*/}
                {/*    </EventModal>*/}
                {/*)}*/}
                <Footer/>
            </div>

        </section>

    )
}