export const queryKeys = {
    adminMegaphone:'adminMegaphone',
    adminMegaphoneDetail:'adminMegaphoneDetail',
    adminLobby:'adminLobby',
    adminUser:'adminUser',
    adminWholeMember:'adminWholeMember',
    adminCommunityBanner:'adminCommunityBanner',
    user: 'user',
    request: 'request',
    megaphoneRequest: 'megaphoneRequest',
    requestHistory: 'requestHistory',
    megaphoneHistory: 'megaphoneHistory',
    megaphoneDetailHistory: 'megaphoneDetailHistory',
    community: 'community',
    communityDetail: 'communityDetail',
    communityComment: 'communityComment',
    userInformation: 'userInformation',
    lobbyists: 'lobbyists',
    lobbyistReview: 'lobbyistReview',
    wholeLobbyist: 'wholeLobbyist',
    creditCard: 'creditCard',
    bankAccount: 'bankAccount',
    payment: 'payment',
    megaphoneLobbyist: 'megaphoneLobbyist',
    notification: 'notification',
    notificationCount: 'notificationCount',
    receivedRequestCount:'receivedRequestCount',
    receivedRequests: 'receivedRequests',
    receivedMegaphoneRequests: 'receivedMegaphoneRequests',
    review: 'review',
    termsOfView: 'termsOfView',
    communityBanner: 'communityBanner',
    chatList: 'chatList',
    roomId: 'roomId',
    paidReview: 'paidReview',
    unreadChatExist: 'unreadChatExist'

};
