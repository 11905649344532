import {useCallback, useEffect, useMemo, useState} from "react";
import {Column, usePagination, useTable, Row, UsePaginationInstanceProps, TableInstance , TableState, UsePaginationState} from "react-table";
import {LobbyistActivationIcon} from "./Component/LobbyistActivationIcon";
import {AlertModal, Title} from "../components/common";
import {useAdminMegaphoneRequest} from "./hooks/useAdminMegaphoneRequests";
import {IAdminMegaphoneRequest} from "./types";
import DateRangePicker from "./Component/DateRangePicker";
import {useAdminMegaphoneDetailRequest} from "./hooks/useAdminMegaphoneDetailRequest";
import {AdminMegaphoneDetailModal} from "./Component/AdminMegaphoneDetailModal";


interface CustomTableInstance extends TableInstance<IAdminMegaphoneRequest>, UsePaginationInstanceProps<IAdminMegaphoneRequest> {}
export default function MegaphoneRequestsManagement(){

    const {
        megaRequests,
        isLoading,
        isFetching,
        isError,
        error,
        pageIndex,
        updatePageIndex,
        hasNextPage,
        updateMegaStatus,
        megaStatus,
        updateCreatedStartedAt,
        updateCreatedEndedAt,
        maxPage} = useAdminMegaphoneRequest()

    const [selectedMegaId, setSelectedMegaId] = useState<string>("")


    const [isRequestDetailModal, setIsRequestDetailModal] = useState<boolean>(false);
    const onClickToggleRequestDetailModal = useCallback(() => {
        setIsRequestDetailModal(isRequestDetailModal => !isRequestDetailModal);
    }, [isRequestDetailModal])




    const data = useMemo<IAdminMegaphoneRequest[]>(() =>
            megaRequests
        , [isLoading,isFetching]);
    const columns = useMemo(
        () => [
            { Header: "번호", accessor: "_id" },
            { Header: "의뢰인", accessor: "clientNickName" },
            { Header: "소개비", accessor: "introducePrice"},
            // { MainHeader: "소개비결제상태", accessor: "status" },
            // { MainHeader: "의뢰내용", accessor: "content" },
            { Header: "심사 통과/반려", accessor: "megaStatus" },
            // { MainHeader: "확성기비용 권환/조치", accessor: "megaphoneCostManagement" },
            // { MainHeader: "소개비 권한/조치", accessor: "introduceCostManagement" },
            {
                Header: "상세 내역",
                accessor:"_id",
                id: "details",
                Cell: ({ row, value }: { row: Row<IAdminMegaphoneRequest>, value: string }) => (
                    <button
                        className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`}
                        onClick={async () =>  {
                            await setSelectedMegaId(value)
                            onClickToggleRequestDetailModal()
                            console.log("Details button clicked for row:", value);
                        }}
                    >
                        상세 내역
                    </button>
                ),
            },
        ],
        []
    );
    const initialState: Partial<TableState<IAdminMegaphoneRequest> & UsePaginationState<IAdminMegaphoneRequest>> = {
        pageIndex: 0,
        pageSize: 10,
    };


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageCount,
        prepareRow,
    } = useTable<IAdminMegaphoneRequest>(
        {
            columns: columns as Column<IAdminMegaphoneRequest>[],
            data,
            initialState: initialState,
        },
        usePagination
    ) as CustomTableInstance;



    return (
        <div className="flex flex-col items-center members mt-25pxr">
            <Title>확성기 의뢰 관리</Title>
            <DateRangePicker
                onStartDateChange={(date) => updateCreatedStartedAt(date)}
                onEndDateChange={(date) => updateCreatedEndedAt(date)}
            />



            <div className="flex flex-row justify-end  items-end w-500pxr">
                <div>
                    로비 진행 상태
                    <select
                        className="border mx-10pxr mt-25pxr"
                        value={megaStatus || ""}
                        onChange={(e) => {
                            updateMegaStatus(e.target.value as "examine"|"reject"|"approve"|"cancel" | null);
                        }}
                    >
                        <option value="">전체</option>
                        <option value="examine">검토중</option>
                        <option value="reject">반려</option>
                        <option value="approve">승인</option>
                        <option value="cancel">취소</option>
                    </select>
                </div>

            </div>
            <table {...getTableProps()} className="members-table  mx-auto mt-50pxr">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()} className="px-15pxr py-15pxr border-b-4">
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row: Row<IAdminMegaphoneRequest>) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td {...cell.getCellProps()} className="px-15pxr py-15pxr border-b-2">
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="flex flex-row pagination space-x-100pxr mt-50pxr">
                <button
                    onClick={() => updatePageIndex(-1)}
                    disabled={pageIndex === 1}
                    className="mx-6pxr"
                >
                    <img className="h-25pxr" src="/left-arrow.png"/>
                </button>
                <div>
                    {pageIndex} / {maxPage}
                </div>
                <button
                    onClick={() => updatePageIndex(+1)}
                    disabled={!hasNextPage}
                    className="mx-6pxr"
                >
                    <img className="h-25pxr" src="/right-arrow.png"/>
                </button>
            </div>
            {isRequestDetailModal &&
                <AdminMegaphoneDetailModal pageIndex={pageIndex} onClickToggleRequestDetailModal={onClickToggleRequestDetailModal} megaId={selectedMegaId}/>
            }

        </div>
    );
}