import Footer from "../main/Footer";
import React, {useCallback, useState} from "react";
import ReceivedRequestIntroduce from "./ReceivedRequestIntroduce";
import BasicHeader from "../common/BasicHeader";

export default function ReceivedRequests(){


    return (
        <section>
            <BasicHeader/>
            <ReceivedRequestIntroduce/>
        </section>

    )
}