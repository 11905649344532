import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import {
    CardPaymentConfirmRequestFormData, ImageUrlResponse,
    IUser,
    ReviewModifyFormData,
    ReviewRequestFormData,
} from "../../types/types";
import { axiosInstance, getJWTHeader } from "../../services/axiosInstance";
import { queryKeys } from "../../services/react-query/constants";
import { useAdminUserContext } from "../AdminUserProvider";
import {
    AdminBannerRegistrationFormData,
    AdminBannerRegistrationModifyFormData,
    AdminRejectFormData,
    IAdminUser
} from "../types";
import {queryClient} from "../../services/react-query/queryClient";
import {AxiosRequestConfig, AxiosResponse} from "axios/index";

export function useAdminCommunityBannerModify(
    onSuccess: () => void,
    imageFile:File | null,
    bannerId:string,
): UseMutateFunction<void, unknown, AdminBannerRegistrationModifyFormData, unknown> {


    async function modifyCommunityBanner(adminUser: IAdminUser | null, adminBannerRegistrationModifyFormData: AdminBannerRegistrationModifyFormData): Promise<void> {



        if (imageFile) {
            const imageUrl = await uploadImage(imageFile, adminUser)
            if (imageUrl) {
                adminBannerRegistrationModifyFormData.image = imageUrl
            }

        }
        const config: AxiosRequestConfig = {};
        if (adminUser) {
            config.headers = { authorization: `${adminUser.accessToken}` };
            await axiosInstance.put(`/admin/banner`, adminBannerRegistrationModifyFormData, config);
        }

    }

    async function uploadImage(imageFile: File, adminUser: IAdminUser | null): Promise<string | null> {
        if (!adminUser) {
            return null;
        }

        const formData = new FormData();
        formData.append('files', imageFile);

        const config: any = {
            headers: {
                authorization: `${adminUser.accessToken}`,
                'Content-Type': 'multipart/form-data',
            },
        };

        const response: AxiosResponse<ImageUrlResponse | null> = await axiosInstance.post('/shared/upload/public/images', formData, config);

        // 이미지 URL 추출
        return response.data?.rows[0] || "";
    }


    const { adminUser, setAdminUser } = useAdminUserContext();
    const { mutate } = useMutation(
        (adminBannerRegistrationModifyFormData: AdminBannerRegistrationModifyFormData) => modifyCommunityBanner(adminUser, adminBannerRegistrationModifyFormData),
        {
            onSuccess: () => {
                console.log("수정")
                queryClient.invalidateQueries([queryKeys.adminCommunityBanner,bannerId])
                queryClient.invalidateQueries([queryKeys.adminCommunityBanner])
                onSuccess();
            },
        }
    );

    return mutate;
}