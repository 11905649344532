import {DivProps} from "./Div";
import {FC} from "react";

export type ModalProps = DivProps & {
    onCloseIconClicked?:() => void
    className?:string
}

export const Modal: FC<ModalProps> = ({className:_className, onCloseIconClicked ,children, ...props}) => {
    const className = ["bg-white h-1/2 md:h-4/5 w-390pxr md:w-650pxr rounded-xl overflow-auto scrollbar",_className].join(' ')


    return(
            <div className={`fixed items-center justify-center w-full h-screen left-0pxr top-0pxr flex bg-black bg-opacity-70 `}>
                <div className={className}>
                    <div className={`flex justify-end`}>
                        <button className={`h-12pxr w-12pxr md:h-17pxr md:w-17pxr mt-11pxr mr-11pxr md:mt-48pxr md:mr-48pxr`} onClick={onCloseIconClicked}>
                            <img src="/close.png"/>
                        </button>

                    </div>
                    {children}
                </div>
            </div>

)
}
