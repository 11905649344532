import {useNavigate} from "react-router-dom";
import {useCallback} from "react";

export default function BasicHeader(){

    const navigate = useNavigate()
    const goHome = useCallback(() => {
        navigate("/")
    },[navigate])
    return (
        <nav>
            <div className={`w-screen h-33pxr md:h-60pxr bg-white flex flex-row justify-center items-center border-b border-gray-300`}>
                <div className={`flex flex-row  pl-0pxr  justify-evenly`}>
                    <button onClick={goHome}>
                        <img className={`w-50pxr md:w-100pxr mt-5pxr md:mt-5pxr  `} src="/main-logo.png"/>
                    </button>
                </div>
            </div>
        </nav>



    )
}