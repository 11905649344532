import './App.css';
import {BrowserRouter, useLocation} from "react-router-dom";
import RoutesSetup from "./routes/RoutesSetup";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {queryClient} from "./services/react-query/queryClient";
import {CombinedRequestDataProvider} from "./components/requests/RequestDataFormContext";
import ReactGA from 'react-ga4';
import {useEffect} from "react";
import {UserProvider} from "./hooks/common/UserContext";
import {HeaderProvider} from "./components/main/HeaderContext";
import {SocketProvider} from "./hooks/chat/SocketProvider";

const GOOGLE_CLIENT_ID:string = process.env.REACT_APP_GOOGLE_CLIENT_ID
const GOOGLE_MEASUREMENT_ID:string = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID
ReactGA.initialize(GOOGLE_MEASUREMENT_ID)
function App() {

    return (

        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
            <UserProvider>
                <SocketProvider>
                    <HeaderProvider>
                        <div className="safeignore-background">
                            <main>
                                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>

                                    <CombinedRequestDataProvider>

                                            <RoutesSetup/>

                                    </CombinedRequestDataProvider>

                                </GoogleOAuthProvider>
                                <ReactQueryDevtools/>

                            </main>
                        </div>
                    </HeaderProvider>
                </SocketProvider>
            </UserProvider>
            </BrowserRouter>
        </QueryClientProvider>


    );
}

export default App;
