import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    BankAccountRegisterRequestFormData,
    CardRegisterRequestFormData,
    IUser,
} from "../../types/types";
import {UseMutateFunction, useMutation, useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../../services/react-query/constants";
import {useUserContext} from "../common/UserContext";
import axios from "axios";



export function useBankAccountRegister(
    onSuccessRegister: () => void,
    onErrorRegister:(errorMessage:string) => void,
) : UseMutateFunction<void, unknown, BankAccountRegisterRequestFormData, unknown> {

    async function setBankAccountRegisterRequest(bankAccountRegisterRequestFormData: BankAccountRegisterRequestFormData, user: IUser | null,): Promise<void> {
        const config: any = {};

        if (user){
            config.headers = getJWTHeader(user);
            await axiosInstance.post(`/user/member/account/regist`, bankAccountRegisterRequestFormData, config)
        }
    }
    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate} = useMutation((bankAccountRegisterRequestFormData:BankAccountRegisterRequestFormData) => setBankAccountRegisterRequest(bankAccountRegisterRequestFormData, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.userInformation])
                onSuccessRegister()
            },
            onError: (errorResponse) => {
                if (axios.isAxiosError(errorResponse)){
                onErrorRegister(errorResponse?.response?.data?.message || '알 수없는 에러가 발생했습니다. \n로비디 이메일로 연락주세요')

                }
            }
        })

    return mutate
}

