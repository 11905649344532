import {UseMutateFunction, useMutation, useQueryClient} from "react-query";
import {CommentRequestFormData, IUser} from "../../types/types";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import axios from "axios";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {useUserContext} from "../common/UserContext";

export function useBlockUser(userId:string , onSuccess: () => void) : UseMutateFunction<void, unknown, string, unknown> {

    async function blockUser(userId:string, user: IUser | null): Promise<void> {
        const config: any = {};
        if (user){
            config.headers = getJWTHeader(user);
            await axiosInstance.post(`/user/member/block/${userId}`, config,);
        }

    }
    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()

    const {mutate} = useMutation((userId:string) => blockUser(userId, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.community])
                queryClient.invalidateQueries([queryKeys.lobbyists])
                queryClient.invalidateQueries([queryKeys.wholeLobbyist])
                onSuccess()
            },

        })

    return mutate
}
