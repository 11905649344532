import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IMegaphoneDetailHistory,
    IMegaphoneDetailHistoryResponse,
    IUser
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserContext} from "../common/UserContext";

async function getMegaphoneReceivedRequestDetail(user:IUser|null, megaId:string): Promise<IMegaphoneDetailHistoryResponse | null> {
    if (!user) return null

    const config: any = {};
    config.headers = getJWTHeader(user);

    const { data } : AxiosResponse<IMegaphoneDetailHistoryResponse> = await axiosInstance.get(`/user/lobby/mega/detail/lobbyist/${megaId}`, config,)
    return data



}

interface UseMegaphoneReceivedRequestDetail {
    detailMegaphoneHistory: IMegaphoneDetailHistory | undefined;
    isLoading:boolean
}


export function useMegaphoneReceivedRequestDetail(megaId:string) :UseMegaphoneReceivedRequestDetail{
    const { user, updateUser, clearUser } = useUserContext();

    const { data , isLoading, isFetching, isError, error } = useQuery(
        [queryKeys.megaphoneDetailHistory,megaId],
        () => getMegaphoneReceivedRequestDetail(user,megaId),
        {
            enabled: !!user
        }
    );

    const detailMegaphoneHistory = data?.row
    return { detailMegaphoneHistory, isLoading };
}