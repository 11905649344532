import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCommunity } from "../../hooks/community/useCommunity";
import { CommunityPost } from "./CommunityPost";
import { useUser } from "../../hooks/common/useUser";
import { useOnScreen } from "../../hooks/common/useOnScreen";
import {IUser} from "../../types/types";



interface CommunityBoardListViewProps  {
    user: IUser | null;
}

export default function CommunityBoardListView({ user }: CommunityBoardListViewProps) {
    const [isFilteringMyPosts, setIsFilteringMyPosts] = useState(false);

    const handleFilterMyPosts = useCallback(() => {
        setIsFilteringMyPosts((prevState) => !prevState);
    }, []);

    const navigate = useNavigate();


    const { posts,hasNextPage, fetchNextPage, isFetchingNextPage } = useCommunity(
        isFilteringMyPosts
            ? () => {
                setIsFilteringMyPosts(true);
            }
            : undefined
    );



    const goCommunityWrite = useCallback(() => {
        navigate("/community/register");
        window.scroll(0, 0);
    }, [navigate]);

    const goLogin = useCallback(() => {
        navigate("/login");
        window.scroll(0, 0);
    }, [navigate]);

    const postsView = posts?.map((post, index) => {
        return <CommunityPost user={user} post={post} key={`${post._id}-${index}`} />;
    });

    const loadMoreRef = useOnScreen<HTMLDivElement>(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage(); // Pass the next page number
        }
    });

    return (
        <section>
            <div className="flex flex-col justify-between w-340pxr md:w-800pxr mt-30pxr">
                <div className="flex flex-row justify-end">
                    <div>
                        <button
                            onClick={user ? goCommunityWrite : goLogin}
                            className="flex flex-row items-center  rounded-lg w-330pxr h-28pxr md:w-140pxr md:h-35pxr justify-center font-semibold  space-x-6pxr bg-black text-[12px] md:text-[15px] text-white"
                        >
                            <img className="h-13pxr w-13pxr md:h-14pxr md:w-14pxr mb-3pxr" src="/pencil.png" />
                            <div>요청 글쓰기</div>
                        </button>
                    </div>
                </div>
                <div className="flex flex-row justify-end mt-33pxr md:mt-63pxr">
                    <div>
                        {user && (
                            <button
                                onClick={handleFilterMyPosts}
                                className="flex flex-row items-center rounded-lg  justify-center font-extrabold text-[10px] md:text-[15px] text-[#bcbcbc]"
                            >
                                {isFilteringMyPosts ? <div>전체 글 보기</div> : <div>내가 쓴 글 보기</div>}
                            </button>
                        )}
                    </div>
                </div>
                {postsView}
                <div ref={loadMoreRef}></div>
            </div>
        </section>
    );
}
