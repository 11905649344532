import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse, AxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";
import {
    IAdminCommunityBanner,
    IAdminCommunityBannerResponse,
    IAdminUser,
    IAdminWholeMember,
    IAdminWholeMemberResponse
} from "../types";
import {useAdminUserContext} from "../AdminUserProvider";

async function getAdminCommunityBanner(
    adminUser: IAdminUser | null,
): Promise<IAdminCommunityBannerResponse> {


    const config: AxiosRequestConfig = {};
    if (adminUser){
        config.headers = { authorization: `${adminUser.accessToken}` }

    }

    const { data } : AxiosResponse<IAdminCommunityBannerResponse> = await axiosInstance.get(`/admin/banner`, config,);


    return data;

}

interface UseAdminCommunityBanner {
    banners:IAdminCommunityBanner[]
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
}

export function useAdminCommunityBanner(
    filter?: () => void
): UseAdminCommunityBanner {

    const { adminUser,setAdminUser } = useAdminUserContext();
    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
    } = useQuery<IAdminCommunityBannerResponse>(
        [queryKeys.adminCommunityBanner],
        () => getAdminCommunityBanner(adminUser),{

        }
    )





    const banners = data?.rows || []


    return {
        banners,
        isLoading,
        isFetching,
        isError,
        error,

    };
}