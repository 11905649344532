import { useEffect, useRef } from 'react';

export function useOnScreen<T extends HTMLElement>(callback: () => void, options?: IntersectionObserverInit) {
    const ref = useRef<T>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                callback();
            }
        }, options);

        const target = ref.current;
        if (target) {
            observer.observe(target);
        }

        return () => {
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [callback, options]);

    return ref;
}
