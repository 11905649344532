import {doSignOut, getAccessTokenWithMutex, useUser} from "../common/useUser";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    CardRegisterRequestFormData,
    IUser,
} from "../../types/types";
import {UseMutateFunction, useMutation, useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../../services/react-query/constants";
import axios, {AxiosResponse} from "axios";
import {clearStoredUser, setStoredUser} from "../../utils/user-storage";
import {useUserContext} from "../common/UserContext";



export function useDeleteUser(
    onSuccessDeleted: () => void,
    onErrorDeleted:() => void,
) : UseMutateFunction<void, unknown, IUser, unknown> {

    async function setUserDeleteRequest( user: IUser | null,): Promise<void> {
        const config: any = {};

        if (user){
            config.headers = getJWTHeader(user);
            await axiosInstance.delete(`/user/member/signout`, config)
        }
    }
    const { user, updateUser, clearUser } = useUserContext();

    const queryClient = useQueryClient()
    const {mutate} = useMutation((user:IUser) => setUserDeleteRequest(user),
        {
            onSuccess: () => {
                clearUser()
                onSuccessDeleted()
            },
            onError: () => {
                onErrorDeleted()
            }
        })

    return mutate

}

