import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    ICommunityBanner,
    ICommunityBannerResponse,
    IPost,
    IPostResponse,
    IUser,
    IUserNotification,
    IUserNotificationCount,
    IUserNotificationResponse
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserInformation} from "../myprofile/useUserInformation";

async function getCommunityBanner(): Promise<ICommunityBannerResponse> {

    const config: any = {};

    const { data } : AxiosResponse<ICommunityBannerResponse> = await axiosInstance.get('/user/search/rank/introducePrice', config,);
    return data

}

interface UseCommunityBanner {
    banners: ICommunityBanner[];

}

export function useCommunityBanner(): UseCommunityBanner {

    const {data, isLoading, isFetching, isError, error} = useQuery<ICommunityBannerResponse>(
        [queryKeys.communityBanner],
        () => getCommunityBanner(),
        {
        }

    );

    const banners = data?.rows || []
    return { banners }

}
