
import {useAuth} from "../../hooks/auth/useAuth";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";




export default function LoginPage()  {

    const auth = useAuth();

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();

        if (userAgent.includes("instagram") || userAgent.includes("facebook") || userAgent.includes("whalenp") ||userAgent.includes("naver") || userAgent.includes("naver webview")|| userAgent.includes("kakaotalk") || userAgent.includes("kakaotalk webview")){
            let isChromeInstalled;
            try {
                isChromeInstalled = window.open('googlechrome://www.lobbyd.com');
            } catch(err) {
                isChromeInstalled = false;
            }

            if (!isChromeInstalled) {
                window.open('safari://www.lobbyd.com');
            }
        }
    }, []);
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get("code");
        if (code) {
            auth.appleLogin(code)
        } else {
        }
    }, [location.search]);

    return (
        <section className={`flex flex-col items-center justify-center bg-black`}>
            <div className={`flex flex-col items-center h-screen bg-background aspect-[9/16] `}>
                <div className="flex flex-col items-center h-full w-full bg-black bg-opacity-50">
                    <a href="/">
                        <img className={`w-173pxr  md:w-210pxr mt-147pxr md:mt-120pxr`} src={`/logo-bright.png`}/>

                    </a>
                    <div className={`flex text-[12px] md:text-[15px] text-white font-semibold mt-23pxr md:mt-28pxr`}>“너가 찾는 사람 내가 소개해줄게”</div>
                    <div className={`flex justify-center items-center w-234pxr h-31pxr md:w-320pxr md:h-44pxr mt-50pxr md:mt-54pxr bg-gray-100 rounded-xl`}>
                        <div className={`text-[10px] md:text-[13px] text-black font-semibold md:py-19pxr`}>소개를 해주는 사람이 정당한 보상을 받을 수 있는 곳</div>
                    </div>
                    <div className={`flex justify-center items-center w-161pxr h-31pxr md:w-250pxr md:h-44pxr mt-21pxr md:mt-20pxr bg-gray-100 rounded-xl`}>
                        <div className={`text-[10px] md:text-[13px] text-black font-semibold md:py-19pxr`}>서로를 돕는 문화를 만들어 가는 곳</div>
                    </div>
                    <div className={`flex justify-center items-center w-234pxr h-31pxr md:w-300pxr md:h-44pxr mt-21pxr md:mt-20pxr bg-gray-100 rounded-xl`}>
                        <div className={`text-[10px] md:text-[13px] text-black font-semibold md:py-19pxr`}>나의 인맥이 누군가에게 도움이 될 수 있는 곳</div>
                    </div>
                    <div className={`mt-50pxr`}>
                        <button className="flex flex-row justify-center  items-center w-274pxr h-35pxr md:w-350pxr md:h-50pxr font-bold text-white bg-black text-[12px] md:text-[15px] border border-white rounded md:rounded-xl" onClick={() => auth.googleLogin()}>
                            <img src="/google-logo.png" className="h-23pxr w-23pxr md:w-24pxr md:h-24pxr mr-5pxr md:mr-15pxr"/>
                            구글 계정으로 로그인</button>
                    </div>
                    <div className={`mt-30pxr`}>
                        <a href={`https://appleid.apple.com/auth/authorize?response_type=code&client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}/login`} className="flex flex-row justify-center  items-center w-274pxr h-35pxr md:w-350pxr md:h-50pxr font-bold text-white bg-black text-[12px] md:text-[15px] border border-white rounded md:rounded-xl">
                            <img src="/apple-login-button.png" className="h-22pxr w-18pxr md:w-18pxr md:h-22pxr mr-5pxr md:mr-15pxr"/>
                            애플 계정으로 로그인
                        </a>
                    </div>
                    <div className={`flex flex-col items-center w-250pxr md:w-318pxr mt-20pxr md:mt-27pxr text-[8px] md:text-[12px] text-center text-white`}>
                        <div>회원가입을 진행하면 LobbyD의 <a className={`text-blue-500 font-semibold`} href="/termsofview">서비스 이용 약관</a> 및
                            <div>
                                <a className={`text-blue-500 font-semibold`} href="/privacypolicy">개인 정보 처리 방침</a> 약관에 동의한 것으로 간주됩니다.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}