import {ChangeEvent, useRef, useState} from 'react';
import {BlockNetwork} from "./BlockNetwork";
import {regaxNormal} from "../../utils/data/helper";


type NetworkData = {
    keywords:[{
        word:string
        detail:string
        price:number
    }]
}

type NetworkFormProps = NetworkData & {
    updateFields: (fields: Partial<NetworkData>) => void
    onRegister: () => void
    isLoading: boolean
    loadingToggle: () => void
}


export default function NetworkChoice({updateFields , onRegister,loadingToggle,isLoading} : NetworkFormProps) {

    const [networkValue,setNetworkValue] = useState<string>("")
    const [detailValue,setDetailValue] = useState<string>("")
    const getValue = (getValue:string) => {
        setNetworkValue(getValue)
    }
    const onInputWord = (e:ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value.replace(/\s\s+/g, ' '); // remove duplicate spaces
        if (regaxNormal(10).test(inputValue)) { // check if input meets criteria
            setNetworkValue(inputValue)
            updateFields({
                keywords:[{
                    word: inputValue,
                    detail: detailValue,
                    price:0
                }]
            })
        }
    }
    const onInputDetail = (e:ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value; // remove leading/trailing spaces
        if (regaxNormal(300).test(inputValue)) { // check if input meets criteria
            setDetailValue(inputValue)
            updateFields({
                keywords:[{
                    word: networkValue,
                    detail: inputValue,
                    price:0
                }]
            })
        }
    }
    return (
        <section>
            <div className={`flex justify-center  ml-15pxr md:ml-0pxr`}>
                <div className={`flex flex-col items-start w-340pxr md:w-600pxr`}>
                    <div className={`flex flex-col items-start justify-between`}>

                        <div className={`flex flex-row md:mt-12pxr text-black font-bold items-end`}>
                            <div className={`text-[18px] md:text-[25px] `}>나와 가까운 지인 1명을 입력해보세요!</div>
                            <div className={`text-[13px] md:text-[15px] ml-5pxr mb-3pxr`}> (선택사항)</div>
                        </div>
                        <div className=" text-[10px] md:text-[15px] font-extrabold text-[#747474]">로비스트가 되기 위한 첫걸음!</div>
                        <div className="flex flex-row justify-center mt-45pxr md:mt-50pxr">
                            <div className="text-[11px] md:text-[20px] text-black font-extrabold">나의 인맥 등록</div>
                            <div className="flex flex-col ml-25pxr">
                                <input type="text" onChange={onInputWord} value={networkValue} className={`resize-none  w-200pxr md:w-400pxr bg-white text-[12px] md:text-[20px] font-extrabold text-black   placeholder:font-bold placeholder:text-[#d0d0d0]  border-b-2 focus:outline-0`} placeholder={`아래 목록에서 선택해주세요 (최대 10글자)`}/>
                                <div className="text-[8px] md:text-[15px] mt-10pxr text-[#747474] font-semibold"> 커서를 올려 내용 수정이 가능해요</div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-center   mt-45pxr md:mt-45pxr">
                            <div className="text-[11px] md:text-[20px]   text-black font-extrabold">상세 설명</div>
                            <input type="text" onChange={onInputDetail} className={`resize-none md:ml-65pxr ml-45pxr  w-200pxr md:w-400pxr bg-white text-[12px] md:text-[20px] font-extrabold text-black   placeholder:font-bold placeholder:text-[#d0d0d0]   border-b-2 focus:outline-0`} placeholder={`나의 인맥에 대해 간단히 설명해주세요`} />
                        </div>
                    </div>
                    <div className={`mt-37pxr md:mt-61pxr`}>
                        <BlockNetwork  onRegister={() => onRegister()} networkValue={networkValue}  getValue={getValue} isLoading={isLoading} loadingToggle={() => loadingToggle()}/>
                    </div>
                </div>
            </div>
        </section>
    )
}



