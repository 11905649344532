
import React, {useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {getStoredAdminUser, useAdminUser} from "./hooks/useAdminUser";
import {useAdminUserContext} from "./AdminUserProvider";
import {LoginState} from "./types";



export default function AdminLoginPage(){

    const [loginState, setLoginState] = useState<LoginState>({
        aId: "",
        password: "",
    });

    const {login} = useAdminUser()
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setLoginState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        await login(loginState)
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <form onSubmit={handleSubmit} className="w-full max-w-sm bg-white shadow-md rounded px-42pxr pt-36pxr pb-48pxr mb-24pxr">
                <h2 className="text-center text-2xl font-semibold mb-24pxr">Admin Login</h2>
                <div className="mb-24pxr">
                    <label className="block text-gray-700 text-sm font-bold mb-12pxr">
                        Id
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-12pxr px-18pxr text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="aId"
                        type="text"
                        name="aId"
                        value={loginState.aId}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-12pxr" htmlFor="password">
                        Password
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-12pxr px-18pxr text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type="password"
                        name="password"
                        value={loginState.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex items-center justify-between">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-12pxr px-24pxr rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        Login
                    </button>
                </div>
            </form>
        </div>
    );
};
