
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMegaphoneLobbyistList } from "../../hooks/megaphone/useMegaphoneLobbyistList";
import {MegaphoneCard} from "../history/MegaphoneCard";
import {useUser} from "../../hooks/common/useUser";
import {useOnScreen} from "../../hooks/common/useOnScreen";
import {formatCurrency, makeArray} from "../../utils/data/helper";
import {useUserContext} from "../../hooks/common/UserContext";

export default function MegaphoneLobbyistListView() {
    const [page, setPage] = useState(1);
    const {user} = useUserContext()

    const navigate = useNavigate();
    const { lobbyists, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage} = useMegaphoneLobbyistList(page,12);



    const goMegaphoneRequest = useCallback((lobbyistIds: string[]) => {
        navigate(`/megaphone/request`, {
            state: {lobbyistIds: lobbyistIds},
        });
    }, [navigate]);
    const goLogin = useCallback(() => {
        navigate(`/login`);
    }, [navigate]);

    const [isChecked, setIsChecked] = useState<boolean[]>([]);

    useEffect(() => {
        if (lobbyists) {
            const prevLength = isChecked.length;
            const newLength = lobbyists.length;
            if (newLength > prevLength) {
                const additionalChecks = makeArray(newLength - prevLength).fill(false);
                setIsChecked((prevIsChecked) => [...prevIsChecked, ...additionalChecks]);
            }
        }
    }, [lobbyists]);

    const toggleChoice = (position: number) => {
        const updatedCheckedState = isChecked.map((item, index) =>
            index === position ? !item : item
        );
        setIsChecked(updatedCheckedState);
    };

    const toggleAll = useCallback(() => {
        if (isChecked.filter((a) => a).length >= 1) {
            setIsChecked((isChecked) => isChecked.map(() => false));
        } else {
            setIsChecked((isChecked) => isChecked.map(() => true));
        }
    }, [isChecked]);

    const isButtonDisabled: boolean = !(isChecked.filter((a) => a).length >= 1);

    const getSelectedLobbyistIds = (): string[] => {
        return isChecked
            .map((checked, index) =>
                lobbyists && checked ? lobbyists[index]._id : null
            )
            .filter((id): id is string => id !== null);
    };

    const renderLobbyistList = () =>
        isChecked.map((item, index) => {
            return (
                <MegaphoneCard
                    key={index}
                    lobbyist={lobbyists ? lobbyists[index] : null}
                    className="relative"
                >
                    <div className="flex flex-row items-start justify-start -mt-10pxr md:mt-0pxr md:mr-13pxr">
                        <button
                            className="flex items-center justify-center h-25pxr w-25pxr md:h-36pxr md:w-36pxr bg-gray-300 rounded-full"
                            onClick={() => toggleChoice(index)}
                        >
                            {item && (
                                <div className="h-20pxr w-20pxr md:h-26pxr md:w-26pxr bg-black rounded-full"/>
                            )}
                        </button>
                    </div>
                </MegaphoneCard>
            );
        });

    const loadMoreRef = useOnScreen<HTMLDivElement>(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage(); // Pass the next page number
        }
    });
    return (
        <section>
            <div className="flex flex-col justify-end items-end">
                <div className="flex flex-row justify-end   w-screen mx-30pxr md:mx-70pxr">
                <button
                    className="flex justify-center items-center mt-25pxr md:mt-18pxr  h-25pxr w-90pxr md:h-45pxr md:w-135pxr bg-black rounded md:rounded-lg"
                    onClick={() => toggleAll()}
                >
                    <div className="text-[8px] md:text-[15px] font-semibold  text-white">
                        {isChecked.filter((a) => a).length >= 1
                            ? "전체 선택 취소"
                            : "로비스트 전체 선택"}
                    </div>
                </button>

                </div>
                <div className={`flex flex-wrap items-start justify-center w-screen pt-15pxr md:pt-20pxr`}>
                    {renderLobbyistList()}
                <div ref={loadMoreRef}></div>
                </div>
                <div
                    className="fixed flex flex-row justify-between px-20pxr items-center bottom-26pxr md:bottom-33pxr right-12pxr md:right-95pxr rounded-xl h-44pxr w-315pxr md:h-75pxr md:w-500pxr pl-16pxr md:pl-35pxr bg-black text-[12px] md:text-[20px] text-white ">
                    <div className={`font-semibold`}>
                        {isChecked.filter((a) => a).length}명 x 200원
                    </div>
                    <div>=</div>
                    <div className={`font-extrabold`}>
                        {formatCurrency(isChecked.filter((a) => a).length * 200)}원
                    </div>
                    <button
                        disabled={isButtonDisabled}
                        className={`h-30pxr w-106pxr md:h-50pxr ml-20pxr md:w-180pxr border-2 rounded ${
                            isButtonDisabled ? "bg-[#ededed]" : "bg-black"
                        } md:rounded-lg border-white `}
                        onClick={user ? () => goMegaphoneRequest(getSelectedLobbyistIds()) : goLogin }
                    >
                        다음 단계로 이동
                    </button>
                </div>
            </div>
        </section>
    )
}