import React, {createContext, FC, ReactNode, useContext, useState} from "react";
import {IAdminUser} from "./types";

interface AdminUserContextValue {
    adminUser: IAdminUser | null;
    setAdminUser: React.Dispatch<React.SetStateAction<IAdminUser | null>>;
}

const AdminUserContext = createContext<AdminUserContextValue | undefined>(
    undefined
);
interface AdminUserProviderProps {
    children: ReactNode;
}

export const AdminUserProvider: FC<AdminUserProviderProps> = ({ children }) => {
    const [adminUser, setAdminUser] = useState<IAdminUser | null>(null);

    return (
        <AdminUserContext.Provider value={{ adminUser, setAdminUser }}>
        {children}
        </AdminUserContext.Provider>
    )
};

export const useAdminUserContext = () => {
    const context = useContext(AdminUserContext);
    if (!context) {
        throw new Error("useAdminUserContext must be used within an AdminUserProvider");
    }
    return context;
};
