import React, { ChangeEvent } from "react";

interface DateRangePickerProps {
    onStartDateChange: (date: Date | null) => void;
    onEndDateChange: (date: Date | null) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
                                                             onStartDateChange,
                                                             onEndDateChange,
                                                         }) => {
    const handleDateChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
        const dateValue = e.target.value ? new Date(e.target.value) : null;
        if (index === 0) {
            onStartDateChange(dateValue);
        } else {
            onEndDateChange(dateValue);
        }
    };

    return (
        <div className="flex flex-row  mt-25pxr">
            <div className="flex flex-col mx-75pxr ">
                <label htmlFor="signup-start-date">가입 시작일:</label>
                <input
                    className="border border-4"
                    type="date"
                    id="signup-start-date"
                    onChange={(e) => handleDateChange(0, e)}
                />
            </div>
            <div className="flex flex-col mx-75pxr">
            <label htmlFor="signup-end-date">가입 종료일:</label>
            <input
                className="border border-4"
                type="date"
                id="signup-end-date"
                onChange={(e) => handleDateChange(1, e)}
            />
            </div>
        </div>
    );
};

export default DateRangePicker;
