import type {FC} from "react";
import {Div} from "./Div";
import type {DivProps} from "./Div";

export type AvatarProps = DivProps & {
}
const IMAGE_PREFIX = process.env.REACT_APP_IMAGE_URL;
export const Avatar : FC<AvatarProps> = ({
    className: _className, style, src, ...props
}) => {
    const prefixedSrc = `${IMAGE_PREFIX}${src}`;
    const className = ['rounded-xl bg-cover bg-white border w-54pxr h-54pxr md:w-65pxr md:h-65pxr',_className ].join(' ')
    return (
        <div {...props} className={className} style={style}>
            <img src={prefixedSrc} className="inset-0 w-full h-full object-cover rounded-lg" alt="avatar" />
        </div>
    )
}