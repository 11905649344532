import MainHeader from "../main/MainHeader";
import Banner from "../main/Banner";
import LobbyistListView from "../main/LobbyistListView";
import RequestModalView from "../common/RequestModalView";
import Footer from "../main/Footer";
import React, {useCallback, useState} from "react";
import NetworkSetting from "./NetworkSetting";

export default function MyNetwork(){


    return (
        <section>
            <NetworkSetting/>
        </section>

    )
}