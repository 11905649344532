import type {CSSProperties, FC, SyntheticEvent} from "react";
import {Div} from "./Div";
import type {DivProps} from "./Div";
import {useState} from "react";

export type ImageProps = DivProps & {
    alt?:string
    onLoad?: () => void;
    preview? : boolean
}
const IMAGE_PREFIX = process.env.REACT_APP_IMAGE_URL;
export const Image : FC<ImageProps> = ({
    className: _className, style,preview, src,alt, onLoad, ...props
}) => {

    const [aspectRatio, setAspectRatio] = useState<number>();

    const handleLoad = (event: SyntheticEvent<HTMLImageElement>) => {
        const { naturalWidth, naturalHeight } = event.currentTarget;
        setAspectRatio(naturalWidth / naturalHeight);
        if (onLoad) {
            onLoad();
        }
    };

    const className = ["inset-0 object-cover",_className].join(' ')

    const prefixedSrc = preview? `${src}` : `${IMAGE_PREFIX}${src}`
    return (
        <div
            style={{ "--aspect-ratio": aspectRatio } as CSSProperties}
            className="relative"
        >
            <img
                onLoad={handleLoad}
                src={prefixedSrc}
                className={className}
                alt={alt}
            />
        </div>

    )
}