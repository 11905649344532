import React, {ChangeEvent, FC, useCallback, useState} from "react";
import {Keyword} from "../../types/types";
import {formatCurrency, regaxNormal} from "../../utils/data/helper";

export type NetworkCardViewProps =  {
    onClose:(index:number) => void
    onMoveUp:() => void
    onPriceChange: (id: number, price: number) => void
    onWordChange: (id: number, word: string) => void
    onDetailChange: (id: number, detail: string) => void
    onCheckboxChange: (id: number, isChecked: boolean) => void
    keyword:Keyword
    checked:boolean
}

export const NetworkCardView:FC<NetworkCardViewProps> = ({onClose, keyword,onDetailChange, onWordChange, onPriceChange, onCheckboxChange, onMoveUp ,checked}) => {


    const [isChecked, setIsChecked] = useState<boolean>(checked)
    const check = useCallback(() => {
        setIsChecked(isChecked => !isChecked);
        onCheckboxChange(keyword.id, !isChecked);
        onPriceChange(keyword.id, 0);
    }, [isChecked,keyword]);

    const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/,/g, "");
        if (/^\d{0,8}$/.test(newValue)) {
            const parsedValue = parseInt(newValue, 10);
            let roundedValue = parsedValue;
            if (parsedValue >= 10000) {
                roundedValue = Math.round(parsedValue / 1000) * 1000;
            }
            onPriceChange(keyword.id, isNaN(roundedValue) ? 0 : roundedValue);
        }
    };
    const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value; // remove leading/trailing spaces
        if (regaxNormal(10).test(inputValue)) {
            const newWord = inputValue;
            onWordChange(keyword.id, newWord);
        }
    }


    const handleDetailChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value; // remove leading/trailing spaces
        if (regaxNormal(300).test(inputValue)) {   // check if input meets criteria
            const newDetail = inputValue;
            onDetailChange(keyword.id, newDetail);
        }
    }



    return (
        <section>
            <div className={`flex  flex-col w-337pxr md:w-530pxr md:h-500pxr items-start mt-18pxr md:mt-40pxr text-[15px] md:text-[20px] text-black font-extrabold bg-[#f7f7f7] rounded-xl`}>
                <button className="w-25pxr h-25pxr md:w-45pxr md:h-45pxr relative -left-10pxr -top-10pxr md:-left-15pxr md:-top-15pxr" onClick={onMoveUp}>
                    <img src="/up-right-arrow.png"/>
                </button>
                <div className={'flex flex-row w-full justify-between'}>
                    <div className={`text-[12px] md:text-[18px] font-bold  ml-12pxr md:ml-20pxr`}>나의 인맥</div>
                    <button className={`h-13pxr w-13pxr mb-10pxr mr-10pxr md:mb-20pxr md:mr-20pxr`} onClick={() => onClose(keyword.id)}><img src="/close.png"/></button>

                </div>
                <div className={`flex  flex-col  w-full px-13pxr  px-8pxr md:px-13pxr`}>
                    <div className={`flex md:h-58pxr   md:mt-5pxr bg-white rounded-xl border-[#707070] border border-3`}>
                        <input value={keyword.word} onChange={handleTitleChange} type={`text`} className={`w-341pxr md:w-450pxr bg-white text-black text-[12px] md:text-[20px] font-semibold text-left m-10pxr md:m-13pxr focus:outline-none`} />
                    </div>
                </div>
                <div className={`text-[12px] md:text-[18px] font-bold mt-12pxr md:mt-20pxr ml-12pxr md:ml-20pxr`}>인맥에 대한 상세 설명</div>
                <div className={`flex  flex-col  w-full  px-8pxr md:px-13pxr`}>
                    <div className={`flex md:h-116pxr  mt-10pxr md:mt-5pxr bg-white rounded-xl border-[#707070] border border-1`}>
                        <textarea  value={keyword.detail} onChange={handleDetailChange} placeholder="인맥에 대해 상세히 설명해주시면 요청률이 높아집니다"  className={`resize-none w-341pxr md:w-500pxr placeholder:text-[#bcbcbc]   bg-white text-black text-[12px] md:text-[20px] font-semibold text-left m-10pxr md:m-13pxr focus:outline-none`} />
                    </div>
                </div>

                <div className={`text-[12px] md:text-[18px] font-bold mt-20pxr md:mt-20pxr ml-12pxr md:ml-20pxr`}>소개비 설정</div>
                <div className={`flex  flex-col  w-full  px-8pxr md:px-13pxr`}>
                    <div className={`flex items-center md:h-58pxr  mt-10pxr md:mt-5pxr ${isChecked ? "bg-[#ededed]"  : "bg-white"} rounded-xl border-[#707070] border border-1`}>
                        <img className={`h-20pxr w-20pxr md:h-33pxr md:w-33pxr ml-13pxr`} src="/won.png"/>
                        <input disabled={isChecked} value={formatCurrency(keyword.price)} onChange={handlePriceChange} type={`text`} className={`w-341pxr md:w-450pxr  ${isChecked ? "bg-[#ededed]"  : "bg-white"} text-black text-[12px] md:text-[20px] font-semibold text-left m-10pxr md:m-13pxr focus:outline-none`} />
                    </div>
                </div>
                {/*<div className={`flex flex-row items-center justify-end text-[12px] md:text-[18px] w-330pxr md:w-full md:pr-20pxr mb-36pxr md:mb-57pxr mt-8pxr md:mt-11pxr`}>*/}
                {/*    {isChecked ?*/}
                {/*        (<button className="h-15pxr w-15pxr" onClick={check}>*/}
                {/*            <img src="/checked.png"/>*/}
                {/*        </button>)*/}
                {/*        :*/}
                {/*        (<button className="h-15pxr w-15pxr" onClick={check}>*/}
                {/*            <img src="/unchecked.png"/>*/}
                {/*        </button>)*/}
                {/*    }*/}

                {/*    <div className="text-[#696969] ml-5pxr font-semibold">소개 나눔</div>*/}
                {/*</div>*/}
            </div>


        </section>
    )
}