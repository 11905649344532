import {ChangeEvent, FocusEvent , KeyboardEvent ,useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AlertModal} from "../common";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {useModifyUserInformation} from "../../hooks/myprofile/useModifyUserInformation";
import {IUserInformation, RegisterFormData, UserDetailRequestFormData} from "../../types/types";
import {useDeleteUser} from "../../hooks/myprofile/useDeleteUser";
import {useUser} from "../../hooks/common/useUser";
import {Image} from "../common/Image";
import {useNicknameValidation} from "../../hooks/myprofile/useNicknameValidation";
import {debounce} from "lodash";
import {useUserContext} from "../../hooks/common/UserContext";



export default function ProfileInformationView(){
    const {userInformation} = useUserInformation()
    const {user} = useUserContext()
    const [localUserInformation, setLocalUserInformation] = useState<IUserInformation>(userInformation);
    const [bLobbyist, setBLobbyist] = useState(localUserInformation.bLobbyist);
    const {checkNickname, responseStatusCode} = useNicknameValidation()

    const [validationNickname, setValidationNickname] = useState<string|null>(null)

    const checkNicknameHandler = (inputValue: string) => {
        checkNickname(inputValue);
    }
    useEffect(() => {
        if (localUserInformation.nickName.trim().length < 2) {
            setValidationNickname("2자 이상 써주세요");
        } else if (responseStatusCode === 409) {
            setValidationNickname("닉네임이 이미 존재합니다.");
        } else if (responseStatusCode === 400) {
            setValidationNickname("올바르지 않은 닉네임입니다.");
        } else {
            setValidationNickname(null);
        }
    }, [responseStatusCode,localUserInformation.nickName]);


    const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.trim();
        checkNicknameHandler(inputValue)
        setLocalUserInformation({ ...localUserInformation, nickName: inputValue });
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.nativeEvent.composed) {
            return;
        }
        event.preventDefault()
        const inputValue = event.target.value.trim();
        // 정규식: 0자 이상 10자 이하, 띄어쓰기 금지
        if (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s]{0,10}$/.test(inputValue)) {
            checkNicknameHandler(inputValue)
            setLocalUserInformation({ ...localUserInformation, nickName: inputValue });
        }
    };


    const handleBLobbyistChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBLobbyist(e.target.checked);
        setLocalUserInformation({...localUserInformation, bLobbyist: e.target.checked})
    };


    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [uploadedImage, setUploadedImage] = useState<File | null>(null);
    const [previewImage, setPreviewImage] = useState<string | null>(null);

    const handleButtonClick = () => {
        inputFileRef.current?.click();
    };
    const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const imgFile = e.target.files[0];
            setUploadedImage(imgFile);

            // Create a FileReader to read the image file
            const reader = new FileReader();
            reader.onloadend = () => {
                // Update the previewImage state with the Data URL
                setPreviewImage(reader.result as string);
            };
            reader.readAsDataURL(imgFile);
        }
    };

    const modifyUserInformation = useModifyUserInformation(() => goBack(), uploadedImage)

    useEffect(() => {
        setLocalUserInformation(userInformation);
        setBLobbyist(userInformation.bLobbyist);
    }, [userInformation]);



    const navigate = useNavigate()
    const goBack = useCallback(() =>{
        navigate(-1)
    },[navigate])
    const goHome= useCallback(() =>{
        navigate("/")
    },[navigate])


    const [isMemberSecessionAlertModal, setIsMemberSecessionAlertModal] = useState<boolean>(false);
    const onClickToggleMemberSecession = useCallback(() => {
        setIsMemberSecessionAlertModal(!isMemberSecessionAlertModal);
    }, [isMemberSecessionAlertModal]);

    const isSaveButtonDisabled = () => {
        return validationNickname !== null || localUserInformation.nickName.trim().length < 2;
    };

    const deleteUser = useDeleteUser(() => goHome(),() => alert("삭제에 실패했습니다. 고객센터에 문의하세요"))

    return (
        <section>


            <div className={`flex flex-col items-center mt-23pxr md:mt-50pxr`}>

                <div className="w-screen ml-18pxr  md:w-1/2">
                    <button>
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}/>
                    </button>
                </div>
                <div className={`mt-14pxr md:mt-6pxr`}>
                    <div className={`text-[15px] md:text-[30px] text-black font-extrabold`}>프로필</div>
                </div>
                <button onClick={handleButtonClick} className={`flex flex-col items-center mt-29pxr md:mt-40pxr`}>
                    {previewImage ? (

                        <Image className={`h-85pxr w-85pxr md:h-100pxr md:w-100pxr rounded-xl`} preview={true} src={previewImage} alt="profile"/>
                    ):(
                        <Image className={`h-85pxr w-85pxr md:h-100pxr md:w-100pxr rounded-xl`} src={userInformation.profileImage || "default-profile.png"} alt="profile"/>

                    )}
                    <div className={`mt-10pxr md:mt-15pxr text-[7px] md:text-[13px] font-bold text-[#696969]`}>프로필 사진 수정</div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                        ref={inputFileRef}
                    />
                </button>

                <label form="toggle"
                       className="mt-35pxr md:mt-60pxr inline-flex  items-center justify-center md:p-16pxr cursor-pointer h-40pxr w-200pxr md:h-52pxr md:w-350pxr">
                    <input  id="toggle" type="checkbox" className="hidden peer " checked={(bLobbyist)} onChange={handleBLobbyistChange}/>
                    {(bLobbyist) ?
                        (<div className={`flex flex-row items-center justify-center border-1 rounded-xl`}>
                            <div className="flex flex-row  items-center justify-center px-15pxr md:px-17pxr py-5pxr  md:py-15pxr w-95pxr md:w-120pxr h-30pxr md:h-40pxr text-[11px] md:text-[13px] font-extrabold text-white peer-checked:text-black bg-black rounded-xl peer-checked:bg-white">Lobbyist ON</div>
                            <div className="flex flex-row  items-center justify-center px-15pxr md:px-17pxr py-5pxr md:py-15pxr w-40pxr md:w-60pxr h-30pxr md:h-40pxr text-[11px] md:text-[13px]  font-extrabold text-gray-300 bg-white rounded-xl peer-checked:bg-black">OFF</div>
                        </div>)
                        :
                        (<div className={`flex flex-row items-center border-1 rounded-xl`}>
                            <div className="flex flex-row  items-center justify-center px-15pxr md:px-17pxr py-5pxr md:py-15pxr w-40pxr md:w-60pxr h-30pxr md:h-40pxr text-[11px] md:text-[13px]  font-extrabold text-gray-300 bg-white rounded-xl peer-checked:bg-black">ON</div>
                            <div className="flex flex-row  items-center justify-center px-10pxr md:px-17pxr py-5pxr md:py-15pxr w-95pxr md:w-120pxr h-30pxr md:h-40pxr text-[11px] md:text-[13px] font-extrabold text-white peer-checked:text-black bg-black rounded-xl peer-checked:bg-white">Lobbyist OFF</div>
                        </div>)
                    }
                </label>

                <div className={`mt-14pxr md:mt-20pxr text-[10px] md:text-[12px] text-black font-semibold`}>
                    로비스트로 활동하시면 고객의 의뢰를 받아보실 수 있습니다
                </div>

                <div className={`flex flex-row mt-41pxr md:mt-80pxr divide-x-[1px] items-center`}>

                    <div className={`flex flex-col  text-black font-semibold`}>
                        <div className={`text-center text-[12px] md:text-[15px]`}>해결 지수</div>
                        <div className={`flex flex-row mt-8pxr md:mt-12pxr`}>
                            <img src="/solution_index.png" className={`h-20pxr w-20pxr md:h-32pxr md:w-32pxr`} />
                            <div className={`text-[12px] md:text-[20px] ml-7pxr  md:ml-13pxr `}>{userInformation.completeCount}</div>
                        </div>
                    </div>
                </div>
                <div className={`flex  flex-col w-310pxr md:w-330pxr  mt-50pxr md:mt-70pxr text-[12px] md:text-[16px] text-black font-extrabold`}>
                    코드네임 수정
                    <div className={`flex h-44pxr w-310pxr md:h-44pxr md:w-330pxr mt-10pxr md:mt-12pxr bg-white rounded border border-1`}>
                        <input onBlur={handleInputBlur} onChange={handleInputChange} value={localUserInformation.nickName} placeholder={userInformation.nickName} type="text" className={`w-341pxr md:w-450pxr   bg-white text-black text-[12px] md:text-[13px] font-semibold text-left m-10pxr md:mx-13pxr focus:outline-none`} />
                    </div>
                    <div className="ml-5pxr mt-5pxr md:mt-8pxr text-[8px] md:text-[10px] font-semibold">
                        {validationNickname && validationNickname}
                    </div>

                </div>

                <div className={`flex  flex-col w-310pxr  md:w-330pxr mt-27pxr md:mt-20pxr text-[12px] md:text-[16px] text-black font-extrabold `}>
                    가입 이메일
                    <div className={`flex h-44pxr w-310pxr md:h-40pxr md:w-330pxr mt-10pxr md:mt-12pxr bg-[#ededed] rounded border border-1`}>
                        <input type="text" className={`w-330pxr md:w-450pxr   bg-[#ededed] text-black text-[12px] md:text-[13px] font-semibold text-left m-10pxr md:mx-13pxr focus:outline-none`} value={userInformation.email} disabled/>
                    </div>

                </div>

                <button disabled={isSaveButtonDisabled()} onClick={() => modifyUserInformation(localUserInformation)} className={`text-[15px] md:text-[15px]  w-310pxr h-35pxr md:w-330pxr md:h-40pxr mt-34pxr md:mt-53pxr ${!isSaveButtonDisabled() ? "bg-black" : "bg-[#ededed]"}  text-white font-extrabold text-center   rounded`}>
                    저장하기
                </button>

                <div className={`w-310pxr md:w-330pxr text-[9px] md:text-[9px]  mt-45pxr mb-13pxr md:mt-53pxr text-[#9f9f9f] text-right`} >
                    <button onClick={onClickToggleMemberSecession}>
                    회원탈퇴
                    </button>
                </div>
                {
                    isMemberSecessionAlertModal && (
                        <AlertModal>
                            <div className={`flex flex-col items-center justify-center`}>
                                <div className={`text-[16px] md:text-[24px] mt-40pxr md:mt-50pxr text-black font-semibold `}>탈퇴하기</div>
                                <div className={`text-[10px] md:text-[13px] mt-36pxr md:mt-45pxr text-[#5d5d5d] font-semibold`}>탈퇴하면 LobbyD에서 활동하신 모든 데이터가</div>
                                <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>사라지며 복구할 수 없습니다.</div>
                                <div className={`text-[10px] md:text-[13px] mt-10pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>정말 탈퇴하시겠습니까?</div>

                                <div className={`flex flex-row mt-30pxr md:mt-70pxr`}>
                                    <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-[#e0e0e0] text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={onClickToggleMemberSecession}>취소</button>
                                    <button onClick={user ? (() => deleteUser(user)) : () => null} className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`}>확인</button>
                                </div>
                            </div>
                        </AlertModal>
                    )
                }
                <div className={`mb-150pxr`}/>
            </div>



        </section>
    )
}