import type {FC,DetailedHTMLProps,HTMLAttributes, PropsWithChildren} from "react";
import React from "react";
import {useRequestDetailHistory} from "../../hooks/history/useRequestDetailHistory";
import {formatCurrency} from "../../utils/data/helper";
import {Modal} from "../../components/common/Modal";
import {useAdminOneToOneDetailRequest} from "../hooks/useAdminOneToOneDetailRequest";


export type AdminRequestDetailModalProps = {
    onClickToggleRequestDetailModal:() => void
    lobbyId:string
}

export const AdminRequestDetailModal : FC<AdminRequestDetailModalProps> = ({onClickToggleRequestDetailModal,lobbyId}) => {
    const {request, isLoading} = useAdminOneToOneDetailRequest(lobbyId)
    return (
        <Modal className="h-350pxr w-300pxr md:w-650pxr md:h-650pxr overflow-y-scroll md:overflow-scroll " onCloseIconClicked={onClickToggleRequestDetailModal}>
            <div className="flex flex-col px-17pxr md:mx-20pxr mt-28pxr text-[17px] md:text-[25px] text-black font-semibold">

                의뢰 내용 보기
                <div className={`text-[13px] md:text-[18px] font-bold mt-20pxr md:mt-35pxr`}>
                    자기소개 / 찾는사람 / 요구사항
                </div>
                <div className={`h-94pxr w-341pxr md:h-150pxr md:w-550pxr mt-8pxr md:mt-12pxr`}>
                    <textarea value={request?.content}
                              readOnly={true}
                              className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl overflow-y-scroll md:overflow-scroll `}

                              />
                </div>
                <div className={`flex flex-row items-end  font-bold mt-20pxr md:mt-45pxr`}>
                    <div className={`text-[12px] md:text-[18px] md:mr-5pxr`}>업무비</div>
                    <div className={`text-[8px] md:text-[15px]`}>(소개받으신 분께 자문을 구하거나 업무를 부탁드리는 경우 지급되는 보수입니다)</div>

                </div>
                <div className={` md:h-90pxr md:w-500pxr mt-10pxr md:mt-12pxr`}>
                   <textarea
                       value={request?.businessPrice}
                       readOnly={true}
                       className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl overflow-y-scroll md:overflow-scroll `}

                         />
                </div>
                <div className={`text-[12px] md:text-[18px] mt-15pxr font-bold `}>
                    의뢰인의 연락처
                </div>
                <div className={` md:h-90pxr md:w-500pxr mt-10pxr md:mt-12pxr`}>
                    <textarea value={request?.contact} readOnly={true} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl`} rows={1}  />
                </div>

                <div className={`flex flex-row items-end text-[15px] mt-20pxr md:mt-0pxr md:text-[18px] bfont-bold `}>
                    <div className={`text-[12px] md:text-[18px] mr-5pxr`}>소개비</div>
                    <div className={`text-[8px] md:text-[12px]`}>(소개해주는 로비스트에게 지급되는 보수입니다)</div>
                </div>
                <div className={` md:h-90pxr md:w-550pxr mt-10pxr md:mt-12pxr`}>
                    <textarea value={formatCurrency(request?.introducePrice ?? 0)} readOnly={true} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[20px] text-black placeholder:text-gray-300 rounded-xl`} rows={1} />
                </div>

            </div>

        </Modal>

    )
}