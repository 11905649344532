import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IUser, IUserNotification, IUserNotificationResponse} from "../../types/types";
import {queryKeys} from "../../services/react-query/constants";

import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserContext} from "../common/UserContext";
import {queryClient} from "../../services/react-query/queryClient";

async function getNotification(user:IUser|null): Promise<IUserNotificationResponse | null> {

    if (!user) return null;
    const config: any = {};
    config.headers = getJWTHeader(user);
    const { data } : AxiosResponse<IUserNotificationResponse | null> = await axiosInstance.get('/user/notification', config,);
    return data

}

interface UseNotification {
    notifications: IUserNotification[] | undefined;

}

export function useNotification(): UseNotification {
    const { user, updateUser, clearUser } = useUserContext();

    const {data, isLoading, isFetching, isError, error} = useQuery<IUserNotificationResponse | null>(
        [queryKeys.notification],
        () => getNotification(user),
        {
            refetchInterval:false,
            refetchOnMount:false,
            refetchOnWindowFocus:false,
            refetchIntervalInBackground:false,
            retry:0,

            enabled: !!user, // user가 없으면 쿼리를 실행하지 않습니다.
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.notificationCount])
            }
        }


    );

    const notifications = data?.rows || undefined
    return { notifications }

}
