import React, {useCallback, useMemo, useState} from "react";
import {Column, usePagination, useTable, Row, UsePaginationInstanceProps, TableInstance , TableState ,UsePaginationState} from "react-table";
import {LobbyistActivationIcon} from "./Component/LobbyistActivationIcon";
import {AlertModal, Title} from "../components/common";
import {useAdminWholeMember} from "./hooks/useAdminWholeMember";
import {useAdminOneToOneRequest} from "./hooks/useAdminOneToOneRequest";
import {IAdminCommunityBanner, IAdminMegaphoneRequest} from "./types";
import DateRangePicker from "./Component/DateRangePicker";
import {useAdminCommunityBanner} from "./hooks/useAdminCommunityBanner";
import {AdminBannerRegistrationModal} from "./Component/AdminBannerRegistrationModal";
import {useAdminCommunityBannerDelete} from "./hooks/useAdminCommunityBannerDelete";
import {Modal} from "../components/common/Modal";
import {AdminBannerModifyModal} from "./Component/AdminBannerModifyModal";


interface CustomTableInstance extends TableInstance<IAdminCommunityBanner>, UsePaginationInstanceProps<IAdminCommunityBanner> {}
export default function CommunityBannerManagement(){

    const {
        banners,
        isFetching
    } = useAdminCommunityBanner()


    const [isBannerModifyModal, setIsBannerModifyModal] = useState<boolean>(false);
    const onClickToggleBannerModifyModal = useCallback(() => {
        setIsBannerModifyModal(isBannerModifyModal => !isBannerModifyModal);
    }, [isBannerModifyModal])



    const [selectedBannerId, setSelectedBannerId] = useState<string>("")


    const data = useMemo<IAdminCommunityBanner[]>(() =>
            banners
        , [isFetching]);
    const columns = useMemo(
        () => [
            { Header: "제목", accessor: "title" },
            { Header: "게시글 Id", accessor: "postId"},
            { Header: "소개비", accessor: "introducePrice"},
            { Header: "업무비", accessor: "businessPrice"},
            { Header: "이미지", accessor: "image" },
            { Header: "작성일", accessor: "createdAt" },
            { Header: "수정일", accessor: "updatedAt" },
            { Header: "삭제 유무", accessor: "softDeleted", Cell: ({ value }: { value: boolean }) => <div> {value ? "예": "아니오"} </div>,},
            {
                Header: "",
                accessor:"_id",
                id: "details",
                Cell: ({ row, value }: { row: Row<IAdminMegaphoneRequest>, value: string }) => (
                    <div className="flex flex-row">
                        <button
                            className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-gray-500 text-white text-[14px] md:text-[18px] rounded font-bold`}
                            onClick={async () => {
                                await setSelectedBannerId(value)
                                onClickToggleBannerModifyModal()
                            }
                            }>배너 수정</button>
                    </div>
                ),
            },
        ],
        []
    );
    const initialState: Partial<TableState<IAdminCommunityBanner> & UsePaginationState<IAdminCommunityBanner>> = {
        pageIndex: 0,
        pageSize: 100,
    };


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageCount,
        prepareRow,
    } = useTable<IAdminCommunityBanner>(
        {
            columns: columns as Column<IAdminCommunityBanner>[],
            data,
            initialState: initialState,
        },
        usePagination
    ) as CustomTableInstance;


    const [isBannerRegistrationModal, setIsBannerRegistrationModal] = useState<boolean>(false);
    const onClickToggleBannerRegistrationModal = useCallback(() => {
        setIsBannerRegistrationModal(isBannerRegistrationModal => !isBannerRegistrationModal);
    }, [isBannerRegistrationModal])




    return (
        <div className="flex flex-col items-center members mt-25pxr">
            <Title>커뮤니티 배너 관리</Title>

            <div className={`flex flex-row items-end justify-end w-750pxr my-25pxr md:my-64pxr`}>
                <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-blue-500 text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={() => onClickToggleBannerRegistrationModal()}>배너 추가</button>
            </div>


            <div className="flex flex-row justify-end  items-end w-500pxr">


            </div>
            <table {...getTableProps()} className="members-table">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()} className="px-15pxr py-15pxr border-b-4">
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row: Row<IAdminCommunityBanner>) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td {...cell.getCellProps()} className="px-15pxr py-15pxr border-b-2">
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            {isBannerRegistrationModal &&
                <AdminBannerRegistrationModal onClickToggleBannerRegistrationModal={onClickToggleBannerRegistrationModal}/>
            }

            {isBannerModifyModal &&
                <AdminBannerModifyModal bannerId={selectedBannerId} onClickToggleBannerModifyModal={onClickToggleBannerModifyModal}/>

            }
        </div>


    );
}