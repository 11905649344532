import axios, { AxiosRequestConfig } from 'axios';

import { baseUrl } from './constants';
import {IUser} from "../../types/types";

export function getJWTHeader(user: IUser): Record<string, string> {
  return { authorization: `${user.accessToken}` };
}

const config: AxiosRequestConfig = { baseURL: baseUrl };
export const axiosInstance = axios.create(config);

