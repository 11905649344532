import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IDetailLobbyistResponse, ILobbyistDetailInformation,
    IPost,
    IPostResponse,
    IUser,
    IUserInformation,
    IUserInformationResponse
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserContext} from "../common/UserContext";

async function getLobbyistInformation(user:IUser|null, lobbyistId:string): Promise<IDetailLobbyistResponse | null> {
    const config: any = {};

    if (user){
        config.headers = getJWTHeader(user);
    }

    const { data } : AxiosResponse<IDetailLobbyistResponse> = await axiosInstance.get(`/user/search/detail/${lobbyistId}`, config,)
    return data

}

interface UseLobbyistDetail {
    lobbyist: ILobbyistDetailInformation | undefined;
    isLoading: boolean

}


export function useLobbyistDetail(lobbyistId:string) :UseLobbyistDetail{
    const { user, updateUser, clearUser } = useUserContext();

    const { data , isLoading} = useQuery<IDetailLobbyistResponse | null>(
        [queryKeys.lobbyists,lobbyistId],
        () => getLobbyistInformation(user, lobbyistId),
        {}
    );

    const lobbyist = data?.row

    return { lobbyist , isLoading};
}