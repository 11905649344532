import {UseMutateFunction, useMutation, useQueryClient} from "react-query";
import {IUser} from "../../types/types";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import axios, {AxiosRequestConfig} from "axios";
import {queryKeys} from "../../services/react-query/constants";
import {useUserContext} from "../common/UserContext";

export function useCompletePostStatus(
    postId:string,
    goBack: () => void)
    : UseMutateFunction<void, unknown, string, unknown> {

    async function completeCommunityStatus(postId: string, user: IUser | null,): Promise<void> {
        const config: AxiosRequestConfig = {};
        if (user){
            config.headers = getJWTHeader(user);
            await axiosInstance.put(`/user/post/complete/${postId}`, null, config);
        }
    }




    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate} = useMutation((postId: string) => completeCommunityStatus(postId, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.community])
                queryClient.invalidateQueries([queryKeys.community,postId])
                goBack()
            }
        })

    return mutate
}
