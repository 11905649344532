import {UseMutateFunction, useMutation, useQueryClient} from "react-query";
import {
    CardPaymentConfirmRequestFormData,
    IUser, ReviewRequestFormData,
} from "../../types/types";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {useUserContext} from "../common/UserContext";
export function useReviewRequest(onSuccess:() => void, lobbyistId:string) : UseMutateFunction<void, unknown, ReviewRequestFormData, unknown> {

    async function confirmPaymentRequest(reviewRequestFormData: ReviewRequestFormData, user: IUser | null,): Promise<void> {

        const config: any = {};
        if (user){
            config.headers = getJWTHeader(user);
            await axiosInstance.post(`/user/review`, reviewRequestFormData, config);
        }
    }

    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate} = useMutation((requestFormData:ReviewRequestFormData) => confirmPaymentRequest(requestFormData, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.lobbyists])
                queryClient.invalidateQueries([queryKeys.requestHistory])
                queryClient.invalidateQueries([queryKeys.megaphoneHistory])
                queryClient.invalidateQueries([queryKeys.paidReview])
                queryClient.invalidateQueries([queryKeys.lobbyistReview,lobbyistId])
                queryClient.refetchQueries([queryKeys.lobbyistReview,lobbyistId])
                onSuccess()
            },
            onError: () => {
                console.log("에러")
            }
        })


    return mutate
}
