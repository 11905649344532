import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IChatList, IChatListResponse, ILobbyist, ILobbyistResponse, IUser} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {useEffect} from "react";
import {useUserContext} from "../common/UserContext";

async function getChatListRequest(user: IUser | null): Promise<IChatListResponse | null> {
    const config: AxiosRequestConfig = {};

    if (user){
        config.headers = getJWTHeader(user)

    }
    const { data }: AxiosResponse<IChatListResponse> = await axiosInstance.get('/user/chat/list', config);

    return data;
}

interface UseChatList {
    chatLists: IChatList[] | undefined;
    isLoading: boolean;

}


export function useChatList() :UseChatList {
    const { user, updateUser, clearUser } = useUserContext();

    const {data, isLoading, } = useQuery<IChatListResponse | null>(
        [queryKeys.chatList],
        () => getChatListRequest(user)
        ,
        {
            keepPreviousData: false,
            refetchOnWindowFocus:true,
            refetchOnMount:true,
            onError: () => {
                queryClient.invalidateQueries([queryKeys.user])
            },
            enabled: !!user,
        },

    );



    const chatLists = data?.rows

    // 모든 페이지의 게시물을 단일 배열로 펼칩니다.

    return { chatLists,isLoading};
}