import {AlertModal} from "../common";
import Footer from "../main/Footer";
import {AccountRegisterForm} from "./AccountRegisterForm";
import React, {useCallback, useState} from "react";
import BasicHeader from "../common/BasicHeader";
import {useNavigate} from "react-router-dom";
import {BankAccountRegisterRequestFormData, CardRegisterRequestFormData} from "../../types/types";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {useCreditCardRegister} from "../../hooks/payment/useCreditCardRegister";
import {useCreditCardRemove} from "../../hooks/payment/useCreditCardRemove";
import {useBankAccountRegister} from "../../hooks/payment/useBankAccountRegister";
import {useBankAccountRemove} from "../../hooks/payment/useBankAccountRemove";

export default function PaymentRegisterAccountView(){


    const {userInformation} = useUserInformation()
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorAlertModal, setIsErrorAlertModal] = useState<boolean>(false);
    const onClickToggleErrorModal = useCallback((errorMessage:string) => {
        setIsErrorAlertModal(isErrorAlertModal => !isErrorAlertModal);
    }, [isErrorAlertModal]);
    const isBankAccountRegistered = userInformation?.accountInfo


    const registerBankAccount = useBankAccountRegister(() => {goBack()}, (errorMessage) => {
        onClickToggleErrorModal(errorMessage)
        setErrorMessage(errorMessage)
    })
    const removeBankAccount = useBankAccountRemove(() => registerBankAccount(bankData),() => onClickToggleErrorModal(errorMessage))

    const handleButtonClick = async () => {
        if (isBankAccountRegistered) {
            try {
                await removeBankAccount();
            } catch (error) {
                console.error("Error during card removal and registration:", error);
            }
        } else {
            try {
                await registerBankAccount(bankData);
            } catch (error) {
                console.error("Error during card registration:", error);
            }
        }
    };
    const navigate = useNavigate()

    const goBack = useCallback(() =>{
        navigate(-1)
        window.scrollTo(0, 0);
    },[navigate])

    const INITIAL_DATA: BankAccountRegisterRequestFormData = {
        bankName: "산업은행",
        accountHolder: "",
        accountNumber:"",
    }
    const [bankData, setBankData] = useState(INITIAL_DATA)

    const updateFields = (fields: Partial<BankAccountRegisterRequestFormData>) => {
        setBankData(prev => {
            return { ...prev, ...fields }
        })
    }
    const isFormValid = (): boolean => {
        return (
            bankData.accountNumber.trim().length >= 10 && bankData.accountNumber.trim().length <= 14 &&
            bankData.bankName.trim().length >= 1 &&
            bankData.accountHolder.trim().length >= 1
        );
    };
    return (
        <section>
            <BasicHeader/>
            <div className={`flex flex-col items-center mt-23pxr md:mt-50pxr`}>

                <div className="w-screen ml-18pxr  md:w-1/2">
                    <button>
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}/>
                    </button>
                </div>
                <div>
                    <div className={`mt-14pxr md:mt-6pxr`}>
                        <div className={`text-[15px] md:text-[30px] text-black font-extrabold`}>소개비 입금 받을 계좌 관리 (로비스트)</div>
                    </div>

                </div>

                <div className="w-275pxr md:w-400pxr">
                    <AccountRegisterForm bankData={bankData} updateFields={updateFields}/>
                </div>

                <button disabled={!isFormValid()} onClick={handleButtonClick} className={`mt-46pxr md:mt-62pxr h-35pxr w-275pxr md:w-400pxr md:h-50pxr text-white font-bold text-[15px] md:text-[20px] ${isFormValid() ? "bg-black" : "bg-gray-200"} rounded}`}>계좌 등록/변경</button>
                <div className="flex flex-col  w-296pxr md:w-400pxr items-start text-[#848484] ">
                    <div className={`text-[10px] md:text-[18px] font-extrabold mt-39pxr md:mt-54pxr `}>
                        유의사항
                    </div>
                    <div className="flex flex-col text-[10px] md:text-[15px] font-semibold mt-16pxr md:mt-28pxr">
                        <div>· 의뢰인에게 소개를 완료하고 의뢰인이 거래를 확정하면 1시간 내로 로비스트의 계좌로 입금되며, 23:00 ~ 01:00 사이에는 은행 점검 등의 이유로 입금이 다소 늦어질 수 있습니다. 또한 로비스트가 계좌정보를 입력하지 않았다면 서비스 금액 입금이 지연될 수 있습니다.</div>
                        <div>· 해당 수집 정보는 국세청 고시 제2015-6호 및 제2018-14호에
                            따라 과세자료 제출용으로 이용됩니다.
                        </div>
                        <div>· 타인 명의로 본인 인증을 할 경우 법적 처벌을 받을 수 있습니다.</div>
                        <div>· 해당 매출에 대한 신고 의무는 고객님께 있습니다.</div>
                        <div>· 거래확정 후 소개비 송금 중에는 계좌 수정이 불가합니다.</div>

                    </div>

                </div>
            </div>
            {isErrorAlertModal && (
                <AlertModal>
                    <div className={`flex flex-col items-center justify-center`}>
                        <div className={`text-[16px] md:text-[24px] mt-30pxr md:mt-40pxr text-black font-semibold `}>등록 오류</div>
                        <div className={`text-[10px] md:text-[13px] mt-18pxr md:mt-30pxr text-[#5d5d5d] font-semibold`}>{errorMessage}</div>
                        <div className={`text-[10px] md:text-[13px] mt-18pxr md:mt-30pxr text-[#5d5d5d] font-semibold`}>내용을 올바르게 입력했는지 다시 한 번 확인해주시고</div>
                        <div className={`text-[10px] md:text-[13px] mt-18pxr md:mt-30pxr text-[#5d5d5d] font-semibold`}>재확인 이후에도 등록이 되지 않는다면</div>
                        <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>카드사 혹은 은행에 문의해주시기 바랍니다.</div>

                        <div className={`flex flex-row mt-15pxr md:mt-45pxr`}>
                            <button className={`mr-8pxr md:mr-12pxr w-243pxr md:w-300pxr h-36pxr md:h-45pxr bg-black text-white text-[14px] md:text-[25px] rounded font-bold`}  onClick={() => onClickToggleErrorModal("")}>확인</button>
                        </div>
                    </div>

                </AlertModal>
            )}

        </section>
    )

}