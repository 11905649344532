import type {FC} from "react";
import {Div, DivProps, ReactDivProps} from "./Div";

export type HashTagProps = DivProps & {
    hashTag:string
}

export const HashTag: FC<HashTagProps> = ({
                                              className:_className,
                                              hashTag
                                          }) => {
    const className = ["flex justify-center items-center rounded-3xl m-2pxr border border-gray-500 h-22pxr md:h-28pxr  text-[11px] md:text-[12px] font-semibold truncate justify-center items-center px-10pxr md:px-12pxr",_className].join(' ')
    return (
        <Div className={className}>{hashTag}</Div>
    )
}
