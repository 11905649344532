import {FC, useCallback, useState, ChangeEvent} from "react";
import {DivProps} from "../common/Div";
import {CardRegisterRequestFormData, RegisterFormData} from "../../types/types";
import {useCreditCardRegister} from "../../hooks/payment/useCreditCardRegister";
import {MBTIData} from "../auth/MBTIChoice";

export type CardRegisterFormProps = DivProps & {
    cardData: CardRegisterRequestFormData,
    updateFields: (fields: Partial<CardRegisterRequestFormData>) => void
}

export const CardRegisterForm:FC<CardRegisterFormProps> = ({cardData,updateFields}) =>{


    const handleCardNoChange = (position: number, value: string) => {
        const cardNoArr = cardData.cardNo.match(/.{1,4}/g) || ["", "", "", ""];
        cardNoArr[position] = value;
        const newCardNo = cardNoArr.join("");
        updateFields({ cardNo: newCardNo });
    };

    const handleInputChange = (field: keyof CardRegisterRequestFormData) => (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        updateFields({[field]: event.target.value });
    };


    return (
        <section>
            <div className="flex flex-col justify-center">
                <div className="mt-49pxr md:mt-105pxr text-[#696969] font-bold text-[13px] md:text-[20px] text-start">카드번호</div>
                <div className="flex flex-row space-x-12pxr md:space-x-20pxr w-260pxr md:w-400pxr mt-16pxr md:mt-26pxr">
                    {Array(4)
                        .fill(null)
                        .map((_, index) => (
                            <div
                                key={index}
                                className="flex flex-col w-50pxr md:w-65pxr justify-center items-center"
                            >
                                <div>
                                    <input
                                        className="bg-white w-50pxr md:w-86pxr placeholder:text-gray-300 text-[13px] md:text-[20px] focus:outline-none text-center"
                                        type={index >= 2 ? "password" : "text"}
                                        pattern="\d*"
                                        maxLength={4}
                                        placeholder="0000"
                                        onChange={(e) => handleCardNoChange(index, e.target.value)}
                                    />
                                </div>
                                <div className="h-2pxr w-50pxr md:w-65pxr bg-black" />
                            </div>
                        ))}


                </div>
                <div className="flex flex-row  md:mt-10pxr">
                    <div className="flex flex-col justify-start items-start">
                        <div className="mt-49pxr md:mt-50pxr text-[#696969] font-bold text-[13px] md:text-[20px] text-start">유효기간</div>
                        <div className="flex flex-row  mt-16pxr md:mt-26pxr">
                            <div className="flex flex-col  w-86pxr justify-start items-start">
                                <div>
                                    <input
                                        className="bg-white w-50pxr md:w-65pxr  placeholder:text-gray-300 text-[13px] md:text-[20px] focus:outline-none text-center"
                                        type="text"
                                        pattern="\d*"
                                        maxLength={2}
                                        placeholder="MM"
                                        value={cardData.expMonth}
                                        onChange={handleInputChange("expMonth")}
                                    />
                                </div>
                                <div className="h-2pxr w-50pxr md:w-65pxr bg-black"/>

                            </div>
                            <div className="flex flex-col w-50pxr md:w-65pxr justify-center items-center">
                                <div>
                                    <input
                                        className="bg-white w-50pxr md:w-65pxr  placeholder:text-gray-300 text-[13px] md:text-[20px] focus:outline-none text-center"
                                        type="text"
                                        pattern="\d*"
                                        maxLength={2}
                                        placeholder="YY"
                                        value={cardData.expYear}
                                        onChange={handleInputChange("expYear")}
                                    />
                                </div>
                                <div className="h-2pxr w-50pxr md:w-65pxr bg-black"/>

                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col ml-50pxr md:ml-80pxr justify-center items-start">
                        <div className="mt-49pxr md:mt-50pxr text-[#696969] font-bold text-[13px] md:text-[20px] text-start">카드 비밀번호</div>

                            <div className="flex flex-col justify-start items-start mt-16pxr md:mt-26pxr">
                                <div>
                                    <input
                                        className="bg-white w-61pxr md:w-80pxr placeholder:text-gray-300 text-[13px] md:text-[20px] focus:outline-none text-center"
                                        type="password"
                                        pattern="\d*"
                                        maxLength={2}
                                        placeholder="앞 2자리"
                                        value={cardData.cardPw}
                                        onChange={handleInputChange("cardPw")}
                                    />
                                </div>
                                <div className="h-2pxr w-61pxr md:w-80pxr bg-black"/>

                            </div>

                    </div>

                </div>
                <div className="mt-49pxr md:mt-50pxr text-[#696969] font-bold text-[13px] md:text-[20px] text-start">생년월일</div>
                    <div className="flex flex-row justify-between w-275pxr md:w-400pxr  mt-26pxr">
                        <div className="flex flex-col w-115pxr justify-center items-start">
                            <div>
                                <input
                                    className="bg-white w-65pxr md:w-115pxr placeholder:text-gray-300 text-[13px] md:text-[20px] focus:outline-none text-center"
                                    type="text"
                                    pattern="\d*"
                                    maxLength={6}
                                    placeholder="YYMMDD"
                                    value={cardData.idNo}
                                    onChange={handleInputChange("idNo")}
                                />
                            </div>
                            <div className="h-2pxr w-65pxr md:w-115pxr bg-black"/>

                        </div>
                    </div>
            </div>
        </section>
    )

}