import {UseMutateFunction, useMutation, useQueryClient} from "react-query";
import {
    CardPaymentConfirmRequestFormData,
    IUser, ReviewModifyFormData, ReviewRequestFormData,
} from "../../types/types";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {useUserContext} from "../common/UserContext";
export function useReviewModifyRequest(onSuccess:() => void, lobbyistId: string) : UseMutateFunction<void, unknown, ReviewModifyFormData, unknown> {

    async function modifyRequest(reviewModifyFormData: ReviewModifyFormData, user: IUser | null,): Promise<void> {

        const config: any = {};
        if (user){
            config.headers = getJWTHeader(user)
            await axiosInstance.put(`/user/review`, reviewModifyFormData, config,);
        }
    }

    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate} = useMutation((reviewModifyFormData:ReviewModifyFormData) => modifyRequest(reviewModifyFormData, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.lobbyists])
                queryClient.invalidateQueries([queryKeys.megaphoneHistory])
                queryClient.invalidateQueries([queryKeys.requestHistory])
                queryClient.invalidateQueries([queryKeys.paidReview])
                queryClient.invalidateQueries([queryKeys.lobbyistReview,lobbyistId])
                queryClient.refetchQueries([queryKeys.lobbyistReview,lobbyistId])
                onSuccess()
            }
        })


    return mutate
}
