import {Avatar, HashTag, DivProps} from "./index";
import React, {useCallback, useEffect, useState} from "react";
import {Modal} from "./Modal";
import {useNavigate} from "react-router-dom";
import {useUser} from "../../hooks/common/useUser";
import {useLobbyistDetail} from "../../hooks/main/useLobbyistDetail";
import {formatCurrency} from "../../utils/data/helper";
import {Comment} from "../community/Comment";
import {useLobbyistReview} from "../../hooks/main/useLobbyistReview";
import {useUserContext} from "../../hooks/common/UserContext";
import {IPostDetail} from "../../types/types";
import {useRequestData} from "../requests/RequestDataFormContext";
import {useChatRoomProvider} from "../../hooks/chat/useChatRoomProvider";

type RequestModalViewProps = DivProps &{
    lobbyistId:string
    onClickRequestModal: () => void
    postDetail?:IPostDetail | undefined
}
export default function RequestModalView({lobbyistId, postDetail, onClickRequestModal ,...props}:RequestModalViewProps) {

    const {user} = useUserContext()
    const { lobbyist, isLoading } = useLobbyistDetail(lobbyistId)

    const { requestDataForm, setRequestDataForm } = useRequestData();

    useEffect(() => {
        setRequestDataForm({
                lobbyistId: lobbyistId,
                introducePrice: postDetail?.introducePrice || 0,
                contact:"",
                businessPrice: postDetail?.businessPrice || "",
                content: postDetail?.content || ""
            }
        )
    },[isLoading])


    const { lobbyistReviews } = useLobbyistReview(lobbyistId)


    const navigate = useNavigate()

    const goLogin = useCallback(() => {
        navigate("/login")
    },[navigate])



    const {fetchRoomIdAndNavigate} = useChatRoomProvider();
    const goChat = () => {
        fetchRoomIdAndNavigate(lobbyist?._id ?? "", lobbyist?.profileImage ?? "", lobbyist?.nickName ?? "" )
    };
    const netWordLists = lobbyist?.keywords.map((keyword,index) =>(
        <div key={index} className="relative inline-block text-[#696969] group">
            {(keyword) &&
                <>
                    <span className=" cursor-pointer">
                        <HashTag  hashTag={keyword.word}/>
                    </span>
                    <div className="hidden group-hover:block absolute left-0 w-200pxr md:w-300pxr text-[#555555] rounded-xl mt-1 bg-[#eaeaea] z-50">
                        <div className="flex flex-col text-[13px] font-semibold px-15pxr py-12pxr">
                            <div>| 소개비: {keyword.price ? formatCurrency(keyword.price) : "0"}원</div>
                            <div>| 설명: {keyword.detail}</div>
                        </div>
                    </div>
                </>
            }
        </div>
    ))

    const commentsLists = lobbyistReviews?.reverse().map((review, index) => (
        <Comment review={review} key={`${review._id}-${index}`}/>
    ))


    return(
        <section key={props.key}>

                    <Modal className="z-100" onCloseIconClicked={onClickRequestModal}>
                        <div className={`flex flex-col mt-29pxr md:mt-20pxr px-17pxr md:px-45pxr`}>
                            <div className={`text-[20px] md:text-[30px] h-24pxr md:h-48pxr  font-bold text-black `}>로비스트에게 의뢰서 보내기</div>
                            <div className={`flex flex-col text-[10px] md:text-[15px] font-semibold text-[#696969] mt-15pxr md:mt-20pxr`}>
                                <div>로비스트는 고객님께서 요청주신 사람을 연결해드리기 위해 최선을 다하고 있습니다.</div>
                                <div>따뜻한 마음으로 로비스트를 대해주시면 감사드리겠습니다.</div>

                            </div>
                            <div className={`flex flex-row items-start justify-between mt-36pxr md:mt-45pxr`}>
                                <div className={`flex flex-row md:items-end items-center`}>
                                    <Avatar className={`h-46pxr w-46pxr md:h-75pxr md:w-75pxr`} src={lobbyist?.profileImage}/>
                                    <div className={`text-[18px] md:text-[30px] h-21pxr md:h-48pxr ml-10pxr md:ml-15pxr font-semibold text-black `}>{lobbyist?.nickName}</div>
                                </div>
                                <div className={`flex flex-row items-start`}>
                                    {/*<div className={`flex flex-col text-black font-semibold`}>*/}
                                    {/*    <div className={`text-center text-[8px] md:text-[15px]`}>나눔 지수</div>*/}
                                    {/*    <div className={`flex flex-row mt-8pxr md:mt-12pxr`}>*/}
                                    {/*        <img src="/share_index.png" className={`h-20pxr w-20pxr md:h-32pxr md:w-32pxr`} />*/}
                                    {/*        <div className={`text-[12px] md:text-[20px] ml-7pxr  md:ml-13pxr `}>{lobbyist?.givingCount}</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div className={`flex flex-col ml-23pxr md:ml-38pxr text-black font-semibold`}>
                                        <div className={`text-center text-[8px] md:text-[15px]`}>해결 지수</div>
                                        <div className={`flex flex-row mt-8pxr md:mt-12pxr`}>
                                            <img src="/solution_index.png" className={`h-20pxr w-20pxr md:h-32pxr md:w-32pxr`} />
                                            <div className={`text-[12px] md:text-[20px] ml-7pxr  md:ml-13pxr `}>{lobbyist?.completeCount}</div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div className={`flex justify-start text-[12px] md:text-[20px] text-black font-semibold divide-x-[1px]  mt-55pxr`}>
                                <div></div>
                                <div className={`flex flex-col text-center px-28pxr md:px-47pxr`}>
                                    평균 소개 보수
                                    <div className={`mt-15pxr md:mt-15pxr`}>{lobbyist ? formatCurrency(lobbyist?.averageIntroducePrice) : "0"}원</div>
                                </div>
                                <div></div>
                            </div>
                            <div className={`flex flex-row items-end mt-25pxr md:mt-50pxr text-black`}>
                                <div className={`text-[15px] md:text-[18px]  font-bold`}>보유한 네트워크</div>
                                <div className={`text-[10px] md:text-[12px] font-semibold pl-10pxr md:pl-21pxr`}>네트워크 키워드를 클릭하면 소개비와 상세 내용을 볼 수 있어요</div>
                            </div>

                            <div className={`my-8pxr md:my-12pxr mt-15pxr md:mt-20pxr mr-15pxr flex flex-wrap flex-row `}>
                                {netWordLists}
                            </div>
                            <button className={`flex justify-center mt-67pxr md:mt-68pxr text-white text-[15pxr] md:text-[18px] font-semibold items-center w-337pxr h-35pxr md:w-550pxr md:h-45pxr bg-black rounded-md md:rounded-xl`} onClick={(user ? () => goChat() : goLogin)}>
                                <img src="/airplain_white@3x.png" className="h-35pxr w-35pxr"/> 채팅 상담하기
                            </button>

                            <div className={`flex flex-row items-end mt-50pxr md:mt-70pxr text-black`}>
                                <div className={`text-[17px] md:text-[18px]  font-bold`}>로비스트에 대한 후기</div>
                            </div>
                            <div className={`text-[10px] md:text-[12px] font-semibold mt-9pxr md:mt-10pxr text-[#696969]`}>의뢰를 진행한 의뢰인이 작성한 후기입니다</div>

                            <div className={`overflow-auto scrollbar my-18pxr md:my-40pxr h-140pxr md:h-180pxr `}>
                                {commentsLists?.length !== 0
                                    ? (commentsLists)
                                    : <div className={`text-[15px] md:text-[25px] font-bold text-center mt-50pxr md:mt-60pxr`}>
                                        아직 후기가 없어요
                                    </div>
                                }

                            </div>

                        </div>


                    </Modal>
        </section>
    )
}
