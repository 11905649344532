import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse, AxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";
import {IAdminUser, IAdminWholeMember, IAdminWholeMemberResponse} from "../types";
import {useAdminUserContext} from "../AdminUserProvider";

async function getAdminWholeMember(
    adminUser: IAdminUser | null,
    page: number,
    nickName: string,
    signupStartedAt: Date | null,
    signupEndedAt: Date | null
): Promise<IAdminWholeMemberResponse> {


    const config: AxiosRequestConfig = {};
    if (adminUser)
        config.headers = { authorization: `${adminUser.accessToken}` }
    config.params = {
        page: page,
        limit: 10,
        signupStartedAt: signupStartedAt?.toISOString(),
        signupEndedAt: signupEndedAt?.toISOString(),
        nickName: nickName,
    };
    const { data } : AxiosResponse<IAdminWholeMemberResponse> = await axiosInstance.get(`/admin/member`, config,);


    return data;

}

interface UseAdminWholeMember {
    members:IAdminWholeMember[]
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
    updatePageIndex: (pageIncrement: number) => void;
    pageIndex: number;
    hasNextPage:boolean
    updateSignupStartedAt: (date: Date | null) => void;
    updateSignupEndedAt: (date: Date | null) => void;
    updateSearchQuery: (query: string) => void;
    maxPage: number;
}

export function useAdminWholeMember(
    filter?: () => void
): UseAdminWholeMember {

    const { adminUser,setAdminUser } = useAdminUserContext();
    const [nickName, setNickName] = useState("");
    const [signupStartedAt, setSignupStartedAt] = useState<Date | null>(null);
    const [signupEndedAt, setSignupEndedAt] = useState<Date | null>(null);
    const [pageIndex, setPageIndex] = useState(1)
    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
    } = useQuery<IAdminWholeMemberResponse>(
        [queryKeys.adminWholeMember,pageIndex, nickName, signupStartedAt, signupEndedAt],
        () => getAdminWholeMember(adminUser, pageIndex, nickName, signupStartedAt, signupEndedAt),{

        }
    )


    function updatePageIndex(pageIncrement:number):void {
        setPageIndex((prev) => prev + pageIncrement)
    }
    function updateSignupStartedAt(date: Date | null): void {
        setSignupStartedAt(date);
    }

    function updateSignupEndedAt(date: Date | null): void {
        setSignupEndedAt(date);
    }

    function updateSearchQuery(query: string): void {
        setNickName(query);
    }
    const hasNextPage = (data?.count || 0) >  (10 * pageIndex)
    const maxPage = Math.ceil((data?.count || 0) / 10);



    useEffect(() => {

        queryClient.prefetchQuery([queryKeys.adminWholeMember,pageIndex + 1],
            () => getAdminWholeMember(adminUser, pageIndex + 1,  nickName, signupStartedAt, signupEndedAt),
        )
    },[queryClient,pageIndex])



    const members = data?.rows || []


    return {
        members,
        isLoading,
        isFetching,
        isError,
        error,
        pageIndex,
        updatePageIndex,
        hasNextPage,
        updateSearchQuery,
        updateSignupEndedAt,
        updateSignupStartedAt,
        maxPage
    };
}