import React from "react";
import {AdminUserProvider} from "./AdminUserProvider";
import AdminLoginPage from "./AdminLoginPage";
import AdminMain from "./AdminMain";

export function Admin() {
    return (
        <AdminUserProvider>
            <AdminMain />
        </AdminUserProvider>
    );
}

