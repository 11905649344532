import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IReceivedRequest,
    IReceivedRequestListResponse,
    IUser
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {useQuery, useQueryClient} from "react-query";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {useEffect, useState} from "react";
import {useUserContext} from "../common/UserContext";

const maxPostPage = 10

async function getReceivedMegaphoneRequestLists(user:IUser|null,page: number, limit: number): Promise<IReceivedRequest[] | null> {
    if (!user) return null

    const config: AxiosRequestConfig = {};
    if (user){
        config.headers = getJWTHeader(user);
        const { data } : AxiosResponse<IReceivedRequestListResponse> = await axiosInstance.get(`/user/lobby/mega/received?page=${page}&limit=10`, config,)
        return data.rows
    }

    return null

}

interface UseReceivedMegaphoneRequest {
    receivedMegaphoneRequests: IReceivedRequest[] | undefined;
}


export function useReceivedMegaphoneRequest() :UseReceivedMegaphoneRequest{
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()


    useEffect(() => {
        if (currentPage < maxPostPage) {
            const nextPage = currentPage + 1
            queryClient.prefetchQuery([queryKeys.receivedMegaphoneRequests, nextPage], () => getReceivedMegaphoneRequestLists(user,nextPage,10))
        }},[currentPage,queryClient])


    const { data ,isLoading, isFetching, isError, error } = useQuery<IReceivedRequest[] | null>(
        [queryKeys.receivedMegaphoneRequests,currentPage],
        () => getReceivedMegaphoneRequestLists(user,currentPage,10),
        {
            keepPreviousData:true,
            refetchInterval:false,
            refetchOnMount:false,
            refetchOnWindowFocus:false,
            refetchIntervalInBackground:false,
            enabled: !!user,
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.receivedRequestCount])
            }
        }
    );

    const receivedMegaphoneRequests = data ?? undefined;

    return { receivedMegaphoneRequests };
}