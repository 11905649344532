import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IMyRequestDetailHistory,
    IMyRequestDetailHistoryResponse,
    IMyRequestHistoryResponse,
    IPost,
    IPostResponse,
    IRequestHistory,
    IUser
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserContext} from "../common/UserContext";

async function getRequestDetailHistory(user:IUser|null, lobbyId:string): Promise<IMyRequestDetailHistoryResponse | null> {
    if (!user) return null

    const config: any = {};
    config.headers = getJWTHeader(user);
    const { data } : AxiosResponse<IMyRequestDetailHistoryResponse> = await axiosInstance.get(`/user/lobby/oto/detail/${lobbyId}`, config,)
    return data



}

interface UseRequestDetailHistory {
    detailRequest: IMyRequestDetailHistory | undefined;
}


export function useRequestDetailHistory(lobbyId:string) :UseRequestDetailHistory{
    const { user, updateUser, clearUser } = useUserContext();

    const { data , isLoading, isFetching, isError, error } = useQuery(
        [queryKeys.requestHistory,lobbyId],
        () => getRequestDetailHistory(user,lobbyId),
        {
            enabled: !!lobbyId

        }
    );
    const detailRequest = data?.row
    return { detailRequest };
}