import {UseMutateFunction, useMutation, useQueryClient} from "react-query";
import {IPost, IPostDetail, IUser, PostRequestFormData} from "../../types/types";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import axios, {AxiosRequestConfig} from "axios";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {useUserContext} from "../common/UserContext";

export function useDeleteCommunityPost(
    postId:string,
    goBack: () => void)
 : UseMutateFunction<void, unknown, IPostDetail, unknown> {

    async function deleteCommunityPost(postDetail: IPostDetail, user: IUser | null): Promise<void> {
        const config: AxiosRequestConfig = {};
        if (user){
            config.headers = getJWTHeader(user);
            await axiosInstance.delete(`/user/post/${postDetail._id}`, config);
        }
    }




    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate} = useMutation((postDetail:IPostDetail) => deleteCommunityPost(postDetail, user),
        {
            onSuccess:  () => {
                queryClient.invalidateQueries([queryKeys.community]).then(() => goBack())
                queryClient.removeQueries([queryKeys.community,postId])

            }
        })

    return mutate
}
