import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IPost,
    IPostResponse,
    IUser,
    IUserNotification,
    IUserNotificationCount,
    IUserNotificationResponse
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserInformation} from "../myprofile/useUserInformation";
import {useUserContext} from "../common/UserContext";

async function getNotificationCount(user:IUser|null): Promise<IUserNotificationCount> {

    const config: any = {};
    if (user){
        config.headers = getJWTHeader(user);
    }
    const { data } : AxiosResponse<IUserNotificationCount> = await axiosInstance.get('/user/notification/count/unread', config,);
    return data

}

interface UseNotificationCount {
    notificationCount: number;

}

export function useNotificationCount(): UseNotificationCount {
    const { user, updateUser, clearUser } = useUserContext();

    const {data, isLoading, isFetching, isError, error} = useQuery<IUserNotificationCount>(
        [queryKeys.notificationCount],
        () => getNotificationCount(user),
        {
            refetchOnMount:false,
            refetchOnReconnect:false,
            refetchIntervalInBackground:false,
            refetchInterval: 1000 * 5,
            retry:0,

            enabled: !!user, // user가 없으면 쿼리를 실행하지 않습니다.
        }

    );

    const notificationCount = data?.row.unreadCount || 0
    return { notificationCount }

}
