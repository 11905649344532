import {useUser} from "../../hooks/common/useUser";
import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {useUserContext} from "../../hooks/common/UserContext";

type MyPageMenuProps = {
    settingMenuOnclick: () => void
}

export default function MyPageMenu({settingMenuOnclick}:MyPageMenuProps){
    const {clearUser} = useUserContext()
    const handleClick = () => {
        clearUser()
        settingMenuOnclick()
        navigate("/")
    };

    const navigate = useNavigate()
    const goMyProfile = useCallback(() => {
        navigate("/profile")
    },[navigate])

     const goManage = useCallback(() => {
        navigate("/payment/manage")
    },[navigate])
    const goMyReputation = useCallback(() => {
        navigate("/review")
    },[navigate])

    return (
        <div className={`flex flex-col absolute font-normal bg-white w-123pxr md:w-200pxr h-150pxr md:h-200pxr border border-black rounded-xl top-35pxr md:right-0pxr md:top-65pxr`}>
            <ul className="text-black font-base text-[10px] md:text-[15px] mt-5pxr md:mt-10pxr pl-12pxr md:pl-13pxr">
                <button onClick={goMyProfile} className={`flex flex-row items-center`}>
                    <img className={`h-24pxr w-24pxr md:h-33pxr md:w-33pxr`} src="/user.png"/>
                    <div  className="block px-4pxr py-8pxr md:px-11pxr md:py-10pxr">프로필 설정</div>
                </button>
                {/*<button onClick={goHistory} className={`flex flex-row items-center`}>*/}
                {/*    <img className={`h-24pxr w-24pxr md:h-33pxr md:w-33pxr`} src="/memo.png"/>*/}
                {/*    <div className="block px-4pxr py-8pxr md:px-11pxr md:py-10pxr">내가 요청한 의뢰</div>*/}
                {/*</button>*/}
                <button onClick={goManage} className={`flex flex-row items-center`}>
                    <img className={`h-24pxr w-24pxr md:h-33pxr md:w-33pxr`} src="/credit-card.png"/>
                    <div className="block px-4pxr py-8pxr md:px-11pxr md:py-10pxr">결제/계좌 관리</div>
                </button>

                <button onClick={goMyReputation} className={`flex flex-row items-center`}>
                    <img className={`h-24pxr w-24pxr md:h-33pxr md:w-33pxr`} src="/reputation@3x.png"/>
                    <div className="block px-4pxr py-8pxr md:px-11pxr md:py-10pxr">후기 관리</div>
                </button>
            </ul>
            <div className="text-[#a2a2a2] pl-12pxr md:px-13pxr pt-10pxr md:pt-30pxr text-[10px] md:text-[12px]">
                <button onClick={handleClick}>로그아웃</button>
            </div>
        </div>
    )
}