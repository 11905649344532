import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IUser, IUserResponse, MegaphoneRequestFormData, RequestFormData} from "../../types/types";
import {UseMutateFunction, useMutation, useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../../services/react-query/constants";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {setStoredUser} from "../../utils/user-storage";
import {useUserContext} from "../common/UserContext";



export function useMegaphoneRequest(
    onComplete: ()  => void
) : UseMutateFunction<void, unknown, MegaphoneRequestFormData, unknown> {

    async function setMegaphoneRequest(megaphoneRequestFormData: MegaphoneRequestFormData, user: IUser | null,): Promise<void> {
        const config: AxiosRequestConfig = {};
        if (user) {
                config.headers = getJWTHeader(user);

            await axiosInstance.post(`/user/lobby/mega/`, megaphoneRequestFormData, config,);
        }
    }

    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate} = useMutation((megaphoneRequestFormData: MegaphoneRequestFormData) => setMegaphoneRequest(megaphoneRequestFormData, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.megaphoneHistory])
                queryClient.invalidateQueries([queryKeys.megaphoneRequest])
                onComplete();
            }
        })

    return mutate
}

