import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import {
    CardPaymentConfirmRequestFormData,
    IUser,
    ReviewModifyFormData,
    ReviewRequestFormData,
} from "../../types/types";
import { axiosInstance, getJWTHeader } from "../../services/axiosInstance";
import { queryKeys } from "../../services/react-query/constants";
import { useAdminUserContext } from "../AdminUserProvider";
import {AdminRejectFormData, IAdminUser} from "../types";
import {queryClient} from "../../services/react-query/queryClient";

export function useAdminMegaReject(
    onSuccess: () => void,
    pageIndex:number
): UseMutateFunction<void, unknown, AdminRejectFormData, unknown> {
    async function rejectRequest(
        adminUser: IAdminUser | null,
        adminRejectFormData: AdminRejectFormData
    ): Promise<void> {
        const config: any = {};
        if (adminUser) {
            config.headers = { authorization: `${adminUser.accessToken}` };
        }
        await axiosInstance.put(`/admin/lobby/mega/reject`, adminRejectFormData, config);
    }

    const { adminUser, setAdminUser } = useAdminUserContext();
    const { mutate } = useMutation(
        (adminRejectFormData: AdminRejectFormData) => rejectRequest(adminUser, adminRejectFormData),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.adminMegaphone,pageIndex])
                onSuccess();
            },
        }
    );

    return mutate;
}