import {UseMutateFunction, useMutation, useQueryClient} from "react-query";
import {IUser, PostRequestFormData, ReportFormData} from "../../types/types";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import axios, {AxiosRequestConfig} from "axios";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {useUserContext} from "../common/UserContext";

export function useReport(
    goBack: () => void)
    : UseMutateFunction<void, unknown, ReportFormData, unknown> {

    async function reportRequest(reportFormData:ReportFormData, user: IUser | null,): Promise<void> {
        const config: AxiosRequestConfig = {};
        if (reportFormData.postId){
            if (user){
                config.headers = getJWTHeader(user);
                await axiosInstance.post(`/user/report/post`,reportFormData, config);
            }
        }else{
            if (user){
                config.headers = getJWTHeader(user);
                await axiosInstance.post(`/user/report/chat`,reportFormData, config);
            }
        }


    }




    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate} = useMutation((reportFormData:ReportFormData) => reportRequest(reportFormData, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.community])
                queryClient.invalidateQueries([queryKeys.chatList])
                goBack()
            }
        })

    return mutate
}
