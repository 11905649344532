import {useNavigate} from "react-router-dom";
import {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";

import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {IUserInformation, Keyword} from "../../types/types";
import {useModifyUserInformation} from "../../hooks/myprofile/useModifyUserInformation";
import {NetworkCardView} from "./NetworkCardView";
import {useModifyMyNetworks} from "../../hooks/mynetwork/useModifyMyNetworks";
import {regaxNormal} from "../../utils/data/helper";

export default function NetworkSettingView(){

    const {userInformation ,isLoading} = useUserInformation()
    const [localUserInformation, setLocalUserInformation] = useState<IUserInformation>(userInformation);
    const modifyUserNetworks = useModifyMyNetworks(() => goBack())



    const idCounter = useRef(0);

    const [keywords, setKeywords] = useState(localUserInformation.keywords.map((k) => ({...k, id: idCounter.current++})))

    useEffect(() => {
        setLocalUserInformation(userInformation);

    }, [isLoading]);

    useEffect(() => {
        setKeywords(localUserInformation.keywords)
        setCheckboxStatus(() => {
            const dic: Record<number, boolean> = {};
            for (let index = 0; index < localUserInformation.keywords.length; index++) {
                const keyword = localUserInformation.keywords[index];
                if (keyword.price === 0) {
                    dic[keyword.id] = true;
                }
            }
            return dic;
        })
    },[localUserInformation])

    const navigate = useNavigate()
    const goBack = useCallback(() =>{
        navigate(-1)
    },[navigate])

    const [inputValue, setInputValue] = useState(""); // inputValue 상태를 추가

    const [checkboxStatus, setCheckboxStatus] = useState<Record<number, boolean>>({});


    const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value.trim().replace(/\s\s+/g, ' '); // remove duplicate spaces
        if (regaxNormal(10).test(inputValue)) { // check if input meets criteria
            setInputValue(inputValue); // save the input value to the state
        }
    };



    const handlePriceChange = (id:number ,price: number) => {
        setLocalUserInformation((prev) => {
            const newUserInformation = { ...prev };
            const keywordIndex = newUserInformation.keywords.findIndex((keyword) =>  keyword.id === id);
            if (keywordIndex !== -1) {
                newUserInformation.keywords[keywordIndex].price = price;
            }
            return newUserInformation;
        });
    };
    const handleWordChange = (id:number ,word: string) => {
        setLocalUserInformation((prev) => {
            const newUserInformation = { ...prev };
            const keywordIndex = newUserInformation.keywords.findIndex((keyword) => keyword.id === id);
            if (keywordIndex !== -1) {
                newUserInformation.keywords[keywordIndex].word = word;
            }
            return newUserInformation;
        });
    };
    const handleDetailChange = (id:number ,detail: string) => {
        setLocalUserInformation((prev) => {
            const newUserInformation = { ...prev };
            const keywordIndex = newUserInformation.keywords.findIndex((keyword) => keyword.id === id);
            if (keywordIndex !== -1) {
                newUserInformation.keywords[keywordIndex].detail = detail;
            }
            return newUserInformation;
        });
    };







    const handleCheckboxChange = (id: number, isChecked: boolean) => {
        setCheckboxStatus((prev) => {
            const newStatus = { ...prev };
            newStatus[id] = isChecked;
            return newStatus;
        });
    };




    const onAddForm = useCallback((keyword:Keyword) =>{
        const newForm: Keyword = { ...keyword }; // 고유한 숫자 ID 추가

        if (keywords.length < 10) {
            setKeywords([newForm, ...keywords]);

            // 동시에 localUserInformation 키워드 배열에 적용
            setLocalUserInformation((prev) => {
                const newUserInformation = { ...prev };
                newUserInformation.keywords = [newForm, ...prev.keywords];
                return newUserInformation;
            });
        }
    }, [keywords]);

    const onRemoveForm = useCallback((id: number) => {
        setKeywords((prevKeywords) => {
            const updatedKeywords = prevKeywords.filter((keyword) => keyword.id !== id);

            // Update localUserInformation
            setLocalUserInformation((prev) => {
                const newUserInformation = { ...prev };
                newUserInformation.keywords = updatedKeywords;
                return newUserInformation;
            });

            return updatedKeywords;
        });
    }, [keywords]);


    const handleMoveUp = useCallback((id: number) => {
        setKeywords((prevKeywords) => {
            const keywordIndex = prevKeywords.findIndex((keyword) => keyword.id === id);
            if (keywordIndex > 0) {
                const updatedKeywords = [...prevKeywords];
                [updatedKeywords[keywordIndex], updatedKeywords[keywordIndex - 1]] = [updatedKeywords[keywordIndex - 1], updatedKeywords[keywordIndex]];
                setLocalUserInformation((prev) => {
                    const newUserInformation = { ...prev };
                    newUserInformation.keywords = updatedKeywords;
                    return newUserInformation;
                });
                return updatedKeywords;
            }
            return prevKeywords;
        });
    }, [keywords]);

    const isValidKeywords = useCallback(() => {
        return localUserInformation.keywords.every((keyword,index) => {
            const checked = checkboxStatus[keyword.id]
            return (keyword.word.trim() !== "" && keyword.detail.trim() !== "" && ((checked && keyword.price === 0) || (!checked && keyword.price >= 1000)));
        });
    }, [localUserInformation,keywords]);


    const forms = keywords.map((keyword) => {
        return (
            <NetworkCardView
                keyword={keyword}
                onWordChange={handleWordChange}
                onCheckboxChange={handleCheckboxChange}
                onDetailChange={handleDetailChange}
                onPriceChange={handlePriceChange}
                onClose={() => onRemoveForm(keyword.id)} key={keyword.id}
                onMoveUp={() => handleMoveUp(keyword.id)} // 추가된 콜백
                checked={checkboxStatus[keyword.id]}
            />
        )
    })

    return (
        <section>
            <div className={`flex flex-col items-center mt-23pxr md:mt-50pxr pb-100pxr`}>
                <div className="w-screen ml-18pxr  md:w-1/2">
                    <button>
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}/>
                    </button>
                </div>
                <div className={`mt-14pxr md:mt-6pxr`}>
                    <div className={`text-[15px] md:text-[30px] text-black font-extrabold`}>나의 인맥 설정</div>
                </div>

                <div className={`w-337pxr md:w-530pxr flex flex-row mt-55pxr md:mt-90pxr justify-between text-[15px] md:text-[20px] text-black font-extrabold`}>
                    <div>나의 인맥 설정</div>
                    <div className={`mt-3pxr md:mt-10pxr`}>{keywords.length} / 10</div>
                </div>
                <div className={`w-337pxr md:w-530pxr md:mt-18pxr text-[10px] md:text-[15px] font-semibold text-black `}>ex) ‘한예종연극학과’ ‘스냅사진작가’ ‘광화문족발집사장님’</div>
                <div className={`w-337pxr md:w-530pxr md:mt-11pxr text-[10px] md:text-[15px] font-semibold text-black `}>중개를 해주고 나는 소개비를 얻고! 친구도 자문, 업무를 해주고 수입을 얻고!</div>

                <div className={`flex  flex-row w-337pxr md:w-530pxr justify-between items-center mt-18pxr md:mt-50pxr text-[15px] md:text-[25px] text-black font-extrabold`}>
                    <div className={`flex h-45pxr w-242pxr md:h-58pxr md:w-410pxr  bg-white rounded-lg border border-black border-1`}>
                        <input type="text"
                               className={`w-341pxr md:w-450pxr placeholder:text-[#a8a8a8]  bg-white text-black text-[12px] md:text-[20px] font-normal text-left m-10pxr md:m-13pxr focus:outline-none`}
                               placeholder={`키워드 입력(최대 10글자)`}
                               value={inputValue}
                               onChange={handleKeywordChange}/>
                    </div>
                    {inputValue ?

                        <button disabled={inputValue === ""} className={`flex justify-center items-center h-45pxr w-70pxr md:h-58pxr md:w-91pxr text-white text-[36px] md:text-[50px] rounded-xl `} onClick={() => {
                            if (inputValue) {
                                onAddForm({id: idCounter.current++ ,word: inputValue, detail: "", price: 0 });
                                setInputValue(""); // 상태 초기화
                            }
                        }}>
                            <img src="/plus-black.png"/>
                        </button>

                        :

                        <img className="h-45pxr w-70pxr md:h-58pxr md:w-91pxr" src="/plus-white.png"/>


                    }
                </div>

                {forms.length !== 0 ? forms : (
                    <div className={`text-[15px] md:text-[25px] font-bold my-66pxr md:my-137pxr`}>
                        아직 등록된 인맥이 없어요
                    </div>
                )}

                <button disabled={!isValidKeywords()} className={`fixed bottom-20pxr text-[15px] md:text-[22px]  w-337pxr h-35pxr md:w-530pxr md:h-58pxr  my-15pxr   md:my-30pxr ${!isValidKeywords() ? "bg-[#ededed]" : "bg-black"} text-white font-extrabold text-center  rounded`}
                        onClick={() => modifyUserNetworks(localUserInformation.keywords)}
                >
                    저장하기
                </button>
            </div>
        </section>
    )
}