import {useMemo, useState} from "react";
import {Column, usePagination, useTable, Row, UsePaginationInstanceProps, TableInstance , TableState, UsePaginationState} from "react-table";
import {LobbyistActivationIcon} from "./Component/LobbyistActivationIcon";
import {Title} from "../components/common";
import {useAdminWholeMember} from "./hooks/useAdminWholeMember";
import {IAdminWholeMember} from "./types";
import DateRangePicker from "./Component/DateRangePicker";

interface CustomTableInstance extends TableInstance<IAdminWholeMember>, UsePaginationInstanceProps<IAdminWholeMember> {}
export default function WholeMembersManagement(){

    const {
        members,
        isLoading,
        isFetching,
        isError,
        error,
        pageIndex,
        maxPage,
        updatePageIndex,
        hasNextPage,
        updateSearchQuery,
        updateSignupEndedAt,
        updateSignupStartedAt,
    } = useAdminWholeMember();




    const data = useMemo<IAdminWholeMember[]>(() =>
            members
    , [isFetching]);
    const columns = useMemo(
        () => [
            // { MainHeader: "상태", accessor: "status" },
            { Header: "이메일", accessor: "email" },
            { Header: "닉네임", accessor: "nickName" },
            { Header: "가입일", accessor: "createdAt" , Cell:({value} : {value:string}) => <div>{new Date(value).toLocaleString()}</div>},
            { Header: "로비스트활성화", accessor: "bLobbyist", Cell: ({ value }: { value: boolean }) => <LobbyistActivationIcon status={value} />,},
            // { MainHeader: "소개비평균", accessor: "introFeeAvg" },
            // { MainHeader: "네트워크", accessor: "network" },
            // { MainHeader: "직군", accessor: "jobField" },
            // { MainHeader: "MBTI", accessor: "mbti" },
            // { MainHeader: "권한/조치", accessor: "authorityAction" },
        ],
        []
    );
    const initialState: Partial<TableState<IAdminWholeMember> & UsePaginationState<IAdminWholeMember>> = {
        pageIndex: 0,
        pageSize: 10,
    };
    const [searchText, setSearchText] = useState("");


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageCount,

        prepareRow,
    } = useTable<IAdminWholeMember>(
        {
            columns: columns as Column<IAdminWholeMember>[],
            data:data,
            initialState: initialState,
        },
        usePagination
    ) as CustomTableInstance;




    return (
        <div className="flex flex-col items-center members mt-25pxr">
            <Title>회원관리</Title>
            <input
                className="mx-auto border w-500pxr mt-25pxr"
                type="text"
                placeholder="검색"
                value={searchText}
                onChange={(e) => {
                    setSearchText(e.target.value)
                    updateSearchQuery(e.target.value)
                }
            }
            />

            <DateRangePicker
                onStartDateChange={(date) => updateSignupStartedAt(date)}
                onEndDateChange={(date) => updateSignupEndedAt(date)}
            />

            <table {...getTableProps()} className="members-table  mx-auto mt-50pxr">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()} className="px-15pxr py-15pxr border-b-4">
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row: Row<IAdminWholeMember>) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                                return (
                                    <td {...cell.getCellProps()} className="px-15pxr py-15pxr border-b-2">
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className="flex flex-row pagination space-x-100pxr mt-50pxr">
                <button
                    onClick={() => updatePageIndex(-1)}
                    disabled={pageIndex === 1}
                    className="mx-6pxr"
                >
                    <img className="h-25pxr" src="/left-arrow.png"/>
                </button>
                <div>
                {pageIndex} / {maxPage}
                </div>
                <button
                    onClick={() => updatePageIndex(+1)}
                    disabled={!hasNextPage}
                    className="mx-6pxr"
                >
                    <img className="h-25pxr" src="/right-arrow.png"/>
                </button>
            </div>
        </div>
    );
}