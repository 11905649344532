import {IUser} from "../../types/types";


const USER_LOCALSTORAGE_KEY = 'lobbyD_user';

// helper to get user from localstorage
export function getStoredUser(): IUser | null {
  const storedUser = localStorage.getItem(USER_LOCALSTORAGE_KEY);
  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredUser(user: IUser): void {
  localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user));
}

export function clearStoredUser(): void {
  localStorage.removeItem(USER_LOCALSTORAGE_KEY);
}

const LAST_VISIT_LOCALSTORAGE_KEY = 'lobbyD_lastVisit';

// 사용자가 마지막으로 페이지를 방문한 시간을 가져옵니다.
export function getLastVisit(): Date | null {
  const lastVisit = localStorage.getItem(LAST_VISIT_LOCALSTORAGE_KEY);
  return lastVisit ? new Date(Number(lastVisit)) : null;
}

// 사용자가 마지막으로 페이지를 방문한 시간을 설정합니다.
export function setLastVisit(date: Date): void {
  localStorage.setItem(LAST_VISIT_LOCALSTORAGE_KEY, String(date.getTime()));
}