import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Footer from "../main/Footer";
import {IUser, PostModifyFormData, PostRequestFormData, RequestFormData} from "../../types/types";
import {formatCurrency, regaxNormal} from "../../utils/data/helper";
import BasicHeader from "../common/BasicHeader";
import {useWriteCommunityPost} from "../../hooks/community/useWriteCommunityPost";
import {useDetailCommunity} from "../../hooks/community/useDetailCommunity";
import {useModifyCommunityPost} from "../../hooks/community/useModifyCommunityPost";
import {useUser} from "../../hooks/common/useUser";


export default function CommunityPostModifyView() {
    const {postId} = useParams()
    const {data} = useDetailCommunity(postId as string)

    const INITIAL_DATA : PostModifyFormData = {
        title: data?.title || "",
        content: data?.content ||"",
        introducePrice: data?.introducePrice || 0,
        businessPrice: data?.businessPrice || "",
        postId: postId || "",
        status: data?.status || "proceed",
    }

    const [postData, setPostData] = useState(INITIAL_DATA)


    const updateFields = (fields: Partial<PostModifyFormData>) => {
        setPostData(prev => {
            return { ...prev, ...fields }
        })

    }

    const navigate = useNavigate()


    const goBack = useCallback(() =>{
        window.scroll(0,0)
        navigate(-1)
    },[navigate])


    const [isChecked, setIsChecked] = useState<boolean>(postData.introducePrice === 0)
    const check = useCallback(() => {
        setIsChecked(isChecked => !isChecked)
        !isChecked && updateFields({introducePrice : 0})
    },[isChecked])


    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/,/g, "");
        if (/^\d{0,8}$/.test(newValue)) {
            const parsedValue = parseInt(newValue, 10);
            let roundedValue = parsedValue;
            if (parsedValue >= 10000) {
                roundedValue = Math.round(parsedValue / 1000) * 1000;
            }
            updateFields({ introducePrice: isNaN(roundedValue) ? 0 : roundedValue });
            setIntroducePrice(isNaN(roundedValue) ? 0 : roundedValue);
        }
    };
    const [introducePrice, setIntroducePrice] =  useState<number>(0)
    const handleCoffeePriceChange = () => {
        setIntroducePrice(prevState => {
            const newValue = prevState + 5000;
            updateFields({introducePrice: newValue});
            return newValue;
        });
    }

    const handleChickenPriceChange = () => {
        setIntroducePrice(prevState => {
            const newValue = prevState + 20000;
            updateFields({introducePrice: newValue});
            return newValue;
        });
    }


    const handleTitle = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (regaxNormal(50).test(inputValue)) {
            updateFields({title: inputValue})
        }
    }

    const handleContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (regaxNormal(1000).test(inputValue)) {
            updateFields({content: inputValue})
        }

    }
    const handleBusinessPrice = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (regaxNormal(30).test(inputValue)) {
            updateFields({businessPrice: inputValue})
        }

    }
    const handleButton: boolean = (
        (postData.introducePrice < 5000) ||
        postData.title.trim().length === 0 ||
        postData.content.trim().length === 0 ||
        postData.businessPrice.trim().length === 0
    );
    const modify = useModifyCommunityPost(postId!,goBack)

    return(
        <section>
            <BasicHeader/>

            <div className={`flex flex-col items-center mb-90pxr md:mb-91pxr `}>

                <div className={`flex flex-col items-start`}>
                    <div className={`relative mt-22pxr md:mt-91pxr items-start right-10pxr md:right-20pxr`}>
                        <button className={`w-8pxr h-16pxr  md:w-15pxr md:h-30pxr`} onClick={goBack}>
                            <img src="/back.png"/>

                        </button>
                    </div>
                    <div className={`flex flex-row items-end mt-10pxr`}>
                        <div className={`flex flex-col mr-11pxr md:mr-17pxr`}>
                            <div className={`w-341pxr md:w-660pxr text-[15px] md:text-[20px] md:text-[25px] font-bold text-black `}>내용을 보고 소개 연결이 가능한 로비스트가 댓글을 남기게 됩니다.</div>
                        </div>

                    </div>
                    <div className={`flex flex-col w-341pxr md:w-600pxr text-[10px] md:text-[16px] text-[#696969] font-bold mt-15pxr md:mt-28pxr`}>
                        <div>의뢰서 내용이 상세할수록 로비스트들이 사람을 찾는 데에 도움이 될 수 있으며
                            회신율이 높아질 수 있습니다.</div>

                        <div>
                            <input type="text" onChange={handleTitle} value={postData.title} className={`resize-none mt-25pxr py-15pxr md:mt-40pxr w-341pxr md:w-600pxr mx-5pxr bg-white text-[12px] md:text-[23px] text-black placeholder:text-[#d0d0d0]  border-b-2 focus:outline-0`} placeholder={`제목을 입력해주세요`} />
                        </div>

                        <div className={`text-[13px] md:text-[18px] text-black font-extrabold mt-30pxr md:mt-50pxr`}>
                            자기소개 / 찾는사람 / 요구사항
                        </div>
                        <div className={`h-94pxr w-341pxr md:h-111pxr md:w-660pxr mt-5pxr md:mt-12pxr`}>
                            <textarea className={`resize-none border border-1 font-normal w-341pxr md:w-600pxr p-5pxr md:p-12pxr bg-white text-[10px] md:text-[18px] text-black placeholder:text-gray-300 rounded-lg`} rows={3} value={postData.content} onChange={handleContent} placeholder={`예시) 붕어빵 장사에 도전해보고 싶은 청년입니다. 1달 정도 도전해보고 싶은데 재료 수급 노하우, 제조법 전수, 기계 렌탈까지 가능한 붕어빵 사장님 소개해주세요.`} />
                        </div>
                        <div className={`flex flex-row items-end text-black  font-extrabold mt-20pxr md:mt-30pxr`}>
                            <div className={`text-[13px] md:text-[18px]`}>업무비</div>
                            <div className={`text-[10px] md:text-[15px] ml-3pxr md:ml-5pxr`}>(소개받으신 분께 자문을 구하거나 업무를 부탁드린다면 보수를 입력해주세요)</div>

                        </div>
                        <div className={`md:h-111pxr md:w-660pxr mt-5pxr  md:mt-12pxr`}>
                            <textarea className={`resize-none border border-1 font-normal w-341pxr md:w-600pxr p-5pxr md:p-12pxr bg-white text-[10px] md:text-[18px] text-black placeholder:text-gray-300  rounded-lg`} value={postData.businessPrice} onChange={handleBusinessPrice} rows={2} placeholder={`예시) 자문비 30,000원 / 업무비 200,000원 / 협업 / 혜택 제공 / 상담 후 결정 / 해당 사항 없음`} />
                        </div>
                        <div className={`flex flex-row items-end text-black font-extrabold mt-30pxr `}>
                            <div className={`text-[13px] md:text-[18px]`}>소개비</div>
                            <div className={`text-[10px] md:text-[15px] ml-3pxr md:ml-5pxr`}>(소개해주는 로비스트에게 지급되는 보수입니다)</div>
                        </div>
                        <div className="flex flex-row w-341pxr  md:w-660pxr h-88pxr justify-start items-start">
                            <div className={`flex flex-row items-center h-44pxr md:h-65pxr  w-240pxr md:w-440pxr  mt-10pxr bg-white rounded-xl border border-1`}>
                                <input type="text"  value={introducePrice === 0 ? "" :formatCurrency(introducePrice)} onChange={handleChange} className={`resize-none w-200pxr md:w-400pxr  py-5pxr bg-white text-black text-[10px] md:text-[18px] m-10pxr text-left  placeholder:text-normal  focus:outline-none `} placeholder={"원하는 만큼의 커피와 치킨을 클릭해보세요!"}/>
                                {introducePrice !== 0 &&
                                    <div className="text-black text-[10px] md:text-[18px] md:mr-10pxr">원</div>
                                }

                            </div>

                            <button onClick={handleCoffeePriceChange} className="flex flex-col items-center h-88pxr w-44pxr mt-10pxr ml-10pxr md:h-130pxr md:w-65pxr text-[10px] text-[#696969]">
                                <img src="/coffee@3x.png" className="h-44pxr w-44pxr md:h-65pxr md:w-65pxr"/>
                                <div>x1 click</div>
                                <div>5,000원</div>
                            </button>
                            <button onClick={handleChickenPriceChange} className="flex flex-col items-center h-88pxr mt-10pxr w-44pxr  md:h-130pxr md:w-65pxr ml-10pxr text-[10px] text-[#696969]">
                                <img src="/chicken@3x.png" className="h-44pxr w-44pxr  md:h-65pxr md:w-65pxr"/>
                                <div>x1 click</div>
                                <div>20,000원</div>
                            </button>
                        </div>
                        <div className="text-[12px]md:text-[18px] font-bold text-black mt-10pxr">의뢰 마감 기간은 14일입니다.</div>
                        <button disabled={handleButton} className={`w-341pxr h-35pxr md:w-600pxr md:h-45pxr mt-30pxr  md:mt-56pxr ${handleButton && !isChecked ? "bg-[#e0e0e0]" : "bg-black"} text[15px] md:text-[20px] text-white font-bold rounded md:rounded-lg`} onClick={() => modify(postData)}>수정하기</button>
                    </div>
                </div>

            </div>






        </section>
    )
}