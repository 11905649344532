import {Title} from "../common";
import {useInterval} from "../../hooks/common";
import React, {ChangeEvent, KeyboardEvent, KeyboardEventHandler, useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useUser} from "../../hooks/common/useUser";
import {useLobbyistSearchList} from "../../hooks/main/useLobbyistSearchList";
import {useReceivedRequestCount} from "../../hooks/main/useReceivedRequestCount";
import {useUserContext} from "../../hooks/common/UserContext";
import ReactGA from "react-ga4";

type BannerProps = {
    searchLobbyist: (query:string) => void
    wholeLobbyistView: () => void
}


export default function Banner({searchLobbyist, wholeLobbyistView}:BannerProps){

    const {user} = useUserContext()

    const navigate = useNavigate()

    const goMegaphone = useCallback(() =>{
        window.scrollTo(0, 0);
        navigate("/megaphone")
    },[navigate])

    const goMyNetwork = useCallback(() =>{
        window.scrollTo(0, 0);
        navigate("/network")
    },[navigate])

    const goReceivedRequest = useCallback(() =>{
        window.scrollTo(0, 0);
        navigate("/received")
    },[navigate])

    const goUserGuide = useCallback(() =>{
        window.scrollTo(0, 0);
        navigate("/userguide")
    },[navigate])

    const goLogin = useCallback(() =>{
        window.scrollTo(0, 0);
        navigate("/login")
    },[navigate])


    // const banner = ["활동 중인 로비스트 273 명 ↑","소개비 누적 7,146,000원 ↑","의뢰 요청 153건 ↑"]
    // const [bannerIndex, setBannerIndex] = useState(0)
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (banner.length - 1 === bannerIndex) {
    //             setBannerIndex((bannerIndex) => bannerIndex - banner.length + 1);
    //         } else {
    //             setBannerIndex((bannerIndex) => bannerIndex + 1);
    //         }
    //     }, 3000);
    //
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [bannerIndex]);

    const [searchValue,setSearchValue] = useState<string>("")
    const handleSearchBar = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!@#$%^&*()_+-={};':"\|,.<>/?]{0,300}$/.test(inputValue)) {
            setSearchValue(inputValue)
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.nativeEvent.isComposing) { 	   // isComposing 이 true 이면
            return;				   // 조합 중이므로 동작을 막는다.
        }

        if (e.key === 'Enter' && e.shiftKey) { // [shift] + [Enter] 치면 걍 리턴
            return;
        } else if (e.key === "Enter") {
            e.preventDefault(); // 기본 동작 막기
            searchLobbyist(searchValue);
            ReactGA.event({
                category: 'Search',
                action: 'User searched for a keyword',
                label: searchValue,
            });
        }
    };


    return (
        <section className={`flex flex-col items-center mt-37pxr md:mt-80pxr`}>
            <Title>제 인맥에게 도움을 요청할 것이 있나요?</Title>
            <div className={`flex flex-row  mt-22pxr md:mt-30pxr`}>
                <input value={searchValue} onChange={handleSearchBar} onKeyDown={handleKeyDown as KeyboardEventHandler<HTMLInputElement>} className={`p-10pxr md:p-20pxr h-30pxr md:h-55pxr w-285pxr md:w-600pxr rounded-lg border border-gray-500 text-[9px] md:text-[18px] placeholder:text-[#bdbdc0] bg-white focus:outline-none`} placeholder={`필요한 네트워크를 검색해보세요 #유학 #성우 #여행유튜버`} type={`text`}/>
                <button onClick={() => {
                    searchLobbyist(searchValue)
                    ReactGA.event({
                        category: 'Search',
                        action: 'User searched for a keyword',
                        label: searchValue,
                    });
                }}
                        className={`-ml-30pxr md:-ml-62pxr rounded-lg bg-black h-30pxr md:h-55pxr w-30pxr md:w-62pxr bg-gray-900 flex items-center justify-center`}><img className={`h-15pxr md:h-32pxr w-18pxr md:w-38pxr`} src="/light-search.png" alt="searchbtn"/></button>
            </div>
            <div className={`flex flex-row justify-end h-30pxr md:h-62pxr pt-8pxr md:pt-15pxr font-bold text-[8px] md:text-[14px] w-285pxr md:w-600pxr`}>
                {/*<div className={`h-10pxr w-1/2 md:h-21pxr  text-black text-start`}>{banner[bannerIndex]}</div>*/}
                <button className={`h-10pxr w-1/2 md:h-21pxr text-[#696969] text-end`} onClick={wholeLobbyistView}>
                    <div>로비스트 전체 둘러보기</div>
                </button>
            </div>

            {/*<div className="flex flex-row items-center  md:justify-end w-340pxr md:w-5/6 mt-20pxr md:mt-0pxr">*/}
            {/*    <button onClick={user ? goMegaphone : goLogin} className="flex flex-row items-center justify-center md:justify-start bg-black w-80pxr h-30pxr md:w-140pxr md:h-45pxr rounded-lg">*/}
            {/*        <img className="w-25pxr h-25pxr md:w-35pxr md:h-35pxr ml-10pxr  md:ml-20pxr" src="/megaphone.png"/>*/}
            {/*        <div className="text-[12px] md:text-[18px] font-extrabold text-white mr-12pxr md:ml-5pxr">확성기</div>*/}
            {/*    </button>*/}

            {/*</div>*/}


            {/*<div className={`flex justify-evenly space-x-10pxr md:space-x-70pxr  pt-35pxr md:pt-50pxr font-bold text-[8px] md:text-[15px] text-black`}>*/}

            {/*    <button className={`flex flex-col  items-center w-66pxr`} onClick={user ?  goMegaphone : goLogin}>*/}
            {/*        <img src="/megaphone.png" className={`h-32pxr md:h-55pxr w-32pxr md:w-55pxr`} alt="megaphone"/>*/}
            {/*        <div className={`mt-7pxr md:mt-15pxr w-70pxr md:w-140pxr`}>확성기</div>*/}
            {/*    </button>*/}
            {/*    <button className={`flex flex-col items-center w-66pxr`} onClick={user? goMyNetwork : goLogin}>*/}
            {/*        <img src="/setting-mynetwork.png" className={`h-32pxr md:h-55pxr w-32pxr md:w-55pxr`} alt="mynetwork"/>*/}
            {/*        <div className={`mt-7pxr md:mt-15pxr w-100pxr md:w-140pxr`}>나의 인맥 설정</div>*/}
            {/*    </button>*/}
            {/*    {receivedCount !== 0 ? (*/}
            {/*        <button className={`flex flex-col  items-center w-66pxr mt-2pxr`} onClick={user ? goReceivedRequest : goLogin}>*/}
            {/*            <img src="/arrived-requests.png" className={`-mt-4pxr -mr-2pxr md:mr-0pxr md:-mt-5pxr h-34pxr md:h-60pxr w-34pxr md:w-60pxr`} alt="requestarrived"/>*/}
            {/*            <div className={`mt-7pxr md:mt-15pxr w-70pxr md:w-140pxr`}>띵동! 의뢰 도착</div>*/}
            {/*        </button>*/}
            {/*    ): (*/}
            {/*        <button className={`flex flex-col  items-center w-66pxr`} onClick={user ? goReceivedRequest : goLogin}>*/}
            {/*            <img src="/not-arrived-requests.png" className={`h-32pxr md:h-55pxr w-32pxr md:w-55pxr`} alt="requestarrived"/>*/}
            {/*            <div className={`mt-7pxr md:mt-15pxr w-70pxr md:w-140pxr`}>띵동! 의뢰 도착</div>*/}
            {/*        </button>*/}

            {/*    )}*/}

            {/*    <button className={`flex flex-col  items-center w-66pxr`} onClick={goUserGuide}>*/}
            {/*        <img src="/manual.png" className={`h-32pxr md:h-55pxr w-32pxr md:w-55pxr`} alt="manual"/>*/}
            {/*        <div className={`mt-7pxr md:mt-15pxr w-70pxr md:w-140pxr`}>사용 설명서</div>*/}
            {/*    </button>*/}

            {/*</div>*/}


        </section>
    )
}