import {AlertModal} from "../common";
import React, {ChangeEvent, useCallback, useState} from "react";
import Footer from "../main/Footer";
import {useNavigate} from "react-router-dom";
import {MegaphoneRequestFormData, RequestFormData} from "../../types/types";
import {formatCurrency, regaxNormal} from "../../utils/data/helper";
import BasicHeader from "../common/BasicHeader";
import {useMegaphoneRequestData} from "../requests/RequestDataFormContext";


export type MegaphoneRequestData = {
    content:string
    businessPrice:string
    contact:string
}

type MegaphoneRequestFormProps = MegaphoneRequestData & {
    updateFields: (fields: Partial<MegaphoneRequestData>) => void
    next: () => void
    data: MegaphoneRequestFormData
}


export default function MegaphoneRequestForm({updateFields,next,data}:MegaphoneRequestFormProps) {
    const { megaphoneRequestDataForm, setMegaphoneRequestDataForm } = useMegaphoneRequestData();
    const [isCancelAlertModal, setIsCancelAlertModal] = useState<boolean>(false);
    const onClickToggleCancelModal = useCallback(() => {
        setIsCancelAlertModal(!isCancelAlertModal);
    }, [isCancelAlertModal]);


    const navigate = useNavigate()

    const goBack = useCallback(() =>{
        navigate(-1)
    },[navigate])

    const handleContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (regaxNormal(1000).test(inputValue)) {
            updateFields({content: inputValue});
        }
    };

    const handleBusinessPrice = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (regaxNormal(30).test(inputValue)) {
            updateFields({businessPrice: inputValue});
        }
    };

    const handleContact = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (regaxNormal(30).test(inputValue)) {
            updateFields({contact: inputValue});
        }
    };
    const handleButtonDisable:boolean = data.businessPrice.trim().length === 0 || data.content.trim().length === 0 || data.contact.trim().length === 0


    return(
        <section>
            <BasicHeader/>

            <div className={`flex flex-col items-center h-full md:mb-91pxr `}>

                <div className={`flex flex-col items-start`}>
                    <div className={`relative mt-22pxr md:mt-91pxr items-start right-10pxr md:right-20pxr`}>
                        <button className={`w-8pxr h-16pxr  md:w-15pxr md:h-30pxr`} onClick={onClickToggleCancelModal}>
                            <img src="/back.png"/>

                        </button>
                    </div>
                    <div className={`flex flex-col items-start w-341pxr md:w-600pxr`}>
                        <div className={`flex flex-row items-end `}>
                            <div className={`flex flex-col mr-11pxr md:mr-17pxr`}>
                                <div className={`text-[12px] md:text-[15px] text-black font-extrabold`}>1/3</div>
                                <div className={`text-[20px] md:text-[35px] font-bold text-black `}>의뢰서 작성</div>
                            </div>
                            <div className={`bg-gray-300 h-2pxr md:h-3pxr w-20pxr md:w-35pxr mb-8pxr md:mb-15pxr  mr-6pxr md:mr-17pxr`}/>
                            <div className={`flex flex-col items-start mr-11pxr md:mr-17pxr`}>

                                <div className={`text-[12px] md:text-[15px] text-[#dddddd] font-extrabold mr-11pxr md:mr-17pxr`}>2/3</div>
                                <div className={`text-[13px] md:text-[25px] font-bold text-[#dddddd] `}>소개비 설정</div>
                            </div>
                            <div className={`bg-gray-300 h-2pxr md:h-3pxr w-20pxr md:w-35pxr mb-8pxr md:mb-15pxr mr-6pxr md:mr-17pxr`}/>
                            <div className={`flex flex-col items-start mr-11pxr md:mr-17pxr`}>

                                <div className={`text-[12px] md:text-[15px] text-[#dddddd] font-extrabold mr-11pxr md:mr-17pxr`}>3/3</div>
                                <div className={`text-[13px] md:text-[25px] font-bold text-[#dddddd] `}>확성기 결제</div>
                            </div>
                        </div>
                        <div className={`flex flex-col text-[10px] md:text-[15px] text-[#696969] font-bold`}>
                            <div className={`pt-20pxr md:pt-50pxr`}>지정하신 로비스트들에게 의뢰서가 발송되며,</div>
                            <div className={``}>소개 연결이 가능한 로비스트가 회신을 드리게 됩니다.</div>
                            <div className={`pt-24pxr md:pt-15pxr`}>내용 검토 후 발송까지는 최대 24시간이 걸릴 수 있으며 </div>
                            <div>부적절한 내용이 포함되어 있을 경우 반려될 수 있습니다.</div>
                            <div className={`flex flex-row`}><div className="font-extrabold underline">심사 통과시 확성기 비용이 결제됩니다.</div></div>

                            <div className="flex flex-row justify-between ">
                                <div className="flex flex-col">
                                    <div className={`text-[13px] md:text-[18px] text-black font-extrabold mt-43pxr md:mt-50pxr`}>지정한 로비스트 수</div>
                                    <div className="mt-14pxr md:mt-21pxr text-[18px] md:text-[23px] font-extrabold text-black">{data.lobbyistIds.length}명</div>
                                    <div className="mt-13pxr md:mt-16pxr text-[10px] md:text-[13px] font-extrabold text-[#696969]">발송비용: 로비스트 수 x 200원</div>
                                </div>
                                <div className="flex flex-col pr-90pxr md:pr-350pxr">
                                    <div className={`text-[13px] md:text-[18px] text-black font-extrabold mt-43pxr md:mt-50pxr`}>일괄 발송 비용</div>
                                    <div className="mt-14pxr md:mt-21pxr text-[18px] md:text-[23px] font-extrabold text-black">{formatCurrency(data.lobbyistIds.length * 200)}원</div>
                                </div>

                            </div>


                            <div className={`text-[13px] md:text-[18px] text-black font-extrabold mt-30pxr md:mt-50pxr`}>
                                자기소개 / 찾는사람 / 요구사항
                            </div>
                            <div className={`h-94pxr w-341pxr md:h-111pxr md:w-660pxr mt-5pxr md:mt-12pxr`}>
                                <textarea className={`resize-none border border-1 font-normal w-341pxr md:w-600pxr p-5pxr md:p-12pxr bg-white text-[10px] md:text-[18px] text-black placeholder:text-gray-300 rounded-lg`} rows={3} value={data.content} onChange={handleContent} placeholder={`예시) 붕어빵 장사에 도전해보고 싶은 청년입니다. 1달 정도 도전해보고 싶은데 재료 수급 노하우, 제조법 전수, 기계 렌탈까지 가능한 붕어빵 사장님 소개해주세요.`} />
                            </div>
                            <div className={`flex flex-row items-end text-black  font-extrabold -mt-10pxr md:mt-30pxr`}>
                                <div className={`text-[13px] md:text-[18px]`}>업무비</div>
                                <div className={`text-[10px] md:text-[15px] ml-3pxr md:ml-5pxr`}>(소개받으신 분께 자문을 구하거나 업무를 부탁드린다면 보수를 입력해주세요)</div>

                            </div>
                            <div className={`md:h-111pxr md:w-660pxr mt-5pxr  md:mt-12pxr`}>
                                <textarea className={`resize-none border border-1 font-normal w-341pxr md:w-600pxr p-5pxr md:p-12pxr bg-white text-[10px] md:text-[18px] text-black placeholder:text-gray-300  rounded-lg`} value={data.businessPrice} onChange={handleBusinessPrice} rows={2} placeholder={`예시) 자문비 30,000원 / 업무비 200,000원 / 협업 / 혜택 제공 / 상담 후 결정 / 해당 사항 없음`} />
                            </div>
                            <div className={`text-[13px] md:text-[18px] mt-20pxr md:mt-30pxr text-black font-extrabold `}>
                                의뢰인의 연락처
                            </div>
                            <div className={`md:h-111pxr md:w-660pxr mt-5pxr md:mt-12pxr`}>
                                <textarea className={`resize-none border border-1 font-normal w-341pxr md:w-600pxr p-5pxr md:p-12pxr bg-white text-[10px] md:text-[18px] text-black placeholder:text-gray-300 rounded-lg`} value={data.contact}  rows={1} onChange={handleContact} placeholder={`연락받으실 휴대폰 번호 or 이메일 주소를 입력해주세요.`} />
                            </div>


                            <button disabled={handleButtonDisable} className={`w-341pxr h-35pxr md:w-600pxr md:h-45pxr  ${handleButtonDisable ? "bg-[#e0e0e0]" : "bg-black"} mt-20pxr  md:mt-0pxr text-[15px] md:text-[20px] text-white font-bold rounded md:rounded-lg`} onClick={next}>다음</button>
                        </div>
                    </div>
                </div>

            </div>


            {isCancelAlertModal &&
                <AlertModal>
                    <div className={`flex flex-col items-center justify-center`}>
                        <div className={`text-[16px] md:text-[24px] mt-40pxr md:mt-66pxr text-black font-semibold `}>그만 작성 할래요</div>
                        <div className={`text-[10px] md:text-[13px] mt-35pxr md:mt-50pxr text-[#5d5d5d] font-semibold`}>페이지를 나가시면 작성했던 내용이 모두 사라집니다.
                        </div>
                        <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>  그래도 나가시겠습니까?</div>

                        <div className={`flex flex-row mt-52pxr md:mt-85pxr`}>
                            <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-[#e0e0e0] text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={onClickToggleCancelModal}>돌아가기</button>
                            <button className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={() => {
                                goBack()
                                setMegaphoneRequestDataForm({
                                    lobbyistIds: [],
                                    content: "",
                                    introducePrice: 0,
                                    businessPrice: "",
                                    contact: "",
                                })
                            }}>나가기</button>
                        </div>
                    </div>

                </AlertModal>
            }

        </section>
    )
}
