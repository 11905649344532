import {useNavigate} from "react-router-dom";
import {useCallback} from "react";

export default function MegaphoneIntroduce(){

    const navigate = useNavigate()
    const goBack = useCallback(() => {
        navigate(-1)
    },[navigate])

    return (
        <section>
            <div className={`flex flex-col items-center mt-23pxr md:mt-50pxr`}>

                <div className="w-screen ml-18pxr  md:w-1/2">
                    <button>
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}/>
                    </button>
                </div>
                <div className={`mt-14pxr md:mt-6pxr`}>
                    <div className={`text-[15px] md:text-[30px] text-black font-extrabold`}>확성기</div>
                </div>

                <div className={`flex flex-col items-center md:mt-45pxr mt-22pxr text-[10px] md:text-[18px] font-semibold text-black`}>
                    <div>“소개 가능한 로비스트분~!”</div>
                    <div>의뢰서를 한번에 일괄로 발송! 의뢰하고 싶은 로비스트를 선택해주세요</div>
                </div>

            </div>
        </section>
    )
}