import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import MyPageMenu from "./MyPageMenu";
import AlertListView from "./AlertListView";
import {useNavigate} from "react-router-dom";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {Image} from "../common/Image";
import {useNotificationCount} from "../../hooks/main/useNotificationCount";
import {useUserContext} from "../../hooks/common/UserContext";
import {useUnreadChatExist} from "../../hooks/main/useUnreadChatExist";


export default function MainHeader(){
    const {user} = useUserContext()
    const {userInformation, isLoading} = useUserInformation()

    const {notificationCount} = useNotificationCount()
    const {unreadExistCount} = useUnreadChatExist()

    const [isSettingOpened,setIsSettingOpened] = useState<boolean>(false)
    const [isAlertOpened,setIsAlertOpened] = useState<boolean>(false)

    const settingMenuOnclick = useCallback(() => {
        setIsSettingOpened(isSettingOpened => !isSettingOpened)
        setIsAlertOpened(false)
    },[isAlertOpened,isSettingOpened])

    const alertOnclick = useCallback(() => {
        setIsAlertOpened(isAlertOpened => !isAlertOpened)
        setIsSettingOpened(false)
    },[isAlertOpened,isSettingOpened])

    const navigate = useNavigate()

    const goLoginPage = useCallback(() => {
        navigate("/login")
    },[navigate])
    const goRequest = useCallback(() => {
        navigate("/request")
    },[navigate])
    const goMain = useCallback(() => {
        navigate("/")
    },[navigate])
     const goChatHome = useCallback(() => {
        navigate("/chatmain")
    },[navigate])



    const settingRef = useRef<HTMLDivElement>(null);
    const alertRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                settingRef.current &&
                !settingRef.current.contains(event.target as Node) &&
                alertRef.current &&
                !alertRef.current.contains(event.target as Node)
            ) {
                setIsSettingOpened(false);
                setIsAlertOpened(false);
            }
        };

        window.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <nav className="sticky top-0 z-10">
            <div className="h-50pxr md:h-60pxr w-full bg-white flex flex-row justify-between items-center border-b border-[#ededed]">
                <div className={`hidden md:flex flex md:w-1/3 justify-evenly items-center md:justify-center`}>
                    <div className={`flex flex-row pl-29pxr md:pl-0pxr  justify-evenly`}>
                        <a href={"/"}>
                            <img className={`w-50pxr md:w-100pxr mt-5pxr md:mt-5pxr  `} src="/main-logo.png"/>
                        </a>
                    </div>
                </div>


                <div className="flex flex-row justify-between space-x-10pxr md:space-x-60pxr ">
                    <button onClick={goMain}>
                    <div className="flex flex-col w-48pxr ml-5pxr md:ml-0pxr items-center justify-center relative inline-block group" >

                        <img className="w-15pxr h-15pxr md:w-30pxr md:h-30pxr mt-3pxr "  src={"/lobby_lounge_black@3x.png"}/>
                            <div className={`flex flex-col items-center justify-center text-[10px] md:text-[12px] mt-5pxr md:w-58pxr text-black  font-semibold md:pt-6pxr`}>
                                로비 라운지
                            </div>
                    </div>
                    </button>

                    <button onClick={goRequest}>
                        <div className="flex flex-col  w-48pxr  md:ml-0pxr items-center justify-center relative inline-block group" >

                            <img className="w-15pxr h-15pxr md:w-30pxr md:h-30pxr mt-3pxr "  src={"/request_white@3x.png"}/>
                            <div className={`flex flex-col items-center justify-center text-[10px] md:text-[12px] mt-5pxr  md:w-58pxr text-[#dbdbdb]  font-semibold md:pt-6pxr`}>
                                1:1 의뢰
                            </div>
                        </div>
                    </button>
                   <button onClick={goChatHome} className="relative">
                    <div className="flex flex-col w-48pxr   md:ml-0pxr items-center justify-center relative inline-block group" >

                        <img className="w-15pxr h-15pxr md:w-30pxr md:h-30pxr mt-3pxr"  src={"/chat_white@3x.png"}/>
                        {unreadExistCount !== 0 && (
                            <div className="absolute top-1pxr right-10pxr md:top-5pxr md:right-0pxr bg-[#f44040] w-8pxr h-8pxr md:w-10pxr md:h-10pxr rounded-full">

                            </div>
                        )}

                        <div className={`flex flex-col items-center justify-center text-[10px] md:text-[12px]  mt-5pxr md:w-58pxr text-[#dbdbdb] font-semibold md:pt-6pxr`}>
                            의뢰 채팅
                        </div>
                    </div>
                   </button>
                </div>





                <div className="flex w-1/2  md:w-1/3 ">
                    <div className={`flex  flex-row relative items-end justify-end text-black text-bold`}>
                        {user ?
                            <div className="flex flex-row justify-between md:justify-end  items-center ml-10pxr md:ml-0pxr  w-175pxr md:w-250pxr lg:w-400pxr">
                                    {isLoading ?
                                        <></>:
                                        (
                                        <div className="w-70pxr mt-5pxr md:w-100pxr">
                                            {userInformation.bLobbyist ? (
                                                    <img
                                                        src="/lobbyist-on.png"
                                                        className="w-full object-cover"
                                                    />
                                                ) : (
                                                    <img
                                                        src="/lobbyist-off.png"
                                                        className="w-full object-cover"
                                                    />
                                                )}
                                        </div>

                                        )}
                                    {/* todo : 알람 유무에 따라서 Alert 변경 가능하게 */}
                                    {notificationCount !== 0 ?
                                        (<div ref={alertRef} className="relative">
                                        <button  onClick={alertOnclick}>
                                        <img className={`h-26pxr w-22pxr  md:h-35pxr md:w-30pxr ml-22pxr md:ml-50pxr pt-4pxr `} src="/alert-received.png"/>
                                        </button>

                                        <div className={`flex justify-end md:mr-59pxr`}>
                                    {isAlertOpened && <AlertListView />}
                                        </div>
                                        </div>)
                                        :
                                        (<div ref={alertRef} className="relative">
                                        <button  onClick={alertOnclick}>
                                        <img className={`h-26pxr w-22pxr  md:h-35pxr md:w-30pxr ml-22pxr md:ml-50pxr pt-6pxr `} src="/alert.png"/>
                                        </button>
                                        <div className={`flex justify-end md:mr-59pxr`}>
                                    {isAlertOpened && <AlertListView/>}
                                        </div>
                                        </div>)
                                    }
                                        <div ref={settingRef} className="relative">
                                        <button className={`h-26pxr w-26pxr md:h-40pxr md:w-40pxr mx-10pxr md:ml-50pxr pt-5pxr md:mt-5pxr `} onClick={settingMenuOnclick}>
                                        <Image className="rounded-full border border-xl border-[#e3e3e3] h-26pxr w-26pxr md:h-40pxr md:w-40pxr" src={user.profileImage}/>
                                        </button>
                                        <div className={`flex justify-end md:mr-59pxr`}>
                                    {isSettingOpened && <MyPageMenu settingMenuOnclick={settingMenuOnclick}/> }
                                        </div>

                                        </div>
                                        </div>
                                        :
                                        <div className="flex flex-row  pr-15pxr md:mr-0pxr  w-150pxr md:w-350pxr font-semibold justify-end items-end ">
                                        <div className="flex flex-row ">
                                        <button onClick={goLoginPage} className="text-[7px] md:text-[15px]">로그인 / 회원가입</button>
                                        </div>
                                        </div>

                                    }

                                </div>
                            </div>
                            </div>

                            </nav>



                            )
                        }