import MegaphoneIntroduce from "./MegaphoneIntroduce";
import MegaphoneLobbyistListView from "./MegaphoneLobbyistListView";
import BasicHeader from "../common/BasicHeader";



export default function Megaphone(){
    return (
        <section>

                <BasicHeader/>
                <MegaphoneIntroduce/>
                <MegaphoneLobbyistListView/>

        </section>
    )
}
