import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IKeywordDataForm,
    ImageUrlResponse,
    IUser,
    IUserResponse, Keyword,
    RequestFormData,
    UserDetailRequestFormData
} from "../../types/types";
import {UseMutateFunction, useMutation, useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../../services/react-query/constants";
import {useUserContext} from "../common/UserContext";



export function useModifyMyNetworks(
    onSuccess:() => void
) : UseMutateFunction<void, unknown, Keyword[], unknown> {

    async function setUserDetailRequest(keywords: Keyword[], user: IUser | null,): Promise<void> {

        const keywordDataForm: IKeywordDataForm = {
            keywords: keywords
        }

        const config: any = {};
        if (user) {
            config.headers = getJWTHeader(user);
            await axiosInstance.put(`/user/member/keyword/`, keywordDataForm, config,);
        }
    }

    const { user, updateUser, clearUser } = useUserContext();
    const queryClient = useQueryClient()
    const {mutate} = useMutation((keywords:Keyword[]) => setUserDetailRequest(keywords, user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.userInformation])
                queryClient.invalidateQueries([queryKeys.lobbyists])
                queryClient.invalidateQueries([queryKeys.wholeLobbyist])
                queryClient.invalidateQueries([queryKeys.megaphoneLobbyist])
                queryClient.refetchQueries([queryKeys.userInformation])
                queryClient.refetchQueries([queryKeys.lobbyists])
                queryClient.refetchQueries([queryKeys.wholeLobbyist])
                queryClient.refetchQueries([queryKeys.megaphoneLobbyist])
                onSuccess()
            },


        })

    return mutate
}

