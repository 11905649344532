export default function Footer(){
    return (
        <section className="mt-60pxr pb-20pxr md:mt-0pxr mx-20pxr md:mx-0pxr">
            <div className={`h-160pxr md:h-400pxr md:mt-100pxr md:mx-168pxr`}>
                <div className={`flex justify-end items-end h-22pxr md:h-40pxr text-[#9f9f9f] text-[8px] md:text-[14px] border-b`}>
                    {/*<a className={`mb-12pxr md:mb-18pxr`} href="/userguide">사용설명서</a>*/}
                    <a className={`ml-15pxr md:ml-37pxr mb-12pxr md:mb-18pxr`} href="/userprotection">이용자보호</a>
                    <a className={`ml-15pxr md:ml-37pxr mb-12pxr md:mb-18pxr`} href="/termsofview">서비스이용약관</a>
                    <a className={`ml-15pxr md:ml-37pxr mb-12pxr md:mb-18pxr`} href="/privacypolicy">개인정보처리방침</a>
                </div>


                <div className={`flex flex-col justify-start items-start h-136pxr md:h-250pxr text-[#5f5f5f] text-[10px] md:text-[13px]`}>
                    <div className={`mt-9pxr md:mt-27pxr`}>
                        배임수픽쳐스(주)는 통신판매중개자로서 통신판매의 당사자가 아니며 개별 판매자가 제공하는 서비스에 대한 이행, 계약 등과 관련한 의무와 책임은 거래당사자에게 있습니다.
                    </div>

                    <div className={`mt-20pxr md:mt-41pxr`}>
                        배임수픽쳐스(주) (대표이사:노원규) | 서울시 종로구 종로1길 50, 더케이트윈타워 B동 2층 | 통신판매번호 : 2024-서울종로-0707 | 사업자등록번호 : 164-87-01191
                        <div >문의/신고: contact@lobbyd.com | 고객센터: 070-4571-4238 </div>
                    </div>
                    <div className={``}>© beimsupictures Inc.</div>
                </div>
            </div>


        </section>




    )
}