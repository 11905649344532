import Footer from "../main/Footer";
import {AlertModal} from "../common";
import {useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {MegaphoneRequestFormData} from "../../types/types";
import {formatCurrency} from "../../utils/data/helper";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {queryClient} from "../../services/react-query/queryClient";
import {queryKeys} from "../../services/react-query/constants";
import {useMegaphoneRequest} from "../../hooks/megaphone/useMegaphoneRequest";
import BasicHeader from "../common/BasicHeader";
import {useMegaphoneRequestData} from "../requests/RequestDataFormContext";


export type MegaphonePaymentProps = {
    back: () => void
    data: MegaphoneRequestFormData
}

export default function MegaphonePayment({back,data}:MegaphonePaymentProps){
    const { megaphoneRequestDataForm, setMegaphoneRequestDataForm } = useMegaphoneRequestData();
    const navigate = useNavigate()
    const goBack = useCallback(() =>{
        back()
    },[navigate])
    const goHistory = useCallback(() => {
        navigate("/request/history")
    },[navigate])

    const goPaymentMethodRegister = useCallback(() => {
        navigate("/payment/register/card")
    },[navigate])


    const [isPayMethodAlertModal, setIsPayMethodAlertModal] = useState<boolean>(false);
    const onClickTogglePayMethodModal = useCallback(() => {
        setIsPayMethodAlertModal(!isPayMethodAlertModal);
    }, [isPayMethodAlertModal]);

    const [isCompleteAlertModal, setIsCompleteAlertModal] = useState<boolean>(false);
    const onClickCompleteToggleModal = useCallback(() => {
        setIsCompleteAlertModal(!isCompleteAlertModal);
    }, [isCompleteAlertModal]);

    const megaphoneRequestProgress = useMegaphoneRequest(onClickCompleteToggleModal)

    const {userInformation} = useUserInformation()

    const [isLoading, setIsLoading] = useState(false);
    const updateCardInfo = async () => {
        setIsLoading(true);
        try {
            await queryClient.invalidateQueries(queryKeys.userInformation);
        } catch (error) {
            // 에러 처리
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        updateCardInfo()
    },[])


    const handleCardRegister:boolean = userInformation?.cardInfo.length === 0

    return (
        <section>
            <BasicHeader/>
            <div className={`flex flex-col items-center h-full mt-23pxr md:mt-50pxr pb-100pxr`}>
                <div className="w-screen ml-18pxr  md:w-1/2">
                    <button onClick={back} className={`w-8pxr h-16pxr  md:w-15pxr md:h-30pxr`} >
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} />
                    </button>
                </div>
                <div className={`flex flex-col items-start w-341pxr  md:w-600pxr`}>
                    <div className={`flex flex-row items-end `}>
                        <div className={`flex flex-col mr-11pxr md:mr-17pxr`}>
                            <div className={`text-[12px] md:text-[15px] text-black font-extrabold`}>3/3</div>
                            <div className={`text-[20px] md:text-[35px] font-bold text-black `}>확성기 결제</div>
                        </div>
                    </div>



                    <div className={`flex flex-col text-[10px] md:text-[15px] text-[#696969] font-bold`}>
                        <div className={`pt-20pxr md:pt-50pxr`}>지정하신 로비스트들에게 의뢰서가 발송되며,</div>
                        <div className={``}>소개 연결이 가능한 로비스트가 회신을 드리게 됩니다.</div>
                        <div className={`pt-24pxr md:pt-15pxr`}>내용 검토 후 발송까지는 최대 24시간이 걸릴 수 있으며 </div>
                        <div>부적절한 내용이 포함되어 있을 경우 반려될 수 있습니다.</div>
                        <div className={`flex flex-row`}><div className="font-extrabold underline">심사 통과 시 확성기 비용이 결제됩니다.</div></div>


                        <div className={`flex flex-col items-start  text-black font-extrabold mt-54pxr md:mt-50pxr`}>
                            <div className={`text-[15px] md:text-[22px]`}>{data.lobbyistIds.length}명 일괄 발송</div>
                            <div className={`flex flex-col text-[10px] md:text-[15px] text-black font-bold mt-12pxr md:mt-20pxr`}>
                                <div className={`md:pt-17pxr`}>심사 통과 시 등록된 카드로 즉시 결제 되며</div>
                                <div className={`underline underline-offset-2`}>내가 요청한 의뢰 페이지에서 확인할 수 있습니다.</div>

                            </div>
                        </div>

                        <button className="flex flex-row justify-center items-center mt-50pxr h-44pxr w-260pxr md:h-58pxr md:w-350pxr bg-black text-[15px] md:text-[18px] text-white rounded"
                            onClick={handleCardRegister ? onClickTogglePayMethodModal : () => megaphoneRequestProgress(data)}>
                            <img src="/light-won.png" className="h-26pxr w-26pxr md:h-28pxr md:w-28pxr mr-10pxr"/> {formatCurrency(data.lobbyistIds.length * 200)} 결제하기
                        </button>


                        <div className={`text-[10px] md:text-[12px] font-semibold mt-50pxr md:mt-50pxr text-[#696969]`}>
                            결제 및 이용 안내
                        </div>
                        <div className={`text-[10px] md:text-[12px] text-[#696969]`}>
                            - 결제 방식으로 등록된 신용/선불카드 결제만을 제공하고 있습니다.
                        </div>
                        <div className={`text-[10px] md:text-[12px] text-[#696969]`}>
                            - 명시된 금액은 부가세(VAT)포함 가격입니다.
                        </div>
                        <div className={`text-[10px] md:text-[12px] text-[#696969]`}>
                            - 결제 후 신용카드 전표는 개인 소득공제용으로만 이용하실 수 있습니다.
                        </div>

                        <div className={`text-[10px] md:text-[12px] font-semibold mt-35pxr md:mt-50≠≠≠≠pxr text-[#696969]`}>
                            취소 안내
                        </div>
                        <div className="flex flex-col text-[10px] md:text-[12px] text-[#696969] mb-50pxr">
                            <div>- 내용 검토 후 24시간 내로 발송되며 부적절한 내용이 포함되어 있을 경우 반려될 수 있습니다.</div>
                            <div>- 프로필 {'>'} 내가 요청한 의뢰 {'>'} 취소하고자 하는 의뢰 건 선택 {'>'} 취소하기로 실시간 취소 가능합니다.</div>
                            <div>- 내용 검토 심사가 끝나고 의뢰 발송이 완료 되었을 시 환불이 불가능합니다. 발송 진행 과정은</div>
                            <div>‘내가 요청한 의뢰’에서 확인하실 수 있습니다.</div>
                        </div>

                    </div>
                </div>

            </div>

            {isPayMethodAlertModal &&
                <AlertModal >
                    <div className={`flex flex-col items-center justify-center`}>
                        <div className={`text-[16px] md:text-[24px] mt-30pxr md:mt-50pxr text-black font-semibold `}>결제 카드 등록 안내</div>
                        <div className={`text-[10px] md:text-[13px] mt-36pxr md:mt-45pxr text-[#5d5d5d] font-semibold`}>소개비 설정을 위해서 결제 카드 등록이 필요합니다.</div>
                        <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>페이지를 나가시더라도 '프로필 {">"} 나의 의뢰 내역'에서</div>
                        <div className={`text-[10px] md:text-[13px] mt-10pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>작성한 내용을 다시 확인하실 수 있습니다.</div>

                        <div className={`flex flex-row mt-35pxr md:mt-65pxr`}>
                            <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-[#e0e0e0] text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={onClickTogglePayMethodModal}>돌아가기</button>
                            <button className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={goPaymentMethodRegister}>등록하기</button>
                        </div>
                    </div>
                </AlertModal>}

            {isCompleteAlertModal &&
                <AlertModal>

                    <div className={`flex flex-col items-center justify-center`}>
                        <div className={`text-[16px] md:text-[24px] mt-30pxr md:mt-50pxr text-black font-semibold `}>심사 제출 완료</div>

                        <div className={`text-[10px] md:text-[13px] mt-45pxr md:mt-70pxr text-[#5d5d5d] font-semibold`}>'프로필 {">"} 내가 요청한 의뢰'에서</div>
                        <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>의뢰를 확인하실 수 있습니다.</div>

                        <div className={`flex flex-row mt-45pxr md:mt-85pxr`}>
                            <button className={`mr-8pxr md:mr-12pxr w-243pxr md:w-300pxr h-36pxr md:h-45pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={() => {
                                goHistory()
                                setMegaphoneRequestDataForm({
                                    lobbyistIds: [],
                                    content: "",
                                    introducePrice: 0,
                                    businessPrice: "",
                                    contact: "",
                                })
                            }}>확인</button>

                        </div>
                    </div>

                </AlertModal>
            }




        </section>
    )
}