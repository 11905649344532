import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {ImageUrlResponse, IUser, IUserResponse, RequestFormData, UserDetailRequestFormData} from "../../types/types";
import {UseMutateFunction, useMutation, useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../../services/react-query/constants";
import axios, {AxiosResponse} from "axios";
import {clearStoredUser, setStoredUser} from "../../utils/user-storage";
import {useState} from "react";
import {useUserContext} from "../common/UserContext";



export function useNicknameValidation(
) : {
    checkNickname:UseMutateFunction<void, unknown, string, unknown>, responseStatusCode: number | null }{

    const [responseStatusCode, setResponseStatusCode] = useState<number | null>(null);

    async function setUserNicknameValidation(nickname:string, user: IUser | null,): Promise<void> {

        const config: any = {};
        if (user) {
            config.headers = getJWTHeader(user);
            try{
                await axiosInstance.post(`/user/nickName/check/${nickname}`, null , config,);
                setResponseStatusCode(200)
            }catch (errorResponse) {
                if (axios.isAxiosError(errorResponse)) {
                    switch (errorResponse.response?.status) {
                        case 409:
                            setResponseStatusCode(409)
                            break
                        case 400:
                            setResponseStatusCode(400)
                            break
                        default:
                            setResponseStatusCode(404)
                            break
                    }

                }
            }
        }
    }


    const { user, updateUser, clearUser } = useUserContext();
    const mutation = useMutation((nickname:string) => setUserNicknameValidation(nickname, user),
        {
            onSuccess: () => {
            },
            onError: () => {

            }
        })

    return {checkNickname: mutation.mutate, responseStatusCode}
}

