import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IPost, IPostDetail, IPostDetailResponse, IPostResponse, IUser} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useEffect, useState} from "react";
import {useUserContext} from "../common/UserContext";

async function getCommunityDetailPost(postId:string): Promise<IPostDetailResponse | null> {


    const { data } : AxiosResponse<IPostDetailResponse> = await axiosInstance.get(`/user/post/${postId}`,);
    return data;

}

interface UseDetailCommunity {
    data: IPostDetail | undefined;
}

export function useDetailCommunity(postId: string): UseDetailCommunity {
    const { user, updateUser, clearUser } = useUserContext();

    const { data : responseData } = useQuery(
        [queryKeys.communityDetail, postId],
        () => getCommunityDetailPost(postId),

    );
    const data = responseData?.row
    console.log(data)
    console.log("체크")
    return { data };
}