import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IPost, IPostResponse, IUser} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse, AxiosRequestConfig} from "axios";
import {useUserInformation} from "../myprofile/useUserInformation";
import {useEffect, useState} from "react";
import {useUserContext} from "../common/UserContext";

async function getCommunityPost(user:IUser|null, page: number): Promise<IPostResponse> {


    const config: AxiosRequestConfig = {};
    if (user) {
        config.headers = getJWTHeader(user)
    }
    config.params = {
        page: page,
        limit: 10,
    };
    const { data } : AxiosResponse<IPostResponse> = await axiosInstance.get(`/user/post`, config,);

    if (data.count > page * 10) {
        data.nextPage = page + 1;
    }
    return data;

}

interface UseCommunity {
    posts: IPost[] | undefined;
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
    hasNextPage:boolean
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
}

export function useCommunity(
    filter?: () => void
): UseCommunity {
    const { user, updateUser, clearUser } = useUserContext();
    const { userInformation } = useUserInformation();

    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
    } = useInfiniteQuery<IPostResponse>(
        [queryKeys.community],
        ({ pageParam = 1 }) => {
            if (pageParam) {
                return getCommunityPost(user, pageParam);
            } else {
                return Promise.reject("No more pages");
            }
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.nextPage || false;
            },
            refetchOnWindowFocus: true,
            staleTime: 0,
            cacheTime: 0,
        }
    );

    const hasNextPage = !!(
        data &&
        data.pages.length &&
        data.pages[data.pages.length - 1]?.nextPage
    );



    const posts = data?.pages.flatMap((pageData) => pageData.rows) || [];
    let filteredPosts = posts;

    if (filter && userInformation) {
        filteredPosts = posts.filter((post) => post.writerId === userInformation._id);
    }

    return {
        posts: filteredPosts,
        isLoading,
        isFetching,
        isError,
        error,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    };
}