import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import BasicHeader from "../common/BasicHeader";
import {useTermsOfPrivacy} from "../../hooks/guideline/useTermsOfPrivacy";
import {useTermsOfUse} from "../../hooks/guideline/useTermsOfView";


export function HtmlToReact({ html }: { html: string }) {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: html }}
            className="flex flex-col  w-350pxr md:w-600pxr text-black items-center text-[10px] md:text-[15px] font-medium"
        />
    );
}

export default function TermsOfUseView(){

    const {termsOfView} = useTermsOfUse()
    const navigate = useNavigate()
    const goBack = useCallback(() =>{
        navigate(-1)
    },[navigate])

    return (
        <section>
            <div className="flex flex-col items-center">
                <div className="w-screen ml-18pxr">

                </div>
                <HtmlToReact html={termsOfView?.html || ""} />

            </div>
        </section>
    )

}