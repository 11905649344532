// CombinedRequestDataContext.tsx

import React, { createContext, FC, useContext, useState } from "react";
import { MegaphoneRequestFormData, RequestFormData } from "../../types/types";

// MegaphoneRequestDataContext
const MegaphoneRequestDataContext = createContext<{
    megaphoneRequestDataForm: MegaphoneRequestFormData;
    setMegaphoneRequestDataForm: (data: MegaphoneRequestFormData) => void;
}>({
    megaphoneRequestDataForm: {
        lobbyistIds: [],
        content: "",
        introducePrice: 0,
        businessPrice: "",
        contact: "",
    },
    setMegaphoneRequestDataForm: () => {},
});

export const useMegaphoneRequestData = () => {
    return useContext(MegaphoneRequestDataContext);
};

// RequestDataContext
const RequestDataContext = createContext<{
    requestDataForm: RequestFormData;
    setRequestDataForm: (data: RequestFormData) => void;
}>({
    requestDataForm: {
        lobbyistId: "",
        content: "",
        introducePrice: 0,
        businessPrice: "",
        contact: "",
    },
    setRequestDataForm: () => {},
});

export const useRequestData = () => {
    return useContext(RequestDataContext);
};

interface CombinedRequestDataProviderProps {
    children: React.ReactNode;
}

export const CombinedRequestDataProvider: FC<CombinedRequestDataProviderProps> = ({ children }) => {
    const [megaphoneRequestDataForm, setMegaphoneRequestDataForm] = useState<MegaphoneRequestFormData>({
        lobbyistIds: [],
        content: "",
        introducePrice: 0,
        businessPrice: "",
        contact: "",
    });

    const [requestDataForm, setRequestDataForm] = useState<RequestFormData>({
        lobbyistId: "",
        content: "",
        introducePrice: 0,
        businessPrice: "",
        contact: "",
    });

    return (
        <MegaphoneRequestDataContext.Provider value={{ megaphoneRequestDataForm, setMegaphoneRequestDataForm }}>
            <RequestDataContext.Provider value={{ requestDataForm, setRequestDataForm }}>
                {children}
            </RequestDataContext.Provider>
        </MegaphoneRequestDataContext.Provider>
    );
};
