import MainHeader from "../main/MainHeader";
import Banner from "../main/Banner";
import LobbyistListView from "../main/LobbyistListView";
import RequestModalView from "../common/RequestModalView";
import Footer from "../main/Footer";
import React, {useCallback, useState} from "react";
import LoginPage from "../auth/LoginPage";
import ProfileSetting from "./ProfileSetting";

export default function MyProfile(){


    return (
        <section>
            <ProfileSetting/>
        </section>

    )
}