import React, { createContext, useContext, ReactNode } from "react";
import { useUser} from "./useUser";
import { UseUser} from "./UserContextTypes";

const UserContext = createContext<UseUser | null>(null);

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const userValue = useUser();

    return (
        <UserContext.Provider value={userValue}>{children}</UserContext.Provider>
    );
};

export function useUserContext(): UseUser {
    const context = useContext(UserContext);
    if (context === null) {
        throw new Error("useUserContext must be used within a UserProvider");
    }
    return context;
}
