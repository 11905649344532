import {ChatMessage} from "./LobbyChat";
import {useUser} from "../../hooks/common/useUser";
import {formatCurrency, timeAgo} from "../../utils/data/helper";
import {Avatar} from "../common";
import React from "react";
import {Image} from "../common/Image";
import {useUserContext} from "../../hooks/common/UserContext";

type ChatMessageProps = {
    message: ChatMessage;
    profileImage: string;
    goReviewPage: () => void
}

function ChatMessageRow({ message, profileImage, goReviewPage}: ChatMessageProps) {
    const {user} = useUserContext()
    const isCurrentUser = message.senderId === user?._id
    const messageType =  message.isPaymentChat ?  "review" : "text"
    const createdTime = message.createdAt
    const text = message.message
    return (
        <div className={`flex items-end ${isCurrentUser ? 'justify-end' : ''}`}>
            {isCurrentUser ? (
                <>
                    <div className="flex flex-row justify-end text-[10px] w-60pxr pr-5pxr">{timeAgo(createdTime)}</div>
                    {messageType === 'text' ? (
                        <div className="p-8pxr max-w-[220px] text-[15px] rounded-lg bg-[#f2f2f2]">{text}</div>
                    ) : (
                        <div className="flex flex-col p-8pxr rounded-lg text-black bg-[#f2f2f2] w-180pxr">
                            <div className="text-[15px] font-bold">거래 확정</div>
                            <div className="text-[15px] font-bold mt-5pxr"> {formatCurrency(message.price)}원</div>
                            <div className="bg-black h-1pxr "/>
                            <div className="flex flex-col text-[10px] mt-10pxr">
                                <div>거래가 완료됐어요!</div>
                                <div>1시간 이내로 소개비가</div>
                                <div>로비스트의 계좌로 자동 입금됩니다.</div>
                                <button onClick={goReviewPage} className="bg-black text-white font-bold text-[14px] h-28pxr rounded-md mt-10pxr">
                                    후기 남기기
                                </button>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <Image src={profileImage} className="w-36pxr h-36pxr rounded-full mr-5pxr"/>
                    {messageType === 'text' ? (
                        <div className="p-8pxr max-w-[220px] rounded-lg text-[15px] bg-white border border-[#bcbcbc] ml-5pxr">{text}</div>
                    ) : (
                        <div className="flex flex-col p-8pxr rounded-lg text-black bg-[#f2f2f2] w-180pxr">
                            <div className="text-[15px] font-bold">거래 확정</div>
                            <div className="text-[15px] font-bold mt-5pxr"> {formatCurrency(message.price)}원</div>
                            <div className="bg-black h-1pxr "/>
                            <div className="flex flex-col text-[10px] mt-10pxr">
                                <div>거래가 완료됐어요!</div>
                                <div>1시간 이내로 소개비가</div>
                                <div>로비스트의 계좌로 자동 입금됩니다.</div>
                            </div>
                        </div>

                    )}
                    <div className="text-[10px] pl-5pxr w-60pxr">{timeAgo(createdTime)}</div>
                </>
            )}
        </div>
    );
}

export default ChatMessageRow;