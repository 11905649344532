import {AlertModal} from "../common";
import Footer from "../main/Footer";
import {useNavigate} from "react-router-dom";
import React, {useCallback, useState} from "react";
import {useCreditCardRegister} from "../../hooks/payment/useCreditCardRegister";
import {CardRegisterRequestFormData} from "../../types/types";
import {useMegaphoneRequestData, useRequestData} from "../requests/RequestDataFormContext";
import {CardRegisterForm} from "./CardRegisterForm";
import BasicHeader from "../common/BasicHeader";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {useCreditCardRemove} from "../../hooks/payment/useCreditCardRemove";

export default function PaymentRegisterCardView(){

    const { requestDataForm } = useRequestData();
    const { megaphoneRequestDataForm} = useMegaphoneRequestData()
    const ifFromRequestForm:boolean = !(requestDataForm.content.trim().length === 0 && requestDataForm.businessPrice.trim().length === 0 && requestDataForm.contact.trim().length === 0)
    const ifFromMegaphoneRequestForm:boolean = !(megaphoneRequestDataForm.content.trim().length === 0 && megaphoneRequestDataForm.businessPrice.trim().length === 0 && megaphoneRequestDataForm.contact.trim().length === 0)
    const {userInformation} = useUserInformation()
    const isCardRegistered = userInformation?.cardInfo && userInformation?.cardInfo.length > 0

    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorAlertModal, setIsErrorAlertModal] = useState<boolean>(false);
    const onClickToggleErrorModal = useCallback((errorMessage:string) => {
        setIsErrorAlertModal(isErrorAlertModal => !isErrorAlertModal);
    }, [isErrorAlertModal]);
    const registerCreditCard = useCreditCardRegister(() => {goToConditionalProcess()}, (errorMessage) => {
        onClickToggleErrorModal(errorMessage)
        setErrorMessage(errorMessage)
    })
    const removeCreditCard = useCreditCardRemove(() => registerCreditCard(cardData), () => onClickToggleErrorModal(errorMessage))

    const INITIAL_DATA: CardRegisterRequestFormData = {
        cardNo: "",
        expYear: "",
        expMonth: "",
        idNo: "",
        cardPw: ""
    }
    const [cardData, setCardData] = useState(INITIAL_DATA)

    const updateFields = (fields: Partial<CardRegisterRequestFormData>) => {
        setCardData(prev => {
            return { ...prev, ...fields }
        })
    }

    const isFormValid = (): boolean => {
        return (
            cardData.cardNo.length === 16 &&
            cardData.expYear.length === 2 &&
            cardData.expMonth.length === 2 &&
            cardData.idNo.length === 6 &&
            cardData.cardPw.length === 2
        );
    };



    const handleButtonClick = async () => {
        if (isCardRegistered) {
            try {
                await removeCreditCard(userInformation.cardInfo[0]);
            } catch (error) {
                console.error("Error during card removal and registration:", error);
            }
        } else {
            try {
                await registerCreditCard(cardData);
            } catch (error) {
                console.error("Error during card registration:", error);
            }
        }
    };


    const navigate = useNavigate()

    const goToConditionalProcess = useCallback(() => {
        if (ifFromRequestForm) {
            navigate("/request",{
                state:{lobbyistId: requestDataForm.lobbyistId}
            });

        } else if (ifFromMegaphoneRequestForm) {
            navigate("/megaphone/request",{
                state:{lobbyistIds: megaphoneRequestDataForm.lobbyistIds}
            });
        } else {
            navigate(-1);
        }
    }, [navigate, ifFromRequestForm, ifFromMegaphoneRequestForm]);

    const goBack = useCallback(() => {
        navigate(-1)
        window.scrollTo(0, 0);
    },[navigate])
    return (
        <section>
            <BasicHeader/>

            <div className={`flex flex-col items-center mt-23pxr md:mt-50pxr`}>

                <div className="w-screen ml-18pxr  md:w-1/2">
                    <button>
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}/>
                    </button>
                </div>
                <div>
                    <div className={`mt-14pxr md:mt-6pxr`}>
                        <div className={`text-[15px] md:text-[30px] text-black font-extrabold`}>결제 카드 관리 (의뢰인)</div>
                    </div>

                </div>
                <CardRegisterForm cardData={cardData} updateFields={updateFields}/>
                <button disabled={!isFormValid()} onClick={handleButtonClick} className={`mt-46pxr md:mt-62pxr h-35pxr w-275pxr md:w-400pxr md:h-50pxr text-white font-bold text-[15px] md:text-[20px] ${isFormValid() ? "bg-black" :"bg-gray-200"} rounded`}>결제 카드 등록/변경</button>
                <div className="flex flex-col  w-296pxr md:w-400pxr items-start text-[#848484]">
                    <div className={`text-[10px] md:text-[18px] font-extrabold mt-39pxr md:mt-54pxr `}>
                        유의사항
                    </div>
                    <div className="flex flex-col text-[10px] md:text-[15px] font-semibold  mt-16pxr md:mt-28pxr mb-50pxr">
                        <div>· 타인 명의로 본인 인증을 할 경우 법적 처벌을 받을 수 있습니다.</div>
                        <div>· 거래확정 후 결제 중에는 카드 정보 수정이 불가합니다.</div>
                        <div>· 잔고 문제 및 카드 정지 등의 사유로 인해 결제가 지체되는 경우 해결을 요청
                            하는 내용이 고지되며 지체가 지속적으로 발생되는 경우 서비스 이용 약관 따라
                            이용 정지 혹은 분쟁 조정이 이루어질 수 있습니다.</div>

                    </div>
                </div>
            </div>
            {isErrorAlertModal && (
                <AlertModal>
                    <div className={`flex flex-col items-center justify-center`}>
                        <div className={`text-[16px] md:text-[24px] mt-30pxr md:mt-40pxr text-black font-semibold `}>등록 오류</div>
                        <div className={`text-[10px] md:text-[13px] mt-18pxr md:mt-30pxr text-[#5d5d5d] font-semibold`}>{errorMessage}</div>
                        <div className={`text-[10px] md:text-[13px] mt-18pxr md:mt-30pxr text-[#5d5d5d] font-semibold`}>내용을 올바르게 입력했는지 다시 한 번 확인해주시고</div>
                        <div className={`text-[10px] md:text-[13px] mt-18pxr md:mt-30pxr text-[#5d5d5d] font-semibold`}>재확인 이후에도 등록이 되지 않는다면</div>
                        <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>카드사 혹은 은행에 문의해주시기 바랍니다.</div>

                        <div className={`flex flex-row mt-15pxr md:mt-45pxr`}>
                            <button className={`mr-8pxr md:mr-12pxr w-243pxr md:w-300pxr h-36pxr md:h-45pxr bg-black text-white text-[14px] md:text-[25px] rounded font-bold`}  onClick={() => onClickToggleErrorModal("")}>확인</button>
                        </div>
                    </div>

                </AlertModal>
            )}

        </section>
    )

}