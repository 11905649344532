import BasicHeader from "../components/common/BasicHeader";
import {SideMenu} from "./SideMenu";
import {useState} from "react";
import MegaphoneRequestsManagement from "./MegaphoneRequestsManagement";
import WholeMembersManagement from "./WholeMembersManagement";
import OneToOneRequestsManagement from "./OneToOneRequestsManagement";
import CommunityBannerManagement from "./CommunityBannerManagement";
import {useAdminUserContext} from "./AdminUserProvider";
import AdminLoginPage from "./AdminLoginPage";
import {getStoredAdminUser} from "./hooks/useAdminUser";

export default function AdminMain(){
    const [activeScreen, setActiveScreen] = useState("회원관리");
    const { adminUser, setAdminUser } = useAdminUserContext();
    return (
        <>
            {adminUser ?
                (
                 <>
                     <BasicHeader />
                     <div className="flex">
                         <div className="w-1/4 p-20pxr min-w-[240px] h-screen text-white bg-[#1b2228]">
                             <SideMenu setActiveScreen={setActiveScreen}/>
                         </div>
                         <div className="w-full">
                             {activeScreen === "회원관리" && <WholeMembersManagement />}
                             {activeScreen === "일반 의뢰 관리" && <OneToOneRequestsManagement />}
                             {activeScreen === "확성기 의뢰 관리" && <MegaphoneRequestsManagement />}
                             {activeScreen === "데이터 수집" && <DataCollection />}
                             {activeScreen === "배너 관리" && <CommunityBannerManagement />}
                             {activeScreen === "약관 관리" && <Terms />}
                             {activeScreen === "결제/정산 히스토리" && <PaymentHistoryManagement />}
                         </div>
                     </div>
                 </>
                ):(
                    <AdminLoginPage/>
                )

            }




        </>
    )
}



function DataCollection() {
    return <div>데이터 수집</div>;
}

function Terms() {
    return <div>약관 관리</div>;
}

function PaymentHistoryManagement() {
    return <div>결제/정산 히스토리</div>;
}