import React, {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import BasicHeader from "../common/BasicHeader";


export default function UserProtectionView(){


    const navigate = useNavigate()
    const goBack = useCallback(() =>{
        navigate(-1)
    },[navigate])

    const [firstToggle, setFirstToggle] = useState<boolean>(false)
    const [secondToggle, setSecondToggle] = useState<boolean>(false)
    const [thirdToggle, setThirdToggle] = useState<boolean>(false)
    const [forthToggle, setForthToggle] = useState<boolean>(false)

    const handleFirstToggle = useCallback(() => {
        setFirstToggle(firstToggle => !firstToggle)
    },[firstToggle])
    const handleSecondToggle = useCallback(() => {
        setSecondToggle(secondToggle => !secondToggle)
    },[secondToggle])
    const handleThirdToggle = useCallback(() => {
        setThirdToggle(thirdToggle => !thirdToggle)
    },[thirdToggle])
    const handleForthToggle = useCallback(() => {
        setForthToggle(forthToggle => !forthToggle)
    },[forthToggle])





    return (
        <section>
            <div className="flex flex-col items-center pb-133pxr">
                <BasicHeader/>

                <div>
                    <div className="flex flex-col items-center ">
                        <div className="text-[15px] md:text-[30px] font-extrabold text-black">이용자 보호</div>
                    </div>
                </div>
                <div className="flex flex-col mt-72pxr md:mt-141pxr  w-350pxr md:w-880pxr text-black items-start text-[10px] md:text-[18px] font-medium">
                    <button onClick={handleFirstToggle}>
                        <div className="mt-14pxr md:mt-15pxr text-[20px] md:text-[30px] font-extrabold text-black">{firstToggle ? "▼" : "▶"} 거래 분쟁이 발생한 경우 어떻게 해야 할까요?</div>
                    </button>
                    {firstToggle && (
                        <>
                            <div className={` mt-39pxr md:mt-54pxr`}>
                                로비디의 모든 거래는 기본적으로 거래 당사자들끼리 대화를 통해 문제를 해결하도록 안내 드리고 있어요
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>대화를 통해 거래 문제가 해결되지 않는 경우 아래 내용을 따라 상대방을 신고해 주세요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>1. 상대방의 닉네임 혹은 연락처와 어떠한 문제가 발생하였는지 내용을 포함하여 Lobbydteam@gmail.com로 보내주세요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>2. 처음 신고가 접수되었다면 신고 상대방에게 문제를 해결해 달라는 안내 메일을 발송하고 있어요. 신고한 다음 날까지 답변이 없거나 문제가 해결되지 않은 경우 다시 한번 사용자를 신고해 주세요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>3. 문제가 해결되지 않은 경우 다음과 같이 조치하고 있어요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>고지되지 않은 경미한 하자로 인한 분쟁일 경우 30일, 중대한 하자로 인한 분쟁일 경우 문제가 해결되기 전까지 이용 정지를 진행하고 있어요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>서로 간의 협의 또는 문제가 해결된 경우 이용 정지를 해제해 드리고 있어요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>다만 재능거래 특성상 컨설팅, 작업 결과물 등의 주관이 대입된 부분에 대해서는 도움 드리기 어려울 수 있으며 분쟁 시 서로 간의 대화를 통해 합의가 되지 않는 경우 로비디에서 분쟁 조정을 도움 드리고 있어요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>로비디에서 진행할 수 있는 최대 조치는 이용 정지이기 때문에 이러한 조치에도 문제가 해결되지 않는 경우 전자문서·전자거래분쟁조정위원회로 분쟁 조정 신청을 접수하여 주시기 바라요.</div>
                            </div>
                        </>
                    )}
                    <button onClick={handleSecondToggle}>
                        <div className="mt-14pxr md:mt-70pxr text-[20px] md:text-[30px] font-extrabold text-black">{secondToggle ? "▼" : "▶"}거래 분쟁 주요 사례에 대한 이용 정지 기준을 참고해주세요.</div>
                    </button>
                    {secondToggle && (
                        <>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>1.  로비스트 및 로비스트의 네트워크에게 잘못이 있는 경우</div>
                            </div>

                            <img className="mt-10pxr md:mt-20pxr" src="/user-protection1.png"/>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>2. 의뢰인에게 잘못이 있는 경우</div>
                            </div>

                            <img className="mt-10pxr md:mt-20pxr" src="/user-protection2.png"/>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>*거래 분쟁으로 인한 이용정지는 해당 문제 해결이 확인되는 즉시 해제되어요.</div>
                            </div>

                            <div className="mt-75pxr md:mt-200pxr w-1100pxr text-[20px] md:text-[40px] font-extrabold text-black">로비디의 분쟁 조정 안내</div>

                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>거래 중 발생한 분쟁에 관하여 당사자 간 원만한 합의가 이루어지지 않는 경우에는, 분쟁이 보다 합리적이고 원활히 해결될 수 있도록 로비디에서 분쟁 조정안을 제시해 드리고 있어요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>첫 번째, 상대방에게 원하시는 합의안을 Lobbydteam@gmail.com로 보내주세요. 로비디가 중간에서 먼저 조정을 도와드릴게요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>예시) 비용 할인 xx원 / 비용 5:5 부담</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>두 번째, 이후에도 합의가 되지 않을 경우 아래 내용을 확인하시고 동의하신다면 신고 내용에 [분쟁 조정을 신청합니다]라는 문구를 포함하여 다시 한번 사용자를 신고해 주세요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>- 로비디 내 분쟁 조정 절차는 어떠한 법적 효력도 갖고 있지 않아요. 로비디는 내부 규정에 근거해 가능한 한 객관적이고 공정한 분쟁 조정안을 제시하려고 노력하고 있어요. 다만 하자 발생 시점이 명확하지 않은 경우 신고자 또는 신고 대상자가 조정안의 내용을 받아들이지 않더라도 서비스 이용 제한 조치를 포함한 어떠한 불이익도 받지 않아요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>- 로비디 고객센터에서는 의뢰서 내용을 기반으로 확인 가능한 사실에 근거해서만 중재를 진행해 드리고 있어요. 추가적인 분쟁 관련 증빙 관련 자료가 있다면 신고 시에 그 내용을 공유해 주세요. 커리어 등 객관적인 제3자로부터 확인받은 내용, 구체적인 하자 발생 시점을 증빙할 수 있는 자료 등이 있다면 보다 신속하고 합리적인 분쟁 조정에 도움이 될 수 있어요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>- 분쟁에 유리한 방향으로 상대방의 프로필이 수정되었다면 프로필이 수정되기 이전 내용을 토대로 분쟁 조정을 진행해요.</div>
                            </div>
                            <div className="flex flex-col mt-16pxr md:mt-40pxr">
                                <div>세 번째, 로비디에서 분쟁 조정안을 신고자와 신고 대상자에게 발송해 드려요.</div>
                            </div>
                        </>


                    )}





                </div>
            </div>
        </section>
    )

}