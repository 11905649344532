 import type {FC} from "react";
import React, {useState} from "react";
import {UseMutateFunction} from "react-query";
import {ReviewModifyFormData, ReviewRequestFormData} from "../../types/types";
import {Modal} from "./Modal";


export type ReviewModalProps = {
    onClickToggleReviewModal:() => void
    handleReviewChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    handleReviewSubmitButton:boolean
    submitReview:UseMutateFunction<void, unknown, ReviewRequestFormData, unknown>
    reviewValue:string
    reviewRequestData : ReviewRequestFormData
}

export const ReviewModal : FC<ReviewModalProps> = ({onClickToggleReviewModal,handleReviewChange,reviewValue,handleReviewSubmitButton,submitReview, reviewRequestData}) => {

    const className = ["bg-white h-250pxr md:h-430pxr w-340xr md:w-650pxr rounded-xl overflow-auto scrollbar",].join(' ')
    return (
        <div className={`fixed items-center justify-center w-full h-screen left-0pxr top-0pxr flex bg-black bg-opacity-70 `}>
            <div className={`fixed items-center justify-center w-full h-screen left-0pxr top-0pxr flex bg-black bg-opacity-70 `}>
                <div className={className}>
                    <div className={`flex justify-end`}>
                        <button className={`h-12pxr w-12pxr md:h-17pxr md:w-17pxr mt-11pxr mr-11pxr md:mt-48pxr md:mr-48pxr`} onClick={onClickToggleReviewModal}>
                            <img src="/close.png"/>
                        </button>

                    </div>
                    <div className="flex flex-col items-center md:items-start mx-20pxr md:mx-64pxr mt-10pxr text-[17px] md:text-[30px] text-black font-semibold">
                        로비스트에 대한 후기
                        <div className="mt-22pxr w-300pxr md:w-500pxr md:mt-40pxr text-[#5d5d5d] font-bold text-[10px] md:text-[15px]">사실관계에 기반하지 않은 부적절한 내용이 포함된 후기는 경고 없이 삭제될 수 있습니다.</div>
                        <textarea onChange={handleReviewChange} value={reviewValue} className={`resize-none mt-30pxr md:mt-40pxr w-300pxr md:w-490pxr mx-5pxr bg-white text-[12px] md:text-[20px] text-black placeholder:text-gray-300   focus:outline-0`}  placeholder={`후기를 입력해주세요`} />
                        <div className="h-1pxr w-300pxr md:w-490pxr bg-[#d3d2d2]"/>

                        <button disabled={handleReviewSubmitButton} className={`w-300pxr h-35pxr md:w-490pxr md:h-45pxr mt-20pxr md:mt-40pxr ${handleReviewSubmitButton ? "bg-[#dddddd]" : "bg-black"} text-white text-[16px] md:text-[20px] rounded md:rounded-xl`} onClick={() => submitReview(reviewRequestData)}>
                            등록하기
                        </button>

                    </div>
                </div>
            </div>

        </div>

    )
}