import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    CardInfo,
    CardRegisterRequestFormData,
    IUser,
} from "../../types/types";
import {UseMutateFunction, useMutation, useQuery, useQueryClient} from "react-query";
import {queryKeys} from "../../services/react-query/constants";
import {useUserContext} from "../common/UserContext";
import {queryClient} from "../../services/react-query/queryClient";



export function useBankAccountRemove(
    onSuccessRegister: () => void,
    onErrorRegister:() => void,
) : UseMutateFunction<void, unknown, void, unknown> {

    async function setBankAccountRequest(user: IUser | null,): Promise<void> {
        const config: any = {};

        if (user){
            config.headers = getJWTHeader(user);
            await axiosInstance.delete(`/user/member/account/delete/`, config)
        }
    }


    const { user, updateUser, clearUser } = useUserContext();


    const {mutate} = useMutation(() => setBankAccountRequest(user),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.userInformation])
                onSuccessRegister()
            },
            onError: () => {
                onErrorRegister()
            }
        })

    return mutate
}

