import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {
    IDetailLobbyistResponse, ILobbyistDetailInformation, ILobbyistReview, ILobbyistReviewResponse,
    IPost,
    IPostResponse,
    IUser,
    IUserInformation,
    IUserInformationResponse
} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserContext} from "../common/UserContext";

async function getLobbyistReview(user:IUser|null, lobbyistId:string ): Promise<ILobbyistReviewResponse | null> {
    const config: any = {};

    if (user){
            config.headers = getJWTHeader(user);

    }

    const { data } : AxiosResponse<ILobbyistReviewResponse> = await axiosInstance.get(`/user/review/${lobbyistId}`, config,)

    return data;

}

interface UseLobbyistDetail {
    lobbyistReviews: ILobbyistReview[] | undefined;
    isLoading: boolean;
}


export function useLobbyistReview(lobbyistId:string) :UseLobbyistDetail{
    const { user, updateUser, clearUser } = useUserContext();

    const { data, isLoading, isFetching, isError, error} = useQuery<ILobbyistReviewResponse | null>(
        [queryKeys.lobbyistReview,lobbyistId],
        () => getLobbyistReview(user, lobbyistId),
        {

            getNextPageParam: (lastPage) => lastPage?.nextPage,

        }
    );

    const lobbyistReviews = data?.rows

    return {
        lobbyistReviews,
        isLoading,
    };
}