import {AlertModal, Avatar, DivProps, Subtitle} from "../common";
import React, {FC, useCallback, useState} from "react";
import {Modal} from "../common/Modal";
import {CardPaymentConfirmRequestFormData, IReceivedRequest, IRequestHistory} from "../../types/types";
import {formatCurrency, timeAgo} from "../../utils/data/helper";
import {useUserInformation} from "../../hooks/myprofile/useUserInformation";
import {useMegaPhoneRequestDetailHistory} from "../../hooks/history/useMegaphoneRequestDetailHistory";
import {useMegaphoneReceivedRequestDetail} from "../../hooks/received-requests/useMegaphoneReceivedRequestDetail";


export type ReceivedMegaphoneRequestCardProps = DivProps & {
    receivedRequest: IReceivedRequest
}

export const ReceivedMegaphoneRequestCard: FC<ReceivedMegaphoneRequestCardProps> = ({className: _className,receivedRequest,...props }) =>{

    const [isRequestDetailModal, setIsRequestDetailModal] = useState<boolean>(false);
    const onClickToggleRequestDetailModal = useCallback(() => {
        setIsRequestDetailModal(isRequestDetailModal => !isRequestDetailModal);
    }, [isRequestDetailModal])


    const {detailMegaphoneHistory} = useMegaphoneReceivedRequestDetail(receivedRequest._id)

    return (
        <section key={props.key} >
            <div className={`pt-27pxr md:pt-40pxr w-340pxr md:w-600pxr`}>
                <div className={`flex flex-row justify-between mb-6pxr md:mb-20pxr`}>
                    <div className={`flex flex-col`}>
                        <div className="flex flex-row">
                            <Avatar src={receivedRequest.clientProfileImage} />
                            <div className={`flex flex-row  md:w-300pxr`}>
                                <div className="flex flex-col ml-8pxr md:ml-12pxr">
                                    <div className={`flex flex-row mt-8pxr mt-6pxr md:mt-12pxr text-[13px] md:text-[15px] text-black `}>
                                        <Subtitle numberOfLines={1}>{receivedRequest.clientNickName}</Subtitle>
                                    </div>
                                    <div className="mt-7pxr md:mt-14pxr text-[10px] md:text-[14px] font-semibold">
                                        {timeAgo(receivedRequest.createdAt)}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col items-end justify-end  ">
                        <button className={`rounded md:rounded-lg my-2pxr md:my-5pxr w-85pxr md:w-115pxr h-22pxr md:h-33pxr bg-black text-[8px] md:text-[12px] font-semibold md:font-bold text-white flex items-center justify-center`}
                                onClick={onClickToggleRequestDetailModal}>
                            의뢰 내용 보기
                        </button>
                        <div className="flex flex-row items-end justify-end mt-10pxr font-bold text-black ">
                            <div className="text-[10px] md:text-[12px] pr-7pxr md:pr-10pxr">소개비</div>
                            <div className="text-[15px] md:text-[16px] ">{(detailMegaphoneHistory?.introducePrice === 0) ? "0원 (소개나눔 요청)" : formatCurrency(detailMegaphoneHistory?.introducePrice ?? 0)+"원"}</div>
                        </div>
                    </div>

                </div>
                <div className="flex flex-col items-end">


                    <div className="mt-30pxr md:mt-35pxr text-[8px] md:text-[13px] text-gray-700 font-bold">

                        {detailMegaphoneHistory?.megaStatus === "cancel" && "의뢰 마감"}
                        {detailMegaphoneHistory?.megaStatus === "approve" && "의뢰 완료"}
                        {detailMegaphoneHistory?.megaStatus === "examine" && "검토중"}
                    </div>


                </div>
                <div className="h-1pxr w-350pxr md:w-600pxr bg-gray-200 mt-7pxr md:mt-5pxr"/>
            </div>
            {isRequestDetailModal && (
                <Modal className="h-350pxr w-300pxr md:w-650pxr md:h-650pxr" onCloseIconClicked={onClickToggleRequestDetailModal}>
                    <div className="flex flex-col px-17pxr md:mx-20pxr mt-28pxr text-[17px] md:text-[25px] text-black font-semibold">

                        의뢰 내용 보기
                        <div className={`text-[13px] md:text-[18px] font-bold mt-20pxr md:mt-35pxr`}>
                            자기소개 / 찾는사람 / 요구사항
                        </div>
                        <div className={`h-94pxr w-341pxr md:h-90pxr md:w-550pxr mt-8pxr md:mt-12pxr`}>
                            <textarea value={detailMegaphoneHistory?.content} readOnly={true} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl`} rows={3} />
                        </div>
                        <div className={`flex flex-row items-end  font-bold mt-20pxr md:mt-45pxr`}>
                            <div className={`text-[12px] md:text-[18px] md:mr-5pxr`}>업무비</div>
                            <div className={`text-[8px] md:text-[15px]`}>(소개받으신 분께 자문을 구하거나 업무를 부탁드리는 경우 지급되는 보수입니다)</div>

                        </div>
                        <div className={` md:h-90pxr md:w-500pxr mt-10pxr md:mt-12pxr`}>
                            <textarea value={detailMegaphoneHistory?.businessPrice} readOnly={true}  className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl`} rows={2}  />
                        </div>
                        <div className={`text-[12px] md:text-[18px] mt-15pxr font-bold `}>
                            의뢰인의 연락처
                        </div>
                        <div className={` md:h-90pxr md:w-500pxr mt-10pxr md:mt-12pxr`}>
                            <textarea value={detailMegaphoneHistory?.contact} readOnly={true} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl`} rows={1}  />
                        </div>

                        <div className={`flex flex-row items-end text-[15px] mt-20pxr md:mt-0pxr md:text-[18px] bfont-bold `}>
                            <div className={`text-[12px] md:text-[18px] mr-5pxr`}>소개비</div>
                            <div className={`text-[8px] md:text-[15px]`}>(소개해주는 로비스트에게 지급되는 보수입니다)</div>
                        </div>
                        <div className={` md:h-90pxr md:w-550pxr mt-10pxr md:mt-12pxr`}>
                            <textarea value={formatCurrency(detailMegaphoneHistory?.introducePrice ?? 0)} readOnly={true} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[20px] text-black placeholder:text-gray-300 rounded-xl`} rows={1} />
                        </div>

                    </div>

                </Modal>
            )}

        </section>
    )
}
