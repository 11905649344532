import axios, { AxiosResponse, AxiosError } from 'axios';

import type {IUser, IUserResponse, IUserValidateResponse} from "../../types/types";
import { axiosInstance, getJWTHeader } from '../../services/axiosInstance';
import { queryKeys } from '../../services/react-query/constants';
import {
    clearStoredUser,
    getStoredUser,
    setStoredUser,
} from '../../utils/user-storage';
import {useMutation, useQuery, useQueryClient, UseQueryOptions} from "react-query";
import {queryClient} from "../../services/react-query/queryClient";
import {getAccessTokenWithMutex} from "../../hooks/common/useUser";
import {useState} from "react";
import {useAdminUserContext} from "../AdminUserProvider";
import {IAdminUser, IAdminUserResponse, LoginState} from "../types";
import {useInterval} from "../../hooks/common";






interface UseAdminUser {
    adminUser: IAdminUser | null;
    login: (loginState: LoginState) => Promise<void>;
}

export function useAdminUser(): UseAdminUser {
    const { adminUser, setAdminUser } = useAdminUserContext();
    const login = async (loginState: LoginState) => {
        try {
            const { data }: AxiosResponse<IAdminUserResponse> = await axiosInstance.post(
                `/admin/member/login`,
                loginState,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            setAdminUser(data.row);
            setStoredAdminUser(data.row)
        } catch (error) {
            console.error(error);
            setAdminUser(null);
            clearStoredAdminUser()
        }
    };

    useInterval(async () => {
        setAdminUser(null);
    }, 15 * 60 * 1000);

    return { adminUser, login };
}
const ADMIN_USER_LOCALSTORAGE_KEY = 'lobbyD_user_admin';

// helper to get user from localstorage
export function getStoredAdminUser(): IAdminUser | null {
    const storedUser = localStorage.getItem(ADMIN_USER_LOCALSTORAGE_KEY);
    return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredAdminUser(adminUser: IAdminUser): void {
    localStorage.setItem(ADMIN_USER_LOCALSTORAGE_KEY, JSON.stringify(adminUser));
}

export function clearStoredAdminUser(): void {
    localStorage.removeItem(ADMIN_USER_LOCALSTORAGE_KEY);
}
