import {FC} from "react";
import {ReactDivProps} from "./Div";



export const NotFound404View : FC<ReactDivProps> = ({}) => {

    return (
        <section>
            <div className="flex w-screen items-center justify-center">
                <div className="flex flex-col mt-225pxr space-y-41pxr w-320pxr md:w-580pxr items-start text-black">
                    <img className="w-176pxr h-46pxr" src="/main-logo.png"/>
                    <div className="flex flex-col text-[20px] md:text-[30px] font-semibold">
                        <div>죄송합니다.</div>
                        <div>요청하신 페이지를 찾을 수 없습니다.</div>
                    </div>

                    <div className="flex flex-col text-[15px] md:text-[20px] font-semibold">
                        <div>방문하시려는 페이지의 주소가 잘못 입력되었거나,
                            페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.
                            입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.</div>
                    </div>

                    <a href="/" className="flex justify-center items-center mt-15pxr w-180pxr h-45pxr md:w-246pxr md:h-60pxr bg-black rounded-xl text-white text-[15px] md:text-[25px] font-bold">
                        로비디 홈으로
                    </a>
                </div>
            </div>
        </section>
    )
}

