import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse, AxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";
import {
    IAdminMegaphoneRequest,
    IAdminMegaphoneRequestResponse,
    IAdminUser,
    IAdminWholeMember,
    IAdminWholeMemberResponse
} from "../types";
import {useAdminUserContext} from "../AdminUserProvider";

async function getAdminMegaphoneRequests(
    adminUser: IAdminUser | null,
    page: number,
    megaStatus: "examine"|"reject"|"approve"|"cancel"| null,
    createdStartedAt: Date | null,
    createdEndedAt: Date | null
): Promise<IAdminMegaphoneRequestResponse> {


    const config: AxiosRequestConfig = {};
    if (adminUser)
        config.headers = { authorization: `${adminUser.accessToken}` }

    if (megaStatus){
        config.params = {
            page: page,
            limit: 10,
            createdStartedAt: createdStartedAt?.toISOString(),
            createdEndedAt: createdEndedAt?.toISOString(),
            megaStatus: megaStatus,
        }
    }else{
        config.params = {
            page: page,
            limit: 10,
            createdStartedAt: createdStartedAt?.toISOString(),
            createdEndedAt: createdEndedAt?.toISOString(),
        }
    }
    const { data } : AxiosResponse<IAdminMegaphoneRequestResponse> = await axiosInstance.get(`/admin/lobby/mega`, config,);


    return data;

}

interface UseAdminMegaphoneRequest {
    megaRequests:IAdminMegaphoneRequest[]
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
    updatePageIndex: (pageIncrement: number) => void;
    pageIndex: number;
    hasNextPage:boolean
    updateCreatedStartedAt: (date: Date | null) => void;
    updateCreatedEndedAt: (date: Date | null) => void;
    updateMegaStatus: (query: "examine"|"reject"|"approve"|"cancel"| null) => void;
    megaStatus: "examine"|"reject"|"approve"|"cancel"| null,
    maxPage: number;
}

export function useAdminMegaphoneRequest(
    filter?: () => void
): UseAdminMegaphoneRequest {

    const { adminUser,setAdminUser } = useAdminUserContext();
    const [megaStatus, setMegaStatus] = useState<"examine"|"reject"|"approve"|"cancel"| null>(null);
    const [createdStartedAt, setCreatedStartedAt] = useState<Date | null>(null);
    const [createdEndedAt, setCreatedEndedAt] = useState<Date | null>(null);
    const [pageIndex, setPageIndex] = useState(1)
    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
    } = useQuery<IAdminMegaphoneRequestResponse>(

        [queryKeys.adminMegaphone,pageIndex, megaStatus, createdStartedAt, createdEndedAt],
        () => getAdminMegaphoneRequests(adminUser, pageIndex, megaStatus, createdStartedAt, createdEndedAt),{

        }
    )


    function updatePageIndex(pageIncrement:number):void {
        setPageIndex((prev) => prev + pageIncrement)
    }
    function updateCreatedStartedAt(date: Date | null): void {
        setCreatedStartedAt(date);
    }

    function updateCreatedEndedAt(date: Date | null): void {
        setCreatedEndedAt(date);
    }

    function updateMegaStatus(status: "examine"|"reject"|"approve"|"cancel"| null) {
        setMegaStatus(status);
        // 선택한 의뢰 상태를 사용하여 필터링을 적용하거나 필요한 로직을 수행합니다.
    }
    const hasNextPage = (data?.count || 0) >  (10 * pageIndex)
    const maxPage = Math.ceil((data?.count || 0) / 10);



    useEffect(() => {

        queryClient.prefetchQuery([queryKeys.adminMegaphone,pageIndex + 1, megaStatus, createdStartedAt, createdEndedAt],
            () => getAdminMegaphoneRequests(adminUser, pageIndex + 1,  megaStatus , createdStartedAt, createdEndedAt),
        )
    },[queryClient,pageIndex])



    const megaRequests = data?.rows || []


    return {
        megaRequests,
        isLoading,
        isFetching,
        isError,
        error,
        pageIndex,
        updatePageIndex,
        hasNextPage,
        updateMegaStatus,
        megaStatus,
        updateCreatedEndedAt,
        updateCreatedStartedAt,
        maxPage
    };
}