import type {FC, DetailedHTMLProps,HTMLAttributes} from "react";


export const makeClassName = (setting: string, _className?: string, numberOfLines?: number) =>
    [setting,
        numberOfLines ? `line-clamp-${numberOfLines}` : '',
        _className]
        .join(' ')
type TextProps = DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>

export type TitleProps = TextProps & {
    numberOfLines?: number
}

export const Title: FC<TitleProps> = ({
    className: _className,
    numberOfLines,
    ...prop
}) => {
    const className = makeClassName(
        'font-bold text-[17px] md:text-[30px] text-black text-center whitespace-pre-line',
        _className,
        numberOfLines,
    )
    return <p {...prop} className={className}/>
}

export type SubtitleProps = TitleProps & {}
export const Subtitle:FC<SubtitleProps> = ({
    className: _className, numberOfLines, ...props
}) =>{
    const className = makeClassName(
        'font-bold line-clamp-1 text-[13px] md:text-[18px]  text-center text-black whitespace-pre-line',
        _className,
        numberOfLines,
    )
    return <p {...props} className={className}/>
}

export type SummeryProps = SubtitleProps & {}
export const Summery: FC<SummeryProps> = ({
    className: _className,
    numberOfLines,
    ...props
}) => {
    const className = makeClassName(
        'text-sm whitespace-pre-line',
        _className,
        numberOfLines,
    )
    return <p {...props} className={className}/>
}

export type ParagraphProps = SummeryProps & {}
export const Paragraph : FC<ParagraphProps> = ({
    className : _className,
    numberOfLines,
    ...props
}) => {
    const className = makeClassName(
        'font-normal text-[8px] md:text-sm whitespace-pre-line',
        _className,
        numberOfLines
    )
    return <p {...props} className={className}/>
}

