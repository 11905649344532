import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IDetailLobbyistResponse, ILobbyist, ILobbyistResponse, IUser} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {clearStoredUser, getStoredUser, setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery, useQueryClient} from "react-query";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {useEffect} from "react";
import {useUserContext} from "../common/UserContext";

async function getLobbyistList(user: IUser | null): Promise<ILobbyistResponse | null> {
    const config: AxiosRequestConfig = {};
    if (user){
        config.headers = getJWTHeader(user)
    }
    try{
        const { data }: AxiosResponse<ILobbyistResponse> = await axiosInstance.get(`/user/search/`, config);
        return data;
    }catch (errorResponse){
        const { data }: AxiosResponse<ILobbyistResponse> = await axiosInstance.get(`/user/search/`);
        return data;

    }


}

interface UseLobbyistList {
    lobbyists: ILobbyist[] | undefined;
    isLoading: boolean;

}


export function useLobbyistList() :UseLobbyistList{
    const { user, updateUser, clearUser } = useUserContext();

    const { data, isLoading, isFetching, isError, error} = useQuery<ILobbyistResponse | null>(
        [queryKeys.lobbyists],
         () => getLobbyistList(user),
        {
            onError: () => {
                queryClient.invalidateQueries([queryKeys.user])
                queryClient.invalidateQueries([queryKeys.userInformation])
            },
            onSuccess: () => {

            },
        }
    );
    const lobbyists = data?.rows
    // 모든 페이지의 게시물을 단일 배열로 펼칩니다.

    return {
        lobbyists ,
        isLoading,
    };
}