import {AlertModal} from "../common";
import React, {ChangeEvent, useCallback, useState} from "react";
import Footer from "../main/Footer";
import {MegaphoneRequestFormData} from "../../types/types";
import {formatCurrency} from "../../utils/data/helper";
import BasicHeader from "../common/BasicHeader";
import {useNavigate} from "react-router-dom";



type MegaphonePaymentData = {
    introducePrice:number
}

export type MegaphoneRequestPaymentSettingProps = MegaphonePaymentData & {
    updateFields: (fields: Partial<MegaphonePaymentData>) => void
    next: () => void
    back: () => void
    data: MegaphoneRequestFormData
}

export default function MegaphoneRequestPaymentSetting({updateFields,back,next,data}:MegaphoneRequestPaymentSettingProps) {


    const [isPayMethodAlertModal, setIsPayMethodAlertModal] = useState<boolean>(false);
    const onClickTogglePayMethodModal = useCallback(() => {
        setIsPayMethodAlertModal(!isPayMethodAlertModal);
    }, [isPayMethodAlertModal]);

    const [isCompleteAlertModal, setIsCompleteAlertModal] = useState<boolean>(false);
    const onClickCompleteToggleModal = useCallback(() => {
        setIsCompleteAlertModal(!isCompleteAlertModal);
    }, [isCompleteAlertModal]);



    const [isChecked, setIsChecked] = useState<boolean>(false)
    const check = useCallback(() => {
        setIsChecked(isChecked => !isChecked)
        updateFields({introducePrice: 0})
    },[isChecked])

    const [priceValue, setPriceValue] = useState(0);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value.replace(/,/g, "");
        if (/^\d{0,9}$/.test(newValue)) {
            const parsedValue = parseInt(newValue, 10);
            let roundedValue = parsedValue;
            if (parsedValue >= 10000) {
                roundedValue = Math.round(parsedValue / 1000) * 1000;
            }
            updateFields({ introducePrice: isNaN(roundedValue) ? 0 : roundedValue });
            setPriceValue(isNaN(roundedValue) ? 0 : roundedValue);
        }
    };

    const navigate = useNavigate()
    const goPaymentMethodRegister = useCallback(() => {
        navigate("/payment/register/card")
    },[navigate])

    const handleButton:boolean = (data.introducePrice < 10000 )&& !isChecked
    return(
        <section >
            <BasicHeader/>

            <div className={`flex flex-col items-center h-full mt-23pxr md:mt-50pxr pb-100pxr`}>
                <div className="w-screen ml-18pxr  md:w-1/2">
                    <button className={`w-8pxr h-16pxr  md:w-15pxr md:h-30pxr`} >
                        <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} />
                    </button>
                </div>
                <div className={`flex flex-col items-start w-341pxr  md:w-600pxr`}>

                    <div className={`flex flex-row items-end `}>
                        <div className={`flex flex-col mr-11pxr md:mr-17pxr`}>
                            <div className={`text-[12px] md:text-[15px] text-black font-extrabold`}>2/3</div>
                            <div className={`text-[20px] md:text-[35px] font-bold text-black `}>소개비 설정</div>
                        </div>
                        <div className={`bg-gray-300 h-2pxr md:h-3pxr w-20pxr md:w-35pxr mb-8pxr md:mb-15pxr mr-6pxr md:mr-17pxr`}/>
                        <div className={`flex flex-col items-start`}>

                            <div className={`text-[12px] md:text-[15px] text-[#dddddd] font-extrabold`}>3/3</div>
                            <div className={`text-[13px] md:text-[25px] font-bold text-[#dddddd] `}>확성기 결제</div>
                        </div>
                    </div>
                    <div className={`flex flex-col text-[10px] md:text-[15px] text-[#696969] font-bold`}>
                        <div className={`pt-20pxr md:pt-47pxr`}>1. 의뢰인이 ‘프로필 {'>'}  내가 요청한 의뢰’에서</div>
                        <div className={`underline underline-offset-2`}>거래확정을 누르기 전까지는 비용이 결제되지 않습니다.</div>
                        <div className={`pt-24pxr md:pt-15pxr`}>2. 소개받고 난 후 의뢰인이 거래를 확정하면,</div>
                        <div className={``}>책정하신 소개비가 로비스트의 계좌로 자동 입금됩니다.</div>


                        <div className={`flex flex-row items-end text-black font-extrabold mt-43pxr md:mt-50pxr`}>
                            <div className={`text-[13px]   md:text-[18px]`}>소개비</div>
                            <div className={`text-[10px] md:text-[13px] ml-5pxr`}> (소개해주는 로비스트에게 1~10만 원 선으로 성의를 표현해보세요)</div>

                        </div>
                        <div className={`flex flex-row items-center h-44pxr w-310pxr md:h-57pxr md:w-450pxr mt-10pxr md:mt-12pxr ${isChecked ? "bg-[#ededed]" : "bg-white"} rounded-xl border border-1`}>
                            {isChecked ?
                                <input type="text" value={formatCurrency(0)}  onChange={handleChange} className={`resize-none w-341pxr md:w-400pxr p-12pxr md:p-12pxr  ${isChecked ? "bg-[#ededed]" : "bg-white"} text-right text-black text-[12px] md:text-[20px] m-10pxr md:mx-10pxr focus:outline-none `} placeholder={`${isChecked ? "0" : ""}`}/>:
                                <input type="text"  value={formatCurrency(data.introducePrice)} onChange={handleChange} className={`resize-none w-341pxr md:w-400pxr p-12pxr  md:p-12pxr ${isChecked ? "bg-[#ededed]" : "bg-white"} text-black text-[12px] md:text-[20px] text-right m-10pxr md:mx-10pxr focus:outline-none `} placeholder={`${isChecked ? "0" : ""}`}/>
                            }
                            <div className={`mr-15pxr text-black text-[12px] md:text-[18px]`}>원</div>
                        </div>
                        <div className={`flex flex-row items-center justify-between w-310pxr md:w-450pxr mt-8pxr  md:mt-14pxr`}>

                            <div className="font-bold text-[#696969] text-[10px] md:text-[13px]">
                                로비스트 본인이 해당될 경우에도 소개비가 지급됩니다
                            </div>
                            <div className="flex flex items-center justify-end">
                                {isChecked ?
                                    (<button className="h-15pxr w-15pxr" onClick={check}>
                                        <img src="/checked.png"/>
                                    </button>)
                                    :
                                    (<button className="h-15pxr w-15pxr" onClick={check}>
                                        <img src="/unchecked.png"/>
                                    </button>)
                                }
                                <div className="ml-5pxr">소개 나눔 요청</div>

                            </div>


                        </div>
                        <div className={`text-[13px]  text-black md:text-[18px] mt-15pxr md:mt-30pxr`}>결제예정 금액</div>
                        <div className={`flex flex-row items-center h-44pxr md:h-57pxr w-160pxr md:w-210pxr mt-10pxr md:mt-12pxr  ${isChecked ? "bg-[#ededed]" : "bg-white"}  rounded-xl border border-1`}>
                            <input type={`text`} value={isChecked ? "0" : formatCurrency(data.introducePrice * (1/10) + data.introducePrice)}  readOnly={true}  className={`resize-none w-120pxr md:w-150pxr p-8pxr   md:p-12pxr  ${isChecked ? "bg-[#ededed]" : "bg-white"}  text-black text-[12px] md:text-[20px] text-right md:m-10pxr focus:outline-none  `} placeholder={`${isChecked ? "0" : ""}`}/>
                            <div className={`ml-10pxr md:ml-5pxr text-black text-[12px] md:text-[18px]`}>원</div>
                        </div>

                        <div className={`text-[10px] md:text-[15px] mt-10pxr md:mt-15pxr text-[#696969]`}>
                            거래확정 후 결제 시 소개비의 10%가 서비스 수수료로 함께 부과됩니다.
                        </div>
                        <div className={`text-[10px] md:text-[15px] font-semibold mt-35pxr md:mt-45pxr text-[#696969]`}>
                            결제 및 이용 안내
                        </div>
                        <div className={`text-[10px] font-semibold md:text-[15px] text-[#696969]`}>
                            - 결제 방식으로 등록된 신용/선불카드 결제만을 제공하고 있습니다.
                        </div>
                        <div className={`text-[10px] md:text-[15px] text-[#696969]`}>
                            - 명시된 금액은 부가세(VAT)포함 가격입니다.
                        </div>
                        <div className={`text-[10px] md:text-[15px] text-[#696969]`}>
                            - 결제 후 신용카드 전표는 개인 소득공제용으로만 이용하실 수 있습니다.
                        </div>

                        <button disabled={handleButton && !isChecked} className={`w-341pxr h-35pxr md:w-600pxr md:h-45pxr mt-30pxr  md:mt-56pxr ${handleButton && !isChecked ? "bg-[#e0e0e0]" : "bg-black"} text-[15px] md:text-[20px] text-white font-bold rounded md:rounded-lg`} onClick={next}>다음</button>
                    </div>
                </div>


            {isPayMethodAlertModal &&
                <AlertModal >
                    <div className={`flex flex-col items-center justify-center`}>
                        <div className={`text-[16px] md:text-[24px] mt-30pxr md:mt-50pxr text-black font-semibold `}>결제 카드 등록 안내</div>
                        <div className={`text-[10px] md:text-[13px] mt-36pxr md:mt-45pxr text-[#5d5d5d] font-semibold`}>소개비 설정을 위해서 결제 카드 등록이 필요합니다.</div>
                        <div className={`text-[10px] md:text-[13px] mt-10pxr md:mt-20pxr text-[#5d5d5d] font-semibold`}>의뢰 내용이 저장된 상태로</div>
                        <div className={`text-[10px] md:text-[13px] text-[#5d5d5d] font-semibold`}>결제 카드 등록 페이지로 이동됩니다.</div>

                        <div className={`flex flex-row mt-52pxr md:mt-65pxr`}>
                            <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-[#e0e0e0] text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={onClickTogglePayMethodModal}>돌아가기</button>
                            <button className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-black text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={goPaymentMethodRegister}>등록하기</button>
                        </div>
                    </div>
                </AlertModal>}

                {isCompleteAlertModal &&
                    <AlertModal>

                        <div className={`flex flex-col items-center justify-center`}>
                            <div className={`text-[16px] md:text-[28px] mt-40pxr md:mt-66pxr text-black font-semibold `}>심사 제출 완료</div>

                            <div className={`text-[10px] md:text-[17px] mt-65pxr md:mt-90pxr text-gray-400 font-semibold`}>'프로필 {">"} 나의 의뢰 내역'에서</div>
                            <div className={`text-[10px] md:text-[17px] text-gray-400 font-semibold`}>의뢰를 확인하실 수 있습니다.</div>

                            <div className={`flex flex-row mt-30pxr md:mt-50pxr`}>
                                <button className={`mr-8pxr md:mr-12pxr w-243pxr md:w-300pxr h-36pxr md:h-45pxr bg-black text-white text-[14px] md:text-[25px] rounded font-bold`} onClick={onClickCompleteToggleModal}>확인</button>

                            </div>
                        </div>

                    </AlertModal>
                }

            </div>
        </section>
    )
}
