import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse, AxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";
import {
    IAdminMegaphoneDetailRequest, IAdminMegaphoneDetailRequestResponse,
    IAdminMegaphoneRequest,
    IAdminMegaphoneRequestResponse,
    IAdminUser,
    IAdminWholeMember,
    IAdminWholeMemberResponse
} from "../types";
import {useAdminUserContext} from "../AdminUserProvider";

async function getAdminMegaphoneDetailRequest(
    adminUser: IAdminUser | null,
    megaId:string
): Promise<IAdminMegaphoneDetailRequestResponse> {


    const config: AxiosRequestConfig = {};
    if (adminUser)
        config.headers = { authorization: `${adminUser.accessToken}` }


    const { data } : AxiosResponse<IAdminMegaphoneDetailRequestResponse> = await axiosInstance.get(`/admin/lobby/mega/${megaId}`, config,);


    return data;

}

interface UseAdminMegaphoneDetailRequest {
    megaDetailRequest:IAdminMegaphoneDetailRequest | null
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;

}

export function useAdminMegaphoneDetailRequest(
    megaId:string
): UseAdminMegaphoneDetailRequest {

    const { adminUser,setAdminUser } = useAdminUserContext();

    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
    } = useQuery<IAdminMegaphoneDetailRequestResponse>(
        [queryKeys.adminMegaphoneDetail,megaId],
        () => getAdminMegaphoneDetailRequest(adminUser,megaId),{

        }
    )





    useEffect(() => {

        queryClient.prefetchQuery([queryKeys.adminMegaphoneDetail,megaId],
            () => getAdminMegaphoneDetailRequest(adminUser, megaId),
        )
    },[queryClient,megaId])



    const megaDetailRequest = data?.row || null


    return {
        megaDetailRequest,
        isLoading,
        isFetching,
        isError,
        error,

    };
}