import type {FC,DetailedHTMLProps,HTMLAttributes, PropsWithChildren} from "react";

import React, {ChangeEvent, useState} from "react";
import {useRequestDetailHistory} from "../../hooks/history/useRequestDetailHistory";
import {formatCurrency, regaxNormal, timeAgo} from "../../utils/data/helper";
import {Modal} from "../../components/common/Modal";
import {useAdminMegaphoneDetailRequest} from "../hooks/useAdminMegaphoneDetailRequest";
import {Button} from "react-query/types/devtools/styledComponents";
import {useAdminMegaApprove} from "../hooks/useAdminMegaApprove";
import {useAdminMegaReject} from "../hooks/useAdminMegaReject";
import {CommentRequestFormData} from "../../types/types";
import {AdminRejectFormData} from "../types";


export type AdminMegaphoneDetailModalProps = {
    onClickToggleRequestDetailModal:() => void
    megaId:string
    pageIndex:number
}

export const AdminMegaphoneDetailModal : FC<AdminMegaphoneDetailModalProps> = ({onClickToggleRequestDetailModal,megaId,pageIndex}) => {
    const {
        megaDetailRequest
    } = useAdminMegaphoneDetailRequest(megaId)



    const handleRejectMsg = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        updateFields({rejectMsg:inputValue})

    }
    const INITIAL_DATA: AdminRejectFormData = {
        rejectMsg: megaDetailRequest?.rejectMsg || "",
        megaId:megaId,
    }
    const [rejectData, setRejectData] = useState(INITIAL_DATA)
    const updateFields = (fields: Partial<AdminRejectFormData>) => {
        setRejectData(prev => {
            return { ...prev, ...fields }
        })
    }

    const approveMegaphone = useAdminMegaApprove(() => onClickToggleRequestDetailModal(),pageIndex)
    const rejectMegaphone = useAdminMegaReject(() => onClickToggleRequestDetailModal(),pageIndex)
    return (
        <Modal className="h-350pxr w-300pxr md:w-650pxr md:h-650pxr overflow-y-scroll " onCloseIconClicked={onClickToggleRequestDetailModal}>
            <div className="flex flex-col px-17pxr md:mx-20pxr mt-28pxr text-[17px] md:text-[25px] text-black font-semibold ">

                의뢰 내용 보기
                <div className={`text-[13px] md:text-[18px] font-semibold mt-20pxr md:mt-35pxr`}>
                    생성일 : {timeAgo(megaDetailRequest?.createdAt || "")}
                </div>
                <div className={`text-[13px] md:text-[18px] font-semibold mt-20pxr md:mt-35pxr`}>
                    수정일 : {timeAgo(megaDetailRequest?.updatedAt || "")}
                </div>
                <div className={`text-[13px] md:text-[18px] font-semibold mt-20pxr md:mt-35pxr`}>
                    확성기 상태 : {megaDetailRequest?.megaStatus}
                </div>


                <div className={`flex flex-row items-end text-[15px]  mt-20pxr md:mt-35pxr md:text-[18px] font-bold `}>
                    <div className={`text-[12px] md:text-[18px] mr-5pxr`}>의뢰인 닉네임</div>
                </div>
                <div className={` md:h-90pxr md:w-550pxr mt-10pxr md:mt-12pxr`}>
                    <textarea value={megaDetailRequest?.clientNickName} readOnly={true} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[20px] text-black placeholder:text-gray-300 rounded-xl`} rows={1} />
                </div>

                <div className={`text-[13px] md:text-[18px] font-bold mt-20pxr md:mt-35pxr`}>
                    자기소개 / 찾는사람 / 요구사항
                </div>
                <div className={`h-94pxr w-341pxr md:h-300pxr md:w-550pxr mt-8pxr md:mt-12pxr`}>
                    <textarea value={megaDetailRequest?.content}
                              readOnly={true}
                              className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr h-300pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl overflow-y-scroll  `}

                    />
                </div>
                <div className={`flex flex-row items-end  font-bold mt-20pxr md:mt-45pxr`}>
                    <div className={`text-[12px] md:text-[18px] md:mr-5pxr`}>업무비</div>
                    <div className={`text-[8px] md:text-[15px]`}>(소개받으신 분께 자문을 구하거나 업무를 부탁드리는 경우 지급되는 보수입니다)</div>

                </div>
                <div className={` md:h-90pxr md:w-500pxr mt-10pxr md:mt-12pxr`}>
                   <textarea
                       value={megaDetailRequest?.businessPrice}
                       readOnly={true}
                       className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl overflow-y-scroll md:overflow-scroll `}

                   />
                </div>
                <div className={`text-[12px] md:text-[18px] mt-15pxr font-bold `}>
                    의뢰인의 연락처
                </div>
                <div className={` md:h-90pxr md:w-500pxr mt-10pxr md:mt-12pxr`}>
                    <textarea value={megaDetailRequest?.contact} readOnly={true} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl`} rows={1}  />
                </div>

                <div className={`flex flex-row items-end text-[15px] mt-20pxr md:mt-0pxr md:text-[18px] bfont-bold `}>
                    <div className={`text-[12px] md:text-[18px] mr-5pxr`}>소개비</div>
                    <div className={`text-[8px] md:text-[12px]`}>(소개해주는 로비스트에게 지급되는 보수입니다)</div>
                </div>
                <div className={` md:h-90pxr md:w-550pxr mt-10pxr md:mt-12pxr`}>
                    <textarea value={formatCurrency(megaDetailRequest?.introducePrice ?? 0)} readOnly={true} className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr p-12pxr bg-white text-[12px] md:text-[20px] text-black placeholder:text-gray-300 rounded-xl`} rows={1} />
                </div>

                <div className={`text-[13px] md:text-[18px] font-bold mt-20pxr md:mt-35pxr`}>
                    반려 사유
                </div>
                <div className={`h-94pxr w-341pxr md:h-300pxr md:w-550pxr mt-8pxr md:mt-12pxr`}>
                    <textarea value={rejectData.rejectMsg}
                              onChange={handleRejectMsg}
                              placeholder="반려사유를 적어주시고 반려 버튼을 눌러주세요"
                              className={`resize-none border border-2 border-[#707070]  w-350pxr md:w-550pxr h-300pxr p-12pxr bg-white text-[12px] md:text-[18px] text-black placeholder:text-gray-300 rounded-xl overflow-y-scroll  `}
                    />
                </div>


                <div className={`flex flex-row items-center justify-center my-25pxr md:my-64pxr`}>
                    <button className={`mr-8pxr md:mr-12pxr w-109pxr md:w-150pxr h-36pxr md:h-45pxr bg-blue-500 text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={() => approveMegaphone(megaId)}>승인</button>
                    <button disabled={!rejectData.rejectMsg} className={`md:w-150pxr w-109pxr md:h-45pxr h-36pxr bg-red-500 text-white text-[14px] md:text-[18px] rounded font-bold`} onClick={() => rejectMegaphone(rejectData)}>반려</button>
                </div>


            </div>

        </Modal>

    )
}