import {AlertModal, Avatar, DivProps, HashTag, Paragraph, Subtitle, Title} from "../common";
import {FC, useCallback, useState} from "react";
import {Modal} from "../common/Modal";
import {useNavigate, useParams} from "react-router-dom";
import {formatCurrency, timeAgo} from "../../utils/data/helper";
import {IPost, IUser} from "../../types/types";
import {useUser} from "../../hooks/common/useUser";


export type CommunityPostProps = DivProps & {
    post: IPost
    user: IUser | null
}

export const CommunityPost: FC<CommunityPostProps> = ({className: _className,user,post,...props }) =>{
    const navigate = useNavigate()

    const goPost = useCallback((postId:string)=>{
        window.scroll(0,0)
        navigate(`/community/detail/${postId}`,{
        })
    },[navigate])
    const goLoginPage = useCallback(()=>{
        window.scroll(0,0)
        navigate(`/login`,{
        })
    },[navigate])
    return (
        <section key={props.key} >
            <div className={`divide-y-2`}>
                <div className={`flex flex-col pt-27pxr md:pt-20pxr w-340pxr md:w-800pxr`}>
                    <button onClick={() => goPost(post._id)}>
                        <div className={`flex flex-row justify-between mb-6pxr md:mb-20pxr`}>
                            <div className="text-[12px] w-300pxr md:h-25pxr md:w-700pxr text-left md:text-[18px] font-extrabold text-black line-clamp-1 whitespace-pre-wrap ">{post.title}</div>
                            {(post.status !== "proceed" ) &&(
                                <button className="w-46pxr h-20pxr md:w-93pxr md:h-35pxr rounded md:rounded-lg bg-[#dddddd] text-[8px] md:text-[15px] text-center font-bold text-[#434343]">의뢰마감</button>
                            )}

                        </div>
                        <div className="flex flex-row justify-end mt-20pxr md:mt-0pxr text-[12px] md:text-[18px] font-extrabold text-black">
                            소개비 {formatCurrency(post.introducePrice)}원
                            {post.introducePrice === 0 && " (소개 나눔 요청)"}
                        </div>
                        <div className="w-300pxr h-15pxr md:h-25pxr md:w-700pxr mt-10pxr md:mt-0pxr text-left  text-[12px] md:text-[18px] font-semibold text-[#696969] line-clamp-1 whitespace-pre-wrap break-words">
                            {post.content}
                        </div>
                        <div className="flex flex-row mt-15pxr md:mt-20pxr mb-12pxr md:mb-15pxr justify-between text-[12px] md:text-[15px] font-semibold text-[#696969] line-clamp-1">
                            <div className="flex flex-row items-center">
                                {/*<img className="h-12pxr w-12pxr md:h-15pxr md:w-15pxr mr-5pxr md:mr-10pxr" src="/air.png"/>*/}
                                {/*{post.replyCount}*/}
                            </div>
                            <div className="text-[10px] md:text-[13px] font-semibold text-[#bcbcbc]">
                                {timeAgo(post.createdAt)}
                            </div>
                        </div>
                    </button>
                </div>
                <div></div>
            </div>



        </section>
    )
}
