import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery} from "react-query";
import axios,{AxiosResponse, AxiosRequestConfig} from "axios";
import {useEffect, useState} from "react";
import {
    IAdminCommunityBanner,
    IAdminCommunityBannerResponse,
    IAdminUser,
    IAdminWholeMember,
    IAdminWholeMemberResponse
} from "../types";
import {useAdminUserContext} from "../AdminUserProvider";

async function getAdminCommunityDetailBanner(
    adminUser: IAdminUser | null,
    bannerId:string
): Promise<IAdminCommunityBannerResponse> {


    const config: AxiosRequestConfig = {};
    if (adminUser){
        config.headers = { authorization: `${adminUser.accessToken}` }

    }

    const { data } : AxiosResponse<IAdminCommunityBannerResponse> = await axiosInstance.get(`/admin/banner/${bannerId}`, config,);


    return data;

}

interface UseAdminCommunityDetailBanner {
    banner:IAdminCommunityBanner | undefined
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    error: unknown;
}

export function useAdminCommunityDetailBanner(
    bannerId:string

): UseAdminCommunityDetailBanner {

    const { adminUser,setAdminUser } = useAdminUserContext();
    const [nickName, setNickName] = useState("");
    const [signupStartedAt, setSignupStartedAt] = useState<Date | null>(null);
    const [signupEndedAt, setSignupEndedAt] = useState<Date | null>(null);
    const [pageIndex, setPageIndex] = useState(1)
    const {
        data,
        isLoading,
        isError,
        error,
        isFetching,
    } = useQuery<IAdminCommunityBannerResponse>(
        [queryKeys.adminCommunityBanner,bannerId],
        () => getAdminCommunityDetailBanner(adminUser,bannerId),{

        }
    )


    const banner = data?.row || undefined


    return {
        banner,
        isLoading,
        isFetching,
        isError,
        error,

    };
}