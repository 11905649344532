import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IPost, IPostResponse, IUser, IUserInformation, IUserInformationResponse} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {queryKeys} from "../../services/react-query/constants";
import {setStoredUser} from "../../utils/user-storage";
import {queryClient} from "../../services/react-query/queryClient";
import {useInfiniteQuery, useQuery, useQueryClient} from "react-query";
import axios,{AxiosResponse} from "axios";
import {useUserContext} from "../common/UserContext";

export async function getUserInformation(user:IUser|null): Promise<IUserInformationResponse | null> {


    const config: any = {};
    if (user) {
        config.headers = getJWTHeader(user);
    }
    const { data } : AxiosResponse<IUserInformationResponse> = await axiosInstance.get('/user/member/info', config,);
    return data;

}

interface UseUserInformation {
    userInformation: IUserInformation;
    isLoading:boolean
}

const defaultUserInformation: IUserInformation = {
    _id:"",
    email:"",
    nickName:"",
    profileImage:"",
    bLobbyist:false,
    MBTI:"",
    phone:"",
    keywords:[],
    accountInfo:{
        bankName:"",
        accountHolder:"",
        accountNumber:""
    },
    cardInfo:[],
    additionalInformation:[],
    jobGroup:0,
    lobbyCount:0,
    reviewCount:0,
    completeCount:0,
    givingCount:0

}


export function useUserInformation() :UseUserInformation{
    const { user, updateUser, clearUser } = useUserContext();
    const { data, isLoading } = useQuery(
        [queryKeys.userInformation],
        () => getUserInformation(user),
        {
            onError: () => {
                queryClient.invalidateQueries([queryKeys.user])
            },
            enabled: !!user,
        }
    )
    const userInformation = data?.row || defaultUserInformation
    return {userInformation, isLoading}
}