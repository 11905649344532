import {Route, Routes, useLocation} from "react-router-dom";
import Main from "../components/main/Main";
import RequestProcess from "../components/requests/RequestProcess";
import MyProfile from "../components/myprofile/MyProfile";
import MyNetwork from "../components/mynetwork/MyNetwork";
import Megaphone from "../components/megaphone/Megaphone";
import History from "../components/history/History";
import PaymentMethodView from "../components/payment/PaymentMethodView";
import PaymentRegisterCardView from "../components/payment/PaymentRegisterCardView";
import PaymentRegisterAccountView from "../components/payment/PaymentRegisterAccountView";
import Community from "../components/community/Community";
import CommunityPostView from "../components/community/CommunityPostView";
import CommunityPostRegisterView from "../components/community/CommunityPostRegisterView";
import LoginPage from "../components/auth/LoginPage";
import SignUp from "../components/auth/SignUp";
import MegaphoneRequestProcess from "../components/megaphone/MegaphoneRequestProcess";
import ReceivedRequests from "../components/received-requests/ReceivedRequests";
import MegaphoneDetailHistory from "../components/history/MegaphoneDetailHistory";
import TermsOfUseView from "../components/guideline/TermsOfUseView";
import PrivacyPolicyView from "../components/guideline/PrivacyPolicyView";
import UserProtectionView from "../components/guideline/UserProtectionView";
import UserGuideView from "../components/guideline/UserGuideView";
import CommunityPostModifyView from "../components/community/CommunityPostModifyView";
import {NotFound404View} from "../components/common/NotFound404View";
import PaymentView from "../components/payment/PaymentView";
import ReactGA from "react-ga4";
import React, {useEffect} from "react";
import AdminMain from "../admin/AdminMain";
import {Admin} from "../admin/Admin";
import ChatMain from "../components/chat/ChatMain";
import {LobbyChat} from "../components/chat/LobbyChat";
import MyReputationView from "../components/main/MyReputationView";

export default function RoutesSetup(){
    const location = useLocation();

    useEffect(() => {
        ReactGA.send('pageview');
    }, [location]);

        return(
            <Routes>
                    <Route path="/request" element={<Main/>} />
                    <Route path="/" element={<Community/>} />
                    {/*<Route path="/request" element={<RequestProcess/>} />*/}
                    <Route path="/auth" element={<SignUp/>} />
                    <Route path="/login" element={<LoginPage/>} />
                    <Route path="/profile" element={<MyProfile/>} />
                    <Route path="/network" element={<MyNetwork/>} />
                    <Route path="/megaphone" element={<Megaphone/>} />
                    <Route path="/megaphone/request" element={<MegaphoneRequestProcess/>} />
                    {/*<Route path="/request/history" element={<History/>} />*/}
                    {/*<Route path="/megaphone/history" element={<MegaphoneDetailHistory />} />*/}
                    <Route path="/payment/manage" element={<PaymentView/>} />
                    <Route path="/payment/register/card" element={<PaymentRegisterCardView/>} />
                    <Route path="/payment/register/account" element={<PaymentRegisterAccountView/>} />
                    {/*<Route path="/community" element={<Community/>} />*/}
                    {/*    id 추가해서 글 볼 수 있도록 */}
                    <Route path="/community/detail/:postId" element={<CommunityPostView/>} />
                    <Route path="/community/register" element={<CommunityPostRegisterView/>} />
                    <Route path="/community/modify/:postId" element={<CommunityPostModifyView/>} />
                    <Route path="/received" element={<ReceivedRequests/>} />

                    <Route path="/termsofview" element={<TermsOfUseView/>} />
                    <Route path="/privacypolicy" element={<PrivacyPolicyView/>} />
                    <Route path="/userprotection" element={<UserProtectionView/>} />
                    <Route path="/userguide" element={<UserGuideView/>} />

                    <Route path="/*" element={<NotFound404View/>} />
                    <Route path="/administrator/main" element={<Admin/>} />
                    <Route path="/chatmain" element={<ChatMain/>}/>
                    <Route path="/chat/:roomId" element={<LobbyChat/>}/>
                    <Route path="/review" element={<MyReputationView/>}/>
            </Routes>
        )
}