import {Avatar, DivProps, HashTag, Paragraph, Subtitle, Title} from "./index";
import React, {FC, ReactElement, useCallback, useState} from "react";
import {ILobbyist, IUserInformation} from "../../types/types";
import {formatCurrency} from "../../utils/data/helper";
import {useUser} from "../../hooks/common/useUser";
import {useUserContext} from "../../hooks/common/UserContext";
import {useNavigate} from "react-router-dom";

export type MyCardProps = DivProps & {
    userInformation: IUserInformation
}

export const MyCard: FC<MyCardProps> = ({className: _className,userInformation,...props }) =>{

    const {user} = useUserContext()


    const filteredPrices = userInformation.keywords
        ? userInformation.keywords.filter(keyword => keyword.price > 0)
        : [];

    const leastPrice = filteredPrices.length > 0
        ? Math.min(...filteredPrices.map(keyword => keyword.price))
        : 0;



    const navigate = useNavigate()


    const goMyNetwork = useCallback(() =>{
        window.scrollTo(0, 0);
        navigate("/network")
    },[navigate])

    const hashTags = userInformation.keywords ? userInformation.keywords.map((keyword,index) =>{


        return(
            <section key={index}>
                <div className="relative inline-block group">
                    {(keyword) &&
                        <>
                    <span className="cursor-pointer">
                        <HashTag className="text-[#696969] bg-white" key={keyword.word} hashTag={keyword.word}/>
                    </span>
                            <div className="hidden group-hover:block absolute left-0 w-170pxr md:w-300pxr text-gray-700 rounded-xl bg-[#eaeaea] z-50">
                                <div className="flex flex-col text-[10px] md:text-[13px] text-[#555555] font-semibold px-15pxr py-12pxr">
                                    <div>| 소개비: {keyword.price ? formatCurrency(keyword.price) : "0"}원</div>
                                    <div>| 설명: {keyword.detail}</div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>
        )
    }) : []

    return (
        <section key={userInformation._id} className="relative p-10pxr">
            <div className={`flex flex-col justify-start pl-5pxr md:pl-20pxr min-h-[145px] md:min-h-[180px] w-340pxr md:w-420pxr border border-1 rounded-xl overflow-visible shadow-md `}>
                <div className="flex flex-row justify-between ">
                    <div className="flex items-center justify-center h-5pxr md:h-13pxr"/>

                </div>
                <div className={`flex flex-row  m-10pxr md:m-0pxr`}>
                    <div className={`flex flex-col items-start w-full`}>
                        <div className="flex flex-row justify-between items-start w-full md:mr-10pxr">
                            <div className="md:text-[20px] font-extrabold">나의 인맥</div>
                            <div>
                                <button className="relative" onClick={goMyNetwork}>
                                    <img src="/add_network.png" className="w-80pxr h-30pxr md:w-100pxr md:h-36pxr mr-5pxr md:mr-10pxr"/>
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/3 -translate-y-1/2 text-[10px] md:text-[14px] text-white font-bold">인맥 등록</div>
                                </button>
                            </div>
                        </div>
                        <div className={`flex flex-row items-center md:items-end w-258pxr  md:w-380pxr`}>
                            <div className={`flex flex-row  text-black `}>
                                <Subtitle numberOfLines={1}>{userInformation.nickName}</Subtitle>
                            </div>
                            <div className="text-[13px] font-semibold ml-3pxr md:ml-5pxr  text-black">
                                | {formatCurrency(leastPrice)}원 ~
                            </div>
                        </div>
                        <div className="my-8pxr md:my-12pxr mr-15pxr md:w-380pxr  flex flex-wrap flex-row">
                            {hashTags}
                        </div>
                    </div>
                </div>


            </div>
        </section>
    )
}
