import {useNavigate} from "react-router-dom";
import React, {useCallback, useState} from "react";
import {useReceivedRequest} from "../../hooks/received-requests/useReceivedRequest";
import {ReceivedRequestCard} from "./ReceivedRequestCard";
import {useReceivedMegaphoneRequest} from "../../hooks/received-requests/useReceivedMegaphoneRequest";
import {ReceivedMegaphoneRequestCard} from "./ReceivedMegaphoneRequestCard";

export default function ReceivedRequestIntroduce(){
    const {receivedRequests} = useReceivedRequest()
    const {receivedMegaphoneRequests} = useReceivedMegaphoneRequest()
    const navigate = useNavigate()
    const goBack = useCallback(() =>{
        navigate(-1)
    },[navigate])


    const [searchLobbyistWord, setSearchLobbyistWord] = useState<string>("")

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.trim();
        if (/^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s!@#$%^&*()_+-={};':"\|,.<>/?]{0,300}$/.test(inputValue)) {
            setSearchLobbyistWord(inputValue)
        }

    };

    const receivedRequestCards = receivedRequests && receivedRequests
        .filter((request) => request.clientNickName.includes(searchLobbyistWord))
        .map((receivedRequest) =>{
        return(
            <ReceivedRequestCard receivedRequest={receivedRequest}  key={receivedRequest._id}/>
        )
    })

    const receivedRequestMegaphoneCards = receivedMegaphoneRequests && receivedMegaphoneRequests
        .filter((request) => request.clientNickName.includes(searchLobbyistWord))
        .map((receivedMegaphoneRequest) =>{
        return(
            <ReceivedMegaphoneRequestCard receivedRequest={receivedMegaphoneRequest}  key={receivedMegaphoneRequest._id}/>
        )
    })


    const [isFromMegaphone,setIsFromMegaphone] = useState<boolean>(false)
    const megaphoneViewChange = useCallback(() => {
        setIsFromMegaphone(isFromMegaphone => !isFromMegaphone)
    },[isFromMegaphone])

    return (
        <section>

                <div className={`flex flex-col items-center mt-23pxr md:mt-50pxr`}>

                    <div className="w-screen ml-18pxr  md:w-1/2">
                        <button>
                            <img src="/back.png" className={`w-8pxr h-16pxr md:w-15pxr md:h-30pxr`} onClick={goBack}/>
                        </button>
                    </div>
                    <div className={`flex flex-col items-center `}>
                        <div className={`text-[15px] md:text-[30px] text-black font-extrabold`}>띵동! 의뢰 도착</div>
                        <div className={`flex flex-row items-center`}>
                            <input onChange={handleSearchChange} className={`p-10pxr md:p-20pxr h-30pxr md:h-50pxr w-340pxr md:w-500pxr mt-22pxr md:mt-42pxr rounded-lg border border-gray-500 text-[9px] md:text-[15px] focus:outline-none  bg-white placeholder:text-[#bdbdc0]`} placeholder={`로비스트의 닉네임을 입력하세요`} type={`search`}/>
                            <button className={`-ml-40pxr md:-ml-68pxr mt-22pxr md:mt-42pxr rounded-lg bg-white h-15pxr md:h-38pxr w-30pxr md:w-62pxr flex items-center justify-center`}><img className={`h-15pxr md:h-32pxr w-18pxr md:w-38pxr`} src={`../../light-search.png`}/></button>
                        </div>
                    </div>

                    <div className="flex flex-row items-center justify-center w-340pxr md:w-500pxr mb-15pxr mt-25pxr  md:mt-40pxr">
                        {isFromMegaphone ? (
                            <div className="flex flex-row justify-between space-x-30pxr md:space-x-103pxr ">
                                <button onClick={megaphoneViewChange}>
                                    <div className="text-[13px] md:text-[20px] text-[#b5b5b5] font-extrabold">1:1 요청</div>
                                </button>
                                <div className="flex flex-col items-center justify-center">
                                    <div className="text-[13px] md:text-[20px]  text-black font-extrabold">확성기</div>
                                    <div className="relative top-4pxr md:top-5pxr h-1pxr w-35pxr md:h-3pxr md:w-50pxr bg-black rounded-xl"/>
                                </div>
                            </div>
                        ):(
                            <div className="flex flex-row justify-between space-x-30pxr md:space-x-103pxr ">
                                <div className="flex flex-col items-center justify-center">

                                    <div className="text-[13px] md:text-[20px]  text-black font-extrabold">1:1 요청</div>
                                    <div className="relative top-4pxr md:top-5pxr h-1pxr w-35pxr md:h-3pxr md:w-60pxr bg-black rounded-xl"/>
                                </div>
                                <button onClick={megaphoneViewChange}>
                                    <div className="text-[13px] md:text-[20px]  text-[#b5b5b5] font-extrabold">확성기</div>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row items-center justify-center w-340pxr md:w-690pxr">

                        <div className={"flex flex-col items-center mt-15pxr md:mt-50pxr"}>
                            {isFromMegaphone ? receivedRequestMegaphoneCards : receivedRequestCards}
                        </div>
                    </div>


                </div>
        </section>
    )
}