import {axiosInstance, getJWTHeader} from "../../services/axiosInstance";
import {IChatList, IChatListResponse, ILobbyist, ILobbyistResponse, IRoomResponse, IUser} from "../../types/types";
import {getAccessTokenWithMutex, useUser} from "../common/useUser";
import {setStoredUser} from "../../utils/user-storage";
import {useUserContext} from "../common/UserContext";
import {useNavigate} from "react-router-dom";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

async function getRoomId(user: IUser | null, lobbyistId:string): Promise<IRoomResponse | null> {
    const config: AxiosRequestConfig = {};

    if (user){
        config.headers = getJWTHeader(user);
    }

    const { data }: AxiosResponse<IRoomResponse> = await axiosInstance.get(`/user/chat/roomId/${lobbyistId}`, config);
    console.log(data.row.roomId);
    return data;
}

export function useChatRoomProvider() {
    const { user, updateUser, clearUser } = useUserContext();
    const navigate = useNavigate();

    const fetchRoomIdAndNavigate = async (lobbyistId: string, partnerProfileImage:string, partnerNickname:string) => {
        const data = await getRoomId(user, lobbyistId);
        const roomId = data?.row.roomId;
        window.scroll(0,0)
        navigate(`/chat/${roomId}`,{
            state: {
                imagePath: partnerProfileImage,
                partnerNickname: partnerNickname,
            }
        })
    };

    return { fetchRoomIdAndNavigate };
}
