import {FC} from "react";
import {useAdminUserContext} from "./AdminUserProvider";
import {clearStoredAdminUser} from "./hooks/useAdminUser";


export const SideMenu: FC<{ setActiveScreen: (screen: string) => void }> = ({ setActiveScreen }) => {

    const { adminUser, setAdminUser } = useAdminUserContext();

    return (
        <div className="py-2 space-y-50pxr">
            <h2 className="font-bold text-xl">관리자</h2>
            <h2 className="font-bold text-xl">컨텐츠 관리</h2>
            <div className="flex flex-col items-start pl-4 space-y-25pxr">
                <button onClick={() => setActiveScreen("회원관리")}>회원관리</button>
                <button onClick={() => setActiveScreen("일반 의뢰 관리")}>일반 의뢰 관리</button>
                <button onClick={() => setActiveScreen("확성기 의뢰 관리")}>확성기 의뢰 관리</button>
                {/*<button onClick={() => setActiveScreen("데이터 수집")}>데이터 수집</button>*/}
                <button onClick={() => setActiveScreen("배너 관리")}>배너 관리</button>
                {/*<button onClick={() => setActiveScreen("약관 관리")}>약관 관리</button>*/}
                {/*<button onClick={() => setActiveScreen("결제/정산 히스토리")}>결제/정산 히스토리</button>*/}
            </div>
            <button onClick={() => {
                setAdminUser(null)
                clearStoredAdminUser()
            }}>
                로그아웃
            </button>
        </div>
    );
};
