import {DivProps} from "../common/Div";
import {FC} from "react";
import {ILobbyistReview} from "../../types/types";
import {Image} from "../common/Image";
import {timeAgo} from "../../utils/data/helper";

export type CommentProps = DivProps & {
    review: ILobbyistReview
}

export const Comment: FC<CommentProps> = ({className: _className,review, ...props }) => {
    return (
        <div key={props.key} className={`flex flex-row items-start justify-between mb-20pxr  md:mb-40pxr mr-5pxr md:mr-10pxr`}>
            <div className={`flex flex-row  items-center`}>
                <Image className="rounded-xl bg-cover bg-white border h-40pxr w-40pxr" src={review.clientProfileImage}/>
                <div className={`font-semibold  text-[11px] md:text-[13px]  ml-6pxr md:ml-12pxr text-left text-black`}>{review.clientNickName}</div>

            </div>
            <div className={`flex flex-col items-center justify-end`}>

                <div className={`flex flex-row items-start  text-[10px] md:text-[13px] w-230pxr md:w-400pxr text-[#696969] md:ml-12pxr`}>
                    <div>

                    {review.content}<a className={`ml-15pxr  text-[7px] text-[#d2d2d2] md:text-[10px]`}>{timeAgo(review.createdAt)}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}